import { butterflyAxios } from '@/api/axios'
import {
  codeSnippetTypesListMap,
  codeSnippetTypeNamesListMap
} from './responseMapper'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'

var endpoint = {
  codeSnippetTypes: 'code-snippet-types'
}

async function apiGetCodeSnippetTypes() {
  let response = await butterflyAxios.get(endpoint.codeSnippetTypes, {
    params: {}
  })

  if (!isOKResponse(response)) {
    throw response
  }

  let data
  data = parseSuccessResponse(response)

  // Convert from JSON array to the expected mapping
  return codeSnippetTypesListMap(data.response)
}

async function apiGetCodeSnippetNames() {
  let response = await butterflyAxios.get(endpoint.codeSnippetTypes, {
    params: {}
  })

  if (!isOKResponse(response)) {
    throw response
  }

  let data
  data = parseSuccessResponse(response)

  // Convert from JSON array to the expected mapping
  return codeSnippetTypeNamesListMap(data.response)
}

export { apiGetCodeSnippetTypes, apiGetCodeSnippetNames }
