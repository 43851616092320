<template>
  <inline-svg :src="bigquery" class="bigquery" />
</template>

<script>
import InlineSvg from 'vue-inline-svg'
export default {
  name: 'BigQueryIcon',
  components: {
    InlineSvg
  },
  created() {
    this.bigquery = require('@/assets/bigquery.svg')
  }
}
</script>

<style>
.bigquery {
  fill: currentColor;
}
</style>
