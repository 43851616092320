import { apiCreate, apiPreview, apiUpdate } from '@/api/push-notification'

async function create(_, { message }) {
  return apiCreate(message)
}

async function update(_, { message }) {
  return apiUpdate(message)
}

async function preview(_, payload) {
  return apiPreview(payload)
}

export default {
  create,
  update,
  preview
}
