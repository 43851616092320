import { butterflyAxios } from '@/api/axios'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'
import { tabListMap } from '@/api/tabs/responseMapper'

const endpoints = {
  tabs: `newsfeed/tabs`
}

async function apiGetTabs(payload) {
  let response = await butterflyAxios.get(endpoints.tabs, {
    params: payload
  })

  if (isOKResponse(response)) {
    const data = parseSuccessResponse(response)

    // Convert from JSON array to the expected mapping
    return tabListMap(data.response)
  }

  throw response
}

export { apiGetTabs }
