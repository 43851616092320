/**
 * Signals Vuex that the code snippet types have changed.
 * @param {vuexState} state
 * @param {*} codeSnippet The new codes snippet types.
 */
function CODE_SNIPPET_TYPES_UPDATED(state, codeSnippetTypes) {
  state.codeSnippetTypes = codeSnippetTypes
}

/**
 * Signals Vuex that the channels names have changed.
 * @param {vuexState} state
 * @param {*} channels The new channels.
 */
function SNIPPET_NAMES_CACHED(state, list) {
  state.snippetNames = list
}

export default {
  CODE_SNIPPET_TYPES_UPDATED,
  SNIPPET_NAMES_CACHED
}
