export const defaultKnown = ['cas_id', 'cod_cas_id']
export const defaultUnknown = [
  'device_id',
  'device_id_android',
  'active_device_id_android',
  'device_id_ios',
  'active_device_id_ios'
]

export const SYNC_STATUS = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
  PROCESSING: 'processing'
}
