import { apiGetCountryNames, apiGetCurrencies } from '@/api/countries'
import CacheSystem from '@/utils/cacheSystem'

/**
 * Fetches the countries from the API, firing
 * any necessary logic associated (such as caching).
 * @param {*} context Vuex context (auto-injected).
 * @param {*} payload The payload to send to the API.
 * @param {*} useCache Whether to use the cache (default is true).
 */
async function fetchCountryNames(context, payload, useCache = true) {
  let cacheSystem = new CacheSystem(useCache)
  cacheSystem.implementGetCacheFunction(() => {
    let result
    if (context.state.countryNames) {
      result = context.state.countryNames
    }
    return result
  })

  cacheSystem.implementSetCacheFunction(async () => {
    let result = await apiGetCountryNames()
    let commit = context.commit
    commit('COUNTRY_NAMES_CACHED', result)
    return result
  })
  cacheSystem.implementOnSetCacheErrorFunction(() => {
    //
  })
  return cacheSystem.execute()
}

/**
 * Fetches a list of currencies
 * @returns {Promise<{responseType: string, response: *, success: boolean, status: *}|AxiosResponse<any>>}
 */
async function fetchCurrencies({ commit }) {
  let currencies = await apiGetCurrencies()
  commit('CURRENCIES_CACHED', currencies)
}

export default {
  fetchCountryNames,
  fetchCurrencies
}
