import { apiGetTabs } from '@/api/tabs'

/**
 * Fetches the tabs from the BE and
 * updates the vuex state accordingly.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} payload The payload to send to the API (query).
 */
async function fetchTabs({ commit }, payload) {
  let response = await apiGetTabs(payload)
  commit('TABS', response)
}

export default {
  fetchTabs
}
