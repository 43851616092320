import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  channels: null,
  channelRates: null,
  finalDataSize: 0
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
