import { setAxiosXAuthToken } from '@/api/axios'
import { USER_CONSTANTS } from '@/store/user/constants'

/**
 * Updates the permissions of a given user.
 * @param {*} param0 The commit object.
 * @param {*} param1 The permissions to update
 */
async function updatePermissions({ commit }, { permissions }) {
  // Prepare a hash-map version of the permissions
  try {
    let permissionsMap = { application: {}, targets: {} }
    permissions.application.forEach(e => {
      permissionsMap.application[e] = true
    })

    for (const t in permissions.targets) {
      permissionsMap.targets[t] = permissions.targets[t].reduce(
        (prev, curr) => {
          prev[curr] = true
          return prev
        },
        {}
      )
    }

    commit('PERMISSIONS_UPDATED', permissionsMap)
  } catch (e) {
    console.debug(e)
  }
}

/**
 * Updates the targets of a given user.
 * @param {*} param0 The commit object.
 * @param {*} param1 The permissions to update
 */
async function updateTargets({ commit }, { targets }) {
  commit('TARGET_UPDATED', targets)
}

/**
 * Updates the tokens of the user (auth & refresh token).
 * @param {*} param0 The commit object.
 * @param {*} param1 The token data for the update.
 */
async function updateTokens({ commit }, { authToken, refreshToken }) {
  setAxiosXAuthToken(authToken)
  commit('TOKENS_UPDATED', { authToken, refreshToken })
}

/**
 * Updates the information relative to a given user
 * @param {*} param0 The commit object
 * @param {*} param1 The user information
 */
async function updateUserInfo({ commit }, userInfo) {
  const email = userInfo.email
  const picture = userInfo.picture
  const name = userInfo.name
  commit('EMAIL_UPDATED', email)
  commit('PICTURE_UPDATED_LS', picture)
  commit('NAME_UPDATED_LS', name)
}

/**
 * Wipes the user data.
 */
async function clearUserData({ commit }) {
  commit('CLEAR_USER_DATA')
}

/**
 * This method is used at the start of App. It is used to load name and picture set in localStorage to memory
 * @param rootState
 * @param commit
 * @returns {Promise<void>}
 */
async function loadProfile({ rootState, commit }) {
  let name = rootState.app.securedState.get(USER_CONSTANTS.USER)[
    USER_CONSTANTS.NAME
  ]

  if (name) {
    commit('NAME_UPDATED', name)
  }

  let picture = rootState.app.securedState.get(USER_CONSTANTS.USER)[
    USER_CONSTANTS.PICTURE
  ]

  if (picture) {
    commit('PICTURE_UPDATED', picture)
  }
}

export default {
  updatePermissions,
  updateTargets,
  updateTokens,
  updateUserInfo,
  clearUserData,
  loadProfile
}
