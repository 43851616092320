import { apiGetChannelTypes } from '@/api/channelTypes'

/**
 * Fetches the channel types from the BE database and
 * updates the vuex state accordingly.
 * @param {*} param0 Vuex state (auto-injected).
 */
async function fetchChannelTypes({ commit }) {
  var response = await apiGetChannelTypes()
  commit('CHANNEL_TYPES_UPDATED', response)
}

export default {
  fetchChannelTypes
}
