/**
 * Signals Vuex that the channels have changed.
 * @param {vuexState} state
 * @param {*} channels The new channels.
 */
function CHANNELS_UPDATED(state, channels) {
  state.channels = channels
}

/**
 * Signals Vuex that the channels names have changed.
 * @param {vuexState} state
 * @param {*} channels The new channels.
 */
function CHANNEL_NAMES_CACHED(state, list) {
  state.channelNames = list
}
/**
 * Signals Vuex that the channel types have changed.
 * @param {vuexState} state
 * @param {*} channelRates The new channel types.
 * @param finalDataSize
 */
function CHANNEL_RATES_UPDATED(state, { channelRates, finalDataSize }) {
  state.channelRates = channelRates
  state.finalDataSize = finalDataSize
}
export default {
  CHANNELS_UPDATED,
  CHANNEL_NAMES_CACHED,
  CHANNEL_RATES_UPDATED
}
