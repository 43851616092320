/**
 * TBD: REVISION
 */
import { TAG_KEYS } from '@/api/instance-tags/constants'
import moment from 'moment/moment'

// maps FE object to API CREATE request object standards
const instanceMapToApi = instance => {
  return {
    name: instance.name,
    csv_delimiter: instance.csvDelimiter,
    description: instance.description,
    segment_id: instance.segmentId,
    filters: instance.filters,
    export_columns: instance.exportColumns,
    user: instance.user,
    target: instance.target,
    tags: mapTagsToIDs(instance.tags),
    ...syncMallOptions(instance),
    ...syncAirshipOptions(instance)
  }
}

// maps FE object to API UPDATE request object standards
const instanceMapToApiUpdate = instance => {
  return {
    id: instance.id,
    name: instance.name,
    csv_delimiter: instance.csvDelimiter,
    description: instance.description,
    filters: instance.filters,
    custom_fields: instance.customFields,
    export_columns: instance.exportColumns,
    app_vertical: instance.appVertical,
    user: instance.user,
    target: instance.target,
    tags: mapTagsToIDs(instance.tags),
    ...syncMallOptions(instance),
    ...syncAirshipOptions(instance)
  }
}

function mapTagsToIDs(tags) {
  const nTags = []
  for (const t of tags) {
    nTags.push(t.value)
  }
  return nTags
}

function syncMallOptions(instance) {
  let res = {}
  if (
    instance.tags.some(
      t => t.text === TAG_KEYS.SYNC_MALL || t.text === TAG_KEYS.INCENTIVE
    )
  ) {
    res = {
      known_id: instance.knownID,
      unknown_id: instance.unknownID
    }
  }
  if (instance.tags.some(t => t.text === TAG_KEYS.INCENTIVE)) {
    res.sync_mall_end_date = moment(instance.syncMall.endDate)
  }
  return res
}

function syncAirshipOptions(instance) {
  if (instance.tags.some(t => t.text === TAG_KEYS.SYNC_AIRSHIP)) {
    return {
      airship_sync: {
        sync_hour: instance.syncHour,
        end_date: instance.endDate,
        project: instance.project
      }
    }
  }

  return {}
}

export { instanceMapToApi, instanceMapToApiUpdate }
