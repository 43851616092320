import { CHANNEL_KEYWORDS } from '@/api/channels/constants'

function isGrowthTool(message) {
  return message.channel.keyword === CHANNEL_KEYWORDS.GROWTH_TOOL
}

function isPushNotification(message) {
  return message.channel.keyword === CHANNEL_KEYWORDS.PUSH_NOTIFICATION
}

export { isGrowthTool, isPushNotification }
