import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const MEDIA_MODULE = 'media'

export default [
  {
    path: '/media',
    component: loadView('ViewMedia', 'media'),
    children: [
      {
        path: '',
        name: 'media',
        component: loadView('ViewMediaList', 'media'),
        meta: {
          navigationDrawer: true,
          module: MEDIA_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MEDIA
        }
      },
      {
        path: 'new',
        name: 'media-add',
        component: loadView('ViewMediaAdd', 'media'),
        meta: {
          navigationDrawer: true,
          module: MEDIA_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MEDIA,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: ':mediaId',
        name: 'media-edit',
        component: loadView('ViewMediaEdit', 'media'),
        meta: {
          navigationDrawer: true,
          module: MEDIA_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MEDIA,
          whiteBackground: true,
          savePrevRoute: true
        }
      }
    ]
  }
]
