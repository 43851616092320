export default {
  actions: [
    {
      name: 'Home',
      keyword: 'home'
    },
    {
      name: 'Landing Page',
      keyword: 'landing_page'
    },
    {
      name: 'Deep Link',
      keyword: 'deep_link'
    },
    {
      name: 'Web Page',
      keyword: 'web_page'
    },
    {
      name: 'Share',
      keyword: 'share'
    }
  ],
  buttons: [
    {
      name: 'Yes/No',
      keyword: 'ua_yes_no_foreground'
    },
    {
      name: 'Accept/Decline',
      keyword: 'ua_accept_decline_foreground'
    },
    {
      name: 'Shop now',
      keyword: 'ua_shop_now'
    },
    {
      name: 'Buy now',
      keyword: 'ua_buy_now'
    },
    {
      name: 'Follow',
      keyword: 'ua_follow'
    },
    {
      name: 'Unfollow',
      keyword: 'ua_unfollow'
    },
    {
      name: 'Tell me more',
      keyword: 'ua_more_info'
    },
    {
      name: 'Like/Dislike',
      keyword: 'ua_like_dislike'
    },
    {
      name: 'Share',
      keyword: 'ua_share'
    },
    {
      name: 'Remind',
      keyword: 'ua_remind_me_later'
    },
    {
      name: 'Happy/Sad',
      keyword: 'ua_icons_happy_sad'
    }
  ],
  devices: [
    {
      name: 'Android',
      keyword: 'android'
    },
    {
      name: 'iOS',
      keyword: 'ios'
    }
  ],
  actionsWithContent: ['web_page', 'deep_link', 'share', 'landing_page'],
  buttonPreview: value => {
    switch (value) {
      case 'ua_yes_no_foreground':
        return ['Yes', 'No']
      case 'ua_accept_decline_foreground':
        return ['Accept', 'Decline']
      case 'ua_shop_now':
        return ['Shop now']
      case 'ua_buy_now':
        return ['Buy now']
      case 'ua_follow':
        return ['Follow']
      case 'ua_unfollow':
        return ['Unfollow']
      case 'ua_more_info':
        return ['Tell me more']
      case 'ua_like_dislike':
        return ['Like', 'Dislike']
      case 'ua_share':
        return ['Share']
      case 'ua_remind_me_later':
        return ['Remind']
      case 'ua_icons_happy_sad':
        return ['Happy', 'Sad']
      default:
        return null
    }
  }
}
