import { apiLogout, refreshToken } from '@/api'
import menu from '@/constants/menu'
import store from '@/store'
import { APP_CONSTANTS } from '@/store/app/constants'

function loadingPageStart({ commit }) {
  commit('LOADING_PAGE_START')
}

function loadingPageEnd({ commit }) {
  commit('LOADING_PAGE_END')
}
/**
 * Caches the client side filters on the app's Vuex store.
 * @param {*} param0 The Vuex context (auto-injected).
 * @param {*} clientSideFilters The client side filters to store.
 */
function cacheClientSideFilters({ commit }, clientSideFilters) {
  commit('CLIENT_SIDE_FILTERS_CACHED', clientSideFilters)
}

/**
 * Caches the server side filters on the app's Vuex store.
 * @param {*} param0 The Vuex context (auto-injected).
 * @param {*} clientSideFilters The client side filters to store.
 */
function cacheServerSideFilters({ commit }, filters) {
  commit('SERVER_SIDE_FILTERS_CACHED', filters)
}

/**
 * Updates the available items on the user's navigation-bar menu
 * @param {*} param0 The context of the call, deconstructed by commit & state
 */
async function updateAvailableMenu(context) {
  const commit = context.commit
  const availableMenu = []

  menu.forEach(item => {
    const hasPermission = context.rootGetters['user/hasPermission']
    if (hasPermission(item.permission)) {
      const subItems = []
      if (item.subItems) {
        item.subItems.forEach(subItem => {
          if (hasPermission(subItem.permission)) {
            subItems.push({
              icon: subItem.icon,
              text: subItem.text,
              to: subItem.to,
              active: subItem.active,
              permission: subItem.permission
            })
          }
        })
      }
      // If we have no items for a group, hide the group.
      if ((item.isGroup && subItems.length > 0) || !item.isGroup) {
        availableMenu.push({
          subHeader: item.subHeader,
          icon: item.icon,
          text: item.text,
          to: item.to,
          active: item.active,
          isGroup: item.isGroup,
          subItems
        })
      }
    }
  })
  commit('AVAILABLE_MENU_UPDATED', { availableMenu })
}

/**
 * Attempts to log in a user with the specified auth code.
 * Returns true if login was successful.
 * @param {vuexContext} param0 The standard vuex context (usually passed automatically).
 * @param authToken
 * @param refreshToken
 * @param email
 * @param picture
 * @param name
 * @param permissions
 */
async function login(
  { commit },
  { authToken, refreshToken, email, picture, name, permissions, targets }
) {
  await store.dispatch('user/updateTokens', { authToken, refreshToken })
  await store.dispatch('user/updateUserInfo', { email, picture, name })
  await store.dispatch('user/updatePermissions', { permissions })
  await store.dispatch('user/updateTargets', { targets })
}

async function refreshLogin({ commit }, { authToken, refreshToken }) {
  await store.dispatch('user/updateTokens', { authToken, refreshToken })
}

/**
 * Logs the user out of the application
 * @param {stateContext} context The default vuex state (auto-injected, usually).
 */
function logout(context) {
  apiLogout()
  store.dispatch('user/clearUserData')
}

// List Search State

function processModuleContextChanged({ commit }, { from, to }) {
  let originModule = from.meta.module
  let destinationModule = to.meta.module
  // If the context changed, trigger a new mutation
  if (originModule !== destinationModule) {
    // Clear settings
    commit('CLEARED_LIST_SEARCH_SETTINGS')
    // Change module
    commit('MODULE_CONTEXT_CHANGED', destinationModule)
  }
}

/**
 * Sets the list search query to be cached.
 * @param {*} param0 The default vuex state (auto-injected, usually).
 * @param {*} query The search query.
 */
function setListSearchQuery({ commit }, query) {
  commit('QUERY_LIST_SEARCH_SETTINGS', query)
}

/**
 * Clears the list search settings' cache that is used for all lists.
 * @param {*} param0 The default vuex state (auto-injected, usually).
 */
function clearListSearchSettings({ commit }) {
  commit('CLEARED_LIST_SEARCH_SETTINGS')
}

// App Info
/**
 * Updates the app to the new version by reloading the app and
 * changing any appropriate states.
 * @param {*} param0 The default vuex state (auto-injected, usually).
 */
function updateToNewVersion({ commit }) {
  commit('APP_NEW_VERSION', true) // signals it's a new version
  location.reload()
}

/**
 * Signals the store that the app knows a new version has been launched,
 * therefore "consuming" the event.
 * @param {*} param0 The default vuex state (auto-injected, usually).
 */
function updatedAppEventConsumed({ commit }) {
  commit('APP_NEW_VERSION', false) // signals it's a new version
}

// Notifications

function setSnackbar(
  { commit },
  { module, action, type, text, customMessage }
) {
  commit('SNACKBAR_UPDATED', { module, action, type, text, customMessage })
}

function showSuccessNotification(
  { commit },
  { action, module, customMessage }
) {
  var type = 'success'
  commit('SNACKBAR_UPDATED', { module, action, type, customMessage })
}

function showInfoNotification({ commit }, { message }) {
  var type = 'info'
  commit('SNACKBAR_UPDATED', { module, type, text: message })
}

function showErrorNotification({ commit }, { message }) {
  var type = 'error'
  var text = message
  commit('SNACKBAR_UPDATED', { text, type, timeout: 5000 })
}

function cleanSnackbar({ commit }) {
  commit('SNACKBAR_CLEANED')
}

const refreshTokenAction = ({ commit }) => {
  return refreshToken()
}

/**
 * Commits the globally select country into Vuex
 * @param commit
 * @param selectedCountry
 */
function updateSelectedCountry({ commit }, selectedCountry) {
  commit('UPDATE_SELECTED_COUNTRY', selectedCountry)
}

/**
 * This method is used at the start of App. It is used to load the selected country set in localStorage to memory
 * @param rootState
 * @param commit
 * @returns {Promise<void>}
 */
async function loadSelectedCountry({ rootState, commit }) {
  let selected = rootState.app.securedState.get(APP_CONSTANTS.APP)[
    APP_CONSTANTS.SELECTED_COUNTRY
  ]

  if (selected) {
    commit('UPDATE_SELECTED_COUNTRY', selected)
  }
}

export default {
  loadingPageStart,
  loadingPageEnd,
  updatedAppEventConsumed,
  login,
  refreshLogin,
  logout,
  updateAvailableMenu,
  setSnackbar,
  cleanSnackbar,
  showSuccessNotification,
  showInfoNotification,
  showErrorNotification,
  refreshTokenAction,
  setListSearchQuery,
  clearListSearchSettings,
  processModuleContextChanged,
  updateToNewVersion,
  cacheClientSideFilters,
  cacheServerSideFilters,
  updateSelectedCountry,
  loadSelectedCountry
}
