/**
 * Converts a JSON code snippet type object from the API format to the format expected by the FE.
 * @param {JSON} codeSnippetType The code snippet type JSON object to convert from.
 */
function codeSnippetTypeMap(codeSnippetType) {
  let { id, name, keyword } = codeSnippetType
  return {
    id,
    name,
    keyword
  }
}

/**
 * Maps an array of code snippet types to another array of code snippet types in the expected FE format.
 * @param {Array} list The list of code snippet types in BE format.
 */
function codeSnippetTypesListMap(list) {
  var results = []
  for (let item of list) {
    results.push(codeSnippetTypeMap(item))
  }
  return results
}

function codeSnippetTypeNamesListMap(list) {
  var results = []
  for (let item of list) {
    results.push({ text: item.name, value: item.id })
  }
  return results
}

export {
  codeSnippetTypeMap,
  codeSnippetTypesListMap,
  codeSnippetTypeNamesListMap
}
