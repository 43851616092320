import { butterflyAxios } from '@/api/axios'
import { codeSnippetListMap, codeSnippetMap, mapAudit } from './responseMapper'
import { i18n } from '@/i18n'
import {
  isCreatedResponse,
  isOKResponse,
  parseSuccessResponse
} from '@/api/utils/response'
import { viewTypes } from '@/utils/viewTypes'

const endpoint = {
  codeSnippets: 'code-snippets',
  codeSnippet: codeSnippetId => `code-snippets/${codeSnippetId}`,
  audit: codeSnippetId => `code-snippets/${codeSnippetId}/audits`
}

/**
 * Get code snippets
 *
 * @param request
 * @returns {Promise<{total: number, codeSnippets, originalData, status: boolean}>}
 */
async function apiGetCodeSnippets(request) {
  const q = request.q
  const page = request.page
  const filters = request.filters
  const perPage = request.perPage
  const view = request.view ? request.view : viewTypes.FullView
  let payload = {}

  if (q != null && q !== '') {
    payload.query = q
  }

  payload.per_page = perPage
  payload.page = page
  payload.view = view

  let { data } = await butterflyAxios.get(endpoint.codeSnippets, {
    params: { ...payload, ...filters }
  })

  let list = data.data
  let pagination = data.meta

  let codeSnippets = codeSnippetListMap(list, view)

  return {
    codeSnippets: codeSnippets,
    finalDataSize: pagination ? pagination.total_records : codeSnippets.length,
    newPage: pagination ? pagination.page : 1,
    originalData: codeSnippets // The original, unaltered data, for any needs any component may have
  }
}

/**
 * Delete a code snippet
 *
 * @param id integer code snippet ID
 * @returns {Promise<void>}
 */
const apiDeleteCodeSnippet = async id => {
  let response = {}
  let finalResponse = {}
  response = await butterflyAxios
    .delete(endpoint.codeSnippet(id))
    .catch(function(error) {
      if (error.response) {
        finalResponse = { status: error.response.status }
        switch (error.response.status) {
          // If it's 422, then it's a deletion of a message being used.
          case 422:
            finalResponse.error_message = i18n.t(
              'code_snippets.errors.already_in_use'
            )
            break
        }
      }
    })
  if (response) {
    response.success = true
  } else {
    response = finalResponse
  }
  return response
}

/**
 * Create code snippet
 *
 * @param code snippet object
 * @returns {Promise<AxiosResponse<any>>}
 */
const apiCreateCodeSnippet = async codeSnippet => {
  let response = await butterflyAxios.post(endpoint.codeSnippets, codeSnippet)
  if (isCreatedResponse(response)) {
    return parseSuccessResponse(response)
  }
  return undefined
}

/**
 * Get a code snippet by Id
 *
 * @param codeSnippetId integer Id of code snippet
 * @returns {Promise<AxiosResponse<any>>}
 */
const apiGetCodeSnippet = async codeSnippetId => {
  let response = await butterflyAxios.get(endpoint.codeSnippet(codeSnippetId))

  if (!isOKResponse(response)) {
    throw response
  }

  let data
  data = parseSuccessResponse(response)

  return codeSnippetMap(data.response, viewTypes.FullView)
}

/**
 * Update code snippet
 * @param code snippet object
 * @returns {Promise<boolean|any>}
 */
const apiUpdateCodeSnippet = async codeSnippet => {
  let response = await butterflyAxios.put(
    endpoint.codeSnippet(codeSnippet.id),
    codeSnippet
  )

  if (!isOKResponse(response)) {
    throw response
  }

  let data
  data = parseSuccessResponse(response)

  return data.response
}

const apiGetAudit = async request => {
  const params = {
    sort_by: request.sortBy,
    order_by: request.orderBy,
    page: request.page,
    per_page: request.perPage
  }
  const response = await butterflyAxios.get(endpoint.audit(request.id), {
    params: { ...params }
  })
  if (isOKResponse(response)) {
    const { response: list, meta: pagination } = parseSuccessResponse(response)
    return {
      audit: mapAudit(list),
      finalDataSize: pagination ? pagination.total_records : list.length
    }
  } else {
    throw response
  }
}

export {
  apiGetCodeSnippets,
  apiDeleteCodeSnippet,
  apiCreateCodeSnippet,
  apiGetCodeSnippet,
  apiUpdateCodeSnippet,
  apiGetAudit
}
