const countries = [
  { text: 'Nigeria', value: 'NG' },
  { text: 'Egypt', value: 'EG' },
  { text: 'Morocco', value: 'MA' },
  { text: 'Kenya', value: 'KE' },
  { text: 'Ivory Coast', value: 'CI' },
  { text: 'Ghana', value: 'GH' },
  { text: 'Senegal', value: 'SN' },
  { text: 'Tunisia', value: 'TN' },
  { text: 'Uganda', value: 'UG' },
  { text: 'Algeria', value: 'DZ' },
  { text: 'South Africa', value: 'ZA' },
  { text: 'Cameroon', value: 'CM' },
  { text: 'Zando', value: 'ZZ' }
]

export function getCountryName(isoCode) {
  let country = countries.find(country => country.value === isoCode)

  if (!country) return null
  return country.text
}

export function getFilterCountryList() {
  return [{ text: 'All', value: null }, ...getCountryList()]
}

export default function getCountryList() {
  return countries.sort((a, b) => a.text.localeCompare(b.text))
}
