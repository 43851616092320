/* eslint-disable camelcase */
import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'
import { CHANNEL_KEYWORDS, CHANNEL_COLORS } from '@/api/channels/constants'
import { parseStatusVanityFrom } from '@/api/campaigns/helpers'
import { STATUS_TYPES } from '@/api/campaigns/constants'

const GREY = '#8e8e93'

const toogleBudget = window.CONFIG.TOGGLE_SMS_BUDGET
/**
 * Parses the vanity properties of the frequency of a campaign.
 * Essentially, computes the label that the 'frequency' value should have.
 * @param {string} campaignFrequency The frequency of the campaign.
 */
function parseFrequencyLabelFrom(campaignFrequency) {
  let label = i18n.t('campaigns.campaign_frequency.error')
  let key = 'campaigns.campaign_frequency.' + campaignFrequency
  if (i18n.te(key)) {
    label = i18n.t(key)
  }
  return label
}

/**
 * A constant to specify functions that allow
 * to create the actions that can be used in the campaigns.
 */
const ACTION = {
  CREATE: () => {
    return {
      event: 'create',
      label: i18n.t('campaigns.actions.create'),
      icon: 'add',
      isPrimary: true,
      options: [
        {
          keyword: CHANNEL_KEYWORDS.NEWS_FEED,
          color: CHANNEL_COLORS.NEWS_FEED,
          icon: 'move_to_inbox',
          label: i18n.t('campaigns.title.newsfeed')
        },
        {
          keyword: CHANNEL_KEYWORDS.NEWSLETTER,
          color: CHANNEL_COLORS.NEWSLETTER,
          icon: 'mdi-email-multiple',
          label: i18n.t('campaigns.title.newsletter')
        },
        {
          keyword: CHANNEL_KEYWORDS.EMAIL,
          color: CHANNEL_COLORS.EMAIL,
          icon: 'email',
          label: i18n.t('campaigns.title.email')
        }
      ]
    }
  },
  DELETE: campaign => {
    return {
      event: 'delete',
      icon: 'delete',
      label: i18n.t('campaigns.actions.delete'),
      confirmationRequired: true,
      confirmationTitleKey: i18n.t('campaigns.delete_confirmation.title'),
      confirmationDescriptionKey: i18n.t(
        'campaigns.delete_confirmation.description',
        campaign
      ),
      disabled: isCampaignCompletedOrArchived(campaign)
    }
  },
  EDIT: () => {
    return {
      event: 'edit',
      icon: 'edit',
      label: i18n.t('campaigns.actions.edit')
    }
  },
  VIEW: () => {
    return {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('campaigns.actions.view')
    }
  },
  CLONE: () => {
    return {
      event: 'clone',
      icon: 'file_copy',
      label: i18n.t('campaigns.actions.clone')
    }
  },
  SEND_TEST: () => {
    return {
      event: 'sendTest',
      icon: 'mdi-email-multiple',
      label: i18n.t('campaigns.actions.send_test_testers')
    }
  },
  SEND_TEST_ME: () => {
    return {
      event: 'sendTestMe',
      icon: 'mdi-email-check',
      label: i18n.t('campaigns.actions.send_test_me')
    }
  },
  SEND_TEST_PN: () => {
    return {
      event: 'sendTest',
      icon: 'mdi-cellphone-android',
      label: i18n.t('campaigns.actions.send_test_testers')
    }
  },
  SEND_TEST_INBOX_ME: () => {
    return {
      event: 'sendTestMe',
      icon: 'inbox',
      label: i18n.t('campaigns.actions.send_test_me')
    }
  },
  SEND_TEST_INBOX: () => {
    return {
      event: 'sendTest',
      icon: 'inbox',
      label: i18n.t('campaigns.actions.send_test_testers')
    }
  },
  SEND_TEST_SMS: () => {
    return {
      event: 'sendTest',
      icon: 'mdi-message',
      label: i18n.t('campaigns.actions.send_test_testers')
    }
  },
  AUDIT: () => {
    return {
      event: 'audit',
      icon: 'mdi-update',
      label: i18n.t('campaigns.actions.audit')
    }
  }
}

/**
 * Maps a campaign object to the object that will be displayed
 * on the list view.
 * @param {*} campaign The campaign object to map
 */
function campaignToListDTO(campaign) {
  // Compute color:
  let chipVanityData = parseStatusVanityFrom(campaign.status)
  return {
    id: campaign.id,
    name: campaign.name,
    channel: campaign.channelName,
    frequency: parseFrequencyLabelFrom(campaign.frequency),
    status: {
      keyword: campaign.status,
      label: chipVanityData.label,
      type: 'JChip',
      color: chipVanityData.color
    },
    updatedAt: formatDate(campaign.updatedAt),
    updatedBy: campaign.updatedBy,
    keyword: campaign.channelKeyword,
    tags: {
      label: i18n.t('campaigns.creation.details_step.tags'),
      color: GREY,
      type: 'JChipList',
      value: formatTagsList(campaign.tags)
    },
    isSelectable: campaign.channelKeyword !== CHANNEL_KEYWORDS.SMS,
    targetFlag: {
      isoCode: campaign.target.code,
      description: campaign.target.code
    },
    messageName: campaign.messageName,
    messageId: campaign.messageId,
    instanceId:
      campaign.audienceType === 'instance' && campaign.audiences[0]
        ? campaign.audiences[0].id
        : undefined,
    endDate: formatDate(campaign.endDate, false),
    messageTitleEn: campaign.messageTitleEn,
    messageTitleFr: campaign.messageTitleFr,
    messageTitleAr: campaign.messageTitleAr,
    messageEn: campaign.messageEn,
    messageFr: campaign.messageFr,
    messageAr: campaign.messageAr
  }
}
function formatTagsList(tags) {
  if (!tags || tags.length === 0) return []

  let list = [tags.pop().name]
  if (tags.length > 0) {
    list.push(`+${tags.length}`)
  }

  return list
}

/**
 * Returns the current campaigns, with the associated logics (e.g. actions
 * that can be performed on them.)
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function campaignsListDTO(state, getters, rootState, rootGetters) {
  if (!state.campaigns) return []

  return state.campaigns.map(campaign => {
    let data = campaignToListDTO(campaign)
    return {
      ...data,
      actions: actionsOnCampaign(
        state,
        getters,
        rootState,
        rootGetters
      )(campaign),
      canExpand:
        data.keyword === CHANNEL_KEYWORDS.EMAIL ||
        data.keyword === CHANNEL_KEYWORDS.NEWSLETTER ||
        data.keyword === CHANNEL_KEYWORDS.PUSH_NOTIFICATION
    }
  })
}

function instancesListDTO(state, getters, rootState, rootGetters) {
  if (!state.instances) return []
  return state.instances.map(instance => {
    return {
      ...instance,
      segment: instance.dataSourceName,
      updated_at: formatDate(instance.updatedDate),
      select: instance.id
    }
  })
}

/**
 * Returns an array containing the actions that the user can perform on the entire list
 * of campaigns (i.e. on the 'collection' level and not on the 'individual' level)
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnCampaignList(state, getters, rootState, rootGetters) {
  const actions = []

  if (rootGetters['user/canCreateCampaigns']) {
    actions.push(ACTION.CREATE())

    if (rootGetters['user/canCreateGrowthToolCampaign']) {
      const gtActionOption = {
        keyword: CHANNEL_KEYWORDS.GROWTH_TOOL,
        color: CHANNEL_COLORS.GROWTH_TOOL,
        icon: 'mdi-cellphone-iphone',
        label: i18n.t('campaigns.title.growth_tool')
      }

      actions[0].options.unshift(gtActionOption)
    }

    if (rootGetters['user/canCreatePushNotificationCampaign']) {
      const pnActionOption = {
        keyword: CHANNEL_KEYWORDS.PUSH_NOTIFICATION,
        color: CHANNEL_COLORS.PUSH_NOTIFICATION,
        icon: 'mdi-cellphone-android',
        label: i18n.t('campaigns.title.push_notification')
      }

      actions[0].options.unshift(pnActionOption)
    }

    if (rootGetters['user/canCreateInboxCampaign']) {
      const inboxActionOption = {
        keyword: CHANNEL_KEYWORDS.INBOX,
        color: CHANNEL_COLORS.INBOX_MESSAGE,
        icon: 'inbox',
        label: i18n.t('campaigns.title.inbox')
      }

      actions[0].options.unshift(inboxActionOption)
    }

    if (rootGetters['user/canCreateSmsCampaign']) {
      const smsActionOption = {
        keyword: CHANNEL_KEYWORDS.SMS,
        color: CHANNEL_COLORS.SMS,
        icon: 'mdi-message',
        label: i18n.t('campaigns.title.sms')
      }

      actions[0].options.unshift(smsActionOption)
    }
  }

  return actions
}

/**
 * Returns an array with the actions that the user can perform on a specific
 * campaign.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnCampaign(state, getters, rootState, rootGetters) {
  return function(campaign) {
    let actions = []

    if (!campaign) return actions

    /**
     * Specifies that the current user can perform the below actions.
     * action: the action that the user can perform
     * permission: path to the 'getter' that confirms the permission.
     */

    const defaultActionGroup = {
      actionGroup: [
        { action: ACTION.EDIT(campaign), permission: 'user/canEditCampaign' },
        { action: ACTION.VIEW(campaign), permission: 'user/canSeeCampaign' }
      ]
    }

    let potentialActions = [defaultActionGroup]

    if (toogleBudget && campaign.channelKeyword === CHANNEL_KEYWORDS.SMS) {
      const smsActionGroup = {
        actionGroup: [
          {
            action: ACTION.EDIT(campaign),
            permission: 'user/canUpdateSmsCampaignByTarget',
            params: [campaign.target.code]
          },
          {
            action: ACTION.VIEW(campaign),
            permission: 'user/canSeeSmsCampaign'
          }
        ]
      }
      potentialActions = isCampaignCompletedOrArchived(campaign)
        ? [smsActionGroup.actionGroup[1]]
        : [smsActionGroup]
    }

    potentialActions.push({
      label: i18n.t('campaigns.actions.more'),
      icon: 'more_vert',
      menu: {
        list: [
          {
            action: ACTION.CLONE(campaign),
            permission:
              campaign.channelKeyword === CHANNEL_KEYWORDS.SMS
                ? 'user/canCreateSmsCampaignByTarget'
                : 'user/canCloneCampaign',
            params: [campaign.target.code],
            divider:
              campaign.channelKeyword === CHANNEL_KEYWORDS.EMAIL ||
              campaign.channelKeyword === CHANNEL_KEYWORDS.NEWSLETTER ||
              campaign.channelKeyword === CHANNEL_KEYWORDS.INBOX ||
              (campaign.channelKeyword === CHANNEL_KEYWORDS.SMS &&
                !toogleBudget) ||
              campaign.channelKeyword === CHANNEL_KEYWORDS.PUSH_NOTIFICATION
          },
          {
            action: ACTION.DELETE(campaign),
            permission:
              campaign.channelKeyword === CHANNEL_KEYWORDS.SMS
                ? 'user/canDeleteSmsCampaignByTarget'
                : 'user/canDeleteCampaign',
            params: [campaign.target.code]
          },
          {
            action: ACTION.AUDIT(campaign),
            permission: 'user/canSeeCampaign',
            divider: true
          }
        ],
        date: {
          display: campaign.channelKeyword === CHANNEL_KEYWORDS.NEWSLETTER,
          available: campaign.dates
        }
      }
    })
    const defaultMoreActions = permission => [
      ...potentialActions[potentialActions.length - 1].menu.list.filter(
        f => f.action.event !== 'audit'
      ),
      {
        action: ACTION.AUDIT(campaign),
        permission,
        divider: true
      }
    ]
    switch (campaign.channelKeyword) {
      case CHANNEL_KEYWORDS.EMAIL:
      case CHANNEL_KEYWORDS.NEWSLETTER:
        potentialActions[potentialActions.length - 1] = {
          ...potentialActions[potentialActions.length - 1],
          menu: {
            ...potentialActions[potentialActions.length - 1].menu,
            list: [
              {
                action: ACTION.SEND_TEST_ME(campaign),
                permission: 'user/canSendTestCampaign',
                dropdown: false
              },
              {
                action: ACTION.SEND_TEST(campaign),
                permission: 'user/canSendTestCampaign',
                dropdown: false
              },
              ...defaultMoreActions('user/canSeeCampaign')
            ]
          }
        }
        break

      case CHANNEL_KEYWORDS.PUSH_NOTIFICATION:
        potentialActions[potentialActions.length - 1] = {
          ...potentialActions[potentialActions.length - 1],
          menu: {
            ...potentialActions[potentialActions.length - 1].menu,
            list: [
              {
                action: ACTION.SEND_TEST_PN(campaign),
                permission: 'user/canSendTestCampaign',
                dropdown: false
              },
              ...defaultMoreActions('user/canSeeCampaign')
            ]
          }
        }
        break
      case CHANNEL_KEYWORDS.INBOX:
        potentialActions[potentialActions.length - 1] = {
          ...potentialActions[potentialActions.length - 1],
          menu: {
            ...potentialActions[potentialActions.length - 1].menu,
            list: [
              {
                action: ACTION.SEND_TEST_INBOX_ME(campaign),
                permission: 'user/canSendTestCampaign',
                dropdown: false
              },
              {
                action: ACTION.SEND_TEST_INBOX(campaign),
                permission: 'user/canSendTestCampaign',
                dropdown: false
              },
              ...defaultMoreActions('user/canSeeCampaign')
            ]
          }
        }
        break
      case CHANNEL_KEYWORDS.SMS:
        potentialActions[potentialActions.length - 1] = {
          ...potentialActions[potentialActions.length - 1],
          menu: {
            ...potentialActions[potentialActions.length - 1].menu,
            list: !toogleBudget
              ? [
                  {
                    action: ACTION.SEND_TEST_SMS(campaign),
                    permission: 'user/canSendTestCampaign',
                    dropdown: false
                  },
                  ...defaultMoreActions('user/canSeeCampaign')
                ]
              : defaultMoreActions('user/canSeeCampaign')
          }
        }
        break
    }

    actions = filterActionsListByPermission(
      rootGetters,
      campaign.updatedBy,
      potentialActions
    )

    return actions
  }
}

function expiringCampaignsToListDTO(campaign) {
  let chipVanityData = parseStatusVanityFrom(campaign.status)
  return {
    targetFlag: {
      isoCode: campaign.target,
      description: campaign.target
    },
    id: campaign.id,
    name: campaign.name,
    status: {
      label: chipVanityData.label,
      type: 'JChip',
      color: chipVanityData.color
    },
    channel: campaign.channel,
    frequency: i18n.t(
      `campaigns.creation.frequency_step.${campaign.frequency}`
    ),
    updated_at: formatDate(campaign.updated_at),
    end_date: formatDate(campaign.end_date),
    metadata: mapMetadata(campaign.metadata)
  }
}

function mapMetadata(metadata) {
  if (metadata && metadata.sent) {
    return i18n.t('dashboard.growth_tools_ending.users_sent', {
      sent: Intl.NumberFormat().format(metadata.sent)
    })
  } else if (metadata && metadata.error) {
    return metadata.error
  }
  return ''
}

function widgetCampaignsListDTO(state, getters, rootState, rootGetters) {
  if (!state.widgetCampaigns) return []
  return state.widgetCampaigns.map(campaign => {
    let data = expiringCampaignsToListDTO(campaign)
    return {
      ...data,
      actions: actionsOnExpiringCampaigns(
        state,
        getters,
        rootState,
        rootGetters
      )(campaign)
    }
  })
}

function actionsOnExpiringCampaigns(state, getters, rootState, rootGetters) {
  return function(campaign) {
    let actions = []

    if (!campaign) return actions

    let potentialActions = [
      {
        actionGroup: [
          {
            action: ACTION.EDIT(campaign),
            permission: 'user/canEditCampaign'
          }
        ]
      },
      {
        label: i18n.t('campaigns.actions.more'),
        icon: 'more_vert',
        menu: {
          list: [
            {
              action: ACTION.AUDIT(campaign),
              permission: 'user/canSeeCampaign'
            }
          ],
          date: {
            display: false,
            available: undefined
          }
        }
      }
    ]

    actions = filterActionsListByPermission(
      rootGetters,
      campaign.updated_at,
      potentialActions
    )

    return actions
  }
}

function isCampaignCompletedOrArchived({ status }) {
  return status === STATUS_TYPES.COMPLETE || status === STATUS_TYPES.ARCHIVED
}

export default {
  campaignsListDTO,
  instancesListDTO,
  actionsOnCampaignList,
  actionsOnCampaign,
  widgetCampaignsListDTO
}
