import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'

const STATE_COLORS = {
  STARTED: '#2A8091',
  SUCCESS: '#20921E',
  ERROR: '#E36049',
  WARNING: '#FF9800'
}

const ACTION = {
  EDIT: () => {
    return {
      event: 'edit',
      icon: 'edit',
      label: i18n.t('edit')
    }
  },
  VIEW: () => {
    return {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('view')
    }
  }
}

const METADATA_TYPE = {
  SEGMENT: sent =>
    i18n.t('dashboard.triggered_events.devices_sent', {
      sent: Intl.NumberFormat().format(sent)
    }),
  CAMPAIGN: sent =>
    i18n.t('dashboard.triggered_events.users_sent', {
      sent: Intl.NumberFormat().format(sent)
    })
}

function parseStatusVanityFrom(triggeredEventState) {
  let label = 'Error'
  let color = STATE_COLORS.ERROR

  if (triggeredEventState) {
    let upperCasedState = triggeredEventState.toUpperCase()

    if (upperCasedState in STATE_COLORS) {
      label = triggeredEventState
      color = STATE_COLORS[upperCasedState]
    }
  }
  return { label, color }
}

function campaignEventToListDTO(triggeredEvent) {
  let chipVanityData = parseStatusVanityFrom(triggeredEvent.status)

  return {
    id: triggeredEvent.id,
    campaignId: triggeredEvent.key,
    description: triggeredEvent.description,
    type: triggeredEvent.type,
    date: formatDate(triggeredEvent.date),
    status: {
      label: chipVanityData.label,
      type: 'JChip',
      color: chipVanityData.color
    },
    targetFlag: {
      isoCode: triggeredEvent.target,
      description: triggeredEvent.target
    },
    channel: triggeredEvent.channel,
    metadata: mapMetadata(triggeredEvent.metadata, METADATA_TYPE.CAMPAIGN)
  }
}

function segmentEventToListDTO(triggeredEvent) {
  let chipVanityData = parseStatusVanityFrom(triggeredEvent.status)

  return {
    id: triggeredEvent.id,
    segmentId: triggeredEvent.key,
    description: triggeredEvent.description,
    type: triggeredEvent.type,
    date: formatDate(triggeredEvent.date),
    status: {
      label: chipVanityData.label,
      type: 'JChip',
      color: chipVanityData.color
    },
    targetFlag: {
      isoCode: triggeredEvent.target,
      description: triggeredEvent.target
    },
    metadata: mapMetadata(triggeredEvent.metadata, METADATA_TYPE.SEGMENT)
  }
}

function mapMetadata(metadata, type) {
  if (metadata && metadata.sent) {
    return type(metadata.sent)
  } else if (metadata && metadata.error) {
    return metadata.error
  }
  return ''
}

function campaignsEventsListDTO(state, getters, rootState, rootGetters) {
  if (!state.campaingsEvents) return []
  return state.campaingsEvents.map(event => {
    let data = campaignEventToListDTO(event)
    return {
      ...data,
      actions: actionsOnTriggeredEvent(
        state,
        getters,
        rootState,
        rootGetters
      )(event)
    }
  })
}

function segmentsEventsListDTO(state, getters, rootState, rootGetters) {
  if (!state.segmentsEvents) return []
  return state.segmentsEvents.map(event => {
    let data = segmentEventToListDTO(event)
    return {
      ...data,
      actions: actionsOnSegmentEvent(
        state,
        getters,
        rootState,
        rootGetters
      )(event)
    }
  })
}

function actionsOnSegmentEvent(state, getters, rootState, rootGetters) {
  return function(segmentEvent) {
    let actions = []

    if (!segmentEvent) return actions

    let potentialActions = [
      {
        action: ACTION.VIEW(segmentEvent),
        permission: 'user/canSeeInstance'
      }
    ]

    actions = filterActionsListByPermission(
      rootGetters,
      segmentEvent.createdBy,
      potentialActions
    )

    return actions
  }
}

function actionsOnTriggeredEvent(state, getters, rootState, rootGetters) {
  return function(triggeredEvent) {
    let actions = []

    if (!triggeredEvent) return actions

    let potentialActions = [
      {
        actionGroup: [
          {
            action: ACTION.EDIT(triggeredEvent),
            permission: 'user/canEditCampaign'
          },
          {
            action: ACTION.VIEW(triggeredEvent),
            permission: 'user/canSeeCampaign'
          }
        ]
      }
    ]

    actions = filterActionsListByPermission(
      rootGetters,
      triggeredEvent.createdBy,
      potentialActions
    )

    return actions
  }
}

export default {
  campaignsEventsListDTO,
  segmentsEventsListDTO,
  actionsOnTriggeredEvent
}
