import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { securedLS } from '@/store/securedStorage'

const state = {
  campaigns: null,
  finalDataSize: null,
  originalData: null,
  instances: null,
  instancesFinalDataSize: null,
  instancesOriginalData: null,
  messages: null,
  securedState: securedLS,
  widgetCampaigns: null
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
