function NAVIGATION_HISTORY_UPDATED(state, prevRoute) {
  if (state.navigationHistory.length > 1) {
    state.navigationHistory.shift()
  }
  state.navigationHistory.push(prevRoute)
}

export default {
  NAVIGATION_HISTORY_UPDATED
}
