import { eyeofsauronAxios, eyeofsauronV2Axios } from '@/api/axios'
import { jobListV1Map, jobListMap } from './responseMapper'
import { paginateData } from '@/api/utils'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'

var endpoint = {
  jobs: '/jobs'
}

async function apiGetJobsV1(request) {
  const q = request.q
  const page = request.page
  const perPage = request.perPage
  const customFilterFunction = request.customFilterFunction

  // From the request object that is returned by milkwayAxios,
  // only obtained the 'data' parameter out of it
  let { data } = await eyeofsauronAxios.get(endpoint.jobs, {
    params: {
      page: page,
      per_page: perPage,
      active: true,
      sort_by: 'name',
      order_by: 'asc'
    }
  })
  // data.jobs is actually an object, not an array.
  // Therefore, we need to convert it into one.
  const jobsArray = []
  for (let [, value] of Object.entries(data.jobs)) {
    jobsArray.push(value)
  }
  const jobsFiltered = jobsArray.filter(el => {
    if (q) {
      return el.name.toLowerCase().includes(q.toLowerCase())
    }
    return el
  })

  let jobs = jobListV1Map(jobsFiltered)

  if (customFilterFunction) {
    jobs = customFilterFunction(jobs)
  }

  if (perPage !== -1) {
    const paginationResult = paginateData(jobs, page, perPage)
    const dataJobs = paginationResult.dataset
    const newPage = paginationResult.newPage

    const finalDataSize = jobs.length

    return {
      jobs: dataJobs,
      finalDataSize,
      newPage
    }
  }
  const dataJobs = jobs

  const finalDataSize = jobs.length

  return {
    jobs: dataJobs,
    finalDataSize
  }
}

async function apiGetJobs({
  query,
  page,
  perPage,
  sortBy,
  orderBy,
  filters = {}
}) {
  let params = {
    page: page,
    per_page: perPage,
    sort_by: sortBy,
    order_by: orderBy,
    ...filters
  }
  if (query && query.trim().length > 0) {
    params.query = query
  }
  const response = await eyeofsauronV2Axios.get(endpoint.jobs, {
    params
  })

  if (isOKResponse(response)) {
    const result = parseSuccessResponse(response)
    const meta = result.meta
    if (result.success) {
      const finalDataSize = meta ? meta.total_records : 0
      return {
        finalDataSize,
        jobs: jobListMap(result.response)
      }
    }
  }
  throw response
}

export { apiGetJobsV1, apiGetJobs }
