/**
 *
 * @param state
 * @param messages
 * @param finalDataSize
 * @param originalData
 * @constructor
 */
function MESSAGE_SCHEDULES_UPDATED(
  state,
  { messageSchedules, finalDataSize, originalData }
) {
  state.messageSchedules = messageSchedules
  state.finalDataSize = finalDataSize
  state.originalData = originalData
}

export default {
  MESSAGE_SCHEDULES_UPDATED
}
