import store from '@/store'

function addPrevRoute(route) {
  const state = navigationHistory()

  const addCondition = state.find(
    stateRoute => stateRoute.meta.module === route.meta.module
  )

  let isScheduleRoute = route.name ? route.name.includes('schedule') : false

  const isListRoute = !!(
    route.name === 'campaigns' ||
    route.name === 'messages' ||
    route.name === 'media' ||
    route.name === 'instances' ||
    route.name === 'code-snippets' ||
    route.name === 'contactlookup'
  )

  if (
    (state.length !== 0 && !isListRoute && !addCondition) ||
    isScheduleRoute
  ) {
    return
  }

  if (route.name) {
    store.dispatch('navigationHistory/changeNavigationHistory', route)
  }
}

function buildRouteQuery(routeName) {
  const state = navigationHistory()

  // state is an array, we want to find from the bottom
  const route = state.reverse().find(route => route.name === routeName)

  if (route) {
    return route.query
  }

  return { page: 1, per_page: 10 }
}

function navigationHistory() {
  const state = store.state.navigationHistory.navigationHistory
  return state
}

function buildRedirectPath(moduleName) {
  const history = navigationHistory()
  let path

  const hasModuleName = history.find(route => route.name === moduleName)
  if (hasModuleName) {
    let query = buildRouteQuery(moduleName)

    if (!query.page) {
      query.page = 1
    }

    if (!query.per_page) {
      query.per_page = 10
    }

    path = `/${moduleName}?page=${query.page}&per_page=${query.per_page}`

    return path
  }

  if (history[1]) {
    path = history[1].fullPath
    return path
  }

  if (history[0] && !history[1]) {
    path = history[0].fullPath
    return path
  }

  if (moduleName === 'newsletters-schedule') {
    path = `/messages`
    return path
  }

  path = `/${moduleName}`
  return path
}

export { addPrevRoute, buildRouteQuery, buildRedirectPath }
