import { formatDate } from '@/utils/commons/dateUtils'
import moment from 'moment/moment'
import {
  currencyFormatWithCode,
  mapUserAction
} from '@/utils/commons/formatter'

function channelBudgetsAuditListMap(audits) {
  return audits.map(a => {
    const {
      id,
      action: userAction,
      updated_by: updatedBy,
      timestamp: updatedAt,
      hc_ticket_id: hcTicket,
      budget
    } = a
    return {
      id,
      userAction: mapUserAction(userAction),
      updatedBy,
      updatedAt: formatDate(updatedAt),
      budget: currencyFormatWithCode(budget),
      hcTicket
    }
  })
}

function mapChannelBudget(channelBudget) {
  return {
    id: channelBudget.id,
    vertical: channelBudget.vertical_id,
    target: channelBudget.target_id,
    budget: channelBudget.value.toFixed(2),
    budgetDate: {
      date: moment({
        year: channelBudget.year,
        month: channelBudget.month - 1,
        day: 1
      }).format('YYYY-MM')
    }
  }
}
export { channelBudgetsAuditListMap, mapChannelBudget }
