/**
 * Constants for tag keys
 *
 * @type {{PUSH_NOTIFICATION: string, SYNC_MALL: string}}
 */
export const TAG_KEYS = {
  SYNC_MALL: 'SYNCMALL',
  INCENTIVE: 'INCENTIVE',
  GROWTH_TOOL: 'GROWTH',
  PUSH_NOTIFICATION: 'PN',
  INBOX_MESSAGE: 'IM',
  SMS: 'SMS',
  SYNC_AIRSHIP: 'SYNCAIRSHIP'
}
