import axios from 'axios'
import store from '../store'
import router from '../router'
import {
  isUnauthorized,
  isForbidden,
  isNotFound,
  isBadRequest
} from '@/utils/errors'
import { USER_CONSTANTS } from '@/store/user/constants'

async function errorHandler(error) {
  const { config: originalRequest, config: { url: endpoint } = {} } = error

  if (endpoint.includes('/refresh-token')) {
    return handleRefreshToken(error)
  }

  if (!endpoint.includes('login'))
    return handleGeneralRequests(endpoint, originalRequest, error)

  return Promise.reject(error)
}

async function handleRefreshToken(error) {
  await store.dispatch('app/logout')
  await router.push({ name: 'homepage' })
  return Promise.reject(error)
}

async function handleGeneralRequests(endpoint, originalRequest, error) {
  if (isUnauthorized(error)) {
    if (
      !originalRequest._retry &&
      !endpoint.includes('/login') &&
      !endpoint.includes('/refresh-token')
    ) {
      originalRequest._retry = true

      // await store.dispatch('app/refreshToken')

      originalRequest.headers['Authorization'] = `Bearer ${
        store.user.securedState.get(USER_CONSTANTS.USER)[
          USER_CONSTANTS.AUTH_TOKEN
        ]
      }`

      return axios(originalRequest)
    }
    await store.dispatch('app/logout')
    await router.push({ name: 'homepage' })
  }

  if (isForbidden(error)) {
    try {
      if (!originalRequest._retry) {
        const response = await store.dispatch('app/refreshTokenAction')
        originalRequest._retry = true
        if (response) {
          originalRequest.headers[
            'Authorization'
          ] = `Bearer ${response.authToken}`
          return axios(originalRequest)
        }
      } else {
        await store.dispatch('app/showErrorNotification', {
          message: `You can't access this information`
        })
        await router.push({ name: 'home' })
      }
    } catch (error) {
      await store.dispatch('app/logout')
      await router.push({ name: 'homepage' })
    }
  }

  // api returned 400 or 404
  // for some reason we need to make a exception
  // we need to test URL against any array comparing
  if (isNotFound(error) || isBadRequest(error)) {
    await router.push({ name: '404' })
  }

  return Promise.reject(error)
}

export { errorHandler }
