import { formatDate } from '@/utils/commons/dateUtils'
import {
  currencyFormatWithCode,
  mapUserAction
} from '@/utils/commons/formatter'
/**
 * Converts a JSON channel object from the API format to the format expected by the FE.
 * @param {JSON} channel The channel JSON object to convert from.
 */
function channelMap(channel) {
  let { id, name, active, icon, keyword } = channel
  let channelType = channelTypeMap(channel.channel_type)
  return {
    id,
    name,
    active,
    icon,
    keyword,
    channelType
  }
}

/**
 * Converts a JSON channel type object from the API format to the format expected by the FE.
 * @param {JSON} channelType The channel type JSON object to convert from.
 */
function channelTypeMap(channelType) {
  let { id, name, keyword, active } = channelType
  return {
    id,
    name,
    keyword,
    active
  }
}

/**
 * Maps an array of channels to another array of channels in the expected FE format.
 * @param {Array} list The list of channels in BE format.
 */
function channelListMap(list) {
  var results = []
  for (let item of list) {
    results.push(channelMap(item))
  }
  return results
}

function channelNamesListMap(list) {
  var results = []
  for (let item of list) {
    results.push({
      text: item.name,
      value: item.id,
      keyword: item.keyword.toUpperCase()
    })
  }
  return results
}

function mapRate(cr) {
  const { id, value, country_id: countryId } = cr
  return {
    id,
    countryId,
    value: value.toFixed(2)
  }
}

function mapRateAuditsList(audits) {
  return audits.map(a => {
    const {
      id,
      country_id: campaignId,
      user_action: userAction,
      updated_by: updatedBy,
      updated_at: updatedAt,
      iso_code: isoCode,
      value,
      hc_ticket_id: hcTicket
    } = a
    return {
      id,
      campaignId,
      userAction: mapUserAction(userAction),
      updatedBy,
      updatedAt: formatDate(updatedAt),
      value: currencyFormatWithCode(value),
      targetFlag: {
        isoCode,
        description: isoCode
      },
      hcTicket
    }
  })
}

export {
  channelMap,
  channelListMap,
  channelTypeMap,
  channelNamesListMap,
  mapRate,
  mapRateAuditsList
}
