import {
  apiCreateMessage,
  apiDeleteMessage,
  apiGetMessage,
  apiGetMessages,
  apiRenderMessage,
  apiUpdateMessage
} from '@/api/messages'
import { apiGetVerticals } from '@/api/verticals'
import { apiGetInstances } from '@/api/instances'
import { viewTypes } from '@/utils/viewTypes'

/**
 * Fetches the messages from the API.
 * Returns the new page the user is currently in.
 * @param {*} param0
 * @param {*} request
 */
async function fetchMessages({ commit }, request) {
  let result
  let commitPayload = { messages: [], finalDataSize: 0, originalData: [] }
  try {
    let response = await apiGetMessages(request)
    result = { newPage: response.newPage }
    commitPayload = {
      messages: response.messages,
      finalDataSize: response.finalDataSize,
      originalData: response.originalData
    }
  } catch (error) {
    /* eslint-disable no-console */
    console.error(error)
    /* eslint-enable no-console */
  }

  commit('MESSAGES_UPDATED', commitPayload)
  return result
}

/**
 * Creates a message on the data base and returns the response given by the API.
 * @param {*} _
 * @param message
 * @param ignoreSyntaxErrors
 */
async function createMessage(_, { message, ignoreSyntaxErrors }) {
  return apiCreateMessage(message, ignoreSyntaxErrors)
}

/**
 * Updates the given message on the data base and returns
 * the response given by the API.
 * @param {*} _
 * @param {*} message
 * @param ignoreSyntaxErrors
 */
async function updateMessage(_, { message, ignoreSyntaxErrors }) {
  return apiUpdateMessage(message, ignoreSyntaxErrors)
}

/**
 * Fetches a single message.
 * @param {*} _
 * @param {*} param1
 */
async function fetchMessage(_, { id: messageId }) {
  return apiGetMessage(messageId)
}

/**
 * Deletes a message on the data base.
 * @param {*} _
 * @param {*} id
 */
async function deleteMessage(_, id) {
  let deleteRequest = await apiDeleteMessage(id)
  return deleteRequest
}

/**
 * Performs a request to render a message on the API side and returns the response.
 * @param {*} _
 * @param {*} template
 * @param {*} languageId
 * @param {integer} instanceId
 * @param {string} campaignName
 */
async function renderMessage(
  _,
  { subject, template, languageId, instanceId, campaignName }
) {
  let renderedTemplate = await apiRenderMessage(
    subject,
    template,
    languageId,
    instanceId,
    campaignName
  )
  return renderedTemplate
}

/**
 * Fetches the verticals from the API.
 * Returns the new page the user is currently in.
 * @param {*} param0
 * @param {*} request
 */
async function fetchVerticals({ commit }, request) {
  let commitPayload = { verticals: [] }
  try {
    let response = await apiGetVerticals(request)
    commitPayload = {
      verticals: response.verticals
    }
  } catch (error) {
    /* eslint-disable no-console */
    console.error(error)
    /* eslint-enable no-console */
  }
  commit('VERTICALS_UPDATED', commitPayload)
}

/**
 * Fetches the instances from the API.
 * @param {*} commit
 * @param {*} payload
 */
async function fetchInstances({ commit }, request) {
  const sortBy =
    request !== undefined && request.sort_by ? request.sort_by : 'name'
  const orderBy =
    request !== undefined && request.order_by ? request.order_by : 'asc'
  const { source } = request

  const req = {
    sort_by: sortBy,
    order_by: orderBy,
    view: viewTypes.BasicView
  }
  req.filters = {}
  if (request.tagId) {
    req.filters.tags = request.tagId
  }
  if (request.source !== undefined) {
    req.filters.source = source
  }

  let commitPayload = {
    instances: []
  }

  try {
    let response = await apiGetInstances(req)
    commitPayload = {
      instances: response.instances
    }
  } catch (error) {
    /* eslint-disable no-console */
    console.error(error)
    /* eslint-enable no-console */
  }
  commit('INSTANCES_UPDATED', commitPayload)
}

export default {
  fetchMessages,
  createMessage,
  fetchMessage,
  updateMessage,
  deleteMessage,
  renderMessage,
  fetchVerticals,
  fetchInstances
}
