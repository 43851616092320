import { apiGetVerticals } from '@/api/verticals'

/**
 * Fetches the verticals from the API, firing
 * any necessary logic associated (such as caching).
 * @param {*} context Vuex context (auto-injected).
 * @param {*} payload The payload to send to the API.
 * @param {*} useCache Whether to use the cache (default is true).
 */
async function fetchVerticals(context, payload, useCache = true) {
  let response = await apiGetVerticals(payload)
  let result = response.verticals
  context.commit('VERTICALS_CACHED', response.verticals)
  return result
}

export default {
  fetchVerticals
}
