function mapCardClassifications(items) {
  return items.map(i => mapCardClassification(i))
}

function mapCardClassification(item) {
  return {
    id: item.id,
    code: item.code,
    description: item.description,
    sellerIdRequired: item.seller_id_required
  }
}

export { mapCardClassifications, mapCardClassification }
