import { apiGetTags } from '@/api/tags'

/**
 * Fetches the tags from the BE database and return them
 * @param {*} param0 Vuex state (auto-injected).
 * @param query - query string to search tags
 */
async function fetchTags({ commit }, params) {
  const response = await apiGetTags(params)
  return response
}

export default {
  fetchTags
}
