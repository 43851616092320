function segmentMap(segment) {
  let {
    id,
    key_name: keyName,
    name,
    updated_at: updatedDate,
    active: isActive,
    status,
    source,
    metadata,
    namespace,
    datasource_mapping: datasourceMapping,
    columns_mapping: columnsMapping
  } = segment

  return {
    id,
    keyName,
    name,
    updatedDate,
    isActive,
    status,
    source,
    metadata,
    namespace,
    datasourceMapping,
    columnsMapping
  }
}

function segmentListMap(list) {
  var segments = []
  for (let item of list) {
    segments.push(segmentMap(item))
  }
  return segments
}

const segmentNameListMap = list => {
  let segments = []

  for (let item of list) {
    // get numRows from metadata
    const numRows =
      item.metadata && item.metadata.NumRows ? item.metadata.NumRows : 0
    segments.push({ text: item.name, value: item.id, numRows: numRows })
  }
  return segments
}

function segmentColumnsMap(cols, separateCountry) {
  // TODO: BACKEND SHOULD REMOVE COUNTRY WHEN PASSED A PARAMETER
  if (!separateCountry) {
    return { hasCountry: false, columns: cols }
  }

  let countryCol = cols.find(column => column.name === 'country')
  if (countryCol)
    return {
      hasCountry: true,
      columns: cols.filter(column => column.name !== 'country')
    }

  return {
    hasCountry: false,
    columns: cols
  }
}

function mapSegmentHistory(list) {
  return list.map(mapHistory)
}

function mapHistory(h) {
  return {
    timestamp: h.created_at,
    rows: h.rows,
    diff: h.diff
  }
}

export {
  segmentMap,
  segmentListMap,
  segmentNameListMap,
  segmentColumnsMap,
  mapSegmentHistory
}
