import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const INSTANCES_MODULE = 'instances'

export default [
  {
    path: '/instances',
    component: loadView('ViewInstance', 'instances'),
    children: [
      {
        path: '',
        name: 'instances',
        component: loadView('ViewInstanceList', 'instances'),
        meta: {
          navigationDrawer: true,
          module: INSTANCES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_SEGMENTATION
        }
      },
      {
        path: 'new',
        name: 'instance-add',
        component: loadView('ViewInstanceAdd', 'instances'),
        meta: {
          navigationDrawer: true,
          module: INSTANCES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_SEGMENT_CREATION,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: ':instanceId',
        name: 'instance-view',
        component: loadView('ViewInstanceView', 'instances'),
        meta: {
          navigationDrawer: true,
          module: INSTANCES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_SEGMENTATION,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: 'clone/:instanceId',
        name: 'instance-clone',
        component: loadView('ViewInstanceClone', 'instances'),
        meta: {
          navigationDrawer: true,
          module: INSTANCES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_SEGMENT_CREATION,
          whiteBackground: true,
          savePrevRoute: true
        }
      }
    ]
  }
]
