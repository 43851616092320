import { butterflyAxios } from '../axios'
import {
  isCreatedResponse,
  isOKResponse,
  parseSuccessResponse
} from '../utils/response'

const endpoint = {
  inboxmessages: 'inboxmessages',
  inboxmessage: id => `inboxmessages/${id}`,
  preview: 'inboxmessages/preview'
}

const apiCreate = async inboxMessage => {
  const response = await butterflyAxios.post(
    endpoint.inboxmessages,
    inboxMessage
  )
  if (isCreatedResponse(response)) {
    return parseSuccessResponse(response)
  }

  return undefined
}

const apiUpdate = async inboxMessage => {
  const response = await butterflyAxios.put(
    endpoint.inboxmessage(inboxMessage.id),
    inboxMessage
  )

  if (isOKResponse(response)) {
    return parseSuccessResponse(response)
  }

  return response
}

const apiPreview = async payload => {
  const response = await butterflyAxios.post(endpoint.preview, payload)

  if (isOKResponse(response)) {
    const res = parseSuccessResponse(response)
    return res.response
  }

  return response
}

export { apiCreate, apiUpdate, apiPreview }
