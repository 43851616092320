import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'
import { currencyFormatWithCode } from '@/utils/commons/formatter'
import { parseStatusVanityFrom } from '@/api/campaigns/helpers'

const action = {
  CREATE: () => {
    return {
      event: 'create',
      label: i18n.t('budgets.title.create'),
      icon: 'add',
      isPrimary: true,
      options: []
    }
  },
  EDIT: () => {
    return {
      event: 'view',
      icon: 'edit',
      label: i18n.t('edit')
    }
  },
  VIEW: () => {
    return {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('view')
    }
  },
  VIEW_CAMPAIGN: campaign => {
    return {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('view'),
      path: `/campaigns/sms/${campaign.id}`
    }
  },
  AUDIT: () => {
    return {
      event: 'audit',
      icon: 'mdi-update',
      label: i18n.t('campaigns.actions.audit')
    }
  }
}

/**
 * Responsible for declaring the actions that can be performed on a specific sms rate.
 */
const actionsOnChannelBudgets = (state, getters, rootState, rootGetters) => {
  return function(smsrate) {
    let actions = []

    if (!smsrate) return actions

    /**
     * Specifies that the current user can perform the below actions.
     * action: the action that the user can perform
     * permission: path to the 'getter' that confirms the permission.
     */

    let potentialActions = [
      {
        actionGroup: [
          {
            action: action.EDIT(smsrate),
            permission: 'user/canEditBudget'
          },
          {
            action: action.VIEW(smsrate),
            permission: 'user/canSeeBudget'
          }
        ]
      },
      {
        label: i18n.t('campaigns.actions.more'),
        icon: 'more_vert',
        menu: {
          list: getMoreActions(smsrate),
          date: {
            display: false
          }
        }
      }
    ]
    actions = filterActionsListByPermission(
      rootGetters,
      smsrate.createdBy,
      potentialActions
    )

    return actions
  }
}

const getMoreActions = smsrate => {
  const moreList = [
    {
      action: action.AUDIT(smsrate),
      permission: 'user/canSeeBudget'
    }
  ]
  return moreList
}

/**
 * The actions that the user can perform on the sms rates per country list as a whole (i.e. on the group).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
const actionsOnChannelBudgetsList = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const actions = []
  if (rootGetters['user/canCreateBudget']) {
    actions.push(action.CREATE())
  }

  return actions
}

const smsBudgetsListDTO = (state, getters, rootState, rootGetters) => {
  if (!state.channelBudgets) return []
  return state.channelBudgets.map(smsBudget => {
    const data = smsBudgetToListDTO(smsBudget)
    return {
      ...data,
      actions: actionsOnChannelBudgets(
        state,
        getters,
        rootState,
        rootGetters
      )(smsBudget)
    }
  })
}

const smsBudgetToListDTO = smsBudget => {
  return {
    id: smsBudget.id,
    name: smsBudget.name,
    description: smsBudget.description,
    targetFlag: {
      isoCode: smsBudget.target_code,
      description: smsBudget.target_code
    },
    vertical_name: smsBudget.vertical_name,
    month: `${smsBudget.month}/${smsBudget.year}`,
    budget: currencyFormatWithCode(smsBudget.value),
    remaining: currencyFormatWithCode(smsBudget.remaining),
    updated_at: formatDate(smsBudget.updated_at),
    updated_by: smsBudget.updated_by,
    canExpand: true
  }
}

const budgetCampaignsListDTO = (state, getters, rootState, rootGetters) => {
  if (!state.budgetCampaigns) return []
  return state.budgetCampaigns.map(budgetCampaign => {
    const data = budgetCampaignToListDTO(budgetCampaign)
    return {
      ...data,
      actions: actionsOnBudgetCampaigns(
        state,
        getters,
        rootState,
        rootGetters
      )(budgetCampaign)
    }
  })
}
const budgetCampaignToListDTO = budgetCampaign => {
  const chipVanityData = parseStatusVanityFrom(budgetCampaign.status)
  return {
    id: budgetCampaign.id,
    name: budgetCampaign.name,
    status: {
      keyword: budgetCampaign.status,
      label: chipVanityData.label,
      type: 'JChip',
      color: chipVanityData.color
    },
    cost: currencyFormatWithCode(budgetCampaign.cost),
    updated_at: formatDate(budgetCampaign.updated_at),
    updated_by: budgetCampaign.updated_by
  }
}

const actionsOnBudgetCampaigns = (state, getters, rootState, rootGetters) => {
  return function(budgetCampaign) {
    if (!budgetCampaign) return []

    const actions = filterActionsListByPermission(rootGetters, null, [
      {
        action: action.VIEW_CAMPAIGN(budgetCampaign),
        permission: 'user/canSeeCampaign'
      }
    ])

    return actions
  }
}

export default {
  actionsOnChannelBudgetsList,
  actionsOnChannelBudgets,
  smsBudgetsListDTO,
  budgetCampaignsListDTO
}
