import {
  apiCreateQueryTemplate,
  apiFetchQueryTemplateAreas,
  apiValidateQueryTemplate,
  apiGetQueryTemplates,
  apiDeleteQueryTemplate,
  apiGetQueryTemplate,
  apiUpdateQueryTemplate,
  apiGetTemplateAudit
} from '@/api/querytemplates'

/**
 * Creates a query template.
 * Returns the response of the API.
 * @param {*} _ Vuex State. Unused, as Vuex is being used as a centralized databag.
 * @param {*} queryTemplate The query template to create.
 */
async function createQueryTemplate(_, queryTemplate) {
  const queryTemplateCreated = await apiCreateQueryTemplate(queryTemplate)
  return queryTemplateCreated
}
/**
 * Fetch the query template areas.
 * Returns the response of the API.
 * @param commit Vuex State.
 */
async function fetchQueryTemplateAreas({ commit }) {
  const areas = await apiFetchQueryTemplateAreas()
  commit('AREAS_UPDATED', { areas })
}

/**
 * Fetch the query template areas.
 * Returns the response of the API.
 * @param {*} _ Vuex State.
 * @param {*} queryTemplate The query template to validate.
 */
async function validateQueryTemplate(_, queryTemplate) {
  const validate = await apiValidateQueryTemplate(queryTemplate)
  return validate
}

/**
 * Fetches the query templates from the API.
 * @param {*} commit
 * @param {*} payload
 */
async function fetchQueryTemplates({ commit }, request) {
  let commitPayload = {
    queryTemplates: [],
    finalDataSize: 0,
    originalData: []
  }
  try {
    let response = await apiGetQueryTemplates(request)
    commitPayload = {
      queryTemplates: response.queryTemplates,
      finalDataSize: response.finalDataSize,
      originalData: response.originalData
    }
  } catch (error) {
    console.debug(error)
  }
  commit('TEMPLATES_UPDATED', commitPayload)
  return commitPayload
}

/**
 * Deletes a query template on the data base.
 * @param {*} _
 * @param {*} id
 */
async function deleteQueryTemplate(_, id) {
  const deleted = apiDeleteQueryTemplate(id)
  return deleted
}

/**
 * Fetches a single query templates.
 * @param _
 * @param instanceId
 * @param view
 */
async function fetchQueryTemplate(_, { id: templateId }) {
  const instance = await apiGetQueryTemplate(templateId)
  return instance
}

/**
 * Updates the given query template on the data base and returns
 * the response given by the API.
 * @param {*} _
 * @param {*} template
 */
async function updateQueryTemplate(_, template) {
  const templateUpdated = await apiUpdateQueryTemplate(template)
  if (templateUpdated) {
    return templateUpdated
  }
  return false
}
/**
 * Fetches a the audit info for a single template.
 * @param {*} _
 * @param {*} payload
 */
async function fetchTemplateAudit(_, payload) {
  const res = await apiGetTemplateAudit(payload)
  return res
}

export default {
  createQueryTemplate,
  fetchQueryTemplateAreas,
  validateQueryTemplate,
  fetchQueryTemplates,
  deleteQueryTemplate,
  fetchQueryTemplate,
  updateQueryTemplate,
  fetchTemplateAudit
}
