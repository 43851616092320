/**
 * Types of views that BE supports
 * full: wants to be a full version of object
 * basic: a short version of the object
 * @type {{FullView: string, BasicView: string}}
 */
export const viewTypes = {
  FullView: 'full',
  BasicView: 'basic'
}
