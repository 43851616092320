import { apiGetTargets } from '@/api/targets'

/**
 * Fetches the targets from the BE database and
 * updates the vuex state accordingly.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} payload The payload to send to the API (query).
 */
async function fetchTargets({ commit }, payload) {
  var response = await apiGetTargets(payload)
  commit('TARGETS_UPDATED', response)
  return response
}

export default {
  fetchTargets
}
