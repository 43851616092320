import { i18n } from '@/i18n'

const currencyFormatter = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const currencyFormat = value => {
  const valStr = String(value)
  if (!isNaN(valStr)) {
    return currencyFormatter
      .format(Number(valStr.replace(',', '')).toFixed(2))
      .replace('$', '')
  }
  return value
}

const currencyFormatWithCode = (value, currencyCode = 'USD') =>
  `${currencyFormat(value)} ${currencyCode}`

const urlify = text => {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
  return text.replace(urlRegex, function(url, _, c) {
    const url2 = c === 'www.' ? 'http://' + url : url
    return (
      '<a href="' + url2 + '" target="_blank" rel="noreferrer">' + url + '</a>'
    )
  })
}

const userActions = {
  CREATE: 'CREATE',
  INSERT: 'INSERT',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
}

function mapUserAction(action) {
  switch (action.toUpperCase()) {
    case userActions.CREATE:
      return i18n.t('campaigns.audit.userActions.create')
    case userActions.INSERT:
      return i18n.t('campaigns.audit.userActions.create')
    case userActions.UPDATE:
      return i18n.t('campaigns.audit.userActions.update')
    case userActions.DELETE:
      return i18n.t('campaigns.audit.userActions.delete')
    default:
      return ''
  }
}

export { currencyFormat, currencyFormatWithCode, urlify, mapUserAction }
