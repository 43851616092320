/**
 * Signal's Vuex state that the code snippets have been updated.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The new code snippets content.
 */
function CODE_SNIPPETS_UPDATED(
  state,
  { codeSnippets, finalDataSize, originalData }
) {
  state.codeSnippets = codeSnippets
  state.finalDataSize = finalDataSize
  state.originalData = originalData
}

export default {
  CODE_SNIPPETS_UPDATED
}
