import { butterflyAxios } from '@/api/axios'
import {
  channelMap,
  channelListMap,
  channelNamesListMap,
  mapRateAuditsList,
  mapRate
} from './responseMapper'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'
import { mapCreateRate, mapUpdateRate } from './requestMapper'

var endpoint = {
  channels: 'channels',
  channelKeyword: keyword => `channels-keyword/${keyword}`,
  rates: channelId => `channels/${channelId}/rates`,
  rateById: (channelId, id) => `channels/${channelId}/rates/${id}`,
  rateAudits: (id, rateId) => `channels/${id}/rates/${rateId}/audits`
}

async function apiGetChannels(request) {
  const active = request && request.active ? request.active : true
  const sortBy = request && request.sort_by ? request.sort_by : 'name'
  const orderBy = request && request.order_by ? request.order_by : 'asc'

  let payload = {
    active: active,
    sort_by: sortBy,
    order_by: orderBy
  }

  let response = await butterflyAxios.get(endpoint.channels, {
    params: { ...payload }
  })

  let data
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    data = processedResponse
  } else {
    throw processedResponse
  }

  // Convert from JSON array to the expected mapping
  return channelListMap(data)
}

async function apiGetChannelsNames() {
  let { data } = await butterflyAxios.get(endpoint.channels, {
    params: {
      active: true,
      sort_by: 'name',
      order_by: 'asc'
    }
  })

  let channels = channelNamesListMap(data.data)
  return channels
}

async function apiGetChannelByKeyword(keyword) {
  let response = await butterflyAxios.get(endpoint.channelKeyword(keyword))

  if (isOKResponse(response)) {
    let result = parseSuccessResponse(response)
    let processedResponse = result.response
    if (result.success) {
      return channelMap(processedResponse)
    }
  }

  throw response
}

async function apiGetRates(request) {
  const payload = {
    page: request.page,
    per_page: request.perPage,
    order_by: request.filters.order_by,
    sort_by: request.filters.sort_by
  }
  let response = await butterflyAxios.get(endpoint.rates(request.channelId), {
    params: { ...payload }
  })

  if (isOKResponse(response)) {
    let result = parseSuccessResponse(response)
    let processedResponse = result.response
    let meta = result.meta
    if (result.success) {
      const finalDataSize = meta ? meta.total_records : 0
      return {
        finalDataSize,
        channelRates: processedResponse
      }
    }
  }
  throw response
}

async function apiGetRateById(request) {
  let response = await butterflyAxios.get(
    endpoint.rateById(request.channelId, request.id)
  )
  if (isOKResponse(response)) {
    let result = parseSuccessResponse(response)
    let processedResponse = result.response
    if (result.success) {
      return mapRate(processedResponse)
    }
  }
  throw response
}

const apiCreateRate = async rate => {
  const response = await butterflyAxios.post(
    endpoint.rates(rate.channelId),
    mapCreateRate(rate)
  )
  return response.data
}

const apiUpdateRate = async rate => {
  const response = await butterflyAxios.put(
    endpoint.rateById(rate.channelId, rate.id),
    mapUpdateRate(rate)
  )
  return response.data
}

const apiGetRateAudits = async request => {
  const payload = {
    page: request.page,
    limit: request.perPage,
    order_by: request.orderBy,
    sort_by: request.sortBy
  }

  let url = endpoint.rateAudits(request.channelId, request.id)
  const { data } = await butterflyAxios.post(
    url,
    {},
    { params: { ...payload } }
  )

  const list = data.data
  const pagination = data.meta

  const auditsList = mapRateAuditsList(list)

  return {
    auditsList,
    finalDataSize: pagination ? pagination.total_records : list.length,
    newPage: pagination ? pagination.page : 1,
    originalData: list
  }
}

export {
  apiGetChannels,
  apiGetChannelsNames,
  apiGetChannelByKeyword,
  apiGetRates,
  apiGetRateById,
  apiCreateRate,
  apiUpdateRate,
  apiGetRateAudits
}
