/**
 * Converts a JSON job object from the API format to the format expected by the FE.
 * @param {JSON} job The job JSON object to convert from.
 */

import { formatUtcDate } from '@/utils/commons/dateUtils'

function jobMap(job) {
  let {
    id,
    name,
    next_run_at: nextRunAt,
    last_run_at: lastRunAt,
    schedule
  } = job

  return {
    id,
    name,
    lastRunAt,
    nextRunAt,
    schedule
  }
}

/**
 * Maps an array of jobs to another array of jobs in the expected FE format.
 * @param {Array} list The list of jobs in BE format.
 */
function jobListV1Map(list) {
  var jobs = []
  for (let item of list) {
    jobs.push(jobMap(item))
  }
  return jobs
}

function jobListMap(list) {
  return list.map(j => {
    return {
      id: j.id,
      name: j.name,
      status: j.status,
      recurring: j.recurring,
      type: j.type,
      next_execution: j.next_execution ? formatUtcDate(j.next_execution) : '',
      last_execution: j.last_execution ? formatUtcDate(j.last_execution) : '',
      end_date: j.end_date ? formatUtcDate(j.end_date) : '',
      max_runs: j.max_runs,
      run_count: j.run_count,
      start_date: formatUtcDate(j.start_date)
    }
  })
}

export { jobMap, jobListV1Map, jobListMap }
