import { isOneOf } from '@/api/utils/errors'

/**
 * Slices an array of contents in order to get an array with countPerPage elements of a given
 * page. This method is aware of any edge cases that may occur with pagination (such as
 * requesting a page that is not possible), and will return the correct page number.
 * Return - an object with {dataset,newPage}.
 * @param {Array} dataset The array  to apply pagination to.
 * @param {*} page The page number (1 based). If out-of-range values are passed, they are
 * automatically clamped (e.g. -1 gets converted to 1).
 * @param {*} countPerPage The number of elements per page.
 */
function paginateData(dataset, page, countPerPage) {
  let result = dataset
  let newPage = Math.max(page, 1)
  if (dataset && dataset.length > countPerPage) {
    let maxPage = Math.ceil(dataset.length / countPerPage)
    newPage = Math.min(newPage, maxPage) // limit the page to the number of pages
    // the data set can show.
    let startIndex = (newPage - 1) * countPerPage
    let endIndex = newPage * countPerPage
    result = dataset.slice(startIndex, endIndex)
  }
  return { dataset: result, newPage: newPage }
}

export { paginateData, isOneOf }
