function getStatus(error) {
  var { response: { status } = {} } = error
  return status
}

function getFormErrors(error) {
  var { response: { data: { errors } = {} } = {} } = error

  return errors
}

function getHeaders(response) {
  var { headers } = response

  return headers
}

function isBadRequest(error) {
  return getStatus(error) === 400
}

function isUnauthorized(error) {
  return getStatus(error) === 401
}

function isForbidden(error) {
  return getStatus(error) === 403
}

function isNotFound(error) {
  return getStatus(error) === 404
}

function isNotAcceptable(error) {
  return getStatus(error) === 406
}

function isConflict(error) {
  return getStatus(error) === 409
}

function hasFormErrors(error) {
  var errors = getFormErrors(error)

  if (errors) return true
  return false
}

const validationErrors = error => {
  return getStatus(error) === 422
}

function hasAttachment(response) {
  var { 'content-disposition': contentDisposition } = getHeaders(response) || {}

  return contentDisposition && contentDisposition.indexOf('attachment') !== -1
}

function getErrorMessage(error) {
  var { response: { data: { message } = {} } = {} } = error
  return message
}

function mapFormErrors(error) {
  var errors = getFormErrors(error)

  var map = {}
  if (errors) {
    errors.forEach(item => {
      let messages = []
      item.errors.forEach(itemError => {
        messages.push(itemError)
      })
      map[item.field] = messages
    })
  }
  return map
}

export {
  isUnauthorized,
  isNotFound,
  isBadRequest,
  isForbidden,
  isNotAcceptable,
  isConflict,
  hasAttachment,
  hasFormErrors,
  mapFormErrors,
  getErrorMessage,
  validationErrors
}
