/**
 * Converts a JSON tag object from the API format to the format expected by the FE.
 * @param {JSON} tag The tag JSON object to convert from.
 */
function tagMap(tag) {
  let { id, key, description } = tag

  return {
    value: id,
    text: key,
    description
  }
}

/**
 * Maps an array of tags to another array of tags in the expected FE format.
 * @param {Array} list The list of tags in BE format.
 */
function tagListMap(list) {
  let tags = []
  for (let item of list) {
    tags.push(tagMap(item))
  }
  return tags
}

export { tagMap, tagListMap }
