import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'
import store from '@/store'

/**
 * Converts a JSON tag object from the API format to the format expected by the FE.
 * @param {JSON} tag The tag JSON object to convert from.
 */
function tagMap(tag) {
  let { id, name, active, updated_by: updatedBy, updated_at: updatedAt } = tag

  return {
    id,
    name,
    active,
    updatedBy,
    updatedAt
  }
}

/**
 * Maps an array of tags to another array of tags in the expected FE format.
 * @param {Array} list The list of tags in BE format.
 */
function tagListMap(list) {
  return list.map(tagMap).map(t => {
    return {
      ...t,
      active: {
        value: t.active,
        label: t.active ? i18n.t('enabled') : i18n.t('disabled'),
        color: t.active ? '#2A8091' : '#8E8E93'
      },
      changeStatus: {
        value: t.active,
        action: 'changeStatus',
        disabled: !store.getters['user/canUpdateStatusCampaignTags']
      },
      updatedAt: formatDate(t.updatedAt),
      actions: mapActions(t)
    }
  })
}

function mapActions(tag) {
  return filterActionsListByPermission(store.getters, tag.updatedBy, [
    {
      actionGroup: [
        { action: actions.EDIT(tag), permission: 'user/canUpdateCampaignTags' },
        { action: actions.VIEW(tag), permission: 'user/canSeeCampaignTags' }
      ]
    },
    {
      action: actions.AUDIT(tag),
      permission: 'user/canSeeCampaignTags'
    }
  ])
}

const actions = {
  EDIT: () => {
    return {
      event: 'edit',
      icon: 'edit',
      label: i18n.t('tags.actions.update')
    }
  },
  VIEW: () => {
    return {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('tags.actions.view')
    }
  },
  AUDIT: () => {
    return {
      event: 'audit',
      icon: 'mdi-update',
      label: i18n.t('audit')
    }
  }
}

const mapTagAudit = audits => {
  return !audits ? [] : audits.map(auditMap)
}

const auditMap = audit => {
  return {
    userAction:
      audit.action === 'INSERT'
        ? i18n.t('tags.audit.actions.create')
        : i18n.t('tags.audit.actions.update'),
    status: {
      value: audit.active,
      label: audit.active ? i18n.t('enabled') : i18n.t('disabled'),
      type: 'JChip',
      color: audit.active ? '#2A8091' : '#8E8E93'
    },
    updatedBy: audit.updated_by,
    updatedAt: formatDate(audit.updated_at)
  }
}

const tagStringListMap = list => {
  return list.map(t => t.name)
}

export { tagMap, tagListMap, mapTagAudit, tagStringListMap }
