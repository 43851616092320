import { fireworksAxios } from './axios.js'
import store from '@/store'
import { USER_CONSTANTS } from '@/store/user/constants'

const endpoint = {
  login: 'v2/login',
  refreshToken: 'v1/refresh-token',
  logout: 'v1/logout'
}

const apiLogin = async authCode => {
  const response = await fireworksAxios.post(endpoint.login, {
    auth_code: authCode,
    redirect_uri: window.CONFIG.APP_ENDPOINT
  })

  return _handleSuccessfulLogin(response.data.data)
}

const refreshToken = async () => {
  const authToken = store.state.user.securedState.get(USER_CONSTANTS.USER)[
    USER_CONSTANTS.AUTH_TOKEN
  ]
  const refreshToken = store.state.user.securedState.get(USER_CONSTANTS.USER)[
    USER_CONSTANTS.REFRESH_TOKEN
  ]

  const { data } = await fireworksAxios.post(endpoint.refreshToken, {
    auth_token: authToken,
    refresh_token: refreshToken
  })

  const res = {
    authToken: data.auth_token,
    refreshToken: data.refresh_token
  }

  await store.dispatch('app/refreshLogin', res)
  return res
}

async function _handleSuccessfulLogin({
  email,
  auth_token: authToken,
  refresh_token: refreshToken,
  picture,
  name,
  permissions,
  targets
}) {
  await store.dispatch('app/login', {
    authToken,
    refreshToken,
    email,
    picture,
    name,
    permissions,
    targets
  })

  return { email, authToken, refreshToken, picture }
}

const apiLogout = () => {
  fireworksAxios.post(endpoint.logout)
}

export { apiLogin, apiLogout, refreshToken }
