import { apiGetProjects, apiGetAllProjects } from '@/api/projects'

async function fetchProjects({ commit }, payload) {
  const response = await apiGetProjects(payload)
  commit('PROJECTS_UPDATED', response)
}
async function fetchAllProjects({ commit }, payload) {
  const response = await apiGetAllProjects(payload)
  commit('PROJECTS_UPDATED', response)
}

export default {
  fetchProjects,
  fetchAllProjects
}
