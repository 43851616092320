import { i18n } from '@/i18n'
import { STATE_COLORS } from '@/api/campaigns/constants'

/**
 * Parses the vanity properties of the status of a campaign.
 * Essentially, computes the label and color that the 'status' value should have.
 * @param {string} campaignState The statusof the campaign.
 */
export function parseStatusVanityFrom(campaignState) {
  let label = i18n.t('campaigns.campaign_state.error')
  let color = STATE_COLORS.ERROR
  // If the campaign state is valid

  if (campaignState) {
    let upperCasedState = campaignState.toUpperCase()

    if (upperCasedState in STATE_COLORS) {
      // if it's a valid state
      label = i18n.t('campaigns.campaign_state.' + campaignState)
      color = STATE_COLORS[upperCasedState]
    }
  }
  return { label, color } // returns an object with the label and color
}
