import { butterflyAxios } from '@/api/axios'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'
import { logicGroupAuditsMap } from '@/api/logicgroups/responseMapper'

const endpoints = {
  lg: 'logicgroups',
  updateActive: id => `logicgroups/${id}`,
  audit: id => `logicgroups/${id}/audit`
}

const apiGetLogicGroups = async request => {
  const response = await butterflyAxios.get(endpoints.lg, {
    params: { ...request }
  })
  if (isOKResponse(response)) {
    const { response: logicGroups, meta: pagination } = parseSuccessResponse(
      response
    )
    return {
      logicGroups: logicGroups,
      finalDataSize: pagination ? pagination.total_records : logicGroups.length
    }
  } else {
    throw response
  }
}

const apiGetLogicGroupAudit = async request => {
  const payload = {
    page: request.page,
    limit: request.perPage,
    order_by: request.orderBy,
    sort_by: request.sortBy
  }
  const response = await butterflyAxios.get(endpoints.audit(request.id), {
    params: { ...payload }
  })
  if (isOKResponse(response)) {
    const { response: audits, meta: pagination } = parseSuccessResponse(
      response
    )
    return {
      audit: logicGroupAuditsMap(audits),
      finalDataSize: pagination ? pagination.total_records : audits.length
    }
  } else {
    throw response
  }
}

const apiPatchStatus = async item => {
  const res = await butterflyAxios.patch(endpoints.updateActive(item.id), {
    active: item.active
  })

  if (!isOKResponse(res)) {
    throw res
  }
}

const apiCreateLogicGroup = async logicGroup => {
  const response = await butterflyAxios.post(endpoints.lg, logicGroup)
  return response.data
}

export {
  apiGetLogicGroups,
  apiGetLogicGroupAudit,
  apiPatchStatus,
  apiCreateLogicGroup
}
