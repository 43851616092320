import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const SEGMENTS_MODULE = 'segments'

export default [
  {
    path: '/segments',
    component: loadView('ViewSegment', 'segments'),
    children: [
      {
        path: '',
        name: 'segments',
        component: loadView('ViewSegmentList', 'segments'),
        meta: {
          navigationDrawer: true,
          module: SEGMENTS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_DATASOURCE
        }
      },
      {
        path: 'new',
        name: 'segment-new',
        component: loadView('SegmentAdd', 'segments'),
        meta: {
          navigationDrawer: true,
          module: SEGMENTS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_SEGMENT_CREATION,
          whiteBackground: true
        }
      },
      {
        path: 'edit/:id',
        name: 'segment-edit',
        component: loadView('SegmentEdit', 'segments'),
        meta: {
          navigationDrawer: true,
          module: SEGMENTS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_SEGMENT_UPDATE,
          whiteBackground: true
        }
      },
      {
        path: ':segmentId',
        name: 'segment-view',
        component: loadView('ViewSegmentView', 'segments'),
        meta: {
          navigationDrawer: true,
          module: SEGMENTS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_DATASOURCE,
          whiteBackground: true
        }
      }
    ]
  }
]
