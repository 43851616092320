import { butterflyAxios } from '@/api/axios'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'
import {
  projectListMap,
  projectAllListMap
} from '@/api/projects/responseMapper'

const endpoint = {
  verticals: 'verticals',
  allVerticals: 'verticals/projects'
}

async function apiGetProjects(payload) {
  let response = await butterflyAxios.get(
    `${endpoint.verticals}/${payload.vertical_id}/projects`
  )

  if (isOKResponse(response)) {
    let data = parseSuccessResponse(response)
    return projectListMap(data.response)
  }
  throw response
}

async function apiGetAllProjects() {
  let response = await butterflyAxios.get(endpoint.allVerticals)

  if (isOKResponse(response)) {
    let data = parseSuccessResponse(response)
    return projectAllListMap(data.response)
  }
  throw response
}

export { apiGetProjects, apiGetAllProjects }
