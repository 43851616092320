import store from '@/store'
import { USER_CONSTANTS } from './constants'
import { setAreaPropertyMultiple } from '@/store/securedStorage'

/**
 * Helper function to set the value of a property that belongs to the user.
 * @param {vuexState} state The vuex state.
 * @param {*} property The property name.
 * @param {*} value The value.
 */
function setUserProperty(state, property, value) {
  setAreaPropertyMultiple(state, USER_CONSTANTS.USER, [
    { property: property, value: value }
  ])
}

function EMAIL_UPDATED(state, email) {
  setUserProperty(state, USER_CONSTANTS.EMAIL, email)
}

function PICTURE_UPDATED_LS(state, picture) {
  setUserProperty(state, USER_CONSTANTS.PICTURE, picture)
  PICTURE_UPDATED(state, picture)
}

function PICTURE_UPDATED(state, picture) {
  state.picture = picture
}

function TARGET_UPDATED(state, targets) {
  setUserProperty(state, USER_CONSTANTS.TARGETS, targets)
  state.targets = targets
}

function NAME_UPDATED_LS(state, name) {
  setUserProperty(state, USER_CONSTANTS.NAME, name)
  NAME_UPDATED(state, name)
}

function NAME_UPDATED(state, name) {
  state.name = name
}

function TOKENS_UPDATED(state, { authToken, refreshToken }) {
  setAreaPropertyMultiple(state, USER_CONSTANTS.USER, [
    { property: USER_CONSTANTS.AUTH_TOKEN, value: authToken },
    { property: USER_CONSTANTS.REFRESH_TOKEN, value: refreshToken }
  ])
}

function PERMISSIONS_UPDATED(state, permissions) {
  setUserProperty(state, USER_CONSTANTS.PERMISSIONS, permissions)
  store.dispatch('app/updateAvailableMenu')
}

/**
 * Clears all user data locally stored.
 */
function CLEAR_USER_DATA(state) {
  state.securedState.removeAll()
}

export default {
  EMAIL_UPDATED,
  PICTURE_UPDATED_LS,
  PICTURE_UPDATED,
  TOKENS_UPDATED,
  NAME_UPDATED_LS,
  NAME_UPDATED,
  PERMISSIONS_UPDATED,
  CLEAR_USER_DATA,
  TARGET_UPDATED
}
