import { apiGetJobs, apiGetJobsV1 } from '@/api/jobs'

/**
 * Fetches the jobs from the API.
 * Returns the new page where the user is currently.
 * @param {*} param0
 * @param {*} param1
 */
async function fetchJobsV1(
  { commit },
  { q, page, perPage, customFilterFunction }
) {
  let result
  try {
    let response = await apiGetJobsV1({
      q,
      page,
      perPage,
      customFilterFunction
    })
    commit('JOBS_V1_UPDATED', {
      jobs: response.jobs,
      finalDataSize: response.finalDataSize
    })
    result = { newPage: response.newPage }
  } catch (error) {
    commit('JOBS_V1_UPDATED', {
      jobs: [],
      finalDataSize: 0
    })
  }
  return result
}

async function fetchJobs({ commit }, payload) {
  const response = await apiGetJobs(payload)
  commit('JOBS_UPDATED', {
    jobs: response.jobs,
    finalDataSize: response.finalDataSize
  })
  return response
}

export default {
  fetchJobs,
  fetchJobsV1
}
