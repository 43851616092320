import moment from 'moment/moment'

function mapChannelBudgetReq(channelBudget) {
  return {
    value: parseFloat(channelBudget.budget),
    target_id: channelBudget.target,
    hc_ticket_id: parseInt(channelBudget.hcTicket),
    channel_id: channelBudget.channelId,
    year: moment(channelBudget.budgetDate.date).year(),
    month: moment(channelBudget.budgetDate.date).month() + 1
  }
}

export { mapChannelBudgetReq }
