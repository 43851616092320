import { butterflyAxios } from '../axios'
import {
  isCreatedResponse,
  isOKResponse,
  parseSuccessResponse
} from '../utils/response'

const endpoint = {
  sms: 'sms',
  smsMessage: id => `sms/${id}`,
  preview: 'sms/preview'
}

const apiCreate = async sms => {
  const response = await butterflyAxios.post(endpoint.sms, sms)
  if (isCreatedResponse(response)) {
    return parseSuccessResponse(response)
  }

  return undefined
}

const apiUpdate = async sms => {
  const response = await butterflyAxios.put(endpoint.smsMessage(sms.id), sms)

  if (isOKResponse(response)) {
    return parseSuccessResponse(response)
  }

  return response
}

const apiPreview = async payload => {
  const response = await butterflyAxios.post(endpoint.preview, payload)

  if (isOKResponse(response)) {
    const res = parseSuccessResponse(response)
    return res.response
  }
}

export { apiCreate, apiUpdate, apiPreview }
