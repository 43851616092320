import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Touch } from 'vuetify/lib/directives'
import BigQueryIcon from '@/components/Icons/BigQueryIcon'

Vue.use(Vuetify, {
  directives: {
    Touch
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      bigquery: {
        component: BigQueryIcon
      }
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#2A8091',
        accent: '#2A8091',
        error: '#b81a0a'
      }
    }
  }
})
