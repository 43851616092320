function CAMPAIGNS_EVENTS_UPDATED(
  state,
  { campaingsEvents, finalCampaignsDataSize }
) {
  state.campaingsEvents = campaingsEvents
  state.finalCampaingsDataSize = finalCampaignsDataSize
}

function SEGMENTS_EVENTS_UPDATED(
  state,
  { segmentsEvents, finalSegmentsDataSize }
) {
  state.segmentsEvents = segmentsEvents
  state.finalSegmentsDataSize = finalSegmentsDataSize
}

export default {
  CAMPAIGNS_EVENTS_UPDATED,
  SEGMENTS_EVENTS_UPDATED
}
