import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { securedLS } from '@/store/securedStorage'

var state = {
  loadingPage: false,
  menu: [],
  snackbar: null,
  securedState: securedLS,
  selectedCountry: null,
  // The List View search settings.
  listSearchSettings: {
    searchBar: null,
    context: null,
    clientSideFilters: null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
