<template>
  <div class="status-loading grey--text text--darken-1">
    <v-progress-circular
      indeterminate
      color="primary"
      :class="`mx-auto mt-12 ${centered ? 'mb-12' : 'mb-4'}`"
    />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'StatusLoading',
  props: {
    text: {
      type: String,
      default: ''
    },
    centered: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.status-loading {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
}
</style>
