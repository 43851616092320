import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'
const CODE_SNIPPETS_MODULE = 'code-snippets'

export default [
  {
    path: '/code-snippets',
    component: loadView('ViewCodeSnippets', 'code-snippets'),
    children: [
      {
        path: '',
        name: 'code-snippets',
        component: loadView('ViewCodeSnippetsList', 'code-snippets'),
        meta: {
          navigationDrawer: true,
          module: CODE_SNIPPETS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CODE_SNIPPETS
        }
      },
      {
        path: 'new/:keyword',
        name: 'code-snippet-add',
        component: loadView('ViewCodeSnippetAdd', 'code-snippets'),
        meta: {
          navigationDrawer: true,
          module: CODE_SNIPPETS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CODE_SNIPPETS,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: ':codeSnippetId',
        name: 'code-snippet-edit',
        component: loadView('ViewCodeSnippetEdit', 'code-snippets'),
        meta: {
          navigationDrawer: true,
          module: CODE_SNIPPETS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CODE_SNIPPETS,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: 'clone/:codeSnippetId',
        name: 'code-snippet-clone',
        component: loadView('ViewCodeSnippetClone', 'code-snippets'),
        meta: {
          navigationDrawer: true,
          module: CODE_SNIPPETS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CODE_SNIPPETS,
          whiteBackground: true,
          savePrevRoute: true
        }
      }
    ]
  }
]
