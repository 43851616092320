/**
 * The purpose of the request mappers, as perceived from use cases, is to convert data that is in
 * FE format into the format that is expected by the BE, for a specific request.
 * For example, if one were creating a campaign, the 'id' property won't be needed
 * on the request object. The appropriate mapping function should only include the
 * necessary properties, excluding 'id' in this case.
 *
 * These mappers have the advantage of serving as an additional protected-variation layer,
 * as variations in the BE won't require changes throughout the FE code. However,
 * the current strategy for a smart usage of these mappers has yet to be defined,
 * specially when considering that there is no "strict" or explicit definition
 * for the format that a given model object (e.g. campaign) should have. In a sense,
 * it's a free-form definition, without any guidelines associated anywhere other than
 * the request mapper itself. Due to these reasons, as well as due to
 * the potential user-gains of implementing these mappers instead
 * of other functionalities, adequate brainstorming and
 * evaluation of the usage of the request mappers will be conducted in the future.
 * As currently campaigns does not require the requestMapper (as it already sends
 * data in the expected format), these mappers won't be implemented.
 *
 */

function toCreateRequestFormat(campaign) {}

function mapPreviewRequest(request) {
  let req = { message_id: request.messageId }
  if (request.instanceId) {
    req.instance_id = request.instanceId
  }
  return req
}

export { toCreateRequestFormat, mapPreviewRequest }
