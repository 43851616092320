import { butterflyAxios } from '@/api/axios'
import { templateMap } from './requestMapper'
import { templateViewMap, templateAuditListMap } from './responseMapper'
import { viewTypes } from '@/utils/viewTypes'
import qs from 'qs'
import { isNoContentResponse, isOKResponse } from '@/api/utils/response'
/**
 * Endpoints for queryTemplates.
 */
const endpoints = {
  queryTemplates: `querytemplates`,
  queryTemplate: queryId => `querytemplates/${queryId}`,
  areas: `/querytemplates/areas`,
  validateQueryTemplate: `/querytemplates/validate`,
  audit: queryId => `querytemplates/${queryId}/audit`
}

/**
 * Creates a queryTemplate on the API.
 * Returns the queryTemplate of the API.
 * @param {*} queryTemplate
 */
const apiCreateQueryTemplate = async queryTemplate => {
  const response = await butterflyAxios.post(
    endpoints.queryTemplates,
    templateMap(queryTemplate)
  )
  return response.data
}
/**
 * Fetch the query template areas on the API.
 */
async function apiFetchQueryTemplateAreas() {
  const response = await butterflyAxios.get(endpoints.areas)
  return response.data.data
}

/**
 * Validate the query template on the API.
 */
const apiValidateQueryTemplate = async queryTemplate => {
  const response = await butterflyAxios.post(
    endpoints.validateQueryTemplate,
    queryTemplate
  )
  return response.data
}

/**
 * Fetches the query templates from the api.
 * @param {*} request
 */
const apiGetQueryTemplates = async request => {
  const q = request.q
  const page = request.page
  const perPage = request.perPage
  const view = request.view ? request.view : viewTypes.FullView
  const sortBy = request.sort_by ? request.sort_by : null
  const orderBy = request.order_by ? request.order_by : null
  let filters = request.filters ? request.filters : {}

  const payload = { view: view }
  if (q != null && q !== '') {
    payload.name = q
  }
  if (page > 0) {
    payload.page = page
  }

  if (sortBy != null && orderBy != null) {
    payload.sort_by = sortBy
    payload.order_by = orderBy
  }

  payload.per_page = perPage

  const params = { ...payload, ...filters }
  const response = await butterflyAxios.get(endpoints.queryTemplates, {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
  const templates = response.data.data
  const pagination = response.data.meta

  return {
    queryTemplates: templates,
    finalDataSize: pagination ? pagination.total_records : templates.length,
    newPage: pagination ? pagination.page : 1,
    originalData: templates
  }
}

/**
 * Deletes a query template from the api.
 * Return true if successful and false otherwise.
 * @param {*} id
 */
const apiDeleteQueryTemplate = async id => {
  const response = await butterflyAxios.delete(endpoints.queryTemplate(id))
  return isNoContentResponse(response)
}

/**
 * Fetches a specific query template.
 * @param templateId
 * @param view
 */
const apiGetQueryTemplate = async templateId => {
  const response = await butterflyAxios.get(endpoints.queryTemplate(templateId))

  return templateViewMap(response.data.data)
}

/**
 * Updates an template.
 * If successful, return the updated template.
 * @param {*} template
 */
const apiUpdateQueryTemplate = async template => {
  const response = await butterflyAxios.put(
    endpoints.queryTemplate(template.id),
    templateMap(template)
  )

  if (isOKResponse(response)) {
    return templateViewMap(response.data.data)
  }

  return false
}

const apiGetTemplateAudit = async request => {
  const payload = {
    page: request.page,
    limit: request.perPage,
    order_by: request.orderBy,
    sort_by: request.sortBy
  }

  const { data } = await butterflyAxios.get(endpoints.audit(request.id), {
    params: { ...payload }
  })

  const list = data.data.user_audit
  const pagination = data.meta

  const templateAudit = templateAuditListMap(list)

  return {
    templateAudit: templateAudit,
    finalDataSize: pagination ? pagination.total_records : templateAudit.length,
    newPage: pagination ? pagination.page : 1,
    originalData: templateAudit
  }
}

export {
  apiCreateQueryTemplate,
  apiFetchQueryTemplateAreas,
  apiValidateQueryTemplate,
  apiGetQueryTemplates,
  apiDeleteQueryTemplate,
  apiGetQueryTemplate,
  apiUpdateQueryTemplate,
  apiGetTemplateAudit
}
