import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  campaingsEvents: [],
  segmentsEvents: [],
  finalCampaingsDataSize: null,
  finalSegmentsDataSize: null
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
