import { butterflyAxios } from '@/api/axios'
import { parseSuccessResponse } from '@/api/utils/response'

var endpoint = {
  languages: 'languages'
}

async function apiGetLanguages() {
  let response = await butterflyAxios.get(endpoint.languages, {
    params: {
      sort_by: 'id',
      order_by: 'asc'
    }
  })

  let data
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    data = processedResponse
  } else {
    throw processedResponse
  }

  return {
    languages: data
  }
}

export { apiGetLanguages }
