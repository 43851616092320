import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'
import { currencyFormatWithCode } from '@/utils/commons/formatter'

const action = {
  CREATE: () => {
    return {
      event: 'create',
      label: i18n.t('rates.title.create'),
      icon: 'add',
      isPrimary: true,
      options: []
    }
  },
  EDIT: () => {
    return {
      event: 'view',
      icon: 'edit',
      label: i18n.t('edit')
    }
  },
  VIEW: () => {
    return {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('view')
    }
  },
  AUDIT: () => {
    return {
      event: 'audit',
      icon: 'mdi-update',
      label: i18n.t('audit')
    }
  }
}

/**
 * Responsible for declaring the actions that can be performed on a specific sms rate.
 */
const actionsOnRates = (state, getters, rootState, rootGetters) => {
  return function(rate) {
    let actions = []

    if (!rate) return actions

    /**
     * Specifies that the current user can perform the below actions.
     * action: the action that the user can perform
     * permission: path to the 'getter' that confirms the permission.
     */

    let potentialActions = [
      {
        actionGroup: [
          {
            action: action.EDIT(rate),
            permission: 'user/canEditRate'
          },
          {
            action: action.VIEW(rate),
            permission: 'user/canSeeRate'
          }
        ]
      },
      {
        label: i18n.t('campaigns.actions.more'),
        icon: 'more_vert',
        menu: {
          list: getMoreActions(rate),
          date: {
            display: false
          }
        }
      }
    ]
    actions = filterActionsListByPermission(
      rootGetters,
      rate.createdBy,
      potentialActions
    )

    return actions
  }
}

const getMoreActions = smsrate => {
  const moreList = [
    {
      action: action.AUDIT(smsrate),
      permission: 'user/canSeeRate'
    }
  ]
  return moreList
}

/**
 * The actions that the user can perform on the sms rates per country list as a whole (i.e. on the group).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
const actionsOnRateList = (state, getters, rootState, rootGetters) => {
  const actions = []
  if (rootGetters['user/canCreateRate']) {
    actions.push(action.CREATE())
  }

  return actions
}

/**
 * Returns the sms rate list, with the
 * format ready to be rendered in the JList.vue component.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
const ratesList = (state, getters, rootState, rootGetters) => {
  if (!state.channelRates) return []
  return state.channelRates.map(rate => {
    const data = mapRate(rate)
    return {
      ...data,
      actions: actionsOnRates(state, getters, rootState, rootGetters)(rate)
    }
  })
}

/**
 * Returns map of rate object
 * @param rate
 * @returns {{updated_at: *, targetFlag: {isoCode: (string|*)}, name, updated_by, id, value: string, country_id: *}}
 */
const mapRate = rate => {
  return {
    id: rate.id,
    channel_id: rate.channel_id,
    country_id: rate.country_id,
    name: rate.name,
    value: currencyFormatWithCode(rate.value),
    targetFlag: {
      isoCode: rate.iso_code
    },
    updated_at: formatDate(rate.updated_at),
    updated_by: rate.updated_by
  }
}

export default {
  ratesList,
  actionsOnRateList,
  actionsOnRates
}
