import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { securedLS } from '@/store/securedStorage'

var state = {
  areas: null,
  queryTemplates: null,
  finalDataSize: null,
  originalData: null,
  securedState: securedLS
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
