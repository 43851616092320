import Vue from 'vue'
import Vuex from 'vuex'
import AppModule from './app'
import Campaigns from '@/store/campaigns/'
import CardTypes from '@/store/card-types/'
import CardClassifications from '@/store/card-classifications'
import ChannelTypes from '@/store/channelTypes'
import Channels from '@/store/channels'
import DataSuggestions from '@/store/data-suggestions'
import InstanceTags from '@/store/instance-tags'
import Instances from '@/store/instances'
import Jobs from '@/store/jobs'
import Segments from '@/store/segments'
import User from '@/store/user/'
import Media from '@/store/media'
import Messages from '@/store/messages'
import MessageSchedules from '@/store/message-schedules'
import GrowthTool from '@/store/growthtool'
import PushNotification from '@/store/push-notification'
import InboxMessage from '@/store/inbox-message'
import Sms from '@/store/sms'
import LandingPageTypes from '@/store/landing-page-types/'
import Languages from '@/store/languages'
import Documentation from '@/store/documentation'
import Statistics from '@/store/statistics'
import Tags from '@/store/tags'
import Targets from '@/store/targets'
import Verticals from '@/store/verticals'
import CodeSnippets from '@/store/code-snippets'
import CodeSnippetTypes from '@/store/code-snippet-types'
import Countries from '@/store/countries/'
import Tabs from '@/store/tabs'
import Projects from '@/store/projects'
import NavigationHistory from '@/store/navigationHistory'
import TriggeredEvents from '@/store/triggered-events'
import QueryTemplates from '@/store/querytemplates'
import EmailTemplates from '@/store/emailtemplates'
import ChannelBudgets from '@/store/channelBudgets'
import LogicGroups from '@/store/logicgroups'
import UserSegments from '@/store/usersegments'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: User,
    app: AppModule,
    campaigns: Campaigns,
    'card-types': CardTypes,
    'card-classifications': CardClassifications,
    channelTypes: ChannelTypes,
    channels: Channels,
    'data-suggestions': DataSuggestions,
    'instance-tags': InstanceTags,
    instances: Instances,
    jobs: Jobs,
    segments: Segments,
    media: Media,
    messages: Messages,
    'message-schedules': MessageSchedules,
    growthtool: GrowthTool,
    'push-notification': PushNotification,
    'inbox-message': InboxMessage,
    sms: Sms,
    'landing-page-types': LandingPageTypes,
    documentation: Documentation,
    languages: Languages,
    statistics: Statistics,
    tags: Tags,
    targets: Targets,
    verticals: Verticals,
    'code-snippets': CodeSnippets,
    'code-snippet-types': CodeSnippetTypes,
    countries: Countries,
    tabs: Tabs,
    projects: Projects,
    navigationHistory: NavigationHistory,
    'triggered-events': TriggeredEvents,
    querytemplates: QueryTemplates,
    emailtemplates: EmailTemplates,
    channelbudgets: ChannelBudgets,
    logicgroups: LogicGroups,
    usersegments: UserSegments
  }
})
