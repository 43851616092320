import { viewTypes } from '@/utils/viewTypes'
import moment from 'moment/moment'
import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
/**
 * Maps an instance with the format and fields of the BE to
 * the fields expected by the FE.
 * @param {*} instance
 */
function instanceFullViewMap(instance) {
  let {
    id,
    created_at: createdDate,
    name,
    csv_delimiter: csvDelimiter,
    description,
    segment_id: segmentId,
    updated_at: updatedDate,
    created_by: createdBy,
    updated_by: updatedBy,
    active: isActive,
    filters,
    export_columns: exportColumns,
    segment: { name: dataSourceName, metadata },
    tags,
    sync_segment_from_instance: syncSegmentFromInstance,
    known_id: knownID,
    unknown_id: unknownID,
    editable_name: editableName,
    airship_sync: airshipSync,
    sync_mall: syncMall,
    sculptor_sync: sculptorSync
  } = instance
  return {
    id,
    createdDate,
    name,
    csvDelimiter,
    description,
    segmentId,
    updatedDate,
    createdBy,
    updatedBy,
    isActive,
    filters: filters,
    exportColumns: exportColumns || [],
    dataSourceName,
    numRows: metadata && metadata.NumRows ? metadata.NumRows : 0,
    target: instance.target ? instance.target : undefined,
    tags: mapInstanceTags(tags),
    syncSegmentFromInstance,
    knownID,
    unknownID,
    editableName,
    syncHour: airshipSync ? airshipSync.sync_hour : '08',
    project: airshipSync ? airshipSync.project : undefined,
    endDate: airshipSync ? airshipSync.end_date : moment().format('YYYY-MM-DD'),
    syncStatus: airshipSync ? airshipSync.sync_status : undefined,
    syncMall: { ...mapSyncMall(syncMall) },
    sculptorSync
  }
}

function instanceBasicViewMap(instance) {
  let { id, name } = instance
  return {
    id,
    name
  }
}

function instanceListMap(list, view) {
  let instances = []
  for (let item of list) {
    instances.push(instanceViewMap(view, item))
  }
  return instances
}

function instanceViewMap(view, instance) {
  switch (view) {
    default:
    case viewTypes.FullView:
      return instanceFullViewMap(instance)
    case viewTypes.BasicView:
      return instanceBasicViewMap(instance)
  }
}

function mapInstanceTags(tags) {
  const tagsView = []
  for (const t of tags) {
    tagsView.push({
      value: t.id,
      text: t.key,
      description: t.description
    })
  }
  return tagsView
}

function mapInstanceHistory(list) {
  return list.map(mapHistory)
}

function mapInstanceSize({
  instance_size: instanceSize,
  segment_size: segmentSize
}) {
  return {
    instanceSize,
    segmentSize
  }
}

function mapSyncMall(syncMall) {
  if (!syncMall) {
    return {}
  }
  const { end_date: endDate, status } = syncMall
  return { endDate, status }
}

function mapHistory(h) {
  return {
    timestamp: h.created_at,
    rows: h.num_device_ids,
    diff: h.diff
  }
}

function mapAudit(list) {
  return list.map(a => {
    return {
      action:
        a.action === 'INSERT'
          ? i18n.t('instances.audit.actions.create')
          : i18n.t('instances.audit.actions.update'),
      updated_by: a.updated_by,
      updated_at: formatDate(a.updated_at)
    }
  })
}

export {
  instanceViewMap,
  instanceListMap,
  mapInstanceHistory,
  mapInstanceSize,
  mapAudit
}
