import { butterflyAxios } from '@/api/axios'
import { messageListMap, messageFullViewMap, mapAudit } from './responseMapper'
import { growthToolFullViewMap } from '@/api/growthtool/responseMapper'
import { pushNotificationFullViewMap } from '@/api/push-notification/responseMapper'
import { isGrowthTool, isPushNotification } from '@/api/messages/utils'
import { i18n } from '@/i18n'
import {
  isCreatedResponse,
  isOKResponse,
  parseSuccessResponse
} from '@/api/utils/response'
import { viewTypes } from '@/utils/viewTypes'

const endpoint = {
  messages: 'messages',
  pn: 'pushnotifications',
  message: messageId => `messages/${messageId}`,
  audit: messageId => `messages/${messageId}/audit`,
  renderPreview: 'messages/render-preview'
}

/**
 * Get messages
 *
 * @param request
 * @returns {Promise<{total: number, messages, originalData, status: boolean}>}
 */
async function apiGetMessages(request) {
  const q = request.q
  const page = request.page
  const filters = request.filters
  const perPage = request.perPage
  const view = request.view ? request.view : viewTypes.FullView
  const sortBy = request.sort_by ? request.sort_by : null
  const orderBy = request.order_by ? request.order_by : null
  const valid = request.valid ? request.valid : null

  let payload = { view: view }

  if (q != null && q !== '') {
    payload.query = q
  }

  if (sortBy != null && orderBy != null) {
    payload.sort_by = sortBy
    payload.order_by = orderBy
  }

  if (valid != null) {
    payload.valid = valid
  }

  payload.per_page = perPage
  payload.page = page

  let { data } = await butterflyAxios.get(endpoint.messages, {
    params: { ...payload, ...filters }
  })

  let list = data.data
  let pagination = data.meta

  let messages = messageListMap(list, view)

  return {
    messages: messages,
    finalDataSize: pagination ? pagination.total_records : messages.length,
    newPage: pagination ? pagination.page : 1,
    originalData: messages // The original, unaltered data, for any needs any component may have
  }
}

/**
 * Delete a message
 *
 * @param id integer MessageId
 * @returns {Promise<void>}
 */
const apiDeleteMessage = async id => {
  let response = {}
  let finalResponse = {}
  response = await butterflyAxios
    .delete(endpoint.message(id))
    .catch(function(error) {
      if (error.response) {
        finalResponse = { status: error.response.status }
        switch (error.response.status) {
          // If it's 422, then it's a deletion of a message being used.
          case 422:
            finalResponse.error_message = i18n.t(
              'messages.errors.already_in_use'
            )
            break
        }
      }
    })
  if (response) {
    response.success = true
  } else {
    response = finalResponse
  }
  return response
}

/**
 * Create message
 *
 * @param message object
 * @param ignoreSyntaxErrors boolean
 * @returns {Promise<{responseType: string, response: *, success: boolean, status: *}>}
 */
const apiCreateMessage = async (message, ignoreSyntaxErrors) => {
  message['ignore_syntax_errors'] = !!ignoreSyntaxErrors

  let response = await butterflyAxios.post(endpoint.messages, message)
  if (isCreatedResponse(response)) {
    return parseSuccessResponse(response)
  }
  return response
}

/**
 * Get a message by Id
 *
 * @param messageId integer Id of message
 * @returns {Promise<{createdAt: *, data: *, createdBy: *, templates: *, name: *, channel: *, description: *, active: *, channelName: *, id: *, channelID: *}>}
 */
const apiGetMessage = async messageId => {
  let response = await butterflyAxios.get(endpoint.message(messageId))
  let data
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    data = processedResponse
  } else {
    throw processedResponse
  }
  if (isGrowthTool(data)) {
    return growthToolFullViewMap(data)
  }

  if (isPushNotification(data)) {
    return pushNotificationFullViewMap(data)
  }
  return messageFullViewMap(data)
}

/**
 * Update message
 * @param message object
 * @param ignoreSyntaxErrors boolean
 * @returns {Promise<{responseType: string, response: *, success: boolean, status: *}>}
 */
const apiUpdateMessage = async (message, ignoreSyntaxErrors) => {
  message['ignore_syntax_errors'] = !!ignoreSyntaxErrors

  let response = await butterflyAxios.put(endpoint.message(message.id), message)
  if (isOKResponse(response)) {
    return parseSuccessResponse(response)
  }
  return response
}

const apiRenderMessage = async (
  subject,
  template,
  languageId,
  instanceId,
  campaignName
) => {
  let payload = {
    subject: subject,
    template: template,
    language_id: parseInt(languageId, 10),
    campaign_name: campaignName
  }
  if (instanceId) {
    payload.instance_id = parseInt(instanceId, 10)
  }

  let response = await butterflyAxios.post(endpoint.renderPreview, payload)

  if (!isOKResponse(response)) {
    throw response
  }
  const result = parseSuccessResponse(response)
  return result.response
}

const apiGetAudit = async request => {
  const params = {
    sort_by: request.sortBy,
    order_by: request.orderBy,
    page: request.page,
    per_page: request.perPage
  }
  const response = await butterflyAxios.get(endpoint.audit(request.id), {
    params: { ...params }
  })
  if (isOKResponse(response)) {
    const { response: list, meta: pagination } = parseSuccessResponse(response)
    return {
      audit: mapAudit(list),
      finalDataSize: pagination ? pagination.total_records : list.length
    }
  } else {
    throw response
  }
}

export {
  apiGetMessages,
  apiDeleteMessage,
  apiCreateMessage,
  apiGetMessage,
  apiUpdateMessage,
  apiRenderMessage,
  apiGetAudit
}
