/**
 * Signals Vuex's state that the instances have been updated.
 * @param {*} state The vuex state.
 * @param {*} param1
 */
const INSTANCES_UPDATED = (
  state,
  { instances, finalDataSize, originalData }
) => {
  state.instances = instances
  state.finalDataSize = finalDataSize
  state.originalData = originalData
}

export default {
  INSTANCES_UPDATED
}
