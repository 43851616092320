import {
  apiGetScheduleListByMessageID,
  apiGetMessageSchedule,
  apiCreateMessageSchedule,
  apiUpdateMessageSchedule,
  apiDeleteMessageSchedule,
  apiCreateMessageWithSchedule,
  apiRenderPreview,
  apiGetNewsletterDates
} from '@/api/message-schedules'

/**
 * Fetches a list of scheduled contents from a message of type newsletter
 * @param _
 * @returns {Promise<{messageScheduleList: [], finalDataSize: number, newPage: number, originalData: []}>}
 * @param request
 */
async function fetchMessageScheduleList({ commit }, request) {
  let result
  let commitPayload = {
    messageSchedules: [],
    finalDataSize: 0,
    originalData: []
  }
  try {
    let response = await apiGetScheduleListByMessageID(request)

    result = {
      newPage: response.newPage,
      messageSchedules: response.messageScheduleList
    }
    commitPayload = {
      messageSchedules: response.messageScheduleList,
      finalDataSize: response.finalDataSize,
      originalData: response.originalData
    }
  } catch (error) {
    //
  }
  commit('MESSAGE_SCHEDULES_UPDATED', commitPayload)
  return result
}

async function fetchMessageSchedule(_, { id: messageScheduleId }) {
  let messageSchedule = await apiGetMessageSchedule(messageScheduleId)
  return messageSchedule
}

async function createMessageWithSchedule(_, { message, ignoreSyntaxErrors }) {
  let messageCreated = await apiCreateMessageWithSchedule(
    message,
    ignoreSyntaxErrors
  )
  return messageCreated
}

async function createMessageSchedule(_, { message, ignoreSyntaxErrors }) {
  let messageScheduleCreated = await apiCreateMessageSchedule(
    message,
    ignoreSyntaxErrors
  )
  return messageScheduleCreated
}

async function updateMessageSchedule(_, { message, ignoreSyntaxErrors }) {
  let messageScheduleUpdated = await apiUpdateMessageSchedule(
    message,
    ignoreSyntaxErrors
  )
  if (messageScheduleUpdated) {
    return messageScheduleUpdated
  }
  return false
}

async function deleteMessageSchedule(_, id) {
  let deleteRequest = await apiDeleteMessageSchedule(id)
  return deleteRequest
}

async function renderPreview(_, request) {
  let response = await apiRenderPreview(request)
  return response
}

async function getNewsletterDates(_, req) {
  let response = await apiGetNewsletterDates(req)
  return response
}

export default {
  fetchMessageScheduleList,
  fetchMessageSchedule,
  createMessageWithSchedule,
  createMessageSchedule,
  updateMessageSchedule,
  deleteMessageSchedule,
  renderPreview,
  getNewsletterDates
}
