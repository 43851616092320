function MapCreate(message) {
  return mapPushNotification(message)
}

function MapUpdate(message) {
  let dto = mapPushNotification(message)
  dto.id = message.id
  return dto
}

function mapPushNotification(message) {
  const formattedTemplates = message.templates.map(t => {
    return {
      language_id: t.language_id,
      content: {
        title: t.title,
        message: t.message,
        summary: t.summary
      }
    }
  })
  return {
    name: message.name,
    description: message.description,
    channel_id: message.channelID,
    data: {
      action: {
        type: message.action.type,
        content: message.action.content
      },
      button: {
        is_static: message.button.isStatic,
        type: message.button.type,
        action: {
          type: message.button.action.type,
          content: message.button.action.content
        }
      },
      image: message.image,
      image_upload: message.imageUpload,
      devices: message.devices,
      attr: message.attr,
      utm: message.utm,
      voucher: message.voucher,
      signal_key: message.signalKey
    },
    templates: formattedTemplates
  }
}

function MapPreview(payload) {
  return {
    instance_id: payload.instanceId,
    title: payload.title,
    message: payload.message,
    summary: payload.summary,
    action_content: payload.actionContent,
    attr: payload.attr,
    utm: payload.utm,
    voucher: payload.voucher,
    signal_key: payload.signalKey,
    image: payload.image,
    btn_type: payload.button ? payload.button.type : '',
    btn_action: payload.button ? payload.button.action : '',
    btn_action_content: payload.button ? payload.button.content : ''
  }
}

export { MapCreate, MapUpdate, MapPreview }
