import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'
const ADMINISTRATION_MODULE = 'jobs'

export default [
  {
    path: '/jobs',
    component: loadView('ViewJobs', 'jobs'),
    children: [
      {
        path: '',
        name: 'jobs',
        component: loadView('ViewJobsList', 'jobs'),
        meta: {
          navigationDrawer: true,
          module: ADMINISTRATION_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_ADMIN
        }
      }
    ]
  }
]
