import { SOURCES } from '@/constants/sources'
const templateMap = template => {
  return {
    id: template.id,
    name: template.name,
    description: template.description,
    source: template.source ? SOURCES.AWS_DATALAKE : SOURCES.BIGQUERY,
    areas: template.areas.map(a => a.id),
    placeholders: template.placeholders,
    sql_query: template.sqlQuery
  }
}

export { templateMap }
