/**
 * Signals Vuex's state that the languages have changed
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 An object containing the new languages.
 */
function LANGUAGES_UPDATED(state, { languages }) {
  state.languages = languages
}

export default {
  LANGUAGES_UPDATED
}
