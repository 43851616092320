/**
 * This file defines the unique local storage to be used for maintaining persisted states in Vuex.
 */
import SecureLS from 'secure-ls'

/**
 * INFO: Why aren't the objects set at startup instead of a getOrDefault?
 * Since data will be stored in a map object, there can occur moments
 * when the user object is not yet present on the secured state.
 * However, injecting this map object at setup would cause issues in reloads
 * (as it would wipe the cached data) and would also expose the variable in
 * the local storage, even if it wasn't being used for anything (for example,
 * on the login screen, the variable would already be present).
 * For this reason, mutations should first check if the map exists before trying to access
 * to it.
 */

const securedLS = new SecureLS({ isCompression: false })

/**
 * Returns the object map that is persisted in the secured
 * local storage. If it doesn't exist yet, an empty map is returned.
 * @param {*} state The current vue local state.
 * @param {*} area The object map of the local state.
 */
function getAreaMap(state, area) {
  let _map = state.securedState.get(area)
  // If, for whatever reason, it's null or undefined
  if (!_map) {
    _map = {}
  }
  return _map
}

/**
 * Returns a property of a given object map that is persisted in the secured
 * local storage.
 * @param {*} state The current vue local state.
 * @param {*} area The object map of the local state.
 * @param {*} property The property to fetch.
 */
function getAreaProperty(state, area, property) {
  let _map = getAreaMap(state, area)
  return _map[property]
}

/**
 * Sets multiples properties of a given object map that is persisted in the secured
 * local storage.
 * @param {*} state The current vue local state.
 * @param {*} area The object map of the local state.
 * @param {*} propertyList The property list. Should be a list of json like so:
 * [{property:'a',value:'b'},...]
 */
function setAreaPropertyMultiple(state, area, propertyList) {
  let _map = getAreaMap(state, area)
  if (propertyList) {
    propertyList.forEach(element => {
      _map[element.property] = element.value
    })
  }
  state.securedState.set(area, _map)
}

/**
 * Set a property of a given object map that is persisted in the secured
 * local storage.
 * @param {*} state The current vue local state.
 * @param {*} area The object map of the local state.
 * @param {*} property The property to fetch.
 * @param {*} value The value to set.
 */
function setAreaProperty(state, area, property, value) {
  setAreaPropertyMultiple(state, area, [{ property: property, value: value }])
}

export {
  securedLS,
  getAreaMap,
  getAreaProperty,
  setAreaProperty,
  setAreaPropertyMultiple
}
