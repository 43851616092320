/**
 * Converts a JSON target object from the API format to the format expected by the FE.
 * @param {JSON} target The target JSON object to convert from.
 */
function targetMap(target) {
  let {
    id,
    name,
    target_languages: targetLanguages,
    default_language_id: defaultLanguageId,
    country,
    code
  } = target

  const tg = targetLanguagesListMap(targetLanguages)
  return {
    id,
    name,
    languages: tg,
    defaultLanguageId,
    isoCode: country.iso_code,
    countryId: country.id,
    code
  }
}

/**
 * Maps an array of targets to another array of targets in the expected FE format.
 * @param {Array} list The list of targets in BE format.
 */
function targetListMap(list) {
  let targets = []
  for (let item of list) {
    targets.push(targetMap(item))
  }
  return targets
}

/**
 * Maps an array of targets to another array of target names in the expected FE format.
 * @param {Array} list The list of targets in BE format.
 */
function targetNamesListMap(list) {
  let targets = []
  for (let item of list) {
    targets.push({ text: item.name, value: item.id })
  }
  return targets
}

/**
 * Maps a target object to another object expected by the FE
 * @param obj The object return by backend
 * @returns {{verticalID: *, id: *, countryID: *}}
 */
function targetByIdMap(obj) {
  return { id: obj.id, verticalID: obj.vertical_id, countryID: obj.country_id }
}

/**
 * Converts a JSON target_language object from the API format to the format expected by the FE.
 * @param {JSON} targetLanguage The target JSON object to convert from.
 */
function targetLanguageMap(targetLanguage) {
  let { language_id: languageId, language, mandatory } = targetLanguage

  return {
    languageId,
    language,
    mandatory
  }
}

/**
 * Maps an array of target languages to another array of target languages in the expected FE format.
 * @param {Array} list The list of target languages in BE format.
 */
function targetLanguagesListMap(list) {
  let targetLanguages = []
  for (let item of list) {
    targetLanguages.push(targetLanguageMap(item))
  }
  return targetLanguages
}

export { targetMap, targetListMap, targetNamesListMap, targetByIdMap }
