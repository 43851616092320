let GoogleClient = {
  install(Vue, options) {
    let callbackFn
    const loginCallback = function(response) {
      if (callbackFn !== undefined && typeof callbackFn === 'function') {
        callbackFn(response)
      }
    }

    let gc
    window.onGoogleLibraryLoad = () => {
      gc = window.google.accounts.oauth2.initCodeClient({
        client_id: options.client_id,
        scope: 'https://www.googleapis.com/auth/userinfo.email',
        callback: loginCallback,
        auto_select: true
      })
      Vue.prototype.$googleGSIClient.isLoaded = true
    }

    Vue.prototype.$googleGSIClient = {
      login: function(callback) {
        callbackFn = callback
        gc.requestCode()
      },
      isLoaded: false
    }
  }
}

export default GoogleClient
