import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  channelBudgets: null,
  finalDataSize: 0,
  budgetCampaigns: null,
  finalDataSizeCampaigns: 0
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
