/**
 * Checks if a given error is one of the error codes expected
 * @param errors array of possible codes
 * @param code expected code
 * @returns {boolean}
 */
function isOneOf(errors, code) {
  for (const error of errors) {
    if (error === code) {
      return true
    }
  }
  return false
}

export { isOneOf }
