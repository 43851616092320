import { milkyWayAxios } from '@/api/axios'

/**
 * Endpoints for the data suggestions.
 */
const endpoints = {
  dataSuggestions: 'data-suggestions'
}

/**
 * Fetches the data suggestions from the API.
 * @param {*} request The request parameters.
 * Specifically, the field which will be used to fetch suggestions from.
 */
const apiGetDataSuggestions = async request => {
  const field = request.field

  const { data } = await milkyWayAxios.get(endpoints.dataSuggestions, {
    params: { field }
  })
  return {
    suggestions: data
  }
}

export { apiGetDataSuggestions }
