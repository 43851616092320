function exportCsv(csv, fileName) {
  if (!fileName) {
    fileName = 'list.csv'
  }
  const data = encodeURI('data:text/csv;charset=utf-8,' + csv)
  const link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', fileName)
  link.click()
}

export { exportCsv }
