import {
  apiGetChannelByKeyword,
  apiGetChannels,
  apiGetChannelsNames,
  apiGetRates,
  apiCreateRate,
  apiUpdateRate,
  apiGetRateById,
  apiGetRateAudits
} from '@/api/channels'
import CacheSystem from '@/utils/cacheSystem'

/**
 * Fetches the channels from the BE database and
 * updates the vuex state accordingly.
 * @param {*} param0 Vuex state (auto-injected).
 */
async function fetchChannels({ commit }, payload) {
  var response = await apiGetChannels(payload)
  commit('CHANNELS_UPDATED', response)
  return response
}

async function fetchChannelNames(context, payload, useCache = true) {
  let cacheSystem = new CacheSystem(useCache)

  cacheSystem.implementGetCacheFunction(() => {
    let result
    if (context.state.channelNames) {
      result = context.state.channelNames
    }
    return result
  })
  cacheSystem.implementSetCacheFunction(async () => {
    let result = await apiGetChannelsNames()

    context.commit('CHANNEL_NAMES_CACHED', result)

    return result
  })
  cacheSystem.implementOnSetCacheErrorFunction(() => {
    //
  })
  return cacheSystem.execute()
}

async function fetchChannelByKeyword(_, keyword) {
  return apiGetChannelByKeyword(keyword)
}

/**
 * Fetches the channel rates from the BE database and
 * updates the vuex state accordingly.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} payload the channel id.
 */
async function fetchRates({ commit }, payload) {
  const response = await apiGetRates(payload)
  commit('CHANNEL_RATES_UPDATED', response)
  return response
}

/**
 * Create the channel rates from the BE database.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} cr the channelRate
 */
async function createRate(_, rate) {
  const response = await apiCreateRate(rate)
  return response
}

/**
 * Update the channel rate from the BE database.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} rate the channelRate
 */
async function updateRate(_, rate) {
  const response = await apiUpdateRate(rate)
  return response
}

/**
 * Fetches the channel rates by id from the BE database and
 * updates the vuex state accordingly.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} payload rateId
 */
async function fetchRateById(_, payload) {
  const r = await apiGetRateById(payload)
  return r
}

/**
 * Fetches the channel rate audits from the BE database.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} payload rateId
 */
async function fetchRateAuditsById(_, payload) {
  const res = await apiGetRateAudits(payload)
  return res
}

export default {
  fetchChannels,
  fetchChannelNames,
  fetchChannelByKeyword,
  fetchRates,
  updateRate,
  createRate,
  fetchRateById,
  fetchRateAuditsById
}
