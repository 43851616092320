function projectMap(project) {
  let {
    id,
    created_at: createdAt,
    updated_at: updatedAt,
    vertical_id: verticalId,
    project_name: projectName
  } = project

  return {
    id,
    createdAt,
    updatedAt,
    verticalId,
    projectName
  }
}
function projectAllMap(project) {
  let { id, app_key: appKey, project_name: projectName } = project

  return {
    id,
    key: appKey,
    projectName
  }
}
function projectListMap(list) {
  let projects = []
  for (let item of list) {
    projects.push(projectMap(item))
  }

  return projects
}

function projectAllListMap(list) {
  let projects = []
  for (let item of list) {
    projects.push(projectAllMap(item))
  }

  return projects
}

export { projectListMap, projectMap, projectAllListMap }
