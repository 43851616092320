/**
 * This file serves the purpose of handling conversions from DTOs to other 'DTO'.
 * Essentially, xyzMap functions map the object JSON obtained from the Back-End (BE) to a javascript object
 * that is easily parsed by the Front-End (FE).
 */
import { viewTypes } from '@/utils/viewTypes'
import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'

/**
 * Converts a JSON code snippet object from the API format to the format expected by the FE.
 * @param {JSON} codeSnippet The code snippet JSON object to convert from.
 */
function codeSnippetMap(codeSnippet, view) {
  switch (view) {
    default:
    case viewTypes.FullView:
      return codeSnippetMapFullView(codeSnippet)
    case viewTypes.BasicView:
      return codeSnippetMapBasicView(codeSnippet)
  }
}

function codeSnippetMapFullView(codeSnippet) {
  let {
    id,
    name,
    code,
    description,
    // eslint-disable-next-line camelcase
    code_snippet_type,
    // eslint-disable-next-line camelcase
    code_blocks_by_language,
    created_at: createdAt,
    created_by: createdBy,
    updated_at: updatedAt,
    updated_by: updatedBy
  } = codeSnippet

  let codeSnippetTypeID = code_snippet_type.id
  let codeSnippetTypeName = code_snippet_type.name

  return {
    id,
    name,
    code,
    description,
    codeSnippetTypeID,
    codeSnippetTypeName,
    code_snippet_type,
    code_blocks_by_language,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy
  }
}

function codeSnippetMapBasicView(codeSnippet) {
  let { id, name } = codeSnippet

  return {
    id,
    name
  }
}

/**
 * Maps an array of code snippets to another array of code snippets in the expected FE format.
 * @param {Array} list The list of code snippets in BE format.
 * @param {string} view The chosen view.
 */
function codeSnippetListMap(list, view) {
  let codeSnippets = []
  for (let item of list) {
    codeSnippets.push(codeSnippetMap(item, view))
  }
  return codeSnippets
}

function mapAudit(list) {
  return list.map(a => {
    return {
      action:
        a.user_action === 'INSERT'
          ? i18n.t('code_snippets.audit.actions.create')
          : i18n.t('code_snippets.audit.actions.update'),
      updated_by: a.updated_by,
      updated_at: formatDate(a.updated_at)
    }
  })
}

export { codeSnippetMap, codeSnippetListMap, mapAudit }
