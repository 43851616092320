import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { securedLS } from '@/store/securedStorage'

var state = {
  name: null,
  picture: null,
  targets: null,
  securedState: securedLS
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
