import {
  USER_CONSTANTS,
  PERMISSIONS_CONSTANTS,
  PERMISSION_TYPE
} from '@/store/user/constants'
import { getAreaProperty } from '@/store/securedStorage'
/**
 * Returns the email of the currently logged in user.
 * @param {*} state The vuex store state, automatically passed.
 */
function getUserEmail(state) {
  let result = 'N/A'
  let email = getAreaProperty(state, USER_CONSTANTS.USER, USER_CONSTANTS.EMAIL)
  if (email !== null && email.length > 0) {
    result = email
  }
  return result
}
/**
 * Returns the email of the currently logged in user.
 * @param {*} state The vuex store state, automatically passed.
 */
function getUserName(state) {
  return state.name
}

/**
 * Returns the email of the currently logged in user.
 * @param {*} state The vuex store state, automatically passed.
 */
function getUserPicture(state) {
  return state.picture
}

/**
 * Returns the user targets of the currently logged in user.
 * @param {*} state The vuex store state, automatically passed.
 */
function getUserTargets(state) {
  return state.targets
}
function getPermissions(state) {
  return getAreaProperty(state, USER_CONSTANTS.USER, USER_CONSTANTS.PERMISSIONS)
}
// Permissions

/**
 * Check if the current user (anonymous or logged in) has a permission.
 * Specifically, this function returns a function that can be used to validate
 * if a user has a permission.
 * @param {vuexState} state The vuex state passed automatically.
 */
function hasPermission(state) {
  return permissionCheckingFunction(state)
}

/**
 * Check if the current user has a permission.
 * Specifically, this function can accept an array of validation types and
 * return if the user has the permission in at least one of it.
 * @param {vuexState} state The vuex state passed automatically.
 */
const permissionCheckingFunction = state => (
  permission,
  types = [PERMISSION_TYPE.APPLICATION]
) =>
  types.some(type => {
    const checkingFunction = getCheckingFunction(type)
    return checkingFunction(state)(permission)
  })

const getCheckingFunction = type =>
  type === PERMISSION_TYPE.APPLICATION
    ? applicationPermissionFunction
    : anyTargetCheckingFunction

const applicationPermissionFunction = state => permission => {
  const permissions = getPermissions(state)
  if (Array.isArray(permission)) {
    return permission.some(p => permissions.application[p] === true)
  }
  let result = permissions.application[permission] === true
  return result
}

const anyTargetCheckingFunction = state => permission => {
  const permissions = getPermissions(state)
  let result = false
  for (const t in permissions.targets) {
    if (!result) {
      result = targetCheckingFunction(permissions, t, permission)
    }
  }
  return result
}

const targetCheckingFunction = (permissions, target, permission) => {
  // let result = false
  const hasTarget = permissions.targets[target]
  if (hasTarget) {
    return permissions.targets[target][permission] === true
  }
  return false
}

const hasPermissionSpecificTarget = state => (permission, target) => {
  const permissions = getPermissions(state)
  return targetCheckingFunction(permissions, target, permission)
}

/**
 * Returns the user targets for a specific permission.
 * @param {vuexState} state The vuex state passed automatically.
 */
function getTargetsFromPermission(state) {
  // By default, the user does not have the permission
  const permissionCheckingFunction = permission => {
    const permissions = getPermissions(state)

    let targets = new Set()
    for (const t in permissions.targets) {
      let result = permissions.targets[t][permission] === true
      if (result) {
        targets.add(t)
      }
    }

    return [...targets]
  }
  return permissionCheckingFunction
}

/**
 * Checks if the user can access the application (CAN_ACCESS permission).
 * @param {vuexState} state
 */
function canAccessApplication(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.CAN_ACCESS)
}

/**
 * Returns a function that checks if the currently logged in user is the owner of
 * something.
 * @param {vuexState} state The vuex state
 * @param {*} createdByEmail The email of the user that created the object (passed to the returning object).
 */
function isOwnerOf(state) {
  let result = createdByEmail => {
    return (
      getUserEmail(state) === createdByEmail && createdByEmail !== undefined
    )
  }
  return result
}

/**
 * Checks if the current user has admin permissions, which extend
 * to all domains, regardless of sub-domain admin permissions.
 * @param {vuexState} state
 */
function hasAdminPermissions(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_ADMIN)
}

function canManageSegments(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_ADMIN) || hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_SEGMENTS_ADMIN)
}

/**
 * Checks if the user has administrative permissions for the
 * segments domain. Same logics apply to similar has.*AdminPermissions
 * @param {vuexState} state
 */
function hasInstancesAdminPermissions(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_SEGMENTS_ADMIN)
}

function hasCampaignsAdminPermissions(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAMPAIGNS_ADMIN)
}
// currently uses campaign as per PO
function hasMessagesAdminPermissions(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAMPAIGNS_ADMIN)
}

function hasCodeSnippetsAdminPermissions(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAMPAIGNS_ADMIN)
}

/**
 * Checks if the currently logged in user can create segments.
 * Same logic applies to similar canCreate.
 * @param {vuexState} state
 */
function canCreateInstances(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_SEGMENT_CREATION)
}

function canCreateCampaigns(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_CREATION)
}

// currently uses campaign as per PO
function canCreateMessages(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_CREATION)
}

function canAccessNLSponsoredProducts(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_NL_SP)
}

function canCreateMedia(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_MEDIA_CREATE)
}

function canCreateCodeSnippet(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_CODE_SNIPPETS
  )
}

/**
 * Checks if the currently logged in user can see a specific segment.
 * Same logic applies to similar canSee.*
 * @param {vuexState} state
 */
function canSeeInstance(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_SEGMENTATION)
}

function canSeeCampaign(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CAMPAIGNS)
}

function canSeeMessage(state) {
  // currently uses campaign as per PO
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CAMPAIGNS)
}

function canSeeMedia(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MEDIA)
}

function canSeeCodeSnippet(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CODE_SNIPPETS
  )
}

function canSeeEventLog(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_EVENT_LOG)
}

function canSeeReminderWidget(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_REMINDER_WIDGET
  )
}
/**
 * Checks if the currently logged in user can edit a specific segment.
 * Same logic applies to similar canEdit
 * @param {vuexState} state
 */
function canEditInstance(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission =
      hasInstancesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_SEGMENT_UPDATE) &&
      hasSpecificPermission
    )
  }
  return helperFunction
}

function canEditCampaign(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission =
      hasCampaignsAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_UPDATE) &&
      hasSpecificPermission
    )
  }
  return helperFunction
}
// currently uses campaigns permissions as per PO
function canEditMessage(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_UPDATE) &&
      hasSpecificPermission
    )
  }
  return helperFunction
}

function canEditCodeSnippet(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission =
      hasCodeSnippetsAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(
        PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_CODE_SNIPPETS
      ) && hasSpecificPermission
    )
  }
  return helperFunction
}

/**
 * Checks if the currently logged in user can delete a specific segment.
 * Same logic applies to similar canDelete.*
 * @param {vuexState} state
 */
function canDeleteInstance(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission =
      hasInstancesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_SEGMENT_DELETE) &&
      hasSpecificPermission
    )
  }
  return helperFunction
}

function canDeleteCampaign(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)

    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_DELETE) &&
      hasSpecificPermission
    )
  }
}

// currently uses campaigns permissions as per PO
function canDeleteMessage(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)

    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_DELETE) &&
      hasSpecificPermission
    )
  }
}

function canDeleteMedia(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission = true
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_MEDIA_CREATE) &&
      hasSpecificPermission
    )
  }
  return helperFunction
}

function canDeleteCodeSnippet(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission = true
    return (
      hasPermission(state)(
        PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_CODE_SNIPPETS
      ) && hasSpecificPermission
    )
  }
  return helperFunction
}

/**
 * Checks if the currently logged in user can clone a specific segment.
 * Same logic applies to similar canClone.*
 * @param {vuexState} state
 */
function canCloneInstance(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission = hasPermission(state)(
      PERMISSIONS_CONSTANTS.MDS_SEGMENT_CREATION
    )
    return hasSpecificPermission
  }
  return helperFunction
}

function canCloneCampaign(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission = hasPermission(state)(
      PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_CREATION
    )
    return hasSpecificPermission
  }
  return helperFunction
}

// currently uses campaign permissions, as per PO instructions
function canCloneMessage(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission = hasPermission(state)(
      PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_CREATION
    )
    return hasSpecificPermission
  }
  return helperFunction
}

function canCloneCodeSnippet(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission = hasPermission(state)(
      PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_CODE_SNIPPETS
    )
    return hasSpecificPermission
  }
  return helperFunction
}

/**
 * Checks if the currently logged in user can download the results of a segment.
 * @param {vuexState} state
 */
function canDownloadInstance(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission = hasPermission(state)(
      PERMISSIONS_CONSTANTS.MDS_SEGMENTS_ACCESS_INFO
    )
    return hasSpecificPermission
  }
  return helperFunction
}

/**
 * Checks if the currently logged in user can download the results of a data source.
 * @param {vuexState} state
 */
function canDownloadSegment(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission = hasPermission(state)(
      PERMISSIONS_CONSTANTS.MDS_SEGMENTS_ACCESS_INFO
    )
    return hasSpecificPermission
  }
  return helperFunction
}

/**
 * Checks if the currently logged in user can send a test of a campaign.
 * @param {vuexState} state
 */
function canSendTestCampaign(state) {
  let helperFunction = contentCreator => {
    let hasSpecificPermission = hasPermission(state)(
      PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_DISPATCH
    )
    return hasSpecificPermission
  }
  return helperFunction
}
/**
 * Checks if the currently logged in user can approve campaigns.
 * @param {vuexState} state
 */
function canApproveCampaigns(state) {
  let hasSpecificPermission = hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAMPAIGNS_APPROVE
  )

  return hasSpecificPermission
}

/**
 * Checks if the currently logged in user can disable a campaign.
 * (note: currently there are no MDS_CAMPAIGN_DISABLE permissions)
 * @param {vuexState} state
 */
function canDisableCampaign(state) {
  /* let helperFunction = contentCreator => {
    let hasSpecificPermission = hasCampaignsAdminPermissions(state)
    return isOwnerOf(state)(contentCreator) && hasSpecificPermission
  } */
  // Currently, force disable the permission until it's ready.
  let helperFunction = contentCreator => {
    return false
  }
  return helperFunction
}

/**
 * Checks if the user can sync with provider
 * @param {*} state Vuex state
 */
function canSyncWithProvider(state) {
  let hasPermissionFunc = hasPermission(state)
  return hasPermissionFunc(PERMISSIONS_CONSTANTS.MDS_SEGMENT_DISPATCH)
}

function canSeeGrowthToolMessage(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_GROWTH_MESSAGES
  )
}

function canCreateGrowthToolMessage(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_GROWTH_MESSAGES
  )
}

function canEditGrowthToolMessage(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(
        PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_GROWTH_MESSAGES
      ) && hasSpecificPermission
    )
  }
}

function canDeleteGrowthToolMessage(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(
        PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_GROWTH_MESSAGES
      ) && hasSpecificPermission
    )
  }
}

function canCloneGrowthToolMessage(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_GROWTH_MESSAGES
  )
}

function canSeeGrowthToolCampaign(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_GROWTH_CAMPAIGN
  )
}

function canCreateGrowthToolCampaign(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_GROWTH_CAMPAIGN
  )
}

function canCloneGrowthToolCampaign(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_GROWTH_CAMPAIGN
  )
}

function canUpdateGrowthToolCampaign(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasCampaignsAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(
        PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_GROWTH_CAMPAIGN
      ) && hasSpecificPermission
    )
  }
}

function canDeleteGrowthToolCampaign(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_GROWTH_CAMPAIGN
  )
}

function canSeePushNotificationCampaign(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_PN_CAMPAIGN)
}

function canCreatePushNotificationCampaign(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_PN_CAMPAIGN)
}

function canUpdatePushNotificationCampaign(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasCampaignsAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_PN_CAMPAIGN) &&
      hasSpecificPermission
    )
  }
}

function canClonePushNotificationCampaign(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_PN_CAMPAIGN)
}

function canDeletePushNotificationCampaign(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasCampaignsAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_PN_CAMPAIGN) &&
      hasSpecificPermission
    )
  }
}

function canSeePushNotificationMessage(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_PN_MESSAGES)
}

function canCreatePushNotificationMessage(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_PN_MESSAGES)
}

function canEditPushNotificationMessage(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_PN_MESSAGES) &&
      hasSpecificPermission
    )
  }
}

function canDeletePushNotificationMessage(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_PN_MESSAGES) &&
      hasSpecificPermission
    )
  }
}

function canClonePushNotificationMessage(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_PN_MESSAGES)
}

function canSeeInboxMessage(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_INBOX_MESSAGES
  )
}

function canCreateInboxMessage(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_INBOX_MESSAGES
  )
}

function canEditInboxMessage(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(
        PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_INBOX_MESSAGES
      ) && hasSpecificPermission
    )
  }
}

function canDeleteInboxMessage(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(
        PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_INBOX_MESSAGES
      ) && hasSpecificPermission
    )
  }
}

function canSeeInboxCampaign(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_INBOX_CAMPAIGN
  )
}

function canCreateInboxCampaign(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_INBOX_CAMPAIGN
  )
}

function canUpdateInboxCampaign(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasCampaignsAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(
        PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_INBOX_CAMPAIGN
      ) && hasSpecificPermission
    )
  }
}

function canDeleteInboxCampaign(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasCampaignsAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(
        PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_INBOX_CAMPAIGN
      ) && hasSpecificPermission
    )
  }
}

function canSeeSmsMessage(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_SMS_MESSAGES)
}

function canCreateSmsMessage(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_SMS_MESSAGES)
}

function canEditSmsMessage(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_SMS_MESSAGES) &&
      hasSpecificPermission
    )
  }
}

function canDeleteSmsMessage(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasMessagesAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_SMS_MESSAGES) &&
      hasSpecificPermission
    )
  }
}

function canSeeSmsCampaign(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_SMS_CAMPAIGN)
}

function canCreateSmsCampaign(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_SMS_CAMPAIGN,
    [PERMISSION_TYPE.TARGET]
  )
}

function canCreateSmsCampaignByTarget(state) {
  return target =>
    hasPermissionSpecificTarget(state)(
      PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_SMS_CAMPAIGN,
      target
    )
}

function canUpdateSmsCampaign(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_SMS_CAMPAIGN)
}

function canUpdateSmsCampaignByTarget(state) {
  return target =>
    hasPermissionSpecificTarget(state)(
      PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_SMS_CAMPAIGN,
      target
    )
}

function canDeleteSmsCampaign(state) {
  return contentCreator => {
    let hasSpecificPermission =
      hasCampaignsAdminPermissions(state) || isOwnerOf(state)(contentCreator)
    return (
      hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_SMS_CAMPAIGN) &&
      hasSpecificPermission
    )
  }
}

function canDeleteSmsCampaignByTarget(state) {
  return target =>
    hasPermissionSpecificTarget(state)(
      PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_SMS_CAMPAIGN,
      target
    )
}

function canSyncSegmentOnDemand(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_SEGMENTS_SYNC_ONDEMAND)
}

function canActivateRetargeting(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_ACTIVATE_RETARGETING
  )
}

function canSeeQueryTemplate(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_TEMPLATES)
}

function canCreateQueryTemplate(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_TEMPLATES)
}

function canCloneQueryTemplate(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_TEMPLATES)
}

function canEditQueryTemplate(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_TEMPLATES)
}

function canDeleteQueryTemplate(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_TEMPLATES)
}

function canSelectCommercial(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_SELECT_COMMERCIAL)
}

function canSelectMarketing(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_SELECT_MARKETING)
}

function canSelectAdvertising(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_SELECT_ADVERTISING)
}

function canSelectOnSite(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_SELECT_ONSITE)
}

function canSelectOnCRM(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_SELECT_CRM)
}

function canSelectOthers(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_SELECT_OTHERS)
}

function canSeeBudget(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_BUDGET)
}

function canCreateBudget(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_BUDGET)
}

function canEditBudget(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_BUDGET)
}

function canSeeRate(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_RATE)
}

function canCreateRate(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_RATE)
}

function canEditRate(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_RATE)
}

/**
 * Checks if the currently logged in user can approve campaigns.
 * @param {vuexState} state
 */
function canApproveSmsCampaigns(state) {
  return target =>
    hasPermissionSpecificTarget(state)(
      PERMISSIONS_CONSTANTS.MDS_CAN_APPROVE_SMS_CAMPAIGN,
      target
    )
}

function canSeeCampaignTags(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CAMPAIGN_TAGS
  )
}

function canCreateCampaignTags(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_CAMPAIGN_TAGS
  )
}

function canUpdateCampaignTags(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_CAMPAIGN_TAGS
  )
}

function canUpdateStatusCampaignTags(state) {
  return hasPermission(state)(
    PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_STATUS_CAMPAIGN_TAGS
  )
}

function canAccessLogicGroups(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_LOGIC_GROUPS)
}

function canCreateLogicGroup(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_LOGIC_GROUPS)
}

function canUpdateLogicGroup(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_LOGIC_GROUPS)
}

function canAccessDeeplinks(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_DEEPLINKS)
}

function canCreateDeeplink(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_DEEPLINKS)
}

function canUpdateDeeplink(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_DEEPLINKS)
}

function canDeleteEmailTemplate(state) {
  return hasPermission(state)(PERMISSIONS_CONSTANTS.MDS_CAN_DELETE_EMAIL_TEMPLATES)
}

export default {
  hasPermission,
  hasAdminPermissions,
  hasInstancesAdminPermissions,
  hasCampaignsAdminPermissions,
  hasMessagesAdminPermissions,
  hasCodeSnippetsAdminPermissions,
  isOwnerOf,
  canSyncWithProvider,
  getUserEmail,
  getUserName,
  getUserPicture,
  getUserTargets,
  getPermissions,
  getTargetsFromPermission,
  canAccessApplication,
  canManageSegments,
  canSeeInstance,
  canSeeCampaign,
  canSeeMessage,
  canSeeMedia,
  canSeeCodeSnippet,
  canEditCampaign,
  canEditInstance,
  canEditMessage,
  canEditCodeSnippet,
  canCreateCampaigns,
  canCreateInstances,
  canCreateMessages,
  canAccessNLSponsoredProducts,
  canCreateMedia,
  canSeeEventLog,
  canCreateCodeSnippet,
  canDeleteInstance,
  canDeleteCampaign,
  canDeleteMessage,
  canDeleteMedia,
  canDeleteCodeSnippet,
  canCloneInstance,
  canCloneCampaign,
  canCloneMessage,
  canCloneCodeSnippet,
  canDownloadInstance,
  canDownloadSegment,
  canSendTestCampaign,
  canDisableCampaign,
  canApproveCampaigns,
  canSeeGrowthToolCampaign,
  canCreateGrowthToolCampaign,
  canCloneGrowthToolCampaign,
  canUpdateGrowthToolCampaign,
  canDeleteGrowthToolCampaign,
  canSeePushNotificationCampaign,
  canCreatePushNotificationCampaign,
  canClonePushNotificationCampaign,
  canUpdatePushNotificationCampaign,
  canDeletePushNotificationCampaign,
  canSeeGrowthToolMessage,
  canCreateGrowthToolMessage,
  canEditGrowthToolMessage,
  canDeleteGrowthToolMessage,
  canCloneGrowthToolMessage,
  canSeePushNotificationMessage,
  canEditPushNotificationMessage,
  canDeletePushNotificationMessage,
  canCreatePushNotificationMessage,
  canClonePushNotificationMessage,
  canSeeInboxMessage,
  canCreateInboxMessage,
  canEditInboxMessage,
  canDeleteInboxMessage,
  canSeeInboxCampaign,
  canCreateInboxCampaign,
  canUpdateInboxCampaign,
  canDeleteInboxCampaign,
  canSeeSmsMessage,
  canCreateSmsMessage,
  canEditSmsMessage,
  canDeleteSmsMessage,
  canSeeSmsCampaign,
  canCreateSmsCampaign,
  canCreateSmsCampaignByTarget,
  canUpdateSmsCampaign,
  canUpdateSmsCampaignByTarget,
  canDeleteSmsCampaign,
  canDeleteSmsCampaignByTarget,
  canSyncSegmentOnDemand,
  canSeeReminderWidget,
  canActivateRetargeting,
  canSeeQueryTemplate,
  canCreateQueryTemplate,
  canCloneQueryTemplate,
  canEditQueryTemplate,
  canDeleteQueryTemplate,
  canSelectCommercial,
  canSelectMarketing,
  canSelectAdvertising,
  canSelectOnSite,
  canSelectOnCRM,
  canSelectOthers,
  canSeeBudget,
  canCreateBudget,
  canEditBudget,
  canApproveSmsCampaigns,
  canSeeRate,
  canCreateRate,
  canEditRate,
  canSeeCampaignTags,
  canCreateCampaignTags,
  canUpdateCampaignTags,
  canUpdateStatusCampaignTags,
  canAccessDeeplinks,
  canCreateDeeplink,
  canUpdateDeeplink,
  canAccessLogicGroups,
  canCreateLogicGroup,
  canUpdateLogicGroup,
  canDeleteEmailTemplate
}
