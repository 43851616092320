import { butterflyAxios } from '@/api/axios'
import {
  campaignListMap,
  campaignMap,
  mapPushNotificationPreview,
  monthStatsMap,
  mapInboxMessagePreview,
  mapSmsPreview,
  campaignAuditListMap,
  listExpiringMap
} from './responseMapper'
import {
  isCreatedResponse,
  isOKResponse,
  parseSuccessResponse
} from '@/api/utils/response'
import { mapPreviewRequest } from '@/api/campaigns/requestMapper'
import { CHANNEL_KEYWORDS } from '@/api/channels/constants'
import { currencyFormatWithCode } from '@/utils/commons/formatter'
import qs from 'qs'

const endpoint = {
  campaigns: 'campaigns',
  campaign: campaignId => `campaigns/${campaignId}`,
  stats: (campaignId, startDate, endDate) =>
    `campaigns/${campaignId}/stats?start_date=${startDate}&end_date=${endDate}`,
  lastStats: campaignId => `campaigns/${campaignId}/stats?resolution=last`,
  monthStats: campaignId => `campaigns/${campaignId}/stats?resolution=month`,
  downloadStatsCsv: campaignId => `campaigns/${campaignId}/stats/export`,
  testCampaign: () => `campaigns/dispatch-test`,
  preview: 'campaigns/preview',
  exportCsv: 'campaigns/export',
  audit: campaignId => `campaigns/${campaignId}/audit`,
  bulkUpdateStatus: 'campaigns/bulk-update',
  expiringCampaigns: 'campaigns/expiring',
  widgetCampaigns: 'campaigns/widget',
  calculateCampaignCost: `campaigns/costs/calculate`,
  campaignsDispatch: 'campaigns/status'
}

async function apiGetCampaigns(request) {
  const q = request.q
  const page = request.page
  const filters = request.filters
  const perPage = request.perPage
  let payload = {}

  if (filters.campaign_id) {
    filters.campaign_id = parseInt(filters.campaign_id)
  }

  if (q != null && q !== '') {
    payload.query = q
  }

  payload.per_page = perPage
  payload.page = page

  let { data } = await butterflyAxios.get(endpoint.campaigns, {
    params: { ...payload, ...filters }
  })

  let list = data.data
  let pagination = data.meta

  let campaigns = campaignListMap(list)

  return {
    campaigns: campaigns,
    finalDataSize: pagination ? pagination.total_records : campaigns.length,
    newPage: pagination ? pagination.page : 1,
    originalData: campaigns // The original, unaltered data, for any needs any component may have
  }
}

async function apiGetCampaignsCsv(request) {
  const filters = request.filters

  const response = await butterflyAxios.get(endpoint.exportCsv, {
    params: { ...filters },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })

  const data = response.data

  if (data) {
    return data
  }

  throw response.error
}
/**
 * Delete a campaign
 *
 * @param id integer CampaignId
 * @returns {Promise<void>}
 */
const apiDeleteCampaign = async id => {
  const response = await butterflyAxios.delete(endpoint.campaign(id))

  if (response) {
    return true
  }

  return false
}

const apiCreateCampaign = async campaign => {
  const response = await butterflyAxios.post(endpoint.campaigns, campaign)
  if (isCreatedResponse(response)) {
    let result = parseSuccessResponse(response)
    return result
  }
  return undefined
}

const apiUpdateCampaign = async campaign => {
  const response = await butterflyAxios.put(
    endpoint.campaign(campaign.id),
    campaign
  )

  let data
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    data = processedResponse
  } else {
    throw processedResponse
  }

  return campaignMap(data)
}

const apiBulkUpdateStatusCampaign = async data => {
  const response = await butterflyAxios.post(endpoint.bulkUpdateStatus, data)

  if (isOKResponse(response)) {
    return response.data.data
  }

  throw response
}

const apiGetCampaign = async campaignId => {
  const response = await butterflyAxios.get(endpoint.campaign(campaignId))

  let data
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    data = processedResponse
  } else {
    throw processedResponse
  }

  return campaignMap(data)
}

const apiTestCampaign = async data => {
  const payload = {
    campaign_id: data.campaignId
  }

  if (data.email) {
    payload.email = data.email
  }

  if (data.date) {
    payload.dispatch_date = data.date
  }

  const response = await butterflyAxios.post(endpoint.testCampaign(), payload)
  return response
}

const apiDownloadCampaignsStatsCsv = async ({ campaignId }) => {
  const response = await butterflyAxios.get(
    endpoint.downloadStatsCsv(campaignId)
  )
  const data = response.data

  if (data) {
    return data
  }

  throw response.error
}

const apiGetCampaignLastStats = async request => {
  const response = await butterflyAxios.get(
    endpoint.lastStats(request.campaignId)
  )

  if (isOKResponse(response)) {
    return response.data.data
  }

  throw response
}

const apiGetCampaignMonthStats = async request => {
  let res = await butterflyAxios.get(endpoint.monthStats(request.campaignId))
  if (isOKResponse(res)) {
    let { response } = parseSuccessResponse(res)

    return monthStatsMap(response, request.channel)
  }
  throw res
}

const apiGetCampaignStats = async request => {
  const response = await butterflyAxios.get(
    endpoint.stats(request.campaignId, request.startDate, request.endDate)
  )

  if (isOKResponse(response)) {
    return response.data.data
  }
  throw response
}

const apiPreview = async request => {
  const payload = mapPreviewRequest(request)
  const res = await butterflyAxios.post(endpoint.preview, payload)
  if (isOKResponse(res)) {
    let { response } = parseSuccessResponse(res)
    switch (request.channel) {
      case CHANNEL_KEYWORDS.PUSH_NOTIFICATION:
        return mapPushNotificationPreview(response)
      case CHANNEL_KEYWORDS.INBOX:
        return mapInboxMessagePreview(response)
      case CHANNEL_KEYWORDS.SMS:
        return mapSmsPreview(response)
    }
  }
  throw res
}

const apiGetCampaignAudit = async request => {
  const payload = {
    page: request.page,
    limit: request.perPage,
    order_by: request.orderBy,
    sort_by: request.sortBy
  }

  const { data } = await butterflyAxios.get(endpoint.audit(request.id), {
    params: { ...payload }
  })

  const list = data.data.user_audit
  const pagination = data.data.pagination

  const campaignsAudit = campaignAuditListMap(list)

  return {
    campaignAudit: campaignsAudit,
    finalDataSize: pagination
      ? pagination.total_records
      : campaignsAudit.length,
    newPage: pagination ? pagination.page : 1,
    originalData: campaignsAudit // The original, unaltered data, for any needs any component may have
  }
}

async function apiGetWidgetCampaigns(request) {
  const params = mountParamsWidgetCampaings(request)

  const res = await butterflyAxios.get(endpoint.widgetCampaigns, {
    params
  })
  if (isOKResponse(res)) {
    const list = res.data.data
    const pagination = res.data.meta
    const mappedList = listExpiringMap(list)

    return {
      expiringCampaigns: mappedList,
      finalDataSize: pagination ? pagination.total_records : list.length
    }
  }
  throw res
}
function mountParamsWidgetCampaings(request) {
  const q = request.q
  const page = request.page
  const perPage = request.perPage
  const filters = request.filters

  let payload = {}

  if (!filters) {
    payload.sort_by = 'end_date'
    payload.order_by = 'asc'
  }

  if (q != null && q !== '') {
    payload.name = q
  }

  payload.per_page = perPage
  payload.page = page
  payload.expiring = request.tab === 'expiring'
  return { ...payload, ...filters }
}
async function apiGetWidgetCampaignsCsv(request) {
  const params = mountParamsWidgetCampaings(request)

  const response = await butterflyAxios.get(endpoint.widgetCampaigns, {
    params
  })

  const data = response.data

  if (data) {
    return data
  }

  throw response.error
}
async function apiCalculateCampaignCost(request) {
  const res = await butterflyAxios.post(endpoint.calculateCampaignCost, request)
  if (isOKResponse(res)) {
    const { response } = parseSuccessResponse(res)

    return {
      cost: response.cost,
      costFormatted: currencyFormatWithCode(response.cost),
      monthBudget: response.month_budget,
      monthBudgetFormatted: currencyFormatWithCode(response.month_budget)
    }
  }
  throw res
}

async function apiCampaignsDispatches() {
  const res = await butterflyAxios.get(endpoint.campaignsDispatch)
  if (isOKResponse(res)) {
    const {
      response: campaignsDispatches,
      meta: pagination
    } = parseSuccessResponse(res)
    const data = {
      campaignsDispatches,
      total: pagination ? pagination.total_records : campaignsDispatches.length
    }
    return data
  }
  throw res
}
export {
  apiGetCampaigns,
  apiDeleteCampaign,
  apiGetCampaign,
  apiGetCampaignsCsv,
  apiDownloadCampaignsStatsCsv,
  apiCreateCampaign,
  apiUpdateCampaign,
  apiTestCampaign,
  apiGetCampaignLastStats,
  apiGetCampaignMonthStats,
  apiGetCampaignStats,
  apiPreview,
  apiGetCampaignAudit,
  apiBulkUpdateStatusCampaign,
  apiGetWidgetCampaigns,
  apiGetWidgetCampaignsCsv,
  apiCalculateCampaignCost,
  apiCampaignsDispatches
}
