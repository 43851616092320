import axios from 'axios'
import { errorHandler } from './interceptors'
import qs from 'qs'

// Common configurations
// axios.defaults.withCredentials = true
axios.defaults.headers.common['Accept-Language'] = `${window.CONFIG.LOCALE}`
axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: 'repeat' })
}

const create = (endpoint, application = 'mds') =>
  axios.create({
    baseURL: process.env.NODE_ENV === 'test' ? '' : `${endpoint}`,
    headers: {
      application
    }
  })

// Axios instances
const milkyWayAxios = create(window.CONFIG.MILKYWAY_ENDPOINT)

const eyeofsauronAxios = create(window.CONFIG.EYEOFSAURON_ENDPOINT)

const eyeofsauronV2Axios = create(window.CONFIG.EYEOFSAURON_ENDPOINT_V2)

const butterflyAxios = create(window.CONFIG.BUTTERFLY_ENDPOINT)

const cosmosAxios = create(window.CONFIG.COSMOS_ENDPOINT)

const sombreroAxios = create(window.CONFIG.SOMBRERO_ENDPOINT)

const fireworksAxios = create(window.CONFIG.FIREWORKS_ENDPOINT)

// Interceptors
function bypassHandler(response) {
  return response
}

axios.interceptors.response.use(bypassHandler, errorHandler)
milkyWayAxios.interceptors.response.use(bypassHandler, errorHandler)
eyeofsauronAxios.interceptors.response.use(bypassHandler, errorHandler)
eyeofsauronV2Axios.interceptors.response.use(bypassHandler, errorHandler)
butterflyAxios.interceptors.response.use(bypassHandler, errorHandler)
cosmosAxios.interceptors.response.use(bypassHandler, errorHandler)
sombreroAxios.interceptors.response.use(bypassHandler, errorHandler)
fireworksAxios.interceptors.response.use(bypassHandler, errorHandler)

// Auxiliary methods
function setAxiosXAuthToken(token) {
  if (process.env.NODE_ENV !== 'testing') {
    milkyWayAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    eyeofsauronAxios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`
    eyeofsauronV2Axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`
    butterflyAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    fireworksAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    cosmosAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    sombreroAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
}

function setAxiosAcceptLanguage(lang) {
  if (process.env.NODE_ENV !== 'testing') {
    milkyWayAxios.defaults.headers.common['Accept-Language'] = lang
    eyeofsauronAxios.defaults.headers.common['Accept-Language'] = lang
    eyeofsauronV2Axios.defaults.headers.common['Accept-Language'] = lang
    butterflyAxios.defaults.headers.common['Accept-Language'] = lang
    fireworksAxios.defaults.headers.common['Accept-Language'] = lang
    cosmosAxios.defaults.headers.common['Accept-Language'] = lang
    sombreroAxios.defaults.headers.common['Accept-Language'] = lang
  }
}

export {
  milkyWayAxios,
  eyeofsauronAxios,
  eyeofsauronV2Axios,
  butterflyAxios,
  fireworksAxios,
  cosmosAxios,
  sombreroAxios,
  setAxiosXAuthToken,
  setAxiosAcceptLanguage
}
