/**
 * Converts a number to a readable number (e.g. 100k).
 * If it's an integer, decimal values will be ignored.
 * @param {*} number The number to be converted.
 * @param {*} decimalValues THe decimal values to round to (will be ignored if the number
 * provided is a whole number, such as 280)
 */
function toReadableNumber(number, decimalValues) {
  const symbols = ['', 'k', 'M', 'B']
  let result = number
  let index = 0
  while (result >= 1000) {
    result *= 0.001
    index++
  }
  let originalValue = result * Math.pow(10, decimalValues)
  let isInt = originalValue % 1 === 0
  result = Math.floor(originalValue) * Math.pow(10, -decimalValues)
  result = isInt ? result.toFixed(0) : result.toFixed(decimalValues)
  return result + symbols[index]
}

/**
 * Formats the size of the bytes into B, kB and MB
 * @param {int} byteSize
 */
function formatBytes(byteSize) {
  let result = byteSize
  let divisions = 0
  let units = ['B', 'kB', 'MB', 'GB', 'TB']
  while (result >= 1024.0) {
    result = result / 1024.0
    divisions++
  }
  return result.toFixed(2) + ' ' + units[divisions]
}

export { toReadableNumber, formatBytes }
