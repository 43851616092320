import { apiGetCardClassifications } from '@/api/card-classifications'

async function fetchCardClassifications({ commit }, payload) {
  const response = await apiGetCardClassifications(payload)

  commit('CARD_CLASSIFICATIONS', response.cardClassifications)

  return response.cardClassifications
}

export default {
  fetchCardClassifications
}
