import { isGrowthTool } from '@/api/messages/utils'
import { SOURCES } from '@/constants/sources'

function MapCreate(message) {
  if (isGrowthTool(message)) {
    return mapGrowthTool(message)
  }
  return message
}

function MapUpdate(message) {
  if (isGrowthTool(message)) {
    let dto = mapGrowthTool(message)
    dto.id = message.id
    return dto
  }
  return message
}

function mapGrowthTool(message) {
  const sqlFields = []
  message.data.sqlFields.forEach((f, index) => {
    sqlFields.push({ name: `sql_field_${index + 1}`, value: f.value })
  })
  const sqlFieldsMap = {}
  const regexp = /sql_field_\d/g
  message.templates.forEach(m => {
    const titleSqlFields = []
    let titleMatches = m.content.title.matchAll(regexp)
    for (const match of titleMatches) {
      titleSqlFields.push(match[0])
    }
    const extendedMessageSqlFields = []
    let extendedMessageMatches = m.content.extendedMessage.matchAll(regexp)
    for (const match of extendedMessageMatches) {
      extendedMessageSqlFields.push(match[0])
    }
    const landingPageSqlFields = []
    let landingPageMatches = m.content.landingPage.matchAll(regexp)
    for (const match of landingPageMatches) {
      landingPageSqlFields.push(match[0])
    }

    const imageSqlFields = []
    let imageMatches = m.content.image.matchAll(regexp)
    for (const match of imageMatches) {
      imageSqlFields.push(match[0])
    }

    const buttonContentSqlFields = []

    let buttonContentMatches = message.button.action.content.matchAll(regexp)

    for (const match of buttonContentMatches) {
      buttonContentSqlFields.push(match[0])
    }

    sqlFieldsMap[m.content.isoCode] = {
      title: titleSqlFields,
      extended_message: extendedMessageSqlFields,
      landing_page: landingPageSqlFields,
      btn_content: buttonContentSqlFields,
      image: imageSqlFields
    }
  })

  return {
    name: message.name,
    description: message.description,
    channel_id: message.channel.id,
    vertical_id: message.verticalId,
    target_id: message.targetId,
    data: {
      instance_id: message.data.instanceId,
      custom_query: message.data.customQuery,
      sql_fields: [...sqlFields],
      sql_fields_map: sqlFieldsMap,
      content: message.content,
      button: {
        type: message.button.type,
        action: {
          type: message.button.action.type,
          content: message.button.action.content
        }
      },
      project_id: message.data.projectId,
      named_users: message.data.namedUsers,
      android_channels: message.data.androidChannels,
      ios_channels: message.data.iosChannels
    },
    active: message.active,
    templates: message.templates,
    ignore_syntax_errors: true,
    program_id: message.programId,
    objective_id: message.objectiveId,
    custom_tag: message.customTag,
    incentive_type_id: message.incentiveTypeId,
    incentive_discount:
      message.incentiveDiscount !== undefined
        ? parseFloat(message.incentiveDiscount)
        : null,
    incentive_amount_currency_id:
      message.incentiveAmountCurrency !== undefined
        ? parseInt(message.incentiveAmountCurrency)
        : null,
    incentive_amount:
      message.incentiveAmount !== undefined
        ? parseFloat(message.incentiveAmount)
        : null,
    incentive_amount_euros:
      message.incentiveAmountEuros !== undefined
        ? parseFloat(message.incentiveAmountEuros)
        : null,
    time_constraint: message.timeConstraint,
    source: message.source ? SOURCES.AWS_DATALAKE : SOURCES.BIGQUERY,
    logic_group_id: message.logicGroupId,
    query_template_id: message.queryTemplateId,
    query_template_placeholders: message.queryTemplatePlaceholders,
    query_method: message.queryMethod
  }
}

function MapValidate(message) {
  return mapGrowthTool(message)
}

export { MapCreate, MapUpdate, MapValidate }
