import actions from './actions'
import mutations from './mutations'

const state = {
  stats: undefined,
  stats_email: undefined,
  stats_newsletter: undefined,
  stats_pn: undefined,
  stats_im: undefined,
  stats_sms: undefined,
  exported_stats: undefined,
  exported_stats_email: undefined,
  exported_stats_newsletter: undefined,
  exported_stats_pn: undefined,
  exported_stats_im: undefined,
  exported_stats_sms: undefined
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
