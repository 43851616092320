import { butterflyAxios } from '@/api/axios'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'
import { mapChannelBudgetReq } from './requestMapper'
import { channelBudgetsAuditListMap, mapChannelBudget } from './responseMapper'

const endpoint = {
  channelBudgets: 'channelbudgets',
  channelBudgetsById: id => `channelbudgets/${id}`,
  channelBudgetsAudit: id => `channelbudgets/${id}/audit`,
  channelBudgetCampaigns: id => `channelbudgets/${id}/campaigns`
}

async function apiGetChannelBudgets(request) {
  const payload = {
    page: request.page,
    per_page: request.perPage,
    order_by: request.filters.order_by,
    sort_by: request.filters.sort_by
  }
  let response = await butterflyAxios.get(endpoint.channelBudgets, {
    params: { ...payload }
  })

  if (isOKResponse(response)) {
    let result = parseSuccessResponse(response)
    let processedResponse = result.response
    let meta = result.meta
    if (result.success) {
      const finalDataSize = meta ? meta.total_records : 0
      return {
        finalDataSize,
        channelBudgets: processedResponse
      }
    }
  }

  throw response
}
async function apiGetCampaignsByBudgetId(request) {
  const payload = {
    order_by: request.orderBy,
    sort_by: request.sortBy
  }
  const response = await butterflyAxios.get(
    endpoint.channelBudgetCampaigns(request.id),
    {
      params: { ...payload }
    }
  )

  if (isOKResponse(response)) {
    const result = parseSuccessResponse(response)
    if (result.success) {
      const finalDataSize = result.meta ? result.meta.total_records : 0
      return {
        finalDataSize,
        budgetCampaigns: result.response
      }
    }
  }

  throw response
}

const apiGetChannelBudgetsAudit = async request => {
  const payload = {
    page: request.page,
    limit: request.perPage,
    order_by: request.orderBy,
    sort_by: request.sortBy
  }

  const response = await butterflyAxios.get(
    endpoint.channelBudgetsAudit(request.id),
    {
      params: { ...payload }
    }
  )

  if (isOKResponse(response)) {
    const result = parseSuccessResponse(response)
    const list = result.response
    const pagination = result.meta

    const channelBudgetsAudits = channelBudgetsAuditListMap(list)

    return {
      channelBudgetsAudits,
      finalDataSize: pagination
        ? pagination.total_records
        : channelBudgetsAudits.length,
      newPage: pagination ? pagination.page : 1,
      originalData: channelBudgetsAudits
    }
  }
  throw response
}

async function apiGetChannelBudgetById(request) {
  let response = await butterflyAxios.get(
    endpoint.channelBudgetsById(request.id)
  )

  if (isOKResponse(response)) {
    let result = parseSuccessResponse(response)
    let processedResponse = result.response
    if (result.success) {
      return mapChannelBudget(processedResponse)
    }
  }

  throw response
}

const apiUpdateChannelBudgets = async channelBudget => {
  const response = await butterflyAxios.put(
    endpoint.channelBudgetsById(channelBudget.id),
    mapChannelBudgetReq(channelBudget)
  )
  return response.data
}

const apiCreateChannelBudgets = async channelBudget => {
  const response = await butterflyAxios.post(
    endpoint.channelBudgets,
    mapChannelBudgetReq(channelBudget)
  )
  return response.data
}

export {
  apiGetChannelBudgets,
  apiGetChannelBudgetsAudit,
  apiCreateChannelBudgets,
  apiUpdateChannelBudgets,
  apiGetChannelBudgetById,
  apiGetCampaignsByBudgetId
}
