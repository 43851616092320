/**
 * Signals Vuex that the campaigns list has changed.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The campaigns, finalDataSize and originalData to mutate.
 */
function CAMPAIGNS_UPDATED(state, { campaigns, finalDataSize, originalData }) {
  state.campaigns = campaigns
  state.finalDataSize = finalDataSize
  state.originalData = originalData
}

/**
 * Signals Vuex that the instances list has changed.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The campaigns, finalDataSize and originalData to mutate.
 */
function INSTANCES_UPDATED(state, { instances, finalDataSize, originalData }) {
  state.instances = instances
  state.instancesFinalDataSize = finalDataSize
  state.instancesOriginalData = originalData
}

/**
 * Signals Vuex that the messages list has changed.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The campaigns, finalDataSize and originalData to mutate.
 */
function MESSAGES_UPDATED(state, { messages }) {
  state.messages = messages
}

function WIDGET_CAMPAIGNS_UPDATED(state, { widgetCampaigns, finalDataSize }) {
  state.widgetCampaigns = widgetCampaigns
  state.finalDataSize = finalDataSize
}

export default {
  CAMPAIGNS_UPDATED,
  INSTANCES_UPDATED,
  MESSAGES_UPDATED,
  WIDGET_CAMPAIGNS_UPDATED
}
