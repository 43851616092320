/**
 * Signal the Vuex state that the verticals were cached.
 * @param {*} state
 * @param {array} newVerticals
 */
function VERTICALS_CACHED(state, newVerticals) {
  state.verticals = newVerticals
}

export default {
  VERTICALS_CACHED
}
