/**
 * Signal the Vuex state that the newsfeed tabs were cached.
 * @param {*} state
 * @param {array} list
 */
function TABS(state, list) {
  state.tabs = list
}

export default { TABS }
