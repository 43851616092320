import store from '@/store'
import { i18n } from '@/i18n'
import { ERROR_TYPES } from '@/constants/errors'
import { parseErrorResponse } from '@/api/utils/response'

/**
 * Checks if the api response is an axios error.
 * @param {*} apiResponse The api response (usually obtained from the catch statement in a throw exception).
 */
function isAxiosResponse(apiResponse) {
  return (
    apiResponse &&
    apiResponse.response &&
    (apiResponse.responseType === undefined ||
      apiResponse.responseType === ERROR_TYPES.GENERIC)
  )
}

function isMDSErrorFormat(apiResponseError) {
  return apiResponseError && apiResponseError.responseType === ERROR_TYPES.MDS
}

function isUnauthorizedError(apiResponseError) {
  return (
    (isAxiosResponse(apiResponseError) &&
      apiResponseError.response.status === 401) ||
    (isMDSErrorFormat(apiResponseError) && apiResponseError.status === 401)
  )
}

function isNotFoundError(apiResponseError) {
  return (
    (isAxiosResponse(apiResponseError) &&
      apiResponseError.response.status === 404) ||
    (isMDSErrorFormat(apiResponseError) && apiResponseError.status === 404)
  )
}

function isConflictError(apiResponseError) {
  return (
    (isAxiosResponse(apiResponseError) &&
      apiResponseError.response.status === 409) ||
    (isMDSErrorFormat(apiResponseError) && apiResponseError.status === 409)
  )
}

function isForbiddenError(apiResponseError) {
  return (
    (isAxiosResponse(apiResponseError) &&
      apiResponseError.response.status === 403) ||
    (isMDSErrorFormat(apiResponseError) && apiResponseError.status === 403)
  )
}

function isValidationError(apiResponseError) {
  return (
    (isAxiosResponse(apiResponseError) &&
      apiResponseError.response.status === 422) ||
    (isMDSErrorFormat(apiResponseError) && apiResponseError.status === 422)
  )
}

/**
 * Parses the validation errors obtained from the api response
 * (after calling parseErrorResponse of api/utils/response).
 * Returns all sources that did not match any attribute in form error messages.
 * Form error messages will contain the messages for each expected source (field).
 * @param {*} mdsResponseError THe api response error (as per the conventions defined for the api response error).
 * @param {*} formErrorMessages The error messages object that will be filled.
 * The object must declare the sources it wants, for example:
 * {
 *  name: null,
 *  startDate: null,
 *  ...
 * }
 */
function parseValidationErrors(mdsResponseError, formErrorMessages) {
  let result
  if (
    isValidationError(mdsResponseError) &&
    isMDSErrorFormat(mdsResponseError)
  ) {
    result = {}
    let errors = mdsResponseError.response.errors
    if (errors !== undefined && formErrorMessages) {
      for (let index in errors) {
        let element = errors[index]
        if (element.source !== undefined && element.source.length > 0) {
          // If we have a source, add it
          let focusStructure = result
          let hasSource = Object.prototype.hasOwnProperty.call(
            formErrorMessages,
            element.source
          )
          if (hasSource) {
            focusStructure = formErrorMessages
          }
          if (!focusStructure[element.source]) {
            focusStructure[element.source] = []
          }
          focusStructure[element.source].push(element.message)
        }
      }
    }
  }
  return result
}

/**
 * Parses all error messages from an MDS-formatted response error,
 * ignoring if they are in respect to a validation form.
 * Returns an array of error messages.
 * @param {*} mdsResponseError The mds response error.
 */
function parseAllErrors(mdsResponseError) {
  let result = []
  if (isMDSErrorFormat(mdsResponseError)) {
    let errors = mdsResponseError.response.errors
    if (errors !== undefined) {
      for (let index in errors) {
        let element = errors[index]
        if (element.message && element.message.length > 0) {
          result.push(element.message)
        }
      }
    }
  }
  return result
}

/**
 * Executes the default logic of handling an error from the BE,
 * usually associated with CREATE, UPDATE and other similar requests.
 * Only works for the new MDS error format.
 * @param {*} apiResponseError The error thrown by the API.
 * @param {*} formErrorMessages The form error messages to be shown.
 * @param {*} extraErrorMessages An array that will contain the extra error messages obtained (
 * i.e. messages that do not have a 'field' on formErrorMessages). If undefined,
 * the field is ignored and the first message is displayed on screen.
 */
function defaultErrorHandlingExecution(
  apiResponseError,
  formErrorMessages,
  extraErrorMessages
) {
  let parsedError = parseErrorResponse(apiResponseError)
  let popupMessage
  let shouldThrowError = false
  if (parsedError.success) {
    let validationErrors = parseValidationErrors(parsedError, formErrorMessages)

    // If it isn't in the new format, we ignore everything.
    if (isMDSErrorFormat(parsedError) && isValidationError(parsedError)) {
      let showDefaultValidationMessage = true
      // If the object has keys (elsewise, call to getOwnPropertyNames will fail)
      if (validationErrors && Object.keys(validationErrors).length > 0) {
        let remainingSources = Object.getOwnPropertyNames(validationErrors)
        if (remainingSources.length > 0) {
          if (extraErrorMessages) {
            for (let index in remainingSources) {
              let elem = validationErrors[remainingSources[index]]
              for (let errIndex in elem) {
                extraErrorMessages.push(elem[errIndex])
              }
            }
          } else {
            let errors = validationErrors[remainingSources[0]]
            if (errors.length > 0) {
              // If we have remaining errors that will not be shown in the forms,
              // we show the first error.
              popupMessage = errors[0]
              showDefaultValidationMessage = false
            }
          }
        }
      }
      // If, for whatever reason, we need to show the default validation error message,
      // we update the popup message to do so.
      if (showDefaultValidationMessage || validationErrors === undefined) {
        let commonErrors = parseAllErrors(parsedError)
        if (commonErrors.length > 0) {
          popupMessage = commonErrors[0]
        } else {
          popupMessage = i18n.t('errors.100')
        }
      }
    } else {
      let commonErrors = parseAllErrors(parsedError)
      if (commonErrors.length > 0) {
        popupMessage = commonErrors[0]
      }
    }

    if (!isForbiddenError(parsedError) && !popupMessage) {
      popupMessage = i18n.t('error.notification')
      shouldThrowError = true
    }
  } else {
    popupMessage = i18n.t('error.notification')
    shouldThrowError = true
  }
  if (popupMessage) {
    store.dispatch('app/showErrorNotification', {
      message: popupMessage
    })
  }
  if (shouldThrowError) {
    throw apiResponseError
  }
}

export {
  defaultErrorHandlingExecution,
  parseValidationErrors,
  parseAllErrors,
  isMDSErrorFormat,
  isUnauthorizedError,
  isValidationError,
  isConflictError,
  isForbiddenError,
  isNotFoundError
}
