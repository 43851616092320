import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'

/**
 * A constant to specify functions that allow
 * to create the actions that can be used in emailTemplates.
 */
const action = {
  VIEW: () => {
    return {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('view')
    }
  },
  DELETE: campaign => {
    return {
      event: 'delete',
      icon: 'delete',
      label: i18n.t('emailtemplates.actions.delete'),
      confirmationRequired: true,
      confirmationTitleKey: i18n.t(
        'emailtemplates.actions.delete_confirmation.title'
      ),
      confirmationDescriptionKey: i18n.t(
        'emailtemplates.actions.delete_confirmation.description',
        campaign
      )
    }
  }
}

/**
 * Returns the emailTemplates list, with the
 * format ready to be rendered in the JList.vue component.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
const emailTemplatesListDTO = (state, getters, rootState, rootGetters) => {
  if (!state.emailTemplates) return []
  return state.emailTemplates.map(template => {
    const data = templateToListDTO(template)
    return {
      ...data,
      actions: actionsOnEmailTemplate(
        state,
        getters,
        rootState,
        rootGetters
      )(template)
    }
  })
}

/**
 * Specifies the column names for the segment list view.
 * The values are obtained from the getter.js and responseMapper.js, as well as the internationalization18
 * (i18) values.
 */
const templateToListDTO = template => {
  return {
    id: template.id,
    name: template.name,
    description: template.description,
    created_at: formatDate(template.created_at),
    last_used_at: formatDate(template.last_used_at),
    domain: template.domain,
    canExpand: true
  }
}

/**
 * Responsible for declaring the actions that can be performed on a specific segment template.
 */
const actionsOnEmailTemplate = (state, getters, rootState, rootGetters) => {
  return function(template) {
    let actions = []

    if (!template) return actions

    /**
     * Specifies that the current user can perform the below actions.
     * action: the action that the user can perform
     * permission: path to the 'getter' that confirms the permission.
     */

    let potentialActions = [
      {
        action: action.VIEW(template),
        permission: 'user/hasAdminPermissions'
      },
      {
        action: action.DELETE(template),
        permission: 'user/canDeleteEmailTemplate'
      }
    ]
    actions = filterActionsListByPermission(
      rootGetters,
      template.createdBy,
      potentialActions
    )

    return actions
  }
}

export default {
  emailTemplatesListDTO
}
