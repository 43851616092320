import { APP_CONSTANTS } from './constants'
import { setAreaPropertyMultiple } from '@/store/securedStorage'

/**
 * Helper function to set the value of a property that belongs to the app local storage.
 * @param {vuexState} state The vuex state.
 * @param {*} property The property name.
 * @param {*} value The value.
 */
function setAppProperty(state, property, value) {
  setAreaPropertyMultiple(state, APP_CONSTANTS.APP, [
    { property: property, value: value }
  ])
}

/**
 * Signals the app that a page is being loaded.
 * Usually, an overlay or other progress indicating widgets may be used,
 * whilst the page is being loaded.
 * @param {vuexState} state
 */
function LOADING_PAGE_START(state) {
  state.loadingPage = true
}

/**
 * Signals the app that a page has finished loading.
 * @param {vuexState} state
 */
function LOADING_PAGE_END(state) {
  state.loadingPage = false
}
/**
 * Represents a mutation of the updated app persisted variable.
 * @param {vuexState} state
 * @param {*} isNew Whether the app is new or not.
 */
function APP_NEW_VERSION(state, isNew) {
  setAppProperty(state, APP_CONSTANTS.UPDATED_APP, isNew)
}

/**
 * Represents a change in the search setting's context.
 * @param {*} state
 * @param {*} module The new module context.
 */
function MODULE_CONTEXT_CHANGED(state, module) {
  state.listSearchSettings.context = module
}

/**
 * Changes the currently used query to search for a given content's name
 * in a list view.
 * @param {*} state
 * @param {*} query The query data.
 */
function QUERY_LIST_SEARCH_SETTINGS(state, query) {
  state.listSearchSettings.searchBar = query
}

/**
 * Changes the currently cached client side filters.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} clientSideFilters The client side filters to cache.
 */
function CLIENT_SIDE_FILTERS_CACHED(state, clientSideFilters) {
  state.listSearchSettings.clientSideFilters = clientSideFilters
}

/**
 * Changes the currently cached server side filters.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} clientSideFilters The client side filters to cache.
 */
function SERVER_SIDE_FILTERS_CACHED(state, filters) {
  state.listSearchSettings.serverSideFilters = filters
}

/**
 * Clears the list search settings of all of its settings.
 * @param {vuexState} state The vuex state.
 */
function CLEARED_LIST_SEARCH_SETTINGS(state) {
  state.listSearchSettings = {
    searchBar: null,
    context: null,
    clientSideFilters: null
  }
}

/**
 * Signals that the part of the menu that is available to the user
 * has been updated.
 * @param {vuexState} state
 * @param {*} availableMenu The new contents of the menu that are available.
 */
function AVAILABLE_MENU_UPDATED(state, availableMenu) {
  state.menu = availableMenu.availableMenu
}

function SNACKBAR_UPDATED(
  state,
  { type, text, action, module, customMessage, timeout }
) {
  state.snackbar = {
    module,
    text,
    type,
    action,
    customMessage,
    timeout
  }
}

function SNACKBAR_CLEANED(state) {
  state.snackbar = null
}

/**
 * Changes the globally set country both in memory and LocalStorage
 * @param {vuexState} state
 * @param selectedCountry
 * @constructor
 */
function UPDATE_SELECTED_COUNTRY(state, selectedCountry) {
  state.selectedCountry = selectedCountry
  setAppProperty(state, APP_CONSTANTS.SELECTED_COUNTRY, selectedCountry)
}

export default {
  LOADING_PAGE_START,
  LOADING_PAGE_END,
  SNACKBAR_UPDATED,
  SNACKBAR_CLEANED,
  AVAILABLE_MENU_UPDATED,
  APP_NEW_VERSION,
  QUERY_LIST_SEARCH_SETTINGS,
  CLEARED_LIST_SEARCH_SETTINGS,
  MODULE_CONTEXT_CHANGED,
  CLIENT_SIDE_FILTERS_CACHED,
  SERVER_SIDE_FILTERS_CACHED,
  UPDATE_SELECTED_COUNTRY
}
