/**
 * Signals Vuex that the channel types have changed.
 * @param {vuexState} state
 * @param {*} channelTypes The new channel types.
 */
function CHANNEL_TYPES_UPDATED(state, channelTypes) {
  state.channelTypes = channelTypes
}

export default {
  CHANNEL_TYPES_UPDATED
}
