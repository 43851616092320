/**
 * Signal the Vuex state that the instance tags were cached.
 * @param {*} state
 * @param {array} list
 */
function INSTANCE_TAGS(state, list) {
  state.tags = list
}

export default { INSTANCE_TAGS }
