import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'
import { SOURCES } from '@/constants/sources'

/**
 * A constant to specify functions that allow
 * to create the actions that can be used in queryTemplates.
 */
const action = {
  CREATE: () => {
    return {
      event: 'create',
      label: i18n.t('querytemplates.title.create'),
      icon: 'add',
      isPrimary: true,
      options: []
    }
  },
  DELETE: template => {
    return {
      event: 'delete',
      icon: 'delete',
      label: i18n.t('delete'),
      confirmationRequired: true,
      confirmationTitleKey: i18n.t('querytemplates.delete_confirmation.title'),
      confirmationDescriptionKey: i18n.t(
        'querytemplates.delete_confirmation.description',
        template
      )
    }
  },
  EDIT: () => {
    return {
      event: 'view',
      icon: 'edit',
      label: i18n.t('edit')
    }
  },
  VIEW: () => {
    return {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('view')
    }
  },
  CLONE: () => {
    return {
      event: 'clone',
      icon: 'file_copy',
      label: i18n.t('clone')
    }
  },
  AUDIT: () => {
    return {
      event: 'audit',
      icon: 'mdi-update',
      label: i18n.t('campaigns.actions.audit')
    }
  }
}

/**
 * Returns the queryTemplates list, with the
 * format ready to be rendered in the JList.vue component.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
const templatesListDTO = (state, getters, rootState, rootGetters) => {
  if (!state.queryTemplates) return []
  return state.queryTemplates.map(template => {
    const data = templateToListDTO(template)
    return {
      ...data,
      actions: actionsOnTemplate(
        state,
        getters,
        rootState,
        rootGetters
      )(template)
    }
  })
}

/**
 * Returns the queryTemplates list, with the
 * format ready to be rendered in the JList.vue component.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
const getAreas = (state, getters, rootState, rootGetters) => {
  if (!state.areas) return []

  return state.areas.map(s => ({
    text: s.name,
    value: s.key,
    permission: s.key
  }))
}
/**
 * The actions that the user can perform on the template list as a whole (i.e. on the group).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
const actionsOnTemplateList = (state, getters, rootState, rootGetters) => {
  const actions = []
  if (rootGetters['user/canSeeQueryTemplate']) {
    actions.push(action.CREATE())
  }

  return actions
}

/**
 * Specifies the column names for the segment list view.
 * The values are obtained from the getter.js and responseMapper.js, as well as the internationalization18
 * (i18) values.
 */
const templateToListDTO = template => {
  return {
    id: template.id,
    name: template.name,
    description: template.description,
    tags: {
      label: i18n.t('querytemplates.form_fields.areas'),
      color: '#8e8e93',
      type: 'JChipList',
      value: formatTagsList(template.areas)
    },
    icon: {
      code: template.source === SOURCES.AWS_DATALAKE ? `mdi-aws` : `$bigquery`,
      label: i18n.t(`segments.form.sources.${template.source}`)
    },
    updated_at: formatDate(template.updated_at),
    user_updated_by: template.updated_by
  }
}

function formatTagsList(areas) {
  if (areas.length === 0) return []
  const clone = [...areas]
  let list = [clone.pop().name]
  if (clone.length > 0) {
    list.push(`+${clone.length}`)
  }

  return list
}

/**
 * Responsible for declaring the actions that can be performed on a specific segment template.
 */
const actionsOnTemplate = (state, getters, rootState, rootGetters) => {
  return function(template) {
    let actions = []

    if (!template) return actions

    /**
     * Specifies that the current user can perform the below actions.
     * action: the action that the user can perform
     * permission: path to the 'getter' that confirms the permission.
     */

    let potentialActions = [
      {
        actionGroup: [
          {
            action: action.EDIT(template),
            permission: 'user/canSeeQueryTemplate'
          },
          {
            action: action.VIEW(template),
            permission: 'user/canSeeQueryTemplate'
          }
        ]
      },
      {
        label: i18n.t('campaigns.actions.more'),
        icon: 'more_vert',
        menu: {
          list: getMoreActions(template),
          date: {
            display: false
          }
        }
      }
    ]
    actions = filterActionsListByPermission(
      rootGetters,
      template.createdBy,
      potentialActions
    )

    return actions
  }
}

const getMoreActions = template => {
  const moreList = [
    {
      action: action.CLONE(template),
      permission: 'user/canSeeQueryTemplate'
    }
  ]

  moreList.push({
    action: action.DELETE(template),
    permission: 'user/canSeeQueryTemplate'
  })

  moreList.push({
    action: action.AUDIT(template),
    permission: 'user/canSeeQueryTemplate',
    divider: true
  })
  return moreList
}

export default {
  templatesListDTO,
  actionsOnTemplateList,
  actionsOnTemplate,
  getAreas
}
