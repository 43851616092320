import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import store from '@/store'

export const mapUserSegmentsList = list => {
  if (!list) return []
  return list.map(segment => {
    const data = mapUserSegment(segment)
    return {
      ...data,
      actions: actionsOnSegment(segment)
    }
  })
}

const mapUserSegment = segment => {
  return {
    id: segment.id,
    name: segment.name,
    target: segment.target,
    targetFlag: {
      isoCode: segment.target,
      description: segment.target
    },
    updated_by: segment.updated_by,
    updated_at: formatDate(segment.updated_at)
  }
}

const action = {
  VIEW: () => {
    return {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('view')
    }
  }
}

const actionsOnSegment = segment => {
  let actions = []
  if (!segment) return actions
  let potentialActions = [
    {
      action: action.VIEW(segment),
      permission: 'user/canSeeInstance'
    }
  ]
  const hasPermission = permission => {
    return store.getters[permission] === true
  }
  for (let i = 0; i < potentialActions.length; i++) {
    potentialActions[i].action.active = hasPermission(
      potentialActions[i].permission
    )
    actions.push({ ...potentialActions[i].action })
  }
  return actions
}

export default { mapUserSegmentsList }
