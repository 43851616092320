import {
  apiCreateCodeSnippet,
  apiDeleteCodeSnippet,
  apiGetCodeSnippet,
  apiGetCodeSnippets,
  apiUpdateCodeSnippet
} from '@/api/code-snippets'

/**
 * Fetches the code snippets from the API.
 * Returns the new page the user is currently in.
 * @param {*} param0
 * @param {*} request
 */
async function fetchCodeSnippets({ commit }, request) {
  let result
  let commitPayload = { codeSnippets: [], finalDataSize: 0, originalData: [] }

  try {
    let response = await apiGetCodeSnippets(request)
    result = { newPage: response.newPage }
    commitPayload = {
      codeSnippets: response.codeSnippets,
      finalDataSize: response.finalDataSize,
      originalData: response.originalData
    }
  } catch (error) {
    //
  }
  commit('CODE_SNIPPETS_UPDATED', commitPayload)
  return result
}

/**
 * Creates a code snippet on the data base and returns the response given by the API.
 * @param {*} _
 * @param {*} code snippet
 */
async function createCodeSnippet(_, codeSnippet) {
  let codeSnippetCreated = await apiCreateCodeSnippet(codeSnippet)
  return codeSnippetCreated
}

/**
 * Updates the given code snippet on the data base and returns
 * the response given by the API.
 * @param {*} _
 * @param {*} code snippet
 */
async function updateCodeSnippet(_, codeSnippet) {
  let codeSnippetUpdated = await apiUpdateCodeSnippet(codeSnippet)
  if (codeSnippetUpdated) {
    return codeSnippetUpdated
  }
  return false
}
/**
 * Fetches a single code snippet.
 * @param {*} _
 * @param {*} param1
 */
async function fetchCodeSnippet(_, { id: codeSnippetId }) {
  let codeSnippet = await apiGetCodeSnippet(codeSnippetId)
  return codeSnippet
}

/**
 * Deletes a code snippet on the data base.
 * @param {*} _
 * @param {*} id
 */
async function deleteCodeSnippet(_, id) {
  let deleteRequest = await apiDeleteCodeSnippet(id)
  return deleteRequest
}

export default {
  fetchCodeSnippets,
  createCodeSnippet,
  updateCodeSnippet,
  fetchCodeSnippet,
  deleteCodeSnippet
}
