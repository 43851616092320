import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  codeSnippets: null,
  finalDataSize: null,
  originalData: null
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
