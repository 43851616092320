import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'
import store from '@/store'

const action = {
  CREATE: () => {
    return {
      event: 'create',
      label: i18n.t('logicgroups.create'),
      icon: 'add',
      isPrimary: true,
      options: []
    }
  },
  AUDIT: () => {
    return {
      event: 'audit',
      icon: 'mdi-update',
      label: i18n.t('logicgroups.actions.audit')
    }
  }
}

const createAction = (state, getters, rootState, rootGetters) => {
  const actions = []
  if (
    rootGetters['user/canCreateLogicGroup'] ||
    rootGetters['user/hasAdminPermissions']
  ) {
    actions.push(action.CREATE())
  }

  return actions
}

const logicListDTO = (state, getters, rootState, rootGetters) => {
  if (!state.logicGroups) return []
  const canUpdate = store.getters['user/canUpdateLogicGroup']
  const isAdmin = store.getters['user/hasAdminPermissions']
  return state.logicGroups.map(lg => {
    const data = logicGroupToDTO(lg)
    return {
      ...data,
      button: {
        value: lg.active,
        action: 'changeStatus',
        disabled: canUpdate ? !canUpdate : !isAdmin
      },
      actions: actionsOnLogicGroup(state, getters, rootState, rootGetters)(lg)
    }
  })
}

const logicGroupToDTO = lg => {
  return {
    id: lg.id,
    name: lg.name,
    key: lg.key,
    status: {
      keyword: lg.active,
      label: lg.active ? i18n.t('enabled') : i18n.t('disabled'),
      type: 'JChip',
      color: lg.active ? '#2A8091' : '#8E8E93'
    },
    updated_at: formatDate(lg.updated_at),
    updated_by: lg.updated_by
  }
}

const actionsOnLogicGroup = (state, getters, rootState, rootGetters) => {
  return function(lg) {
    let actions = []
    if (!lg) return actions
    actions = [
      {
        actionGroup: [
          {
            action: action.AUDIT(lg),
            permission: [
              'user/canAccessLogicGroups',
              'user/hasAdminPermissions'
            ]
          }
        ]
      }
    ]
    return filterActionsListByPermission(rootGetters, lg.updated_by, actions)
  }
}

export default {
  createAction,
  logicListDTO
}
