import {
  apiGetMedia,
  apiGetSingleMedia,
  apiDeleteMedia,
  apiCreateMedia
} from '@/api/media'

/**
 * Obtains the media by calling the respective API.
 * As pagination can differ from the expected page,
 * if successful, the result will contain the new page
 * to be used by the List Views.
 * @param {*} param0
 * @param {*} param1
 */
async function fetchMedia({ commit }, request) {
  let result
  let commitPayload = {
    media: [],
    finalDataSize: 0,
    originalData: []
  }
  try {
    let response = await apiGetMedia(request)

    commitPayload = {
      media: response.media,
      finalDataSize: response.finalDataSize,
      originalData: response.originalData
    }
    result = { newPage: response.newPage }
  } catch (error) {
    //
  }
  commit('MEDIA_UPDATED', commitPayload)
  return result
}
/**
 * Deletes a specific media.
 * Returns whether deletion was successful or not.
 * @param {*} _ Vuex State. Unused, as Vuex is being used as a centralized databag.
 * @param {*} id The id of the media to delete.
 */
async function deleteMedia(_, id) {
  const deleted = await apiDeleteMedia(id)
  return !!deleted
}

/**
 * Creates a media.
 * Returns the response of the API.
 * @param {*} _ Vuex State. Unused, as Vuex is being used as a centralized databag.
 * @param {*} media The media to create.
 */
async function createMedia(_, media) {
  const mediaCreated = await apiCreateMedia(media)
  return mediaCreated
}

/**
 * Returns a single media's data.
 * @param {*} _ Vuex State. Unused, as Vuex is being used as a centralized databag.
 * @param {*} param1 The id of the media whose data will be fetched.
 */
async function fetchSingleMedia(_, { id: mediaId }) {
  const media = await apiGetSingleMedia(mediaId)
  return media
}

export default {
  createMedia,
  fetchMedia,
  deleteMedia,
  fetchSingleMedia
}
