import { cosmosAxios } from '@/api/axios'
import { mediaListMap, mediaMap } from './responseMapper'
import {
  isOKResponse,
  parseSuccessResponse,
  isCreatedResponse
} from '@/api/utils/response'

var endpoint = {
  media: 'media',
  mediaSingle: mediaId => `media/${mediaId}`
}

async function apiGetMedia(request) {
  const payload = {}
  const q = request.q
  const page = request.page
  const perPage = request.perPage ? request.perPage : 10
  let filters = request.filters ? request.filters : {}

  if (q != null && q !== '') {
    payload.query = q
  }

  payload.per_page = perPage
  payload.page = page

  // From the request object that is returned by milkwayAxios,
  // only obtained the 'data' parameter out of it
  let { data } = await cosmosAxios.get(endpoint.media, {
    params: {
      ...payload,
      ...filters
    }
  })
  let mediaList = data.data
  let pagination = data.meta
  let mappedMedias = mediaListMap(mediaList)

  return {
    media: mappedMedias,
    finalDataSize: pagination ? pagination.total_records : mappedMedias.length,
    newPage: pagination ? pagination.page : 1,
    originalData: mappedMedias
  }
}
/**
 * Delete a media
 *
 * @param id integer MediaId
 * @returns {Promise<void>}
 */
const apiDeleteMedia = async id => {
  const response = await cosmosAxios.delete(endpoint.mediaSingle(id))

  return isOKResponse(response)
}

const apiCreateMedia = async media => {
  const response = await cosmosAxios.post(endpoint.media, media)
  if (isCreatedResponse(response)) {
    let result = parseSuccessResponse(response)
    return result
  }
  return undefined
}

const apiGetSingleMedia = async mediaId => {
  const response = await cosmosAxios.get(endpoint.mediaSingle(mediaId))
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    return mediaMap(processedResponse)
  } else {
    throw processedResponse
  }
}

export { apiGetMedia, apiGetSingleMedia, apiCreateMedia, apiDeleteMedia }
