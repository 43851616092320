import { butterflyAxios } from '@/api/axios'
import {
  isCreatedResponse,
  isOKResponse,
  parseSuccessResponse
} from '@/api/utils/response'
import {
  messageScheduleListMap,
  scheduleMap,
  mapDates,
  mapAudit
} from '@/api/message-schedules/responseMapper'
import { paginateData } from '@/api/utils'
import { i18n } from '@/i18n'
import {
  mapRenderPreview,
  messageScheduleMapper,
  messageWithScheduleMapper,
  scheduleUpdateMapper
} from '@/api/message-schedules/requestMapper'

const endpoint = {
  newsletters: 'newsletters',
  createMessageWithSchedule: 'newsletters/create-message',
  renderPreview: 'newsletters/render-preview',
  newsletter: messageScheduleId => `newsletters/${messageScheduleId}`,
  audit: messageScheduleId => `newsletters/${messageScheduleId}/audit`,
  dates: newsletterID => `newsletters/${newsletterID}/dates`
}

async function apiGetScheduleListByMessageID(request) {
  const page = request.page
  const perPage = request.perPage
  const messageId = request.messageId
  const sortBy = request.sortBy
  const orderBy = request.orderBy
  let filters = request.filters
  let payload = {}

  payload.per_page = perPage
  payload.page = page
  payload.message_id = messageId
  payload.sort_by = sortBy
  payload.order_by = orderBy

  let response = await butterflyAxios.get(endpoint.newsletters, {
    params: { ...payload, ...filters }
  })

  let data
  let responseResult = parseSuccessResponse(response)
  let processedResponse = responseResult.response
  if (responseResult.success) {
    data = processedResponse
  } else {
    throw processedResponse
  }

  const scheduleList = data

  let schedule = messageScheduleListMap(scheduleList)

  const finalDataSize = schedule.length
  let result = { dataset: schedule, newPage: 1 }
  if (perPage !== 1) {
    result = paginateData(schedule, page, perPage)
    schedule = result.dataset

    const newPage = result.newPage
    return {
      messageScheduleList: schedule,
      finalDataSize,
      newPage,
      originalData: messageScheduleListMap(scheduleList) // The original, unaltered data, for any needs any component may have
    }
  }

  return {
    messageScheduleList: schedule,
    finalDataSize,
    originalData: messageScheduleListMap(scheduleList) // The original, unaltered data,
  }
}

const apiGetMessageSchedule = async messageScheduleId => {
  let response = await butterflyAxios.get(
    endpoint.newsletter(messageScheduleId)
  )

  if (!isOKResponse(response)) {
    throw response
  }

  let data
  data = parseSuccessResponse(response)

  return scheduleMap(data.response)
}

const apiCreateMessageWithSchedule = async (message, ignoreSyntaxErrors) => {
  let msgReq = messageWithScheduleMapper(message, ignoreSyntaxErrors)

  let response = await butterflyAxios.post(
    endpoint.createMessageWithSchedule,
    msgReq
  )
  if (isCreatedResponse(response)) {
    return parseSuccessResponse(response)
  }
  return undefined
}

const apiCreateMessageSchedule = async (
  messageSchedule,
  ignoreSyntaxErrors
) => {
  let msgReq = messageScheduleMapper(messageSchedule, ignoreSyntaxErrors)

  let response = await butterflyAxios.post(endpoint.newsletters, msgReq)

  if (isCreatedResponse(response)) {
    return parseSuccessResponse(response)
  }
  return undefined
}

const apiUpdateMessageSchedule = async (
  messageSchedule,
  ignoreSyntaxErrors
) => {
  let msgReq = scheduleUpdateMapper(messageSchedule, ignoreSyntaxErrors)
  let response = await butterflyAxios.put(
    endpoint.newsletter(msgReq.id),
    msgReq
  )

  if (!isOKResponse(response)) {
    throw response
  }

  let data
  data = parseSuccessResponse(response)

  return data.response
}

const apiDeleteMessageSchedule = async id => {
  let response = {}
  let finalResponse = {}
  response = await butterflyAxios
    .delete(endpoint.newsletter(id))
    .catch(function(error) {
      if (error.response) {
        finalResponse = { status: error.response.status }
        finalResponse.error_message = i18n.t(
          'messages.newsletter.actions.delete_error'
        )
      }
    })
  if (response) {
    response.success = true
  } else {
    response = finalResponse
  }
  return response
}

const apiRenderPreview = async request => {
  let msgReq = mapRenderPreview(request)

  let response = await butterflyAxios.post(endpoint.renderPreview, msgReq)
  if (isOKResponse(response)) {
    return parseSuccessResponse(response)
  }
  return undefined
}

const apiGetNewsletterDates = async req => {
  let payload = {}
  if (req.from) payload.from = req.from
  if (req.to) payload.to = req.to

  let response = await butterflyAxios.get(endpoint.dates(req.ID), {
    params: payload
  })

  if (!isOKResponse(response)) {
    throw response
  }

  return mapDates(response.data.data)
}

const apiGetAudit = async request => {
  const params = {
    sort_by: request.sortBy,
    order_by: request.orderBy,
    page: request.page,
    per_page: request.perPage
  }
  const response = await butterflyAxios.get(endpoint.audit(request.id), {
    params: { ...params }
  })
  if (isOKResponse(response)) {
    const { response: list, meta: pagination } = parseSuccessResponse(response)
    return {
      audit: mapAudit(list),
      finalDataSize: pagination ? pagination.total_records : list.length
    }
  } else {
    throw response
  }
}

export {
  apiGetScheduleListByMessageID,
  apiGetMessageSchedule,
  apiCreateMessageWithSchedule,
  apiCreateMessageSchedule,
  apiUpdateMessageSchedule,
  apiDeleteMessageSchedule,
  apiRenderPreview,
  apiGetNewsletterDates,
  apiGetAudit
}
