// Should be a separate file so that permission constants are the same through all files
// and don't get confused with actual permissions (instead of just a permission name to reference the permission from ACL)
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

export default [
  {
    icon: 'home',
    text: 'Home',
    active: true,
    isGroup: false,
    permission: PERMISSIONS_CONSTANTS.CAN_ACCESS,
    to: 'home'
  },
  {
    icon: 'assignment',
    text: 'Segmentation',
    active: true,
    isGroup: true,
    permission: PERMISSIONS_CONSTANTS.CAN_ACCESS,
    subItems: [
      {
        icon: 'dynamic_feed',
        to: 'segments',
        text: 'Data Source',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_DATASOURCE
      },
      {
        icon: 'data_usage',
        to: 'instances',
        text: 'Segments',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_SEGMENTATION
      },
      {
        icon: 'mdi-account-search',
        to: 'contactlookup',
        text: 'Contact Lookup',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_ADMIN
      }
    ]
  },
  {
    icon: 'assignment',
    text: 'Campaign',
    active: true,
    isGroup: true,
    permission: PERMISSIONS_CONSTANTS.CAN_ACCESS,
    subItems: [
      {
        icon: 'email',
        to: 'messages',
        text: 'Messages',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MESSAGES
      },
      {
        icon: 'mdi-bullhorn-outline',
        to: 'campaigns',
        text: 'Campaigns',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CAMPAIGNS
      }
    ]
  },
  {
    icon: '',
    text: 'Content',
    active: true,
    isGroup: true,
    permission: PERMISSIONS_CONSTANTS.CAN_ACCESS,
    subItems: [
      {
        icon: 'code',
        to: 'code-snippets',
        text: 'Code Snippets',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CODE_SNIPPETS
      },
      {
        icon: 'mdi-file-code',
        to: 'querytemplates',
        text: 'Query Templates',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_TEMPLATES
      },
      {
        icon: 'mdi-folder-multiple-image',
        to: 'media',
        text: 'Media',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MEDIA
      }
    ]
  },
  {
    icon: 'assignment',
    text: 'Administration',
    active: true,
    isGroup: true,
    permission: PERMISSIONS_CONSTANTS.CAN_ACCESS,
    subItems: [
      {
        icon: 'mdi-tag',
        to: 'tags',
        text: 'Campaign Tags Manager',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CAMPAIGN_TAGS
      },
      {
        icon: 'mdi-link-variant',
        to: 'deeplinks',
        text: 'Deeplink Manager',
        active: true,
        permission: [
          PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_DEEPLINKS,
          PERMISSIONS_CONSTANTS.MDS_ADMIN
        ]
      },
      {
        icon: 'mdi-inbox-full',
        to: 'emailtemplates',
        text: 'Email Templates Manager',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_ADMIN
      },
      {
        icon: 'mdi-format-list-bulleted',
        to: 'logicgroups',
        text: 'Logic Groups Manager',
        active: true,
        permission: [
          PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_LOGIC_GROUPS,
          PERMISSIONS_CONSTANTS.MDS_ADMIN
        ]
      },
      {
        icon: 'mdi-message-processing',
        to: 'rates',
        text: 'SMS Rate Definition',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_RATE
      },
      {
        icon: 'mdi-message-lock',
        to: 'budgets',
        text: 'SMS Budget Definition',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_BUDGET
      },
      {
        icon: 'alarm',
        to: 'jobs',
        text: 'Jobs',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_ADMIN
      },
      {
        icon: 'settings',
        to: 'settings',
        text: 'Settings',
        active: true,
        permission: PERMISSIONS_CONSTANTS.MDS_ADMIN
      }
    ]
  },
  {
    icon: 'help',
    text: 'Help',
    active: true,
    isGroup: true,
    permission: PERMISSIONS_CONSTANTS.MDS_DOCUMENTATION,
    subItems: [
      {
        icon: 'help',
        text: 'Documentation',
        active: true,
        isGroup: false,
        permission: PERMISSIONS_CONSTANTS.MDS_DOCUMENTATION,
        to: 'docs'
      }
    ]
  }
]
