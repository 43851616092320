import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const MODULE = 'logicgroups'

export default [
  {
    path: `/${MODULE}`,
    component: loadView('ViewLogicGroups', MODULE),
    children: [
      {
        path: '',
        name: MODULE,
        component: loadView('ViewLogicGroupsList', MODULE),
        meta: {
          navigationDrawer: true,
          module: MODULE,
          role: [
            PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_LOGIC_GROUPS,
            PERMISSIONS_CONSTANTS.MDS_ADMIN
          ]
        }
      },
      {
        path: 'new',
        name: `${MODULE}-add`,
        component: loadView('ViewLogicGroupsAdd', MODULE),
        meta: {
          navigationDrawer: true,
          module: MODULE,
          role: [
            PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_LOGIC_GROUPS,
            PERMISSIONS_CONSTANTS.MDS_ADMIN
          ],
          whiteBackground: true,
          savePrevRoute: true
        }
      }
    ]
  }
]
