import {
  apiCreateInstance,
  apiDeleteInstance,
  apiDownloadInstance,
  apiGenerateGraphic,
  apiGetInstance,
  apiGetInstances,
  apiGetQuery,
  apiUpdateInstance,
  apiGetInstanceHistory,
  apiSyncInstanceById,
  apiGetInstanceSize
} from '@/api/instances'

/**
 * Fetches the instances from the API.
 * @param {*} param0
 * @param {*} payload
 */
async function fetchInstances({ commit }, payload) {
  let result
  let commitPayload = {
    instances: [],
    finalDataSize: 0,
    originalData: []
  }
  try {
    let response = await apiGetInstances(payload)
    result = { newPage: response.newPage }
    commitPayload = {
      instances: response.instances,
      finalDataSize: response.finalDataSize,
      originalData: response.originalData
    }
  } catch (error) {
    console.debug(error)
  }
  commit('INSTANCES_UPDATED', commitPayload)
  return result
}

/**
 * Fetches a single instance.
 * @param _
 * @param instanceId
 * @param view
 */
async function fetchInstance(_, { id: instanceId, view }) {
  const instance = await apiGetInstance(instanceId, view)
  return instance
}

/**
 * Get instance query
 * @param _
 * @param request
 */
async function fetchInstanceQuery(_, request) {
  try {
    return await apiGetQuery(request)
  } catch (e) {
    console.debug(e)
  }
}

/**
 * Creates an instance on the data base and returns the response given by the API.
 * @param {*} _
 * @param {*} instance
 */
async function createInstance(_, instance) {
  const instanceCreated = await apiCreateInstance(instance)
  return instanceCreated
}

/**
 * Updates the given instance on the data base and returns
 * the response given by the API.
 * @param {*} _
 * @param {*} instance
 */
async function updateInstance(_, instance) {
  const instanceUpdated = await apiUpdateInstance(instance)
  if (instanceUpdated) {
    return instanceUpdated
  }
  return false
}

/**
 * Deletes an instance on the data base.
 * @param {*} _
 * @param {*} id
 */
async function deleteInstance(_, id) {
  const deleted = await apiDeleteInstance(id)
  return deleted
}

/**
 * Downloads the contents of the segment/instance.
 * @param {*} _
 * @param {*} id
 */
const downloadInstance = async (_, id) => {
  const response = await apiDownloadInstance(id)
  return response
}

/**
 * Make a manual sync on the API.
 * Returns the response of the API.
 * @param {*} _
 * @param {*} id instance id
 */

const syncInstance = async (_, id) => {
  const response = await apiSyncInstanceById(id)
  return response
}

/**
 * Generates the graphic data so that it can be used to show to the user.
 * @param {*} _
 * @param {*} param1
 */
const generateGraphic = async (_, { dataSourceId, filters, target }) => {
  const response = await apiGenerateGraphic(dataSourceId, filters, target)
  return response.data
}

async function getInstanceHistory(_, { id }) {
  const response = await apiGetInstanceHistory(id)
  return response
}
async function getInstanceSize(_, instanceId) {
  const response = await apiGetInstanceSize(instanceId)
  return response
}

export default {
  fetchInstances,
  fetchInstance,
  createInstance,
  updateInstance,
  deleteInstance,
  downloadInstance,
  generateGraphic,
  fetchInstanceQuery,
  getInstanceHistory,
  syncInstance,
  getInstanceSize
}
