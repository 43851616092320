/**
 * Signal the Vuex state that the landing page types were cached.
 * @param {*} state
 * @param {array} newLandingPageTypes
 */
function LANDING_PAGE_TYPES_CACHED(state, newLandingPageTypes) {
  state.landingPageTypes = newLandingPageTypes
}

export default {
  LANDING_PAGE_TYPES_CACHED
}
