const countryNamesListMap = list => {
  let countries = []

  for (let item of list) {
    countries.push({ text: item.name, value: item.id, isoCode: item.iso_code })
  }
  return countries
}

function currenciesListMap(list) {
  let currencies = []
  for (let item of list) {
    currencies.push(currencyMap(item))
  }
  return currencies
}

function currencyMap(currency) {
  return {
    id: currency.id,
    code: currency.code,
    countries: currency.countries
  }
}

export { countryNamesListMap, currenciesListMap, currencyMap }
