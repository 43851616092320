import { APP_CONSTANTS } from './constants'
import { getAreaProperty } from '@/store/securedStorage'

/**
 * Returns the available main menu pages.
 * @param {vuexState} state
 */
function availablePagesOnMenu(state) {
  return state.menu.filter(function filterSubHeader(el) {
    return !el.subheader
  })
}

/**
 * Returns whether the app has been recently updated or not.
 * @param {vuexState} state
 */
function getUpdatedApp(state) {
  let result = getAreaProperty(
    state,
    APP_CONSTANTS.APP,
    APP_CONSTANTS.UPDATED_APP
  )
  return result
}

/**
 * Returns the globally select country
 * @param {vuexState} state
 */
function getSelectedCountry(state) {
  return state.selectedCountry
}

export default {
  availablePagesOnMenu,
  getUpdatedApp,
  getSelectedCountry
}
