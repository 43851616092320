import { butterflyAxios } from '@/api/axios'
import { templateViewMap } from './responseMapper'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'
import { isNoContentResponse } from '../utils/response'

/**
 * Endpoints for emailTemplates.
 */
const endpoints = {
  emailTemplates: `emailtemplates`,
  emailTemplate: templateId => `emailtemplates/${templateId}`,
  emailTemplateRelated: templateId => `emailtemplates/${templateId}/related`
}

/**
 * Fetches the email templates from the api.
 * @param {*} request
 */
const apiGetEmailTemplates = async request => {
  const q = request.q
  const page = request.page
  const perPage = request.perPage
  const sortBy = request.sort_by ? request.sort_by : null
  const orderBy = request.order_by ? request.order_by : null
  let filters = request.filters ? request.filters : {}

  const payload = {}
  if (q != null && q !== '') {
    payload.name = q
  }
  if (page > 0) {
    payload.page = page
  }

  if (sortBy != null && orderBy != null) {
    payload.sort_by = sortBy
    payload.order_by = orderBy
  }

  payload.per_page = perPage

  const params = { ...payload, ...filters }
  const response = await butterflyAxios.get(endpoints.emailTemplates, {
    params
  })
  if (isOKResponse(response)) {
    const { response: templates, meta: pagination } = parseSuccessResponse(
      response
    )
    return {
      emailTemplates: templates,
      finalDataSize: pagination ? pagination.total_records : templates.length,
      newPage: pagination ? pagination.page : 1,
      originalData: templates
    }
  }

  throw response
}

/**
 * Fetches a specific email template.
 * @param templateId
 * @param view
 */
const apiGetEmailTemplate = async templateId => {
  const response = await butterflyAxios.get(endpoints.emailTemplate(templateId))
  if (isOKResponse(response)) {
    let result = parseSuccessResponse(response)
    return templateViewMap(result.response)
  }
  throw response
}

/**
 * Fetches a specific email template.
 * @param templateId
 */
const apiDeleteEmailTemplate = async templateId => {
  const response = await butterflyAxios.delete(endpoints.emailTemplate(templateId))
  return isNoContentResponse(response)
}

const apiGetEmailTemplatesRelated = async templateId => {
  const response = await butterflyAxios.get(
    endpoints.emailTemplateRelated(templateId)
  )

  if (isOKResponse(response)) {
    let result = parseSuccessResponse(response)
    return result.response
  }

  throw response
}

export {
  apiGetEmailTemplates,
  apiGetEmailTemplate,
  apiDeleteEmailTemplate,
  apiGetEmailTemplatesRelated
}
