import {
  apiCreateSegment,
  apiUpdateSegment,
  apiGetSegment,
  apiGetSegmentHistory,
  apiGetSegments,
  apiGetSegmentsName,
  apiGetSegmentTableColumns,
  apiSyncAll,
  apiSyncById
} from '@/api/segments'

/**
 * Fetches all segments (data sources) available in the data base.
 * @param {*} param0
 * @param {*} param1
 */
async function fetchSegments({ commit }, request) {
  let result
  let commitPayload = {
    segments: [],
    finalDataSize: 0,
    originalData: []
  }
  try {
    let response = await apiGetSegments(request)
    commitPayload = {
      segments: response.segments,
      finalDataSize: response.finalDataSize,
      originalData: response.originalData
    }
    result = { newPage: response.newPage }
  } catch (error) {
    //
  }
  commit('SEGMENTS_UPDATED', commitPayload)
  return result
}

/**
 * Fetches a single segment with the given id.
 * @param {*} _
 * @param {*} param1
 */
async function fetchSegment(_, { id }) {
  const segment = await apiGetSegment(id)
  return segment
}

/**
 * Fetches the segments list names (names of the segments list).
 * @param {*} _
 */
async function fetchSegmentsListNames({ commit }, request) {
  const segments = await apiGetSegmentsName(request)
  return segments
}

/**
 * Fetches the table columns of a specific segment (instance).
 * @param {*} _
 * @param {*} param1
 */
async function fetchSegmentTableColumns(_, { id, separateCountry }) {
  const segmentTableColumns = await apiGetSegmentTableColumns(
    id,
    separateCountry
  )
  return segmentTableColumns
}

/**
 * Sync all segments.
 *
 */
async function syncAll() {
  const response = await apiSyncAll()
  return response
}

/**
 * Syncs for keyName.
 */
async function syncById(_, { id, allowForceSync }) {
  const response = await apiSyncById(id, allowForceSync)
  return response
}

/**
 * Creates a segment
 */
async function createSegment(_, payload) {
  const response = await apiCreateSegment(payload)
  return response
}

async function UpdateSegment(_, payload) {
  const response = await apiUpdateSegment(payload)
  return response
}

async function getSegmentHistory(_, { id }) {
  const response = await apiGetSegmentHistory(id)
  return response
}

export default {
  createSegment,
  UpdateSegment,
  fetchSegment,
  fetchSegments,
  fetchSegmentsListNames,
  fetchSegmentTableColumns,
  syncAll,
  syncById,
  getSegmentHistory
}
