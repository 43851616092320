/**
 * This file serves the purpose of handling conversions from DTOs to other 'DTO'.
 * Essentially, xyzMap functions map the object JSON obtained from the Back-End (BE) to a javascript object
 * that is easily parsed by the Front-End (FE).
 */

/**
 * Converts a JSON media object from the API format to the format expected by the FE.
 * @param {JSON} media The media JSON object to convert from.
 */
function mediaMap(media) {
  let {
    name,
    url,
    size,
    created_at: createdAt,
    created_by: createdBy,
    id
  } = media
  return {
    id,
    name,
    url,
    size,
    createdAt,
    createdBy
  }
}

/**
 * Maps an array of medias to another array of medias in the expected FE format.
 * @param {Array} list The list of medias in BE format.
 */
function mediaListMap(list) {
  let medias = []
  for (let item of list) {
    medias.push(mediaMap(item))
  }
  return medias
}

const mediaNameListMap = list => {
  let medias = []
  for (let item of list) {
    medias.push({ text: item.name, value: item.id })
  }
  return medias
}

export { mediaMap, mediaListMap, mediaNameListMap }
