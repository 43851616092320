/**
 * Signal's Vuex state that the messages have been updated.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The new messages content.
 */
function MESSAGES_UPDATED(state, { messages, finalDataSize, originalData }) {
  state.messages = messages
  state.finalDataSize = finalDataSize
  state.originalData = originalData
}

/**
 * Signals Vuex that the verticals list has changed.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The campaigns, finalDataSize and originalData to mutate.
 */
function VERTICALS_UPDATED(state, { verticals }) {
  state.verticals = verticals
}

/**
 * Signals Vuex that the instances list has changed.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The campaigns, finalDataSize and originalData to mutate.
 */
function INSTANCES_UPDATED(state, { instances }) {
  state.instances = instances
}

/**
 * Signals Vuex that the tags list has changed.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The tags
 */
function TAGS_UPDATED(state, { tags }) {
  state.tags = tags
}
function LOGIC_GROUPS_UPDATED(state, { logicGroups }) {
  state.logicGroups = logicGroups
}

export default {
  MESSAGES_UPDATED,
  VERTICALS_UPDATED,
  INSTANCES_UPDATED,
  LOGIC_GROUPS_UPDATED,
  TAGS_UPDATED
}
