import { apiGetCardTypes } from '@/api/card-types'
import CacheSystem from '@/utils/cacheSystem'

/**
 * Fetches the card types from the API, firing
 * any necessary logic associated (such as caching).
 * @param {*} context Vuex context (auto-injected).
 * @param {*} payload The payload to send to the API.
 * @param {*} useCache Whether to use the cache (default is true).
 */
async function fetchCardTypes(context, payload, useCache = true) {
  let cacheSystem = new CacheSystem(useCache)
  cacheSystem.implementGetCacheFunction(() => {
    let result
    if (context.state.cardTypes !== undefined) {
      result = context.state.cardTypes
    }
    return result
  })
  cacheSystem.implementSetCacheFunction(async () => {
    let response = await apiGetCardTypes(payload)
    let result = response.cardTypes
    context.commit('CARD_TYPES_CACHED', response.cardTypes)
    return result
  })
  cacheSystem.implementOnSetCacheErrorFunction(() => {
    //
  })
  return cacheSystem.execute()
}

export default {
  fetchCardTypes
}
