import { butterflyAxios } from '@/api/axios'
import { targetListMap, targetNamesListMap } from './responseMapper'
import { parseSuccessResponse } from '@/api/utils/response'

const endpoint = {
  targets: 'targets'
}

async function apiGetTargets(payload) {
  let response = await butterflyAxios.get(endpoint.targets, {
    params: payload
  })

  let data
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    data = processedResponse
  } else {
    throw processedResponse
  }

  // Convert from JSON array to the expected mapping
  return targetListMap(data)
}

async function apiGetTargetNames() {
  let { data } = await butterflyAxios.get(endpoint.targets, {})

  // Convert from JSON array to the expected mapping
  return targetNamesListMap(data.data)
}

export { apiGetTargets, apiGetTargetNames }
