import { apiGetDocumentation } from '@/api/documentation'
import CacheSystem from '@/utils/cacheSystem'

/**
 * Fetches the documentation notes from the API, firing
 * any necessary logic associated (such as caching).
 * @param {*} context Vuex context (auto-injected).
 * @param {*} payload The payload to send to the API.
 * @param {*} useCache Whether to use the cache (default is true).
 */
async function fetchDocumentation(context, payload, useCache = true) {
  let cacheSystem = new CacheSystem(useCache)
  cacheSystem.implementGetCacheFunction(() => {
    let result
    if (context.state.landingPageTypes !== undefined) {
      result = context.state.documentation
    }
    return result
  })
  cacheSystem.implementSetCacheFunction(async () => {
    let response = await apiGetDocumentation()
    context.commit('DOCUMENTATION_CACHED', response)
    return response
  })
  cacheSystem.implementOnSetCacheErrorFunction(() => {
    //
  })
  return cacheSystem.execute()
}

export default {
  fetchDocumentation
}
