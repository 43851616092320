import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const TEMPLATES_MODULE = 'rates'

export default [
  {
    path: '/rates',
    component: loadView('ViewRates', TEMPLATES_MODULE),
    children: [
      {
        path: '',
        name: 'rates',
        component: loadView('ViewRatesList', TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_RATE
        }
      },
      {
        path: 'new',
        name: 'rate-add',
        component: loadView('ViewRatesAdd', TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_RATE,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: ':id',
        name: 'rate-view',
        component: loadView('ViewRatesEdit', TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_RATE,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: ':id',
        name: 'rate-edit',
        component: loadView('ViewRatesEdit', TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_RATE,
          whiteBackground: true,
          savePrevRoute: true
        }
      }
    ]
  }
]
