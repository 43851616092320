import moment from 'moment/moment'
import staticValues from '@/modules/messages/push_notification/staticValues'
import { CHANNEL_KEYWORDS } from '@/api/channels/constants'
import { formatDate } from '@/utils/commons/dateUtils'
import { mapUserAction } from '@/utils/commons/formatter'
import { parseStatusVanityFrom } from '@/api/campaigns/helpers'

/**
 * This file serves the purpose of handling conversions from DTOs to other 'DTO'.
 * Essentially, xyzMap functions map the object JSON obtained from the Back-End (BE) to a javascript object
 * that is easily parsed by the Front-End (FE).
 */

/**
 * Calculates the dates available to send test
 * @param dates
 * @returns {*}
 */
function mapScheduledDates(dates) {
  let today = moment().format('YYYY-MM-DD')

  return dates.map(date => {
    let dispatchDate = moment(date.dispatch_at)
    if (dispatchDate.isSameOrAfter(today)) {
      return dispatchDate.format('YYYY-MM-DD')
    }
  })
}

/**
 * Converts a JSON campaign object from the API format to the format expected by the FE.
 * @param {JSON} campaign The campaign JSON object to convert from.
 */
function campaignMap(campaign) {
  let {
    id,
    name,
    status,
    active,
    description,
    created_at: createdAt,
    created_by: createdBy,
    updated_at: updatedAt,
    updated_by: updatedBy,
    user_updated_by: userUpdatedBy,
    user_updated_at: userUpdatedAt,
    tested_group: testedGroup,
    data,
    channel: {
      id: channelId,
      name: channelName,
      keyword: channelKeyword,
      channel_type: { name: channelType, keyword: channelTypeKeyWord }
    },
    target: {
      id: countryId,
      name: country,
      vertical_id: verticalId,
      code: countryIsoCode
    },
    message_id: messageId,
    message: {
      name: messageName,
      schedules: scheduledDates,
      templates: messageTemplates
    },
    schedule_data: scheduleData,
    schedule_type: frequency,
    tags,
    target,
    campaign_audiences: audiences,
    audience_type: audienceType,
    vertical_project: verticalProject,
    retargeting,
    budget,
    end_date: endDate
  } = campaign

  let dates = []
  if (scheduledDates) {
    dates = mapScheduledDates(scheduledDates)
  }

  const newAudiences = []
  if (audiences && audiences.length > 0) {
    for (const l of audiences) {
      newAudiences.push({
        id: l.audience_id,
        option: l.option
      })
    }
  }

  const templates = mapTemplates(channelKeyword, messageTemplates)
  const reservedCost = budget.value
  const monthBudget = budget.channel_budget.value

  return {
    id,
    name,
    status,
    active,
    data,
    description,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    userUpdatedBy,
    userUpdatedAt,
    testedGroup,
    channelId,
    channelName,
    channelType,
    countryId,
    channelKeyword,
    channelTypeKeyWord,
    verticalId,
    country,
    countryIsoCode,
    frequency,
    scheduleData,
    tags,
    messageId,
    messageName,
    messageTitleEn: templates.messageTitleEn ? templates.messageTitleEn : '',
    messageTitleFr: templates.messageTitleFr ? templates.messageTitleFr : '',
    messageTitleAr: templates.messageTitleAr ? templates.messageTitleAr : '',
    messageEn: templates.messageEn ? templates.messageEn : '',
    messageFr: templates.messageFr ? templates.messageFr : '',
    messageAr: templates.messageAr ? templates.messageAr : '',
    endDate,
    audiences: newAudiences,
    audienceType: audienceType,
    dates,
    verticalProject,
    target,
    retargeting,
    reservedCost,
    monthBudget
  }
}

function mapTemplates(channel, templates) {
  switch (channel) {
    case CHANNEL_KEYWORDS.EMAIL:
      return mapEmailTemplates(templates)
    case CHANNEL_KEYWORDS.NEWS_FEED:
      return mapGenericTemplates(templates)
    case CHANNEL_KEYWORDS.GROWTH_TOOL:
      return mapGrowthToolTemplates(templates)
    case CHANNEL_KEYWORDS.PUSH_NOTIFICATION:
      return mapGenericTemplates(templates)
    case CHANNEL_KEYWORDS.INBOX:
      return mapGenericTemplates(templates)
    case CHANNEL_KEYWORDS.SMS:
      return mapGenericTemplates(templates)
    default:
      return {}
  }
}

function mapGenericTemplates(templates) {
  let res = {}
  templates.forEach(t => {
    if (t.language.iso_code === 'EN') {
      res.messageTitleEn = t.content.title
      res.messageEn = t.content.message
    }
    if (t.language.iso_code === 'FR') {
      res.messageTitleFr = t.content.title
      res.messageFr = t.content.message
    }
    if (t.language.iso_code === 'AR') {
      res.messageTitleAr = t.content.title
      res.messageAr = t.content.message
    }
  })
  return res
}

function mapEmailTemplates(templates) {
  let res = {}
  templates.forEach(t => {
    if (t.language.iso_code === 'EN') {
      res.messageTitleEn = t.content.subject
      res.messageEn = t.content.content.substring(0, 10) + '...'
    }
    if (t.language.iso_code === 'FR') {
      res.messageTitleFr = t.content.subject
      res.messageFr = t.content.content.substring(0, 10) + '...'
    }
    if (t.language.iso_code === 'AR') {
      res.messageTitleAr = t.content.subject
      res.messageAr = t.content.content.substring(0, 10) + '...'
    }
  })
  return res
}

function mapGrowthToolTemplates(templates) {
  let res = {}
  templates.forEach(t => {
    if (t.language.iso_code === 'EN') {
      res.messageTitleEn = t.content.title
      res.messageEn = t.content.extendedMessage
    }
    if (t.language.iso_code === 'FR') {
      res.messageTitleFr = t.content.title
      res.messageFr = t.content.extendedMessage
    }
    if (t.language.iso_code === 'AR') {
      res.messageTitleAr = t.content.title
      res.messageAr = t.content.extendedMessage
    }
  })
  return res
}

/**
 * Maps an array of campaigns to another array of campaigns in the expected FE format.
 * @param {Array} list The list of campaigns in BE format.
 */
function campaignListMap(list) {
  let campaigns = []
  for (let item of list) {
    campaigns.push(campaignListMapView(item))
  }
  return campaigns
}

function campaignListMapView(campaign) {
  let {
    id,
    name,
    status,
    updated_at: updatedAt,
    updated_by: updatedBy,
    schedule_type: frequency,
    target,
    channel: { id: channelId, name: channelName, keyword: channelKeyword },
    // extras
    tags,
    end_date: endDate,
    message: {
      id: messageId,
      name: messageName,
      templates: messageTemplates,
      schedules: scheduledDates
    },
    audience_type: audienceType,
    audiences
  } = campaign

  let dates = []
  if (scheduledDates) {
    dates = mapScheduledDates(scheduledDates)
  }

  const newAudiences = []
  if (audiences && audiences.length > 0) {
    for (const l of audiences) {
      newAudiences.push({
        id: l.audience_id,
        option: l.option
      })
    }
  }

  const templates = mapTemplates(channelKeyword, messageTemplates)

  return {
    id,
    name,
    target,
    channel: { id: channelId, keyword: channelKeyword },
    channelId,
    channelName,
    channelKeyword,
    status,
    frequency,
    updatedAt,
    updatedBy,
    dates,
    // non default columns
    tags,
    messageId,
    messageName,
    messageTitleEn: templates.messageTitleEn ? templates.messageTitleEn : '',
    messageTitleFr: templates.messageTitleFr ? templates.messageTitleFr : '',
    messageTitleAr: templates.messageTitleAr ? templates.messageTitleAr : '',
    messageEn: templates.messageEn ? templates.messageEn : '',
    messageFr: templates.messageFr ? templates.messageFr : '',
    messageAr: templates.messageAr ? templates.messageAr : '',
    endDate,
    audienceType: audienceType,
    audiences: newAudiences
  }
}

function campaignAuditMap(campaignAudit) {
  const {
    id,
    campaign_id: campaignId,
    user_action: userAction,
    updated_by: updatedBy,
    updated_at: updatedAt,
    campaign_status: status
  } = campaignAudit

  const chipVanityData = parseStatusVanityFrom(status)
  return {
    id,
    campaignId,
    userAction: mapUserAction(userAction),
    updatedBy,
    updatedAt: formatDate(updatedAt),
    status: {
      keyword: status,
      label: chipVanityData.label,
      type: 'JChip',
      color: chipVanityData.color
    }
  }
}

function campaignAuditListMap(list) {
  return list.map(item => campaignAuditMap(item))
}

const campaignNameListMap = list => {
  let campaigns = []
  for (let item of list) {
    campaigns.push({ text: item.name, value: item.id })
  }
  return campaigns
}

function getLastMonthDays() {
  let result = []
  const dateNow = moment()
  const dateLastMonth = moment(dateNow).subtract(1, 'months')
  let date = dateNow

  // Fill array with last month dates
  while (date.isSameOrAfter(dateLastMonth)) {
    result.push({
      date: date.format('YYYY-MM-DD')
    })
    date = date.subtract(1, 'days')
  }

  return result
}

function getPNInitialStats(stats) {
  return stats.map(item => {
    return {
      ...item,
      sent: 0,
      delivered: 0,
      reaction: 0,
      indirect_reaction: 0,
      opt_out: 0,
      uninstall: 0
    }
  })
}

function getInitialStats(stats) {
  return stats.map(item => {
    return {
      ...item,
      sent: 0,
      delivered: 0,
      opened: 0,
      clicked: 0,
      unsubscribed: 0,
      failed: 0
    }
  })
}

function monthStatsMap(monthStats, channel) {
  const days = getLastMonthDays()

  let result

  if (channel === CHANNEL_KEYWORDS.PUSH_NOTIFICATION) {
    result = getPNInitialStats(days)
  } else {
    result = getInitialStats(days)
  }

  if (monthStats.stats && monthStats.stats.length > 0) {
    monthStats.stats.forEach(stat => {
      stat.values.forEach(val => {
        const index = result.findIndex(day => day.date === val.date)
        if (index > -1) {
          const day = result[index]
          day[stat.type] = val.value

          result[index] = day
        }
      })
    })
  }

  return result
}

function mapPushNotificationPreview(preview) {
  let result = []
  if (preview) {
    preview.templates
      .sort((t1, t2) => t1.language_id - t2.language_id)
      .forEach(t => {
        result.push({
          languageId: t.language_id,
          title: t.title,
          message: t.message,
          summary: t.summary,
          actionContent: preview.action_content,
          attr: preview.attr,
          utm: preview.utm,
          voucher: preview.voucher,
          imgUrl: preview.image,
          button: staticValues.buttonPreview(preview.btn_type),
          signalKey: preview.signal_key
        })
      })
  }
  return result
}

function mapInboxMessagePreview(preview) {
  const result = []
  if (preview) {
    const sortedTemplates = preview.templates.sort(
      (t1, t2) => t1.language_id - t2.language_id
    )
    sortedTemplates.forEach(t => {
      result.push({
        title: t.title,
        message: t.message,
        languageId: t.language_id
      })
    })
  }
  return result
}

function mapSmsPreview(preview) {
  const result = []
  if (preview) {
    const sortedTemplates = preview.templates.sort(
      (t1, t2) => t1.language_id - t2.language_id
    )
    sortedTemplates.forEach(t => {
      result.push({
        message: t.message,
        languageId: t.language_id
      })
    })
  }
  return result
}

function expiringMap(event) {
  return {
    id: event.id,
    name: event.name,
    channel: event.channel,
    target: event.target,
    status: event.status,
    frequency: event.frequency,
    updated_at: event.updated_at,
    end_date: event.end_date
  }
}

function listExpiringMap(list) {
  const events = []
  for (let item of list) {
    events.push(expiringMap(item))
  }
  return events
}

export {
  campaignMap,
  campaignListMap,
  campaignNameListMap,
  monthStatsMap,
  mapPushNotificationPreview,
  mapInboxMessagePreview,
  mapSmsPreview,
  campaignAuditListMap,
  listExpiringMap
}
