import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const MODULE = 'tags'

export default [
  {
    path: `/${MODULE}`,
    component: loadView('ViewTags', MODULE),
    children: [
      {
        path: '',
        name: MODULE,
        component: loadView('ViewTagsList', MODULE),
        meta: {
          navigationDrawer: true,
          module: MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CAMPAIGN_TAGS
        }
      },
      {
        path: `add`,
        name: `${MODULE}-add`,
        component: loadView('ViewTagsAdd', MODULE),
        meta: {
          navigationDrawer: true,
          module: MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_CAMPAIGN_TAGS
        }
      },
      {
        path: ':id',
        name: `${MODULE}-view`,
        component: loadView('ViewTagsEdit', MODULE),
        meta: {
          navigationDrawer: true,
          module: MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CAMPAIGN_TAGS
        }
      },
      {
        path: ':id',
        name: `${MODULE}-edit`,
        component: loadView('ViewTagsEdit', MODULE),
        meta: {
          navigationDrawer: true,
          module: MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_CAMPAIGN_TAGS
        }
      }
    ]
  }
]
