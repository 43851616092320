/**
 * Signal the Vuex state that the documentation was cached.
 * @param {*} state
 * @param {array} documentation
 */
function DOCUMENTATION_CACHED(state, newDoc) {
  state.documentation = newDoc
}

export default {
  DOCUMENTATION_CACHED
}
