import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'
import { SOURCES } from '@/constants/sources'

const colors = {
  active: '#2A8091',
  inactive: '#8e8e93',
  error: '#E36049'
}

function activeSync(status) {
  return [1, 2, 3, 4].includes(status)
}

function isEditable(source) {
  return source === SOURCES.AWS_DATALAKE
}

function allowForceSync(status) {
  return [1, 2].includes(status)
}

/**
 * A constant to specify functions that allow
 * to create the actions that can be used in segments.
 */
const action = {
  SYNC: segment => {
    return {
      event: 'sync',
      icon: 'mdi-database-refresh',
      label: i18n.t('segments.action.sync'),
      active: activeSync(segment.status)
    }
  },
  EDIT: () => {
    return {
      event: 'edit',
      icon: 'edit',
      label: i18n.t('segments.action.edit'),
      active: true // MUST DEFINE POLICY FOR EDIT BUTTON "ENABLE/DISABLE", IF ANY
    }
  }
}

/**
 * Returns the segments list, with the
 * format ready to be rendered in the DataTable.vue component (ListDTO format).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */

function getListDTO(state, getters, rootState, rootGetters) {
  if (!state.segments) return []
  return state.segments.map(segment => {
    let data = listDTO(segment)
    return {
      ...data,
      actions: listActions(state, getters, rootState, rootGetters)(segment),
      canExpand: true
    }
  })
}

/**
 * Responsible for declaring the actions that can be performed on a specific segment.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */

function listActions(state, getters, rootState, rootGetters) {
  return function (segment) {
    let actions = []

    if (!segment) return actions

    /**
     * Specifies that the current user can perform the below actions.
     * action: the action that the user can perform
     * permission: path to the 'getter' that comfirms the permission.
     * specialConditionValue: the boolean value of special conditions the action may have (e.g.
     * can only be viewed if status is 'done')
     */
    const potentialActions = [
      {
        action: action.SYNC(segment),
        permission: 'user/canManageSegments',
        specialConditionValue: activeSync(segment.status)
      },
      {
        action: action.EDIT(),
        permission: 'user/canManageSegments',
        specialConditionValue: isEditable(segment.source)
      }
    ]
    actions = filterActionsListByPermission(rootGetters, '', potentialActions)

    return actions
  }
}

function getStatusLabelAndColor(status) {
  let label
  let color
  switch (status) {
    case 1:
      label = i18n.t('segments.state_machine.pending')
      color = colors.inactive
      break
    case 2:
      label = i18n.t('segments.state_machine.processing')
      color = colors.inactive
      break
    case 3:
      label = i18n.t('segments.state_machine.done')
      color = colors.active
      break
    case 4:
      label = i18n.t('segments.state_machine.error')
      color = colors.error
      break
    default:
      label = i18n.t('segments.state_machine.pending')
      color = colors.inactive
  }
  return { label: label, color: color, type: 'JChip', allowForceSync: allowForceSync(status) }
}

function listDTO(segment) {
  return {
    ...segment,
    icon: {
      code: segment.source === SOURCES.AWS_DATALAKE ? `mdi-aws` : `$bigquery`,
      label: i18n.t(`segments.form.sources.${segment.source}`)
    },
    size:
      segment.metadata == null
        ? i18n.t('segments.values.defaultTableSize')
        : new Intl.NumberFormat().format(segment.metadata.NumRows),
    updated_at: formatDate(segment.updatedDate),
    status: getStatusLabelAndColor(segment.status)
  }
}

export default {
  getListDTO,
  listActions
}
