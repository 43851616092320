function parseSKUs(list, hasTrack) {
  let SKUs = []
  list.forEach(l => {
    SKUs.push({
      value: l.value,
      track: hasTrack ? l.track : null
    })
  })

  return SKUs
}

function parseBanners(list) {
  return list.map(b => {
    return {
      banner_image: b.banner_image,
      banner_landing_page: b.banner_landing_page,
      page_type_id: b.page_type_id,
      banner_url_key: b.banner_url_key,
      banner_tracking_tag: b.banner_tracking_tag,
      banner_type: b.banner_type,
      banner_alt: b.banner_alt
    }
  })
}

function parseTemplate(t) {
  return {
    language_id: t.languageID,
    ab_test_type_id: t.abTestTypeId,
    ab_test_details: t.abTestDetails,
    from: t.from,
    subject: t.subject,
    header_gif: t.headerGif,
    header_landing_page: t.headerLandingPage,
    pre_header: t.preHeader,
    utm_content: t.utmContent,
    ab_test: t.abTest,
    reco_position: t.recoPosition,
    banners: parseBanners(t.banners),
    promo_description: t.promoDescription,
    promo_card: t.promoCard,
    discount_code: t.discountCode,
    availability_ends: t.availabilityEnds
  }
}

function parseTemplates(templates) {
  return templates.map(t => {
    return parseTemplate(t)
  })
}

function parseSchedule(s, ignoreSyntaxErr) {
  return {
    dispatch_at: s.date,
    status: s.status,
    code_snippet_id: s.codeSnippet.id,
    sku_position: s.skuPosition,
    skus: parseSKUs(s.skus, false),
    sponsored_skus: parseSKUs(s.sponsoredSkus, true),
    templates: parseTemplates(s.templates),
    ignore_syntax_errors: ignoreSyntaxErr
  }
}

function messageScheduleMapper(scheduleMsg, ignoreSyntaxErr) {
  return {
    message_id: scheduleMsg.messageID,
    schedule: parseSchedule(scheduleMsg.schedule, ignoreSyntaxErr)
  }
}

function messageWithScheduleMapper(msg, ignoreSyntaxErr) {
  return {
    name: msg.name,
    description: msg.description,
    channel_id: msg.channelID,
    schedule: parseSchedule(msg.schedule, ignoreSyntaxErr)
  }
}

function scheduleUpdateMapper(msg, ignoreSyntaxErr) {
  return {
    id: msg.ID,
    message_id: msg.messageID,
    schedule: parseSchedule(msg.schedule, ignoreSyntaxErr)
  }
}

function mapRenderPreview(msg) {
  return {
    code_snippet_code: msg.codeSnippet.code,
    language_id: msg.languageID,
    instance_id: msg.instanceID,
    country_iso_code: msg.countryISOCode,
    campaign_name: msg.campaignName || null,
    skus: parseSKUs(msg.skus, false),
    sponsored_skus: parseSKUs(msg.sponsoredSkus, true),
    sku_position: msg.skuPosition,
    template: parseTemplate(msg.template)
  }
}

export {
  messageWithScheduleMapper,
  messageScheduleMapper,
  scheduleUpdateMapper,
  mapRenderPreview
}
