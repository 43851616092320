import { milkyWayAxios } from '@/api/axios'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'
import { mapUserSegmentsList } from './responseMapper'

const endpoints = {
  userSegments: uuid => `/usersegments/user/${uuid}`
}

const apiGetUserSegments = async request => {
  const response = await milkyWayAxios.get(
    endpoints.userSegments(request.uuid),
    { params: { ...request.params } }
  )
  if (isOKResponse(response)) {
    const { response: userSegments, meta: pagination } = parseSuccessResponse(
      response
    )
    return {
      userSegments: mapUserSegmentsList(userSegments),
      finalDataSize: pagination ? pagination.total_records : userSegments.length
    }
  } else {
    throw response
  }
}

export { apiGetUserSegments }
