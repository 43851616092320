import { i18n } from '@/i18n'
import { filterActionsListByPermission } from '@/utils/auth'
import { formatDate } from '@/utils/commons/dateUtils'
import { formatBytes } from '@/utils/humanization'

/**
 * A constant to specify functions that allow
 * to create the actions that can be used in the campaigns.
 */
const ACTION = {
  CREATE: () => {
    return {
      event: 'create',
      label: i18n.t('media.actions.create'),
      icon: 'add',
      isPrimary: true,
      options: []
    }
  },
  DELETE: media => {
    return {
      event: 'delete',
      icon: 'delete',
      label: i18n.t('media.actions.delete'),
      confirmationRequired: true,
      confirmationTitleKey: i18n.t('media.delete_confirmation.title'),
      confirmationDescriptionKey: i18n.t(
        'media.delete_confirmation.description',
        media
      )
    }
  },
  VIEW: () => {
    return {
      event: 'edit',
      icon: 'visibility',
      label: i18n.t('media.actions.view')
    }
  },
  CLIPBOARD_COPY: () => {
    return {
      event: 'clipboard-copy',
      icon: 'mdi-clipboard',
      label: i18n.t('media.actions.copy_url')
    }
  }
}

/**
 * Maps a media object to the object that will be displayed
 * on the list view.
 * @param {*} media The media object to map
 */
function singleMediaToListDTO(media) {
  return {
    id: media.id,
    thumbnail: media.url,
    name: media.name,
    size: formatBytes(media.size),
    created_at: formatDate(media.createdAt),
    created_by: media.createdBy
  }
}

/**
 * Returns the current media, with the associated logics (e.g. actions
 * that can be performed on them.)
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function mediaListDTO(state, getters, rootState, rootGetters) {
  if (!state.media) return []
  return state.media.map(media => {
    let data = singleMediaToListDTO(media)
    return {
      ...data,
      actions: actionsOnSingleMedia(
        state,
        getters,
        rootState,
        rootGetters
      )(media)
    }
  })
}

/**
 * Returns an array containing the actions that the user can perform on the entire list
 * of media (i.e. on the 'collection' level and not on the 'individual' level)
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnMediaList(state, getters, rootState, rootGetters) {
  var actions = []

  if (rootGetters['user/canCreateMedia']) {
    actions.push(ACTION.CREATE())
  }

  return actions
}

/**
 * Returns an array with the actions that the user can perform on a specific
 * media.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnSingleMedia(state, getters, rootState, rootGetters) {
  return function(media) {
    let actions = []

    if (!media) return actions

    /**
     * Specifies that the current user can perform the below actions.
     * action: the action that the user can perform
     * permission: path to the 'getter' that comfirms the permission.
     */
    let potentialActions = [
      {
        action: ACTION.CLIPBOARD_COPY(media),
        permission: 'user/canSeeMedia'
      },
      { action: ACTION.DELETE(media), permission: 'user/canDeleteMedia' },
      { action: ACTION.VIEW(media), permission: 'user/canSeeMedia' }
    ]
    actions = filterActionsListByPermission(rootGetters, '', potentialActions)

    return actions
  }
}

export default {
  mediaListDTO,
  actionsOnMediaList,
  actionsOnSingleMedia
}
