function getTabs(state) {
  if (state.tabs) {
    return state.tabs
  }
  return []
}

export default {
  getTabs
}
