import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

export default [
  {
    path: '/docs',
    component: loadView('CustomDocs', 'docs'),
    children: [
      {
        path: '',
        name: 'docs',
        component: loadView('CustomDocs', 'docs'),
        meta: {
          navigationDrawer: true,
          module: 'docs',
          role: PERMISSIONS_CONSTANTS.MDS_DOCUMENTATION
        }
      }
    ]
  }
]
