import { SOURCES } from '@/constants/sources'
import { formatDate } from '@/utils/commons/dateUtils'
import { mapUserAction } from '@/utils/commons/formatter'

const templateViewMap = template => {
  return {
    id: template.id,
    name: template.name,
    description: template.description,
    source: template.source === SOURCES.AWS_DATALAKE,
    areas: template.areas,
    placeholders: template.placeholders || [],
    sqlQuery: template.sql_query
  }
}

function templateAuditMap(templateAudit) {
  const {
    id,
    template_id: templateId,
    user_action: userAction,
    updated_by: updatedBy,
    updated_at: updatedAt
  } = templateAudit

  return {
    id,
    templateId,
    userAction: mapUserAction(userAction),
    updatedBy,
    updatedAt: formatDate(updatedAt)
  }
}

function templateAuditListMap(list) {
  return list.map(item => templateAuditMap(item))
}

export { templateViewMap, templateAuditListMap }
