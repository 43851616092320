import store from '@/store'
import { USER_CONSTANTS } from '@/store/user/constants'
import { isForbidden } from '@/utils/errors'
import { apiLogin } from '@/api'
import { i18n } from '@/i18n'
/**
 * Attempts to log in the user (using google auth).
 * @param {string} credential The google auth context to use (usually, this.$gAuth)
 * @param {*} loginSuccessCallback A callback function to execute when login is successful.
 * @param {*} errorCallback A callback function to execute when an error in login occurs. This
 * callback replaces the default error logic handling. This argument can be undefined (uses default).
 */
async function logIn(credential, loginSuccessCallback, errorCallback) {
  try {
    const { email, authToken, refreshToken } = await apiLogin(credential)
    // const success = await store.dispatch('app/login', authCode)
    const success = email && authToken && refreshToken
    if (success) {
      loginSuccessCallback()
    }
  } catch (error) {
    if (errorCallback) {
      errorCallback()
    } else {
      if (!closedLoginPopupByUser(error)) {
        if (isForbidden(error)) {
          await store.dispatch('app/showErrorNotification', {
            message: i18n.t('no_permissions.login')
          })
        } else {
          await store.dispatch('app/showErrorNotification', {
            message: i18n.t('error.notification')
          })
        }
      }
    }
  }
}

/**
 * Checks if the error occurred due to the user closing the google auth window.
 * @param {error} error The error to check for.
 */
function closedLoginPopupByUser(error) {
  return error && error.error && error.error === 'popup_closed_by_user'
}

/**
 * Returns a list of actions of which the user has permissions to perform. This list is obtained from a potential
 * list of actions. This filtering corresponds to "activating" or "deactivating" the action accordingly.
 * Furthermore, it also allows the specification of a group of actions exclusive to each other, like so:
 * {actionGroup:[
 *      {action:action,permission:permission, specialConditionValue (optional): boolean},
 *       ...
 *    ]
 * }, useful for case like edit/view (you can either edit, or view).
 * Finally, the 'specialConditionValue' specifies the boolean value of special conditions
 * that must be 'AND' joined with the permissions (e.g. canSeeSegment && status===3)
 * @param {rootGetters} rootGetters The vuex root getters.
 * @param {string} contentCreator The email of the one who created the content to be checked (e.g. instance's created by)
 * @param {[]} actionList A list with tuples of structure {action:action,permission:rootGetterPermissionPath}
 */
function filterActionsListByPermission(
  rootGetters,
  contentCreator,
  actionList
) {
  let actions = []
  for (let i in actionList) {
    let element = actionList[i]
    if (element.menu) {
      element.menu.list = filterActionsListByPermission(
        rootGetters,
        contentCreator,
        element.menu.list
      )
      actions.push(element)
    } else {
      let finalAction = parseActionObject(rootGetters, contentCreator, element)
      actions.push({
        ...finalAction.action,
        divider: finalAction.divider || false
      })
    }
  }
  return actions
}

/**
 * Checks if the given action object has the permission it specifies.
 * Action object structure: {action:action,permission:permission,specialConditionValue (optional): boolean}
 * @param {*} rootGetters RootGetters of vuex
 * @param {*} contentCreator The identifier of the object's creator (e.g. campaigns.createdBy)
 * @param {*} action The action object
 */
function checkActionPermission(rootGetters, contentCreator, action) {
  if (Array.isArray(action.permission)) {
    return action.permission.some(p => rootGetters[p])
  }
  let func = rootGetters[action.permission]
  let boolVal = false
  if (typeof func === 'boolean') {
    // Some getter functions may return a function or a boolean, depending on what they need.
    // As such, we need to validate this.
    boolVal = func
  } else if (typeof func === 'function') {
    // If it's a function, call it
    boolVal = func(contentCreator)
    if (!boolVal && action.params) {
      boolVal = func(...action.params)
    }
  }
  // If the object has a special condition value, do an AND with it.
  if (action.specialConditionValue !== undefined) {
    boolVal = boolVal && action.specialConditionValue
  }
  return boolVal
}

/**
 * Parses an action object, as it can either be a group of actions
 * or a single action.
 * @param {*} rootGetters RootGetters of vuex
 * @param {*} contentCreator The identifier of the object's creator (e.g. campaigns.createdBy)
 * @param {*} action The action object to be parsed.
 */
function parseActionObject(rootGetters, contentCreator, actionElement) {
  let result = actionElement
  if (actionElement.actionGroup) {
    // It's a group action, so we need to apply an exclusive logic.
    // If none of the conditions are true, return first.
    let foundAction = false
    for (let index in actionElement.actionGroup) {
      let action = actionElement.actionGroup[index]
      if (checkActionPermission(rootGetters, contentCreator, action)) {
        result = action
        action.action.active = !action.action.disabled
        foundAction = true
        break
      }
    }
    if (!foundAction && actionElement.actionGroup.length > 0) {
      result = actionElement.actionGroup[0]
      result.action.active = false
    }
  } else {
    result.action.active = result.action.disabled
      ? false
      : checkActionPermission(rootGetters, contentCreator, actionElement)
  }
  return result
}

const hasToken = () =>
  store.state.user.securedState.get(USER_CONSTANTS.USER)[
    USER_CONSTANTS.AUTH_TOKEN
  ]

const hasRefreshToken = () =>
  store.state.user.securedState.get(USER_CONSTANTS.USER)[
    USER_CONSTANTS.REFRESH_TOKEN
  ]

export { logIn, hasToken, filterActionsListByPermission, hasRefreshToken }
