import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const BUDGETS_MODULE = 'budgets'

export default [
  {
    path: '/budgets',
    component: loadView('ViewBudget', BUDGETS_MODULE),
    children: [
      {
        path: '',
        name: 'budgets',
        component: loadView('ViewBudgetList', BUDGETS_MODULE),
        meta: {
          navigationDrawer: true,
          module: BUDGETS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_BUDGET
        }
      },
      {
        path: 'new',
        name: 'budgets-add',
        component: loadView('ViewBudgetAdd', BUDGETS_MODULE),
        meta: {
          navigationDrawer: true,
          module: BUDGETS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_BUDGET,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: ':id',
        name: 'budgets-view',
        component: loadView('ViewBudgetEdit', BUDGETS_MODULE),
        meta: {
          navigationDrawer: true,
          module: BUDGETS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_BUDGET,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: ':id',
        name: 'budgets-edit',
        component: loadView('ViewBudgetEdit', BUDGETS_MODULE),
        meta: {
          navigationDrawer: true,
          module: BUDGETS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_BUDGET,
          whiteBackground: true,
          savePrevRoute: true
        }
      }
    ]
  }
]
