import { milkyWayAxios, sombreroAxios } from '@/api/axios'
import {
  segmentMap,
  segmentListMap,
  segmentNameListMap,
  segmentColumnsMap,
  mapSegmentHistory
} from './responseMapper'
import { isOKResponse } from '@/api/utils/response'
import {
  mapCreateSegment,
  mapUpdateSegment
} from '@/api/segments/requestMapper'

var endpoint = {
  segments: '/segments',
  segment: id => `/segments/${id}`,
  segmentHistory: id => `/datasource-logs/${id}`,
  downloadCsv: id => `/segments/${id}/url`,
  tableNames: id => `/segments/${id}/columns`,
  syncAll: '/segments/cron/update',
  syncById: id => `/data-sources/${id}/sync`
}

/**
 * Gets a single segment from the api.
 * @param {*} id
 */
async function apiGetSegment(id) {
  var response = await milkyWayAxios.get(endpoint.segment(id))
  return segmentMap(response.data)

}

/**
 * Gets all segments available in the API.
 * @param {*} request
 */
async function apiGetSegments(request) {
  const payload = {}
  const q = request.q
  const visibility = request.visibility ? request.visibility : 'all'
  const perPage = request.per_page ? request.per_page : 10
  let filters = request.filters ? request.filters : {}
  let page = request.page

  if (q != null && q !== '') {
    payload.query = q
  }

  // needed for listing data-sources when creating instances
  if (!filters.sort_by) {
    filters.sort_by = 'name'
    filters.order_by = 'asc'
  }

  payload.visibility = visibility
  payload.per_page = perPage
  payload.active = true
  payload.page = page

  let { data } = await milkyWayAxios.get(endpoint.segments, {
    params: { ...payload, ...filters }
  })

  let segmentList = data.data
  let pagination = data.meta
  let mappedSegments = segmentListMap(segmentList)

  return {
    segments: mappedSegments,
    finalDataSize: pagination
      ? pagination.total_records
      : mappedSegments.length,
    newPage: pagination ? pagination.page : 1,
    originalData: mappedSegments
  }
}

/**
 * Downloads the segment's data.
 * @param {*} param0
 */
async function apiDownloadSegmentCsv({ id }) {
  const response = await milkyWayAxios.get(endpoint.downloadCsv(id))
  const url = response.data.url

  if (url) {
    return url
  }

  throw response.error
}

/**
 * Gets the names of the segments on the data base.
 */
async function apiGetSegmentsName(request) {
  let calcNumRows =
    request !== undefined && request.calc_num_rows
      ? request.calc_num_rows
      : false

  let { data } = await milkyWayAxios.get(endpoint.segments, {
    params: {
      active: true,
      sort_by: 'name',
      order_by: 'asc',
      calc_num_rows: calcNumRows
    }
  })

  const segments = segmentNameListMap(data.data)

  return segments
}

/**
 * Gets the columns of the segment.
 * @param {*} id
 */
const apiGetSegmentTableColumns = async (id, separateCountry) => {
  const { data } = await milkyWayAxios.get(endpoint.tableNames(id))
  return segmentColumnsMap(data.columns, separateCountry)
}

/**
 * Sync all segments.
 */
const apiSyncAll = async () => {
  const response = await milkyWayAxios.post(endpoint.syncAll)

  if (response.data) {
    return response.data
  }

  throw response.error
}

/**
 * Sync by KeyName
 */
const apiSyncById = async (id, forceSync) => {
  const response = await milkyWayAxios.get(endpoint.syncById(id), { params: { force_sync: forceSync } })

  if (isOKResponse(response)) {
    return response.data
  }

  throw response.error
}

const apiCreateSegment = async request => {
  let body = mapCreateSegment(request)
  let response = await milkyWayAxios.post(endpoint.segments, body)

  if (isOKResponse(response)) {
    return response.data
  }

  throw response.error
}

const apiUpdateSegment = async segment => {
  let body = mapUpdateSegment(segment)
  let response = await milkyWayAxios.put(endpoint.segment(segment.id), body)

  if (isOKResponse(response)) {
    return response.data
  }

  throw response.error
}

async function apiGetSegmentHistory(id) {
  let response = await sombreroAxios.get(endpoint.segmentHistory(id))
  if (isOKResponse(response)) {
    return mapSegmentHistory(response.data.data)
  }

  throw response.error
}

export {
  apiGetSegment,
  apiGetSegments,
  apiDownloadSegmentCsv,
  apiGetSegmentsName,
  apiGetSegmentTableColumns,
  apiSyncAll,
  apiSyncById,
  apiCreateSegment,
  apiUpdateSegment,
  apiGetSegmentHistory
}
