import {
  apiGetCampaigns,
  apiUpdateCampaign,
  apiGetCampaign,
  apiDeleteCampaign,
  apiCreateCampaign,
  apiTestCampaign,
  apiGetCampaignStats,
  apiGetCampaignLastStats,
  apiGetCampaignMonthStats,
  apiPreview,
  apiGetCampaignAudit,
  apiGetWidgetCampaigns,
  apiCalculateCampaignCost
} from '@/api/campaigns'
import { apiGetInstances } from '@/api/instances'
import { apiGetMessages } from '@/api/messages'
import { viewTypes } from '@/utils/viewTypes'
import { apiBulkUpdateStatusCampaign } from '../../api/campaigns'

/**
 * Obtains the campaigns by calling the respective API.
 * As pagination can differ from the expected page,
 * if successful, the result will contain the new page
 * to be used by the List Views.
 * @param {*} param0
 * @param {*} param1
 */
async function fetchCampaigns({ commit }, request) {
  let result
  let commitPayload = {
    campaigns: [],
    finalDataSize: 0,
    originalData: []
  }
  try {
    let response = await apiGetCampaigns(request)
    commitPayload = {
      campaigns: response.campaigns,
      finalDataSize: response.finalDataSize,
      originalData: response.originalData
    }
    result = { newPage: response.newPage }
  } catch (error) {
    //
  }
  commit('CAMPAIGNS_UPDATED', commitPayload)
  return result
}
/**
 * Deletes a specific campaign.
 * Returns whether deletion was successful or not.
 * @param {*} _ Vuex State. Unused, as Vuex is being used as a centralized databag.
 * @param {*} id The id of the campaign to delete.
 */
async function deleteCampaign(_, id) {
  const deleted = await apiDeleteCampaign(id)
  return !!deleted
}

/**
 * Creates a campaign.
 * Returns the response of the API.
 * @param {*} _ Vuex State. Unused, as Vuex is being used as a centralized databag.
 * @param {*} campaign The campaign to create.
 */
async function createCampaign(_, campaign) {
  const campaignCreated = await apiCreateCampaign(campaign)
  return campaignCreated
}

/**
 * Returns a single campaign's data.
 * @param {*} _ Vuex State. Unused, as Vuex is being used as a centralized databag.
 * @param {*} param1 The id of the campaign whose data will be fetched.
 */
async function fetchCampaign(_, { id: campaignId }) {
  const campaign = await apiGetCampaign(campaignId)
  return campaign
}

/**
 * Updates the specified campaign
 * Returns the API response if the update was successful. Otherwise,
 * returns false.
 * @param {*} _ Vuex State. Unused, as Vuex is being used as a centralized databag.
 * @param {*} campaign The campaign to update. It should include the id
 * of the campaign that will be updated, as well as the updated data.
 */
async function updateCampaign(_, campaign) {
  const campaignUpdated = await apiUpdateCampaign(campaign)
  if (campaignUpdated) {
    return campaignUpdated
  }
  return false
}

/**
 * Bulk updates status of campaigns
 * @param {*} data Object containing campaign Ids and the status to update to
 * @returns {Promise<AxiosResponse<any>>}
 */
async function bulkUpdateStatus(_, data) {
  const res = await apiBulkUpdateStatusCampaign(data)
  return res
}

/**
 * Send test of a campaign
 * @param {*} _ Vuex State. Unused, as Vuex is being used as a centralized databag.
 * @param {object} request To request test and contains campaignId, email, date.
 * @returns {Promise<AxiosResponse<any>>}
 */
async function testCampaign(_, request) {
  return apiTestCampaign(request)
}

/**
 * Get stats of a campaign
 * @param _
 * @param campaignId
 * @returns {Promise<boolean>}
 */
async function fetchCampaignStats(_, payload) {
  const res = await apiGetCampaignStats(payload)
  return res
}

/**
 * Get last occurrence stats of a campaign
 * @param _
 * @param campaignId
 * @returns {Promise<boolean>}
 */
async function fetchCampaignLastStats(_, payload) {
  const res = await apiGetCampaignLastStats(payload)
  return res
}

/**
 * Get last month stats of a campaign
 * @param _
 * @param campaignId
 * @returns {Promise<boolean>}
 */
async function fetchCampaignMonthStats(_, payload) {
  const res = await apiGetCampaignMonthStats(payload)
  return res
}

async function fetchCampaignAudit(_, payload) {
  const res = await apiGetCampaignAudit(payload)
  return res
}

/**
 * Fetches the instances from the API.
 * @param {*} commit
 * @param {*} payload
 */
async function fetchInstances({ commit }, payload) {
  let result
  let commitPayload = {
    instances: [],
    finalDataSize: 0,
    originalData: []
  }
  try {
    let response = await apiGetInstances(payload)
    result = { newPage: response.newPage }
    commitPayload = {
      instances: response.instances,
      finalDataSize: response.finalDataSize,
      originalData: response.originalData
    }
  } catch (error) {
    /* eslint-disable no-console */
    console.error(error)
    /* eslint-enable no-console */
  }
  commit('INSTANCES_UPDATED', commitPayload)
  return result
}

/**
 * Fetches the messages from the API.
 * Returns the new page the user is currently in.
 * @param {*} param0
 * @param {*} request
 */
async function fetchMessages({ commit }, request) {
  let result
  const sortBy =
    request !== undefined && request.sort_by ? request.sort_by : 'name'
  const orderBy =
    request !== undefined && request.order_by ? request.order_by : 'asc'
  const filters =
    request !== undefined && request.filters
      ? request.filters
      : { active: true }
  const req = {
    sort_by: sortBy,
    order_by: orderBy,
    filters: filters,
    view: viewTypes.BasicView
  }
  let commitPayload = { messages: [] }
  try {
    let response = await apiGetMessages(req)
    result = { newPage: response.newPage }
    commitPayload = {
      messages: response.messages
    }
  } catch (error) {
    /* eslint-disable no-console */
    console.error(error)
    /* eslint-enable no-console */
  }
  commit('MESSAGES_UPDATED', commitPayload)
  return result
}

/**
 * Fetches the preview of the message
 * @param _
 * @param payload
 * @returns {Promise<[]>}
 */
async function previewMessage(_, payload) {
  const res = await apiPreview(payload)
  return res
}

async function fetchWidgetCampaigns({ commit }, request) {
  let commitPayload = {
    widgetCampaigns: [],
    finalDataSize: 0
  }

  try {
    const response = await apiGetWidgetCampaigns(request)
    commitPayload = {
      widgetCampaigns: response.expiringCampaigns,
      finalDataSize: response.finalDataSize
    }
  } catch (error) {
    console.debug(error)
  }

  commit('WIDGET_CAMPAIGNS_UPDATED', commitPayload)
}

async function calculateCampaignCost(_, payload) {
  const res = await apiCalculateCampaignCost(payload)
  return res
}

export default {
  createCampaign,
  updateCampaign,
  fetchCampaigns,
  deleteCampaign,
  fetchCampaign,
  testCampaign,
  fetchCampaignLastStats,
  fetchCampaignMonthStats,
  fetchCampaignStats,
  fetchInstances,
  fetchMessages,
  previewMessage,
  fetchCampaignAudit,
  bulkUpdateStatus,
  fetchWidgetCampaigns,
  calculateCampaignCost
}
