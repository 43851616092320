function mapCreateRate(cr) {
  return {
    value: parseFloat(cr.value),
    country_id: cr.countryId,
    hc_ticket_id: parseInt(cr.hcTicket)
  }
}

function mapUpdateRate(cr) {
  return {
    value: parseFloat(cr.value),
    hc_ticket_id: parseInt(cr.hcTicket)
  }
}

export { mapCreateRate, mapUpdateRate }
