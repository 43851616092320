import { sombreroAxios } from '@/api/axios'
import { eventsListMap } from './responseMapper'
const endpoint = {
  events: 'events'
}

async function apiGetTriggeredEvents(request) {
  const q = request.q
  const page = request.page
  const perPage = request.perPage
  const filters = request.filters
  const type = request.type

  let payload = {}

  if (!filters) {
    payload.sort_by = 'date'
    payload.order_by = 'desc'
  }

  if (q != null && q !== '') {
    payload.description = q
  }

  payload.per_page = perPage
  payload.page = page

  if (type !== '') {
    payload.type = type
  }

  const { data } = await sombreroAxios.get(endpoint.events, {
    params: { ...payload, ...filters }
  })

  const list = data.data
  const pagination = data.meta

  const events = eventsListMap(list)

  return {
    triggeredEvents: events,
    finalDataSize: pagination ? pagination.total_records : list.length
  }
}

export { apiGetTriggeredEvents }
