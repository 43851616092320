import { viewTypes } from '@/utils/viewTypes'
import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'

/**
 * This file serves the purpose of handling conversions from DTOs to other 'DTO'.
 * Essentially, xyzMap functions map the object JSON obtained from the Back-End (BE) to a javascript object
 * that is easily parsed by the Front-End (FE).
 */

/**
 * Converts a JSON message object from the API format to the format expected by the FE.
 * @param {JSON} message The message JSON object to convert from.
 */
function messageFullViewMap(message) {
  let {
    id,
    name,
    description,
    channel,
    valid,
    active,
    created_at: createdAt,
    created_by: createdBy,
    updated_at: updatedAt,
    updated_by: updatedBy,
    templates,
    source,
    data
  } = message
  let channelID = channel.id
  let channelName = channel.name
  return {
    id,
    name,
    description,
    channelID,
    channelName,
    channel,
    valid,
    active,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    templates,
    source,
    data
  }
}

/**
 * Converts a JSON message object from the API format to the format expected by the FE.
 * @param {JSON} message The message JSON object to convert from.
 */
function messageBasicViewMap(message) {
  let { id, name } = message
  return {
    id,
    name
  }
}

/**
 * Maps an array of messages to another array of messages in the expected FE format.
 * @param list {Array}  The list of messages in BE format.
 * @param view {String} Type message view.
 */
function messageListMap(list, view) {
  let messages = []
  for (let item of list) {
    switch (view) {
      default:
      case viewTypes.FullView:
        messages.push(messageFullViewMap(item))
        break
      case viewTypes.BasicView:
        messages.push(messageBasicViewMap(item))
        break
    }
  }
  return messages
}

function mapAudit(list) {
  return list.map(a => {
    return {
      action:
        a.action === 'INSERT'
          ? i18n.t('instances.audit.actions.create')
          : i18n.t('instances.audit.actions.update'),
      updated_by: a.updated_by,
      updated_at: formatDate(a.updated_at)
    }
  })
}

export { messageFullViewMap, messageListMap, mapAudit }
