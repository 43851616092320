import {
  apiCreate,
  apiGetIncentiveTypes,
  apiGetObjectives,
  apiGetPrograms,
  apiSendTest,
  apiUpdate,
  apiValidate
} from '@/api/growthtool'

/**
 * Creates a growth tool on the data base and returns the response given by the API.
 * @param {*} _
 * @param pushNotification
 */
async function create(_, { message }) {
  return apiCreate(message, true)
}

/**
 * Creates a message on the data base and returns the response given by the API.
 * @param {*} _
 * @param message
 */
async function update(_, { message }) {
  return apiUpdate(message, true)
}

/**
 * Fetches a list of growth tool programs
 * @returns {Promise<{responseType: string, response: *, success: boolean, status: *}|AxiosResponse<any>>}
 */
async function fetchPrograms({ commit }) {
  let programs = await apiGetPrograms()
  commit('PROGRAMS_UPDATED', programs)
}

/**
 * Fetches a list of growth tool objectives
 * @returns {Promise<{responseType: string, response: *, success: boolean, status: *}|AxiosResponse<any>>}
 */
async function fetchObjectives({ commit }) {
  let objectives = await apiGetObjectives()
  commit('OBJECTIVES_UPDATED', objectives)
}

/**
 * Fetches a list of growth tool incentive types
 * @returns {Promise<{responseType: string, response: *, success: boolean, status: *}|AxiosResponse<any>>}
 */
async function fetchIncentiveTypes({ commit }) {
  let incentiveTypes = await apiGetIncentiveTypes()
  commit('INCENTIVE_TYPES_UPDATED', incentiveTypes)
}

/**
 * Validates growth tool custom query
 * @param {*} _
 * @param message
 */
async function validate(_, message) {
  return apiValidate(message)
}

/**
 * Dispatch a test
 * @param _
 * @param payload
 * @returns {Promise<AxiosResponse<any>>}
 */
async function sendTest(_, payload) {
  return apiSendTest(payload)
}

export default {
  create,
  update,
  fetchPrograms,
  fetchObjectives,
  fetchIncentiveTypes,
  validate,
  sendTest
}
