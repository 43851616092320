/**
 * Signal the Vuex state that the suggestions of data for a specific field
 * were cached.
 * @param {*} state
 * @param {string} field The field whose data will be cached.
 * @param {array} dataSuggestions The data suggestions to be cached.
 */
function DATA_SUGGESTIONS_FIELD_CACHED(state, { field, dataSuggestions }) {
  state.suggestions[field] = dataSuggestions
}

export default {
  DATA_SUGGESTIONS_FIELD_CACHED
}
