import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'

/**
 * WARNING: The below data and function should be centralized or reorganized.
 * Computes the status' color for the message.
 */
const STATE_COLORS = {
  VALID: '#20921E',
  APPROVED: '#2A8091',
  NOT_APPROVED: '#8e8e93',
  ERROR: '#E36049'
}

/**
 * Parses the label and color for the message schedules' state.
 * @param {*} messageScheduleState The message schedules' state.
 */
function parseStatusVanityFrom(messageScheduleState) {
  let label = i18n.t('messages.message_state.error')
  let color = STATE_COLORS.ERROR
  // If the message state is valid
  let messageScheduleStateStr
  if (messageScheduleState !== undefined) {
    messageScheduleStateStr = messageScheduleState.toString()
  }
  if (messageScheduleStateStr) {
    let upperCasedState = messageScheduleStateStr.toUpperCase()

    if (upperCasedState in STATE_COLORS) {
      // if it's a valid state
      label = i18n.t(
        'messages.newsletter.schedule_status.' +
          messageScheduleStateStr.toLowerCase()
      )
      color = STATE_COLORS[upperCasedState]
    }
  }
  return { label, color } // returns an object with the label and color
}

/**
 * A constant to specify functions that allow
 * to create the actions that can be used in messages.
 */
var action = {
  CREATE: () => {
    return {
      event: 'create',
      label: i18n.t('messages.newsletter.actions.create'),
      icon: 'add',
      isPrimary: true
    }
  },
  DELETE: messageSchedule => {
    return {
      event: 'delete',
      icon: 'delete',
      label: i18n.t('messages.newsletter.actions.delete'),
      confirmationRequired: true,
      confirmationTitleKey: i18n.t(
        'messages.newsletter.actions.delete_confirmation.title'
      ),
      confirmationDescriptionKey: i18n.t(
        'messages.newsletter.actions.delete_confirmation.description',
        messageSchedule
      ),
      active: true
    }
  },
  EDIT: () => {
    return {
      event: 'edit',
      icon: 'edit',
      label: i18n.t('messages.newsletter.actions.edit'),
      active: true // MUST DEFINE POLICY FOR EDIT BUTTON "ENABLE/DISABLE", IF ANY
    }
  },
  VIEW: () => {
    return {
      event: 'edit',
      icon: 'visibility',
      label: i18n.t('messages.newsletter.actions.view'),
      active: true // MUST DEFINE POLICY FOR EDIT BUTTON "ENABLE/DISABLE", IF ANY
    }
  },
  CLONE: () => {
    return {
      event: 'clone',
      icon: 'file_copy',
      label: i18n.t('messages.newsletter.actions.clone')
    }
  },
  AUDIT: () => {
    return {
      event: 'audit',
      icon: 'mdi-update',
      label: i18n.t('messages.view_audit')
    }
  }
}

/**
 * Specifies the column names for the message schedules list view.
 * The values are obtained from the getter.js and responseMapper.js, as well as the internationalization18
 * (i18) values.
 * Essentially, maps a message object to the object that will be displayed
 * on the list view.
 * @param {*} message The message that will be converted to a ListView DTO.
 */
function messageSchedulesToListDTO(messageSchedule) {
  let chipVanityData = parseStatusVanityFrom(messageSchedule.status)

  return {
    id: messageSchedule.id,
    dispatch_at: formatDate(messageSchedule.dispatchAt, false),
    status: {
      label: chipVanityData.label,
      color: chipVanityData.color,
      type: 'JChip'
    },
    valid: {
      label: messageSchedule.valid
        ? i18n.t('messages.valid.true')
        : i18n.t('messages.valid.false'),
      color: messageSchedule.valid ? STATE_COLORS.VALID : STATE_COLORS.ERROR,
      type: 'JChip'
    },
    updated_at: formatDate(messageSchedule.updatedAt),
    updated_by: messageSchedule.updatedBy
  }
}

/**
 * Returns the message schedules list, with the
 * format ready to be rendered in the JList.vue component (ListDTO format).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function messageSchedulesListDTO(state, getters, rootState, rootGetters) {
  if (!state.messageSchedules) return []
  return state.messageSchedules.map(messageSchedule => {
    const data = messageSchedulesToListDTO(messageSchedule)
    return {
      ...data,
      actions: actionsOnMessageSchedule(
        state,
        getters,
        rootState,
        rootGetters
      )(messageSchedule)
    }
  })
}
/**
 The actions that the user can perform on the message list as a whole (i.e. on the group).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnMessageScheduleList(state, getters, rootState, rootGetters) {
  var actions = []

  if (rootGetters['user/canCreateMessages']) {
    actions.push(action.CREATE())
  }

  return actions
}
/**
 * Responsible for declaring the actions that can be performed on a specific message.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnMessageSchedule(state, getters, rootState, rootGetters) {
  return function(messageSchedule) {
    if (!messageSchedule) return []

    return filterActionsListByPermission(
      rootGetters,
      messageSchedule.createdBy,
      [
        {
          actionGroup: [
            {
              action: action.EDIT(messageSchedule),
              permission: 'user/canEditMessage'
            },
            {
              action: action.VIEW(messageSchedule),
              permission: 'user/canSeeMessage'
            }
          ]
        },
        {
          label: i18n.t('messages.actions.more'),
          icon: 'more_vert',
          menu: {
            list: [
              {
                action: action.CLONE(messageSchedule),
                permission: 'user/canCloneMessage'
              },
              {
                action: action.DELETE(messageSchedule),
                permission: 'user/canDeleteMessage'
              },
              {
                action: action.AUDIT(messageSchedule),
                permission: 'user/canSeeMessage'
              }
            ],
            date: {
              display: false
            }
          }
        }
      ]
    )
  }
}

export default {
  messageSchedulesListDTO,
  actionsOnMessageScheduleList,
  actionsOnMessageSchedule
}
