import { sombreroAxios } from '@/api/axios'
import { parseSuccessResponse } from '@/api/utils/response'
import { defaultErrorHandlingExecution } from '@/utils/mds_errors'

const endpoint = {
  sent_stats: 'campaigns/stats/sent',
  exportSentStats: 'campaigns/stats/export/sent'
}

/**
 * Get statistics of the specified fields.
 *
 * @param request
 * @returns {Promise<{total: number, messages, originalData, status: boolean}>}
 */
async function apiGetStatistics(request) {
  let response = await sombreroAxios.get(endpoint.sent_stats, {
    params: request
  })
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    return processedResponse
  }
  throw processedResponse
}

/**
 * Export statistics of the specified fields.
 *
 * @param request
 * @returns {Promise<{day, value}>}
 */
async function apiExportSentStats(request) {
  const response = await sombreroAxios.get(endpoint.exportSentStats, {
    params: request
  })
  const data = response.data
  if (data) {
    return data
  }
  defaultErrorHandlingExecution(response.error)
}
export { apiGetStatistics, apiExportSentStats }
