import {
  apiGetEmailTemplates,
  apiGetEmailTemplate,
  apiDeleteEmailTemplate,
  apiGetEmailTemplatesRelated
} from '@/api/emailtemplates'

/**
 * Fetches the email templates from the API.
 * @param {*} commit
 * @param {*} payload
 */
async function fetchEmailTemplates({ commit }, request) {
  let commitPayload = {
    queryTemplates: [],
    finalDataSize: 0,
    originalData: []
  }
  let response = await apiGetEmailTemplates(request)
  commitPayload = {
    emailTemplates: response.emailTemplates,
    finalDataSize: response.finalDataSize,
    originalData: response.originalData
  }
  commit('TEMPLATES_UPDATED', commitPayload)
  return commitPayload
}

/**
 * Fetches a single email template from the API.
 * @param {*} _
 * @param {*} templateId
 */
function fetchEmailTemplate(_, templateId) {
  return apiGetEmailTemplate(templateId)
}

/**
 * Delete an email template from the API.
 * @param {*} _
 * @param {*} templateId
 */
function deleteEmailTemplate(_, templateId) {
  return apiDeleteEmailTemplate(templateId)
}

/**
 * Fetches the related messages and campaigns by email templates id from the API.
 * @param {*} _
 * @param {*} templateId
 */
function fetchEmailTemplatesRelated(_, templateId) {
  return apiGetEmailTemplatesRelated(templateId)
}

export default {
  fetchEmailTemplates,
  fetchEmailTemplatesRelated,
  fetchEmailTemplate,
  deleteEmailTemplate
}
