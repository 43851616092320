import { ERROR_TYPES } from '@/constants/errors'
/**
 * Parses a response obtained by the API
 * according to the conventions defined in https://confluence.jumia.com/display/AFRMON/REST+response+conventions.
 * Only handles for the success case. If it corresponds to
 * an error (i.e. no data), the original response is returned.
 * @param {*} response The API response, with a flag that
 * allows to check if the handling was successful, like the following structure:
 * {
 *  response: (parsedResponse || response),
 *  success: (boolean)
 * }
 */
function parseSuccessResponse(response) {
  let result = response
  let meta = null
  let success = false
  let status = response.status
  let responseType = ERROR_TYPES.GENERIC
  // Axios, by default, has a 'data' field.
  if (
    response.data &&
    Object.prototype.hasOwnProperty.call(response.data, 'data')
  ) {
    result = response.data.data
    meta = response.data.meta
    success = true
    responseType = ERROR_TYPES.MDS
  }
  return {
    response: result,
    success,
    status,
    responseType,
    meta
  }
}

/**
 * Parses a response obtained by the API
 * according to the conventions defined in https://confluence.jumia.com/display/AFRMON/REST+response+conventions.
 * Only handles for the error case. If it corresponds to
 * an success (i.e. no error field), the original response is returned.
 * @param {*} response The API response, with a flag that
 * allows to check if the handling was successful, like the following structure:
 * {
 *  response: (parsedResponse || response),
 *  success: (boolean)
 * }
 */
function parseErrorResponse(response) {
  let result = response
  let success = false
  let status
  let responseType = ERROR_TYPES.GENERIC
  // Axios, by default, has a 'data' field.
  if (
    response &&
    response.response &&
    Object.prototype.hasOwnProperty.call(response.response, 'data')
  ) {
    if (response.response.data.errors) {
      result = response.response.data
      success = true
      status = response.response.status
      responseType = ERROR_TYPES.MDS
    }
  }
  return {
    response: result,
    success: success,
    status: status,
    responseType: responseType
  }
}

/**
 * Checks if the reply of the API is a 200 (StatusOK)
 * @param {*} response
 */
function isOKResponse(response) {
  return response.status === 200
}

/**
 * Checks if the reply of the API is a 201 (Created)
 * @param {*} response
 */
function isCreatedResponse(response) {
  return response.status === 201
}

/**
 * Checks if the reply of the API is a 202 (Accepted)
 * @param {*} response
 */
function isAcceptedResponse(response) {
  return response.status === 202
}

/**
 * Checks if the reply of the API is a 204 (No Content)
 * @param {*} response
 */
function isNoContentResponse(response) {
  return response.status === 204
}

export {
  parseSuccessResponse,
  parseErrorResponse,
  isOKResponse,
  isCreatedResponse,
  isAcceptedResponse,
  isNoContentResponse
}
