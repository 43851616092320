import { butterflyAxios } from '@/api/axios'
import {
  isCreatedResponse,
  isOKResponse,
  parseSuccessResponse
} from '@/api/utils/response'
import {
  MapCreate,
  MapUpdate,
  MapValidate
} from '@/api/growthtool/requestMapper'
import {
  objectivesListMap,
  programsListMap,
  incentiveTypesListMap,
  validateMap
} from '@/api/growthtool/responseMapper'

const endpoint = {
  growthtool: 'growthtool',
  growth: id => `growthtool/${id}`,
  programs: 'growthtool/programs',
  objectives: 'growthtool/objectives',
  incentiveTypes: 'growthtool/incentive-types',
  validate: 'growthtool/validate',
  sendTest: 'growthtool/dispatch-test',
  logicGroups: 'growthtool/logic-groups'
}

const apiCreate = async (growthTool, ignoreSyntaxErrors) => {
  const gt = MapCreate(growthTool)
  let response = await butterflyAxios.post(endpoint.growthtool, gt)
  if (isCreatedResponse(response)) {
    return parseSuccessResponse(response)
  }
  return undefined
}

const apiUpdate = async (growthTool, ignoreSyntaxErrors) => {
  const gt = MapUpdate(growthTool)
  let response = await butterflyAxios.put(endpoint.growth(growthTool.id), gt)
  if (isOKResponse(response)) {
    return parseSuccessResponse(response)
  }
  return response
}

const apiGetPrograms = async () => {
  let res = await butterflyAxios.get(endpoint.programs)
  if (isOKResponse(res)) {
    let { response } = parseSuccessResponse(res)
    return programsListMap(response)
  }
  return res
}

const apiGetObjectives = async () => {
  let res = await butterflyAxios.get(endpoint.objectives)
  if (isOKResponse(res)) {
    let { response } = parseSuccessResponse(res)
    return objectivesListMap(response)
  }
  return res
}

const apiGetIncentiveTypes = async () => {
  let res = await butterflyAxios.get(endpoint.incentiveTypes)
  if (isOKResponse(res)) {
    let { response } = parseSuccessResponse(res)
    return incentiveTypesListMap(response)
  }
  return res
}

const apiValidate = async message => {
  const req = MapValidate(message)
  let res = await butterflyAxios.post(endpoint.validate, req)
  if (isOKResponse(res)) {
    let { response } = parseSuccessResponse(res)
    return validateMap(response)
  }
  return undefined
}

const apiSendTest = async payload => {
  let res = await butterflyAxios.post(endpoint.sendTest, payload)
  return res
}
export {
  apiCreate,
  apiUpdate,
  apiGetPrograms,
  apiGetObjectives,
  apiGetIncentiveTypes,
  apiValidate,
  apiSendTest
}
