/**
 * Signals Vuex's state that the instances have been updated.
 * @param {*} state The vuex state.
 * @param {*} param1
 */
const LOGIC_GROUPS_UPDATED = (state, { logicGroups, finalDataSize }) => {
  state.logicGroups = logicGroups
  state.finalDataSize = finalDataSize
}

export default {
  LOGIC_GROUPS_UPDATED
}
