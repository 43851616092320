import { formatDate } from '@/utils/commons/dateUtils'
import { i18n } from '@/i18n'

const logicGroupAuditsMap = audits => {
  return !audits ? [] : audits.map(auditMap)
}

const auditMap = audit => {
  return {
    userAction:
      audit.action === 'INSERT'
        ? i18n.t('logicgroups.audit.actions.create')
        : i18n.t('logicgroups.audit.actions.update'),
    status: {
      keyword: audit.active,
      label: audit.active ? i18n.t('enabled') : i18n.t('disabled'),
      type: 'JChip',
      color: audit.active ? '#2A8091' : '#8E8E93'
    },
    updatedBy: audit.updated_by,
    updatedAt: formatDate(audit.updated_at)
  }
}

export { logicGroupAuditsMap }
