import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const MODULE = 'deeplinks'

export default [
  {
    path: `/${MODULE}`,
    component: loadView('ViewDeepLink', MODULE),
    children: [
      {
        path: '',
        name: MODULE,
        component: loadView('ViewDeepLinkList', MODULE),
        meta: {
          navigationDrawer: true,
          module: MODULE,
          role: [
            PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_DEEPLINKS,
            PERMISSIONS_CONSTANTS.MDS_ADMIN
          ]
        }
      },
      {
        path: `new`,
        name: `${MODULE}-new`,
        component: loadView('ViewDeepLinkAdd', MODULE),
        meta: {
          navigationDrawer: true,
          module: MODULE,
          role: [
            PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_DEEPLINKS,
            PERMISSIONS_CONSTANTS.MDS_ADMIN
          ]
        }
      }
    ]
  }
]
