import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const TEMPLATES_MODULE = 'querytemplates'

export default [
  {
    path: '/querytemplates',
    component: loadView('ViewTemplates', TEMPLATES_MODULE),
    children: [
      {
        path: '',
        name: 'querytemplates',
        component: loadView('ViewTemplatesList', TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_TEMPLATES
        }
      },
      {
        path: 'new',
        name: 'template-add',
        component: loadView('ViewTemplatesAdd', TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_TEMPLATES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: ':templateId',
        name: 'template-view',
        component: loadView('ViewTemplatesEdit', TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_TEMPLATES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: ':templateId',
        name: 'template-edit',
        component: loadView('ViewTemplatesEdit', TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_TEMPLATES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: 'clone/:templateId',
        name: 'template-clone',
        component: loadView('ViewTemplatesClone', TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_TEMPLATES,
          whiteBackground: true,
          savePrevRoute: true
        }
      }
    ]
  }
]
