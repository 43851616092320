import {
  apiGetChannelBudgets,
  apiGetChannelBudgetsAudit,
  apiCreateChannelBudgets,
  apiUpdateChannelBudgets,
  apiGetChannelBudgetById,
  apiGetCampaignsByBudgetId
} from '@/api/channelBudgets'

/**
 * Fetches the sms budgets from the BE database and
 * updates the vuex state accordingly.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} payload
 */
async function fetchChannelBudgets({ commit }, payload) {
  const response = await apiGetChannelBudgets(payload)
  commit('CHANNEL_BUDGETS_UPDATED', response)
  return response
}

/**
 * Fetches the campaigns related to a budget from the BE database and
 * updates the vuex state accordingly.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} payload
 */
async function fetchCampaignsByBudgetId({ commit }, payload) {
  const response = await apiGetCampaignsByBudgetId(payload)
  commit('BUDGETS_CAMPAIGNS', response)
  return response
}
/**
 * Fetches the channel budgets by id from the BE database and
 * updates the vuex state accordingly.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} payload the channel budgets id.
 */
async function fetchChannelBudgetById(_, budgetId) {
  const response = await apiGetChannelBudgetById({ id: budgetId })
  return response
}

/**
 * Fetches the channel budgets audit from the BE database.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} channelRates the channel budget id.
 */
async function fetchChannelBudgetAudit(_, payload) {
  const res = await apiGetChannelBudgetsAudit(payload)
  return res
}

/**
 * Update the channel budgets from the BE database.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} channelRates the channelRates.
 */
async function updateChannelBudgets(_, channelBudgets) {
  const response = await apiUpdateChannelBudgets(channelBudgets)
  return response
}

/**
 * Create the channel budget from the BE database.
 * @param {*} param0 Vuex state (auto-injected).
 * @param {*} channelRates the channelRates.
 */
async function createChannelBudgets(_, channelBudgets) {
  const response = await apiCreateChannelBudgets(channelBudgets)
  return response
}
export default {
  fetchChannelBudgets,
  fetchChannelBudgetAudit,
  createChannelBudgets,
  updateChannelBudgets,
  fetchChannelBudgetById,
  fetchCampaignsByBudgetId
}
