import { i18n } from '@/i18n'
import { formatDate, scheduleParser } from '@/utils/commons/dateUtils'

// Corresponds to the date that tells a job hasn't run yet.
const NOT_RUN_DATE = '0001-01-01T00:00:00Z'

/**
 * A constant to specify functions that allow
 * to create the actions that can be used in jobs.
 */
var action = {
  VIEW: () => {
    return {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('view'),
      active: false
    }
  }
}

/**
 * Specifies the column names for the job list view.
 * The values are obtained from the getter.js and responseMapper.js, as well as the internationalization18
 * (i18) values.
 * Essentially, maps a job object to the object that will be displayed
 * on the list view.
 * @param {*} job The job that will be converted to a ListView DTO.
 */

function jobListMap(job) {
  let scheduleData = scheduleParser(job.schedule)

  let ret = {
    name: job.name,
    lastRunAt:
      job.lastRunAt !== NOT_RUN_DATE
        ? formatDate(job.lastRunAt)
        : i18n.t('jobs.values.notRunYet'),
    nextRunAt: formatDate(job.nextRunAt),
    repetition: scheduleData.repetition,
    interval: scheduleData.duration
  }

  return ret
}

/**
 * Returns the jobs list, with the
 * format ready to be rendered in the DataTable.vue component (ListDTO format).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */

function jobsListV1DTO(state, getters, rootState, rootGetters) {
  if (!state.jobsV1) return []

  return state.jobsV1.map(job => {
    let data = jobListMap(job)
    return {
      ...data,
      actions: actionsOnJob(state, getters, rootState, rootGetters)(job)
    }
  })
}

/**
 The actions that the user can perform on the jobs list as a whole (i.e. on the group).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnJobList(state, getters, rootState, rootGetters) {
  var actions = []

  return actions
}

/**
 * Responsible for declaring the actions that can be performed on a specific job.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnJob(state, getters, rootState, rootGetters) {
  return function(job) {
    var actions = []

    if (!job) return actions

    actions.push(action.VIEW(job))

    return actions
  }
}

function jobsListDTO(state, getters, rootState, rootGetters) {
  if (!state.jobs) return []

  return state.jobs.map(job => {
    let endCondition = ''
    if (job.max_runs) {
      endCondition =
        job.max_runs === 1 ? `${job.max_runs} time` : `${job.max_runs} times`
    }
    if (job.end_date) {
      endCondition = job.end_date
    }
    if (job.recurring && endCondition.trim() === '') {
      endCondition = i18n.t('jobs.values.never')
    }
    return {
      id: job.id,
      name: job.name,
      status: {
        keyword: job.status,
        label: i18n.t(`jobs.status.${job.status}`),
        type: 'JChip'
      },
      recurring: job.recurring,
      type: mapIntervalType(job),
      next_execution: job.next_execution,
      last_execution: job.last_execution,
      run_count:
        job.run_count === 1
          ? `${job.run_count} time`
          : `${job.run_count} times`,
      end_condition: endCondition,
      start_date: job.start_date
    }
  })
}
function mapIntervalType(job) {
  if (job.recurring) {
    return {
      keyword: i18n.t(`jobs.type.recurring`),
      label: i18n.t(`jobs.type.recurring`),
      type: 'JChip'
    }
  } else {
    return {
      keyword: i18n.t(`jobs.type.one_time`),
      label: i18n.t(`jobs.type.one_time`),
      type: 'JChip'
    }
  }
}
export default {
  jobsListV1DTO,
  actionsOnJobList,
  actionsOnJob,
  jobsListDTO
}
