/**
 * Caching system that has the generic flow
 * for caching purposes. Currently, only supports infinite time caching
 * (i.e. only fetches data once).
 * @param {*} useCache Whether to use the cache system. Setting this
 * variable to false will disable caching.
 */
class CacheSystem {
  constructor(useCache = true) {
    this._getCache = undefined
    this._setCache = undefined
    this._onSetCacheError = undefined
    this.useCache = useCache
  }

  /**
   * Provides the implementation for getting the cache's value.
   * Must be implemented and must return a value or undefined if
   * the value is not yet cached.
   */
  implementGetCacheFunction(func) {
    if (typeof func !== 'function') {
      throw new TypeError('GetCacheFuction must be a function')
    }
    this._getCache = func
  }
  /**
   * Provides the implementation for setting the cache's value.
   * Must be implemented and must return the value that was set.
   * This function is encapsulated by a 'try'
   */
  implementSetCacheFunction(func) {
    if (typeof func !== 'function') {
      throw new TypeError('SetCacheFuction must be a function')
    }
    this._setCache = func
  }
  /**
   * Provides the implementation that must be executed in case
   * an exception occurs during 'setCache'.
   * If no implementation is provided, the error is thrown.
   */
  implementOnSetCacheErrorFunction(func) {
    if (typeof func !== 'function') {
      throw new TypeError('OnSetCacheErrorFunction must be a function')
    }
    this._onSetCacheError = func
  }
  /**
   * Executes the caching strategy specified by this system,
   * using the implementations provided.
   * Will either return undefined or the value (regardless of being
   * cached or new).
   */
  async execute() {
    if (!(this._getCache && this._setCache)) {
      throw new Error(
        'In order to cache data, getCache and setCache must be implemented. Please provide an implementation.'
      )
    }
    let result
    let cachedValue = await this._getCache()

    if (this.useCache && cachedValue !== undefined) {
      result = cachedValue
    } else {
      try {
        result = await this._setCache()
      } catch (error) {
        if (this._onSetCacheError) {
          await this._onSetCacheError(error)
        } else {
          throw error
        }
      }
    }
    return result
  }
}

export default CacheSystem
