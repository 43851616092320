import { loadView } from './utils'

const USERS_MODULE = 'users'
const PERMISSION_READ_LIST = 'read_list_users'
const PERMISSION_READ = 'read_users'
const PERMISSION_UPDATE = 'update_users'
const PERMISSION_CREATE = 'create_users'

export default [
  {
    path: '/users',
    component: loadView('ViewUser', 'users'),
    children: [
      {
        path: '',
        name: 'users',
        component: loadView('ViewUserList', 'users'),
        meta: {
          navigationDrawer: true,
          module: USERS_MODULE,
          role: PERMISSION_READ_LIST
        }
      },
      {
        path: 'new',
        name: 'user-add',
        component: loadView('ViewUserAdd', 'users'),
        meta: {
          module: USERS_MODULE,
          role: PERMISSION_CREATE
        }
      },
      {
        path: ':userId',
        name: 'user-view',
        component: loadView('ViewUserView', 'users'),
        meta: {
          module: USERS_MODULE,
          role: PERMISSION_READ
        }
      },
      {
        path: ':userId/edit',
        name: 'user-edit',
        component: loadView('ViewUserEdit', 'users'),
        meta: {
          module: USERS_MODULE,
          role: PERMISSION_UPDATE
        }
      }
    ]
  }
]
