/**
 * Signals Vuex that the jobs state has changed.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The new jobs content.
 */
function JOBS_V1_UPDATED(state, { jobs, finalDataSize }) {
  state.jobsV1 = jobs
  state.finalDataSizeV1 = finalDataSize
}

/**
 * Signals Vuex that the jobs state has changed.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The new jobs content.
 */
function JOBS_UPDATED(state, { jobs, finalDataSize }) {
  state.jobs = jobs
  state.finalDataSize = finalDataSize
}

export default {
  JOBS_UPDATED,
  JOBS_V1_UPDATED
}
