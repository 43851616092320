import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'

/**
 * A constant to specify functions that allow
 * to create the actions that can be used in code snippets.
 */
var action = {
  CREATE: () => {
    return {
      event: 'create',
      label: i18n.t('code_snippets.actions.create'),
      icon: 'add',
      isPrimary: true,
      options: [
        {
          keyword: 'template',
          color: 'blue',
          icon: 'mdi-file-code-outline',
          label: i18n.t('code_snippets.title.template')
        },
        {
          keyword: 'email_block',
          color: 'red',
          icon: 'mdi-code-tags',
          label: i18n.t('code_snippets.title.emailBlock')
        }
      ]
    }
  },
  DELETE: codeSnippet => {
    return {
      event: 'delete',
      icon: 'delete',
      label: i18n.t('code_snippets.actions.delete'),
      confirmationRequired: true,
      confirmationTitleKey: i18n.t('code_snippets.delete_confirmation.title'),
      confirmationDescriptionKey: i18n.t(
        'code_snippets.delete_confirmation.description',
        codeSnippet
      ),
      active: true
    }
  },
  EDIT: () => {
    return {
      event: 'edit',
      icon: 'edit',
      label: i18n.t('code_snippets.actions.edit'),
      active: true // MUST DEFINE POLICY FOR EDIT BUTTON "ENABLE/DISABLE", IF ANY
    }
  },
  VIEW: () => {
    return {
      event: 'edit',
      icon: 'visibility',
      label: i18n.t('code_snippets.actions.view'),
      active: true // MUST DEFINE POLICY FOR EDIT BUTTON "ENABLE/DISABLE", IF ANY
    }
  },
  CLONE: () => {
    return {
      event: 'clone',
      icon: 'file_copy',
      label: i18n.t('code_snippets.actions.clone')
    }
  },
  AUDIT: () => {
    return {
      event: 'audit',
      icon: 'mdi-update',
      label: i18n.t('code_snippets.actions.audit')
    }
  }
}

/**
 * Specifies the column names for the code snippets list view.
 * The values are obtained from the getter.js and responseMapper.js, as well as the internationalization18
 * (i18) values.
 * Essentially, maps a code snippet object to the object that will be displayed
 * on the list view.
 * @param {*} codeSnippet The code snippet that will be converted to a ListView DTO.
 */
function codeSnippetToListDTO(codeSnippet) {
  let ret = {
    id: codeSnippet.id,
    name: codeSnippet.name,
    code: '%%SNIPPET.' + codeSnippet.code + '%%',
    type: codeSnippet.codeSnippetTypeName,
    updated_at: formatDate(codeSnippet.updatedAt),
    updated_by: codeSnippet.updatedBy
  }

  return ret
}

/**
 * Returns the code snippets list, with the
 * format ready to be rendered in the JList.vue component (ListDTO format).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function codeSnippetsListDTO(state, getters, rootState, rootGetters) {
  if (!state.codeSnippets) return []
  return state.codeSnippets.map(codeSnippet => {
    let data = codeSnippetToListDTO(codeSnippet)
    return {
      ...data,
      actions: actionsOnCodeSnippets(
        state,
        getters,
        rootState,
        rootGetters
      )(codeSnippet)
    }
  })
}
/**
 The actions that the user can perform on the code snippets list as a whole (i.e. on the group).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnCodeSnippetList(state, getters, rootState, rootGetters) {
  var actions = []

  if (rootGetters['user/canCreateCodeSnippet']) {
    actions.push(action.CREATE())
  }

  return actions
}

/**
 * Responsible for declaring the actions that can be performed on a specific code snippet.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnCodeSnippets(state, getters, rootState, rootGetters) {
  return function(codeSnippet) {
    if (!codeSnippet) return []

    return filterActionsListByPermission(rootGetters, codeSnippet.createdBy, [
      {
        actionGroup: [
          {
            action: action.EDIT(codeSnippet),
            permission: 'user/canEditCodeSnippet'
          },
          {
            action: action.VIEW(codeSnippet),
            permission: 'user/canSeeCodeSnippet'
          }
        ]
      },
      {
        label: i18n.t('code_snippets.actions.more'),
        icon: 'more_vert',
        menu: {
          list: [
            {
              action: action.CLONE(codeSnippet),
              permission: 'user/canCloneCodeSnippet'
            },
            {
              action: action.DELETE(codeSnippet),
              permission: 'user/canDeleteCodeSnippet'
            },
            {
              action: action.AUDIT(codeSnippet),
              permission: 'user/canSeeCodeSnippet'
            }
          ],
          date: {
            display: false
          }
        }
      }
    ])
  }
}

export default {
  codeSnippetsListDTO,
  actionsOnCodeSnippetList,
  actionsOnCodeSnippets
}
