/**
 * Signals Vuex that the targets have changed.
 * @param {vuexState} state
 * @param {*} targets The new targets..
 */
function TARGETS_UPDATED(state, targets) {
  state.targets = targets
}

export default {
  TARGETS_UPDATED
}
