import { apiGetLandingPageTypes } from '@/api/landing-page-types'
import CacheSystem from '@/utils/cacheSystem'

/**
 * Fetches the landing page types from the API, firing
 * any necessary logic associated (such as caching).
 * @param {*} context Vuex context (auto-injected).
 * @param {*} payload The payload to send to the API.
 * @param {*} useCache Whether to use the cache (default is true).
 */
async function fetchLandingPageTypes(context, payload, useCache = true) {
  let cacheSystem = new CacheSystem(useCache)
  cacheSystem.implementGetCacheFunction(() => {
    let result
    if (context.state.landingPageTypes !== undefined) {
      result = context.state.landingPageTypes
    }
    return result
  })
  cacheSystem.implementSetCacheFunction(async () => {
    let response = await apiGetLandingPageTypes(payload)
    let result = response.landingPageTypes
    context.commit('LANDING_PAGE_TYPES_CACHED', response.landingPageTypes)
    return result
  })
  cacheSystem.implementOnSetCacheErrorFunction(() => {
    //
  })
  return cacheSystem.execute()
}

export default {
  fetchLandingPageTypes
}
