import { apiGetDataSuggestions } from '@/api/data-suggestions'
import CacheSystem from '@/utils/cacheSystem'

/**
 * Fetches the data suggestions from the API, firing
 * any necessary logic associated (such as caching).
 * @param {*} context Vuex context (auto-injected).
 * @param {*} payload The payload to send to the API.
 * @param {*} useCache Whether to use the cache (default is true).
 */
async function fetchDataSuggestions(context, payload, useCache = true) {
  let cacheSystem = new CacheSystem(useCache)
  let field = payload && payload.field ? payload.field : undefined
  cacheSystem.implementGetCacheFunction(() => {
    let result
    if (field) {
      if (context.state.suggestions[field] !== undefined) {
        result = { suggestions: context.state.suggestions[field] }
      }
    }
    return result
  })
  cacheSystem.implementSetCacheFunction(async () => {
    let response = await apiGetDataSuggestions(payload)
    let result = response
    context.commit('DATA_SUGGESTIONS_FIELD_CACHED', {
      field,
      dataSuggestions: response.suggestions
    })
    return result
  })
  cacheSystem.implementOnSetCacheErrorFunction(() => {
    //
  })
  return cacheSystem.execute()
}

export default {
  fetchDataSuggestions
}
