import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const MODULE = 'contactlookup'

export default [
  {
    path: `/${MODULE}`,
    component: loadView('ViewContactLookup', MODULE),
    children: [
      {
        path: '',
        name: MODULE,
        component: loadView('ContactLookupForm', MODULE),
        meta: {
          navigationDrawer: true,
          module: MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_ADMIN
        }
      }
    ]
  }
]
