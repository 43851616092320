import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'

const EMAIL_TEMPLATES_MODULE = 'emailtemplates'

export default [
  {
    path: '/emailtemplates',
    component: loadView('ViewEmailTemplates', EMAIL_TEMPLATES_MODULE),
    children: [
      {
        path: '',
        name: 'emailtemplates',
        component: loadView('ViewEmailTemplatesList', EMAIL_TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: EMAIL_TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_ADMIN
        }
      },
      {
        path: ':templateId',
        name: 'emailtemplates-view',
        component: loadView('ViewEmailTemplatesView', EMAIL_TEMPLATES_MODULE),
        meta: {
          navigationDrawer: true,
          module: EMAIL_TEMPLATES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_ADMIN,
          whiteBackground: true,
          savePrevRoute: true
        }
      }
    ]
  }
]
