/**
 * Constants for status types
 */
export const STATUS_TYPES = {
  COMPLETE: 'complete',
  ARCHIVED: 'archived',
  APPROVED_SCHEDULED: 'approved_scheduled',
  DRAFT: 'draft',
  FINAL_NOT_APPROVED: 'final_not_approved'
}

export const STATE_COLORS = {
  APPROVED_SCHEDULED: '#20921E',
  DRAFT: '#8e8e93',
  FINAL_NOT_APPROVED: '#e39e49',
  ERROR: '#E36049',
  COMPLETE: '#379EF4',
  ARCHIVED: '#5497A3'
}
