<template>
  <v-app>
    <AppNavigationDrawer
      v-if="showNavigationDrawer"
      app-logo="Logo.svg"
      :app-name="$t('app_name')"
      :options="AVAILABLE_PAGES_ON_MENU"
    />

    <v-main v-if="showContent" :style="vContentStyles">
      <AppSnackbar class="snackbar-notifications" />
      <StatusLoading v-if="LOADING_PAGE" :text="$t('loading_page')" />
      <router-view v-else></router-view>
    </v-main>

    <AppBottomNavigation
      v-if="showBottomNavigation"
      :list="AVAILABLE_PAGES_ON_MENU"
    />

    <AppAddToHomeScreen />

    <v-snackbar
      v-model="newVersion"
      class="slide-fade-exit-active"
      right
      color="primary"
      :timeout="timeout"
      vertical
    >
      <v-progress-linear
        color="white"
        :value="remainingTime"
        class="version-progress-bar"
      />
      <span>
        <p class="mb-n4 mt-1">
          {{ $t('version_updated') }}
          <v-btn small text @click="newVersion = false">
            {{ $t('close') }}
          </v-btn>
        </p>
      </span>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppAddToHomeScreen from '@/components/AppAddToHomeScreen/AppAddToHomeScreen'
import StatusLoading from '@/components/StatusLoading/StatusLoading'
import { hasToken } from '@/utils/auth'
export default {
  name: 'App',
  components: {
    StatusLoading,
    AppAddToHomeScreen,
    AppSnackbar: () =>
      import(
        /* webpackChunkName: "AppSnackbar" */ `@/components/AppSnackbar/AppSnackbar`
      ),
    AppNavigationDrawer: () =>
      import(
        /* webpackChunkName: "AppNavigationDrawer" */ `@/components/AppNavigationDrawer/AppNavigationDrawer`
      ),
    AppBottomNavigation: () =>
      import(
        /* webpackChunkName: "AppBottomNavigation" */ `@/components/AppBottomNavigation/AppBottomNavigation`
      )
  },
  data() {
    return {
      logo: null,
      newVersion: false,
      timeout: 3000, // milliseconds
      remainingTime: 0,
      counter: 1 // var not used, can be removed, was defined to force build to production
    }
  },
  computed: {
    ...mapState('app', {
      LOADING_PAGE: state => state.loadingPage
    }),
    ...mapGetters({
      AVAILABLE_PAGES_ON_MENU: 'app/availablePagesOnMenu',
      UPDATED_APP: 'app/getUpdatedApp'
    }),
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    isMenuHash() {
      return this.$route.hash === '#menu'
    },
    showWhiteBackground() {
      return this.$route.meta.whiteBackground
    },
    vContentStyles() {
      if (this.$route.name !== 'homepage') {
        return {
          'background-color':
            !this.$route.meta.navigationDrawer ||
            (this.isMenuHash && !this.isDesktop) ||
            this.showWhiteBackground
              ? 'white'
              : 'inherit'
        }
      }
      return {
        'background-color': '#eeeeee'
      }
    },
    showNavigationDrawer() {
      return (
        (this.$route.meta.navigationDrawer && this.isDesktop) ||
        (this.isMenuHash && !this.isDesktop)
      )
    },
    showContent() {
      return this.isDesktop || (!this.isMenuHash && !this.isDesktop)
    },
    showBottomNavigation() {
      return !this.isDesktop && this.$route.meta.navigationDrawer
    }
  },
  created() {
    if (this.UPDATED_APP) {
      this.newVersion = true
      this.fireBarAnimation()
      this.$store.dispatch('app/updatedAppEventConsumed')
    }
    window.addEventListener('new-service-worker', e => {
      this.$store.dispatch('app/updateToNewVersion')
      // The below code triggers the snackbar notification.
      // However, it will only work when vuexPersistedState is implemented.
      /* this.newVersion = true
      this.fireBarAnimation() */
    })
    this.logo = require('@/assets/logo_group.svg')
    if (hasToken()) {
      // If the user has a token, then it is logged in
      // Can be changed in the future to also account for unauthenticated users.
      this.$store.dispatch('app/updateAvailableMenu')
      this.$store.dispatch('app/loadSelectedCountry')
      this.$store.dispatch('user/loadProfile')
    }
  },
  methods: {
    /**
     * Fires the interval responsible for the animation of the progress bar
     */
    fireBarAnimation() {
      this.remainingTime = 0.0 // 100%
      let delay = 32
      let interval = setInterval(() => {
        this.$nextTick(() => {
          this.remainingTime += (delay / this.timeout) * 110.0
          if (this.remainingTime <= 0) {
            clearInterval(interval)
          }
        })
      }, delay)
    }
  }
}
</script>
<style>
.theme--light.v-application {
  background-color: #f2f2f2 !important;
}

.version-progress-bar {
  transition: none !important;
}

.slide-fade-exit-active {
  transition: all 1s ease;
}

.slide-fade-exit-active .v-snack__content {
  margin-right: 0;
}
</style>
