import {
  apiGetLogicGroups,
  apiCreateLogicGroup,
  apiPatchStatus,
  apiGetLogicGroupAudit
} from '@/api/logicgroups'

/**
 * Fetches the logic groups from the API.
 * @param {*} commit
 * @param {*} payload
 */
async function fetchLogicGroups({ commit }, request) {
  let commitPayload = {
    logicGroups: [],
    finalDataSize: 0
  }

  let response = await apiGetLogicGroups(request)
  commitPayload = {
    logicGroups: response.logicGroups,
    finalDataSize: response.finalDataSize
  }

  commit('LOGIC_GROUPS_UPDATED', commitPayload)
  return commitPayload
}

async function fetchAudit(_, request) {
  const res = await apiGetLogicGroupAudit(request)
  return res
}

async function patchStatus(_, item) {
  await apiPatchStatus(item)
}

async function createLogicGroup(_, logicGroups) {
  await apiCreateLogicGroup(logicGroups)
}

export default {
  fetchLogicGroups,
  fetchAudit,
  patchStatus,
  createLogicGroup
}
