import { butterflyAxios } from '@/api/axios'
import {
  isCreatedResponse,
  isOKResponse,
  parseSuccessResponse
} from '@/api/utils/response'

import {
  MapCreate,
  MapPreview,
  MapUpdate
} from '@/api/push-notification/requestMapper'
import { MapPreviewResponse } from '@/api/push-notification/responseMapper'

const endpoint = {
  pushnotifications: 'pushnotifications',
  pushNotification: id => `pushnotifications/${id}`,
  preview: 'pushnotifications/preview'
}

const apiCreate = async pushNotification => {
  const pn = MapCreate(pushNotification)
  const response = await butterflyAxios.post(endpoint.pushnotifications, pn)
  if (isCreatedResponse(response)) {
    return parseSuccessResponse(response)
  }
  return undefined
}

const apiUpdate = async pushNotification => {
  const pn = MapUpdate(pushNotification)
  const response = await butterflyAxios.put(
    endpoint.pushNotification(pushNotification.id),
    pn
  )
  if (isOKResponse(response)) {
    return parseSuccessResponse(response)
  }
  return response
}

const apiPreview = async payload => {
  const reqPayload = MapPreview(payload)
  const res = await butterflyAxios.post(endpoint.preview, reqPayload)
  if (isOKResponse(res)) {
    let { response } = parseSuccessResponse(res)
    return MapPreviewResponse(response)
  }
  throw res
}

export { apiCreate, apiUpdate, apiPreview }
