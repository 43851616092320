/**
 * Converts a JSON tab object from the API format to the format expected by the FE.
 * @param {JSON} tab The tab JSON object to convert from.
 */
function tabMap(tab) {
  let {
    id,
    name,
    is_default: isDefault,
    start_date: startDate,
    end_date: endDate
  } = tab

  return {
    id,
    name,
    isDefault,
    startDate,
    endDate
  }
}

/**
 * Maps an array of tabs to another array of tabs in the expected FE format.
 * @param {Array} list The list of tabs in BE format.
 */
function tabListMap(list) {
  let tabs = []

  for (let item of list) {
    tabs.push(tabMap(item))
  }

  // Sort the list to put default value first
  tabs.sort(function(t1, t2) {
    return t2.isDefault - t1.isDefault
  })

  return tabs
}

export { tabMap, tabListMap }
