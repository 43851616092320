import {
  apiGetCodeSnippetTypes,
  apiGetCodeSnippetNames
} from '@/api/code-snippet-types'
import CacheSystem from '@/utils/cacheSystem'

/**
 * Fetches the code snippet types from the BE database and
 * updates the vuex state accordingly.
 * @param {*} param0 Vuex state (auto-injected).
 */
async function fetchCodeSnippetTypes({ commit }) {
  var response = await apiGetCodeSnippetTypes()
  commit('CODE_SNIPPET_TYPES_UPDATED', response)
}

async function fetchCodeSnippetNames(context, payload, useCache = true) {
  let cacheSystem = new CacheSystem(useCache)

  cacheSystem.implementGetCacheFunction(() => {
    let result
    if (context.state.snippetNames) {
      result = context.state.snippetNames
    }
    return result
  })
  cacheSystem.implementSetCacheFunction(async () => {
    let result = await apiGetCodeSnippetNames()

    context.commit('SNIPPET_NAMES_CACHED', result)

    return result
  })
  cacheSystem.implementOnSetCacheErrorFunction(() => {
    //
  })
  return cacheSystem.execute()
}

export default {
  fetchCodeSnippetTypes,
  fetchCodeSnippetNames
}
