/**
 * Signals Vuex that the tags have changed.
 * @param {vuexState} state
 * @param {*} tags The new tags.
 */
function TAGS_UPDATED(state, tags) {
  state.tags = tags
}

export default {
  TAGS_UPDATED
}
