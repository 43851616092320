import { milkyWayAxios } from '@/api/axios'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'
import { tagListMap } from '@/api/instance-tags/responseMapper'

const endpoints = {
  tags: `tags`,
  tagKey: key => `tags/${key}`
}

async function apiGetTags(request) {
  const key = request && request.key ? request.key : null
  let payload = {}

  if (key !== null) {
    payload.key = key
  }

  let response = await milkyWayAxios.get(endpoints.tags, {
    params: { ...payload }
  })

  if (isOKResponse(response)) {
    const data = parseSuccessResponse(response)
    // Convert from JSON array to the expected mapping
    return tagListMap(data.response)
  }
  throw response
}

async function apiGetTagByKey(key) {
  if (!key) return undefined

  let response = await milkyWayAxios.get(endpoints.tagKey(key))
  if (isOKResponse(response)) {
    const data = parseSuccessResponse(response)
    // Convert from JSON array to the expected mapping
    return data.response
  }
  throw response
}

export { apiGetTags, apiGetTagByKey }
