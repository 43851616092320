function getTags(state) {
  if (state.tags) {
    return state.tags
  }
  return []
}

export default {
  getTags
}
