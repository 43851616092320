import { formatDate } from '@/utils/commons/dateUtils'

const templateViewMap = template => {
  return {
    id: template.id,
    name: template.name,
    lastUsed: formatDate(template.last_used_at),
    updatedAt: formatDate(template.updated_at),
    domain: template.domain,
    versions: template.versions.map(v => ({
      content: v.content,
      languageCode: v.language_code,
      updatedAt: formatDate(v.updated_at),
      languageId: v.language_id
    }))
  }
}

export { templateViewMap }
