import { formatDate } from '@/utils/commons/dateUtils'
import { codeSnippetMap } from '@/api/code-snippets/responseMapper'
import { maxSkus, maxSponsoredSkus } from '@/api/message-schedules/constants'
import { i18n } from '@/i18n'

function messageScheduleListMap(list) {
  let result = []
  for (let item of list) {
    result.push(scheduleListItem(item))
  }
  return result
}

function scheduleListItem(messageSchedule) {
  let {
    id,
    message_id: messageId,
    code_snippet_id: codeSnippetId,
    dispatch_at: dispatchAt,
    status,
    valid,
    templates,
    skus,
    sponsored_skus: sponsoredSkus,
    sku_position: skuPosition,
    code_snippet: codeSnippet,
    created_at: createdAt,
    created_by: createdBy,
    updated_at: updatedAt,
    updated_by: updatedBy
  } = messageSchedule

  dispatchAt = formatDate(dispatchAt, false)

  return {
    id,
    messageId,
    codeSnippetId,
    dispatchAt,
    status,
    valid,
    templates: parseTemplates(templates),
    skus,
    sponsoredSkus,
    skuPosition,
    codeSnippet,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy
  }
}

function parseSkus(list, hasTrack, size) {
  let skus = list.map((sku, index) => {
    let s = {
      key: index,
      value: sku.value
    }

    if (hasTrack) {
      s['track'] = sku.track
    }

    return s
  })

  for (let i = list.length; i < size; i++) {
    // fills remaining if missing
    skus.push({
      key: i,
      value: '',
      track: hasTrack ? '' : null
    })
  }

  return skus
}

function parseTemplates(list) {
  return list.map(t => {
    let promoTab =
      t.promo_description !== '' ||
      t.promo_card !== '' ||
      t.discount_code !== '' ||
      t.availability_ends !== ''

    let banners = t.banners.map(b => ({
      ...b,
      error: { image: null, pageType: null }
    }))

    return {
      languageID: t.language_id,
      abTestTypeId: t.ab_test_type_id,
      abTestDetails: !t.ab_test_details ? '' : t.ab_test_details,
      from: t.from,
      subject: t.subject,
      headerGif: t.header_gif,
      headerLandingPage: t.header_landing_page,
      preHeader: t.pre_header,
      utmContent: t.utm_content,
      abTest: t.ab_test,
      recoPosition: t.reco_position,
      banners: banners,
      promoTab: promoTab,
      promoDescription: t.promo_description,
      promoCard: t.promo_card,
      discountCode: t.discount_code,
      availabilityEnds: t.availability_ends
    }
  })
}

function scheduleMap(obj) {
  return {
    ID: obj.id,
    messageID: obj.message_id,
    schedule: {
      status: obj.status,
      date: formatDate(obj.dispatch_at, false),
      codeSnippet: codeSnippetMap(obj.code_snippet),
      skuPosition: obj.sku_position,
      skus: parseSkus(obj.skus, false, maxSkus),
      sponsoredSkus: parseSkus(obj.sponsored_skus, true, maxSponsoredSkus),
      templates: parseTemplates(obj.templates),
      ignoreSyntaxErrors: false,
      createdAt: obj.created_at,
      createdBy: obj.created_by,
      updatedAt: obj.updated_at,
      updatedBy: obj.updated_by
    }
  }
}

function mapDates(dates) {
  return dates.map(d => formatDate(d, false))
}

function mapAudit(list) {
  return list.map(a => {
    return {
      action:
        a.action === 'INSERT'
          ? i18n.t('instances.audit.actions.create')
          : i18n.t('instances.audit.actions.update'),
      updated_by: a.updated_by,
      updated_at: formatDate(a.updated_at)
    }
  })
}

export { messageScheduleListMap, scheduleMap, mapDates, mapAudit }
