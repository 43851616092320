import { milkyWayAxios, sombreroAxios } from '@/api/axios'
import {
  instanceListMap,
  instanceViewMap,
  mapAudit,
  mapInstanceHistory,
  mapInstanceSize
} from './responseMapper'
import { instanceMapToApi, instanceMapToApiUpdate } from './requestMapper'
import store from '@/store'
import { viewTypes } from '@/utils/viewTypes'
import {
  isOKResponse,
  parseSuccessResponse,
  isAcceptedResponse
} from '@/api/utils/response'

/**
 * Endpoints for instances.
 */
const endpoints = {
  instances: `instances`,
  instance: instanceId => `instances/${instanceId}`,
  syncInstance: instanceId => `instances/${instanceId}/sync`,
  query: instanceId => `instances/${instanceId}/query`,
  download: 'instances/export-csv',
  calculate: 'instances/statistic-results',
  instanceHistory: id => `staticlistsync/${id}/logs`,
  instanceSize: instanceId => `/instances/${instanceId}/size`,
  audit: instanceId => `/instances/${instanceId}/audit`
}

/**
 * Fetches the instances from the api.
 * @param {*} request
 */
const apiGetInstances = async request => {
  const q = request.q
  const page = request.page
  const perPage = request.perPage
  const active = request.active
  const hasMetadataColumn = request.hasMetadataColumn
  const view = request.view ? request.view : viewTypes.FullView
  const sortBy = request.sort_by ? request.sort_by : null
  const orderBy = request.order_by ? request.order_by : null
  let filters = request.filters ? request.filters : {}

  const payload = { view: view }
  if (typeof active === 'boolean') {
    payload.active = true
  }
  if (hasMetadataColumn) {
    payload.columns = hasMetadataColumn
  }
  if (page > 0) {
    payload.page = page
  }

  payload.per_page = perPage

  if (q !== '' && q != null) {
    payload.query = q
  }
  if (sortBy != null && orderBy != null) {
    payload.sort_by = sortBy
    payload.order_by = orderBy
  }

  const { data } = await milkyWayAxios.get(endpoints.instances, {
    params: { ...payload, ...filters }
  })

  let instances = data.data
  let pagination = data.meta

  let mappedInstances = instanceListMap(instances, view)

  return {
    instances: mappedInstances,
    finalDataSize: pagination
      ? pagination.total_records
      : mappedInstances.length,
    newPage: pagination ? pagination.page : 1,
    originalData: mappedInstances
  }
}

/**
 * Fetches a specific instance.
 * @param instanceId
 * @param view
 */
const apiGetInstance = async (instanceId, view) => {
  const params = {}
  if (view) {
    params['view'] = view
  }
  const response = await milkyWayAxios.get(endpoints.instance(instanceId), {
    params: params
  })

  return instanceViewMap(view, response.data.data)
}
/**
 * Make a manual sync on the API.
 * Returns the response of the API.
 * @param {*} id the instance id
 */
const apiSyncInstanceById = async id => {
  const response = await milkyWayAxios.get(endpoints.syncInstance(id))

  if (isOKResponse(response) || isAcceptedResponse(response)) {
    return response.data
  }

  throw response.error
}
/**
 * Creates an instance on the API.
 * Returns the response of the API.
 * @param {*} instance
 */
const apiCreateInstance = async instance => {
  const response = await milkyWayAxios.post(
    endpoints.instances,
    instanceMapToApi(instance)
  )
  return response.data
}

/**
 * Updates an instance.
 * If successful, return the updated instance.
 * @param {*} instance
 */
const apiUpdateInstance = async instance => {
  const response = await milkyWayAxios.put(
    endpoints.instance(instance.id),
    instanceMapToApiUpdate(instance)
  )

  if (response) {
    return instanceViewMap(viewTypes.FullView, response.data.data)
  }

  return false
}

/**
 * Deletes an instance from the api.
 * Return true if successful and false otherwise.
 * @param {*} id
 */
const apiDeleteInstance = async id => {
  const response = await milkyWayAxios.delete(endpoints.instance(id))

  return !!response // converts to boolean, then converts to original value.
}

/**
 * Requests the download of an instance's data.
 * @param {*} id
 */
const apiDownloadInstance = async id => {
  const response = await milkyWayAxios.post(endpoints.download, {
    id: id,
    user_email: store.getters['user/getUserEmail']
  })
  return response
}

/**
 * Generates the necessary data to display in a graphic.
 * @param {*} dataSourceId
 * @param {*} filters
 */
const apiGenerateGraphic = async (dataSourceId, filters, target) => {
  const response = await milkyWayAxios.post(endpoints.calculate, {
    segment_id: dataSourceId,
    filters: { ...filters },
    target: target
  })
  return response.data
}

/**
 * Get instance query
 *
 * @returns {Promise<{responseType: string, response: *, success: boolean, status: *}>}
 */
const apiGetQuery = async request => {
  let params = {}
  if (request.external) {
    params['external'] = true
  }

  if (request.source) {
    params['source'] = request.source
  }

  const response = await milkyWayAxios.get(
    endpoints.query(request.instanceId),
    {
      params: params
    }
  )

  if (isOKResponse(response)) {
    let result = parseSuccessResponse(response)
    return result.response
  }

  throw response
}

async function apiGetInstanceHistory(id) {
  let response = await sombreroAxios.get(endpoints.instanceHistory(id))
  if (isOKResponse(response)) {
    return mapInstanceHistory(response.data.data)
  }

  throw response.error
}

async function apiGetInstanceSize(instanceId) {
  let response = await milkyWayAxios.get(endpoints.instanceSize(instanceId))
  if (isOKResponse(response)) {
    return mapInstanceSize(response.data.data)
  }

  throw response.error
}

const apiGetAudit = async request => {
  const params = {
    sort_by: request.sortBy,
    order_by: request.orderBy,
    page: request.page,
    per_page: request.perPage
  }
  const response = await milkyWayAxios.get(endpoints.audit(request.id), {
    params: { ...params }
  })
  if (isOKResponse(response)) {
    const { response: list, meta: pagination } = parseSuccessResponse(response)
    return {
      audit: mapAudit(list),
      finalDataSize: pagination ? pagination.total_records : list.length
    }
  } else {
    throw response
  }
}

export {
  apiGetInstances,
  apiGetInstance,
  apiCreateInstance,
  apiUpdateInstance,
  apiDeleteInstance,
  apiDownloadInstance,
  apiGenerateGraphic,
  apiGetQuery,
  apiGetInstanceHistory,
  apiSyncInstanceById,
  apiGetInstanceSize,
  apiGetAudit
}
