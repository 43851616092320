import { butterflyAxios } from '@/api/axios'
import { channelTypeListMap } from './responseMapper'
import { parseSuccessResponse } from '@/api/utils/response'

var endpoint = {
  channelTypes: 'channel-types'
}

async function apiGetChannelTypes() {
  let response = await butterflyAxios.get(endpoint.channelTypes, {
    params: {
      active: true,
      sort_by: 'name',
      order_by: 'asc'
    }
  })

  let data
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    data = processedResponse
  } else {
    throw processedResponse
  }

  // Convert from JSON array to the expected mapping
  let channelTypes = channelTypeListMap(data)
  return channelTypes
}

export { apiGetChannelTypes }
