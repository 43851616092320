function mapCreateSegment(request) {
  return {
    key_name: request.keyName,
    name: request.name,
    source: request.source,
    namespace: request.namespace,
    datasource_mapping: request.datasourceMapping,
    columns_mapping:request.columnsMapping,
    active: request.active
  }
}

function mapUpdateSegment(request) {
  return {
    key_name: request.keyName,
    name: request.name,
    source: request.source,
    namespace: request.namespace,
    datasource_mapping: request.datasourceMapping,
    columns_mapping:request.columnsMapping,
    active: request.active
  }
}

export { mapCreateSegment, mapUpdateSegment }
