import { butterflyAxios } from '@/api/axios'
import { parseSuccessResponse } from '@/api/utils/response'

/**
 * Endpoints for the card types.
 */
const endpoints = {
  verticals: 'verticals'
}

/**
 * Fetches the verticals from the API.
 * @param {*} request The request parameters.
 */
const apiGetVerticals = async request => {
  const name = request.name
  const payload = {}
  if (name !== undefined && name.length) {
    payload.name = name
  }
  const response = await butterflyAxios.get(endpoints.verticals, {
    params: { ...request, ...payload }
  })

  let data
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    data = processedResponse
  } else {
    throw processedResponse
  }

  return {
    verticals: data
  }
}

export { apiGetVerticals }
