import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { securedLS } from '@/store/securedStorage'

const state = {
  instances: null,
  finalDataSize: null,
  originalData: null,
  securedState: securedLS
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
