async function apiGetDocumentation() {
  let result = await fetch(window.CONFIG.HELP_NOTES_URL, {
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Origin: window.location.origin
    }
  })
  let data = await result.text()
  return data
}

export { apiGetDocumentation }
