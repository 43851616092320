/**
 * Constants for channel keywords
 */
export const CHANNEL_KEYWORDS = {
  EMAIL: 'email',
  NEWSLETTER: 'newsletter',
  NEWSLETTER_SCHEDULE: 'schedule',
  NEWS_FEED: 'news_feed',
  GROWTH_TOOL: 'growth_tool',
  PUSH_NOTIFICATION: 'push_notification',
  INBOX: 'inbox_message',
  SMS: 'sms'
}

export const CHANNEL_COLORS = {
  EMAIL: '#2196F3',
  NEWSLETTER: '#F44336',
  NEWSLETTER_SCHEDULE: '#8e8e93',
  NEWS_FEED: '#4CAF50',
  GROWTH_TOOL: '#FF9800',
  PUSH_NOTIFICATION: '#FBC02D',
  INBOX_MESSAGE: '#9C27B0',
  SMS: '#FF5722'
}
