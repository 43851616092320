import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  jobsV1: null,
  jobs: null,
  finalDataSizeV1: null,
  finalDataSize: null
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
