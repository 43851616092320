import { butterflyAxios } from '@/api/axios'
import { countryNamesListMap, currenciesListMap } from './responseMapper'
import { isOKResponse, parseSuccessResponse } from '@/api/utils/response'

/**
 * Endpoints for instances.
 */
const endpoints = {
  countries: `countries`,
  currencies: 'currencies'
}

/**
 * Returns list of countries names and its IDs
 * @returns {Promise<[]>}
 */
const apiGetCountryNames = async () => {
  const { data } = await butterflyAxios.get(endpoints.countries, {
    params: {
      sort_by: 'name',
      order_by: 'asc'
    }
  })
  return countryNamesListMap(data.data)
}

const apiGetCurrencies = async () => {
  let res = await butterflyAxios.get(endpoints.currencies)
  if (isOKResponse(res)) {
    let { response } = parseSuccessResponse(res)
    return currenciesListMap(response)
  }
  return res
}

export { apiGetCountryNames, apiGetCurrencies }
