import Vue from 'vue'
import { i18n } from '@/i18n'
import moment from 'moment/moment'
Vue.use(require('moment'))

const HOUR_ZERO = '00'

function campaignDatetimeToTimestamp(datetimeObj) {
  let date = moment.utc(datetimeObj.date + ' ' + datetimeObj.time)
  return date.toISOString()
}

function fromTimestampToCampaignDatetime(timestamp) {
  let date = moment.utc(timestamp)
  return {
    date: date.format('YYYY-MM-DD'),
    time: date.format('HH:mm')
  }
}

function fromCampaignDatetime(datetimeObj) {
  const time = datetimeObj.time || HOUR_ZERO
  let date = moment(datetimeObj.date + ' ' + time)
  return date
}

function fromMomentToCampaignDatetime(momentDate) {
  return {
    date: momentDate.format('YYYY-MM-DD'),
    time: momentDate.format('HH:mm')
  }
}

/**
 * Checks if two string dates are the same, comparing only until the day
 * @param {string} dateStr1 The first date
 * @param {string} dateStr2 The second date.
 */
function isSameDay(dateStr1, dateStr2) {
  let moment1 = moment(dateStr1, 'YYYY-MM-DD')
  let moment2 = moment(dateStr2, 'YYYY-MM-DD')
  return moment1.isSame(moment2, 'day')
}

/**
 * Formats a moment js duration to the 'repetition' value of a job schedule, as
 * this functionality is not supported in moment.js
 * @param {*} duration The moment js duration object
 */
function formatDuration(duration) {
  const year = duration.get('years')
  const month = duration.get('months')
  const day = duration.get('days')
  const hour = duration.get('hours')
  const minute = duration.get('minutes')
  const second = duration.get('seconds')
  const sumTime =
    Math.abs(year) +
    Math.abs(month) +
    Math.abs(day) +
    Math.abs(hour) +
    Math.abs(minute) +
    Math.abs(second)
  let result
  // If we have any given time anywhere, parse it.
  if (sumTime > 0) {
    result = `${year}y ${month}m ${day}d ${hour}h ${minute}min ${second}s`
  }
  return result
}

/**
 * Parses an ISO 8601 Notation schedule string, as per the standards
 * of the ISO 8601 Notation and those defined in (https://confluence.jumia.com/display/AFRMON/Scheduler+Service).
 * @param {String} scheduleString The string with the schedule to be parsed.
 */
function scheduleParser(scheduleString) {
  var components = scheduleString.split('/')
  var repetition = components[0]
  var nextDate = moment(components[1], moment.ISO_8601).format(
    'YYYY-MM-DD HH[h]mm[m]ss'
  )
  var duration = moment.duration(components[2], moment.ISO_8601)
  var formattedDuration = formatDuration(duration)
  if (formattedDuration) {
    formattedDuration = i18n.t('jobs.values.interval', {
      duration: formattedDuration
    })
  } else {
    formattedDuration = i18n.t('jobs.values.none')
  }

  var repetitionFormatted = i18n.t('jobs.values.repeatsForever')
  var repetitionRegex = /R([0-9]*)/gi
  var match = repetitionRegex.exec(repetition)
  if (match[1]) {
    // If any matches were found
    var value = parseInt(match[1])
    repetitionFormatted = i18n.t('jobs.values.repeatsNTimes', {
      times: match[1].toString()
    })
    // On the special case of one, do appropriate label.
    if (value === 1) {
      repetitionFormatted = i18n.t('jobs.values.repeatsOnce')
    }
  }
  var result = {
    repetition: repetitionFormatted,
    duration: formattedDuration,
    nextDate: nextDate
  }
  return result
}

/**
 * Computes the difference between Now and the target date.
 * Returns a duration.
 * @param {string} timestampDate The utc date (timestamp)
 */
function dateDiffNow(timestampDate) {
  return moment.duration(moment().diff(moment(timestampDate)))
}

/**
 * Formats the date to a string format.
 * @param {timestampString} utcDate
 * @param {boolean} showTime Whether to show the "time" part or not.
 */
function formatDate(utcDate, showTime = true) {
  let momentDate = moment(utcDate, moment.ISO_8601)
  if (showTime) {
    return momentDate.format('YYYY-MM-DD HH:mm')
  } else {
    return momentDate.format('YYYY-MM-DD')
  }
}

/**
 * Formats the date to a utc string format.
 * @param {timestampString} utcDate
 * @param {boolean} showTime Whether to show the "time" part or not.
 */
function formatUtcDate(utcDate, showTime = true) {
  let momentDate = moment(utcDate, moment.ISO_8601)
  if (showTime) {
    return momentDate.utc().format('YYYY-MM-DD HH:mm')
  } else {
    return momentDate.utc().format('YYYY-MM-DD')
  }
}

export {
  campaignDatetimeToTimestamp,
  fromTimestampToCampaignDatetime,
  fromCampaignDatetime,
  fromMomentToCampaignDatetime,
  isSameDay,
  scheduleParser,
  formatDate,
  formatUtcDate,
  dateDiffNow
}
