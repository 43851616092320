import { butterflyAxios } from '@/api/axios'
import { parseSuccessResponse } from '@/api/utils/response'

/**
 * Endpoints for the card types.
 */
const endpoints = {
  cardTypes: 'newsfeed/card-types'
}

/**
 * Fetches the card types from the API.
 * @param {*} request The request parameters.
 */
const apiGetCardTypes = async request => {
  const response = await butterflyAxios.get(endpoints.cardTypes, {
    params: request
  })

  let data
  let result = parseSuccessResponse(response)
  let processedResponse = result.response
  if (result.success) {
    data = processedResponse
  } else {
    throw processedResponse
  }

  return {
    cardTypes: data
  }
}

export { apiGetCardTypes }
