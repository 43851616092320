import { butterflyAxios } from '@/api/axios'
import { parseSuccessResponse } from '../utils/response'
import { mapCardClassifications } from '@/api/card-classifications/responseMapper'

const endpoints = {
  cardClassifications: 'newsfeed/card-classifications'
}

const apiGetCardClassifications = async request => {
  const response = await butterflyAxios.get(endpoints.cardClassifications, {
    params: request
  })

  let data
  const result = parseSuccessResponse(response)
  const processedResponse = result.response
  if (result.success) {
    data = mapCardClassifications(processedResponse)
  } else {
    throw processedResponse
  }

  return {
    cardClassifications: data
  }
}

export { apiGetCardClassifications }
