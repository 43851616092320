import { apiGetTriggeredEvents } from '@/api/triggered-events'
import { EVENTS_TYPE } from '@/constants/events'

async function fetchTriggeredEvents({ commit }, request) {
  let commitPayload = {
    triggeredEvents: [],
    finalDataSize: 0
  }

  try {
    const response = await apiGetTriggeredEvents(request)
    commitPayload = {
      triggeredEvents: response.triggeredEvents,
      finalDataSize: response.finalDataSize
    }
  } catch (error) {
    console.debug(error)
  }
  if (request.type === EVENTS_TYPE.CAMPAIGNS_DISPATCH) {
    commit('CAMPAIGNS_EVENTS_UPDATED', {
      campaingsEvents: commitPayload.triggeredEvents,
      finalCampaignsDataSize: commitPayload.finalDataSize
    })
  } else {
    commit('SEGMENTS_EVENTS_UPDATED', {
      segmentsEvents: commitPayload.triggeredEvents,
      finalSegmentsDataSize: commitPayload.finalDataSize
    })
  }
}

export default {
  fetchTriggeredEvents
}
