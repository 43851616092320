import { loadView } from './utils'

const PERMISSION_READ = 'can_access'

export default [
  {
    path: '/404',
    name: '404',
    component: loadView('ViewPage404', 'app'),
    meta: {
      navigationDrawer: true,
      module: 'app',
      role: PERMISSION_READ
    }
  }
]
