/**
 * Signals Vuex that the media list has changed.
 * @param {vuexState} state
 * @param {*} param1 The media, finalDataSize and originalData to mutate.
 */
function MEDIA_UPDATED(state, { media, finalDataSize, originalData }) {
  state.media = media
  state.finalDataSize = finalDataSize
  state.originalData = originalData
}

export default {
  MEDIA_UPDATED
}
