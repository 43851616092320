import { apiGetLanguages } from '@/api/languages'
import CacheSystem from '@/utils/cacheSystem'

/**
 * Fetches the languages from the API, firing
 * any necessary logic associated (such as caching).
 * Returns the cached data, which can be used if needed.
 * Should be kept in mind that this action still
 * performs the adequate mutations to Vuex's state for ...mapState use,
 * for example.
 * @param {*} context Vuex context.
 * @param {*} useCache Whether to use cache or not (default true).
 */
async function fetchLanguages(context, useCache = true) {
  let cacheSystem = new CacheSystem(useCache)
  cacheSystem.implementGetCacheFunction(() => {
    let result
    if (context.state.languages) {
      result = context.state.languages
    }
    return result
  })
  cacheSystem.implementSetCacheFunction(async () => {
    let response = await apiGetLanguages()
    let result = response.languages
    context.commit('LANGUAGES_UPDATED', {
      languages: response.languages
    })
    return result
  })
  cacheSystem.implementOnSetCacheErrorFunction(() => {
    //
  })
  return cacheSystem.execute()
}

export default {
  fetchLanguages
}
