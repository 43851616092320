import actions from './actions'
import mutations from './mutations'
import getters from './getters'

var state = {
  emailTemplates: null,
  finalDataSize: null,
  originalData: null
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
