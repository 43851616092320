/**
 * Signal's Vuex state that the statistics have been updated.
 * @param {*} state Vuex state (auto-injected).
 * @param {*} param1 The new statistics content.
 */
function STATISTICS_UPDATED(state, { stats }) {
  state.stats = stats
}

function EMAIL_STATS_UPDATED(state, { stats }) {
  state.stats_email = stats
}

function NEWSLETTER_STATS_UPDATED(state, { stats }) {
  state.stats_newsletter = stats
}

function PN_STATS_UPDATED(state, { stats }) {
  state.stats_pn = stats
}

function IM_STATS_UPDATED(state, { stats }) {
  state.stats_im = stats
}

function SMS_STATS_UPDATED(state, { stats }) {
  state.stats_sms = stats
}

function STATISTICS_EXPORTED(state, { stats }) {
  state.exported_stats = stats
}

function EMAIL_STATS_EXPORTED(state, { stats }) {
  state.exported_stats_email = stats
}

function NEWSLETTER_STATS_EXPORTED(state, { stats }) {
  state.exported_stats_newsletter = stats
}

function PN_STATS_EXPORTED(state, { stats }) {
  state.exported_stats_pn = stats
}

function IM_STATS_EXPORTED(state, { stats }) {
  state.exported_stats_im = stats
}

function SMS_STATS_EXPORTED(state, { stats }) {
  state.exported_stats_sms = stats
}

export default {
  STATISTICS_UPDATED,
  EMAIL_STATS_UPDATED,
  NEWSLETTER_STATS_UPDATED,
  PN_STATS_UPDATED,
  IM_STATS_UPDATED,
  SMS_STATS_UPDATED,
  STATISTICS_EXPORTED,
  EMAIL_STATS_EXPORTED,
  NEWSLETTER_STATS_EXPORTED,
  PN_STATS_EXPORTED,
  IM_STATS_EXPORTED,
  SMS_STATS_EXPORTED
}
