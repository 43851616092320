import actions from './actions'
import mutations from './mutations'

const state = {
  // Cached suggestions objects, by field-[data] (key-value)
  suggestions: {}
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
