function pushNotificationFullViewMap(message) {
  const {
    id,
    name,
    description,
    channel,
    valid,
    active,
    updated_at: updatedAt,
    updated_by: updatedBy,
    created_at: createdAt,
    created_by: createdBy,
    templates,
    data
  } = message

  const channelID = channel.id

  return {
    id,
    name,
    description,
    channelID,
    valid,
    active,
    updatedAt,
    updatedBy,
    createdAt,
    createdBy,
    templates: templatesMap(templates),
    image: data.image,
    imageUpload: data.image_upload,
    devices: data.devices,
    action: data.action,
    button: {
      isStatic: data.button.is_static,
      type: data.button.type,
      action: {
        type: data.button.action.type,
        content: data.button.action.content
      }
    },
    attr: data.attr,
    utm: data.utm,
    voucher: data.voucher,
    signalKey: data.signal_key
  }
}

function templatesMap(templates) {
  return templates.map(t => {
    return {
      language_id: t.language_id,
      title: t.content.title,
      message: t.content.message,
      summary: t.content.summary
    }
  })
}

function MapPreviewResponse(response) {
  return {
    title: response.title,
    message: response.message,
    summary: response.summary,
    actionContent: response.action_content,
    attr: response.attr,
    utm: response.utm,
    voucher: response.voucher,
    signalKey: response.signal_key,
    imgUrl: response.image,
    button: {
      type: response.btn_type,
      action: {
        type: response.btn_action,
        content: response.btn_action_content
      }
    }
  }
}

export { pushNotificationFullViewMap, MapPreviewResponse }
