import { butterflyAxios } from '@/api/axios'
import { tagListMap, mapTagAudit, tagMap } from './responseMapper'
import {
  isCreatedResponse,
  isOKResponse,
  parseSuccessResponse
} from '@/api/utils/response'

const endpoint = {
  tags: 'tags',
  tag: id => `tags/${id}`,
  audit: id => `tags/${id}/audit`
}

async function apiGetTags(params) {
  switch (params.sort_by) {
    case 'updatedAt':
      params.sort_by = 'updated_at'
      break
    case 'updatedBy':
      params.sort_by = 'updated_by'
      break
  }
  const response = await butterflyAxios.get(endpoint.tags, { params: params })
  if (isOKResponse(response)) {
    const { response: tags, meta: pagination } = parseSuccessResponse(response)
    return {
      tags: tagListMap(tags),
      total: pagination ? pagination.total_records : tags.length,
      newPage: pagination ? pagination.page : 1
    }
  }
}

const apiGetTagById = async id => {
  const response = await butterflyAxios.get(endpoint.tag(id))
  if (isOKResponse(response)) {
    const { response: tag } = parseSuccessResponse(response)
    return tagMap(tag)
  }
}

const apiCreateTag = async tag => {
  const res = await butterflyAxios.post(endpoint.tags, {
    name: tag.name
  })
  if (!isCreatedResponse(res)) {
    throw res
  }
}

const apiUpdateTag = async tag => {
  const res = await butterflyAxios.put(endpoint.tag(tag.id), {
    name: tag.name
  })
  if (!isOKResponse(res)) {
    throw res
  }
}

const apiPatchTagStatus = async item => {
  const res = await butterflyAxios.patch(endpoint.tag(item.id), {
    active: item.active
  })
  if (!isOKResponse(res)) {
    throw res
  }
}

const apiGetTagAudit = async request => {
  const payload = {
    page: request.page,
    per_page: request.perPage,
    order_by: request.orderBy,
    sort_by: request.sortBy
  }
  const response = await butterflyAxios.get(endpoint.audit(request.id), {
    params: { ...payload }
  })
  if (isOKResponse(response)) {
    const { response: audits, meta: pagination } = parseSuccessResponse(
      response
    )
    return {
      audit: mapTagAudit(audits),
      finalDataSize: pagination ? pagination.total_records : audits.length
    }
  } else {
    throw response
  }
}

export {
  apiGetTags,
  apiGetTagById,
  apiCreateTag,
  apiUpdateTag,
  apiPatchTagStatus,
  apiGetTagAudit
}
