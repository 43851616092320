import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/en.json'
import app from '@/locales/app.json'
import errors from '@/locales/errors.en.json'
import { setAxiosAcceptLanguage } from '@/api/axios'
import campaigns from '@/locales/campaigns.en.json'
import segments from '@/locales/segments.en.json'
import instances from '@/locales/instances.en.json'
import jobs from '@/locales/jobs.en.json'
import users from '@/locales/users.en.json'
import media from '@/locales/media.en.json'
import messages from '@/locales/messages.en.json'
import dashboard from '@/locales/dashboard.en.json'
import selfService from '@/locales/self-service.en.json'
import settings from '@/locales/settings.en.json'
import codeSnippets from '@/locales/code-snippets.en.json'
import docs from '@/locales/docs.en.json'
import querytemplates from '@/locales/querytemplates.en.json'
import emailtemplates from '@/locales/emailtemplates.en.json'
import rates from '@/locales/rates.en.json'
import budgets from '@/locales/budgets.en.json'
import logicgroups from '@/locales/logicgroups.en.json'
import contactlookup from '@/locales/contactlookup.en.json'
import deeplinks from '@/locales/deeplinks.en.json'
import tags from '@/locales/tags.en.json'

Vue.use(VueI18n)

const locale = `${window.CONFIG.LOCALE}` || 'en'
export var i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      ...en,
      errors,
      app: app,
      campaigns: campaigns,
      segments: segments,
      instances: instances,
      jobs: jobs,
      users: users,
      messages: messages,
      media: media,
      dashboard: dashboard,
      settings: settings,
      code_snippets: codeSnippets,
      self_service: selfService,
      docs: docs,
      querytemplates: querytemplates,
      emailtemplates,
      rates,
      budgets,
      logicgroups,
      contactlookup,
      deeplinks,
      tags
    }
  }
})

setI18nLanguage(locale)

function setI18nLanguage(lang) {
  i18n.locale = lang
  setAxiosAcceptLanguage(lang)
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

// TODO - lazy loading disabled to allow vuex cross module communication
// async function loadModuleLanguage({ module }) {
//   if (!i18n.messages[locale][module]) {
//     var msgs = await import(
//       /* webpackChunkName: "lang-[request]" */ `@/locales/${module}.${locale}.json`
//     )
//     i18n.mergeLocaleMessage(locale, {
//       [module]: msgs.default
//     })
//     return setI18nLanguage(locale)
//   }
// }
//
// export async function loadLanguage({ module }) {
//   if (!i18n.messages[locale]) {
//     var msgs = await import(
//       /* webpackChunkName: "lang-[request]" */ `@/locales/${locale}.json`
//     )
//
//     i18n.setLocaleMessage(locale, msgs.default)
//     await loadModuleLanguage({ module: 'errors' })
//     if (module) {
//       return loadModuleLanguage({ module })
//     }
//   } else if (module) {
//     return loadModuleLanguage({ module })
//   }
// }
