/**
 * Signal the Vuex state that the card types were cached.
 * @param {*} state
 * @param {array} newCardTypes
 */
function CARD_TYPES_CACHED(state, newCardTypes) {
  state.cardTypes = newCardTypes
}

export default {
  CARD_TYPES_CACHED
}
