/**
 * Signals Vuex that the push notification programs have changed.
 * @param {vuexState} state
 * @param {*} channels The new programs.
 */
function PROGRAMS_UPDATED(state, programs) {
  state.programs = programs
}

/**
 * Signals Vuex that the push notification objectives have changed.
 * @param {vuexState} state
 * @param {*} channels The new objectives.
 */
function OBJECTIVES_UPDATED(state, objectives) {
  state.objectives = objectives
}

/**
 * Signals Vuex that the push notification incentive types have changed.
 * @param {vuexState} state
 * @param {*} channels The new objectives.
 */
function INCENTIVE_TYPES_UPDATED(state, incentiveTypes) {
  state.incentiveTypes = incentiveTypes
}

export default {
  PROGRAMS_UPDATED,
  OBJECTIVES_UPDATED,
  INCENTIVE_TYPES_UPDATED
}
