import { apiCreate, apiUpdate, apiPreview } from '@/api/sms'

async function create(_, { message }) {
  return apiCreate(message)
}
async function update(_, { message }) {
  return apiUpdate(message)
}

async function preview(_, payload) {
  return apiPreview(payload)
}

export default {
  create,
  update,
  preview
}
