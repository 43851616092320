/**
 * Converts a JSON channel object from the API format to the format expected by the FE.
 * @param {JSON} channel The channel JSON object to convert from.
 */
function channelMap(channel) {
  let { id, name, active, icon, keyword } = channel
  let channelType = channelTypeMap(channel.channel_type)
  return {
    id,
    name,
    active,
    icon,
    keyword,
    channelType
  }
}

/**
 * Converts a JSON channel type object from the API format to the format expected by the FE.
 * @param {JSON} channelType The channel type JSON object to convert from.
 */
function channelTypeMap(channelType) {
  let { id, name, keyword, active } = channelType
  let channels = []
  if (channelType.channels) {
    channels = channelListMap(channelType.channels)
  }

  return {
    id,
    name,
    keyword,
    active,
    channels
  }
}

/**
 * Maps an array of channels to another array of channels in the expected FE format.
 * @param {Array} list The list of channels in BE format.
 */
function channelListMap(list) {
  var results = []
  for (let item of list) {
    results.push(channelMap(item))
  }
  return results
}

/**
 * Maps an array of channel types to another array of channel types in the expected FE format.
 * @param {Array} list The list of channel types in BE format.
 */
function channelTypeListMap(list) {
  var results = []
  for (let item of list) {
    results.push(channelTypeMap(item))
  }
  return results
}

export { channelMap, channelListMap, channelTypeMap, channelTypeListMap }
