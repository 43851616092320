/**
 * Signals Vuex's state that the emailTemplates have been updated.
 * @param {*} state The vuex state.
 * @param {*} param1
 */
const TEMPLATES_UPDATED = (
  state,
  { emailTemplates, finalDataSize, originalData }
) => {
  state.emailTemplates = emailTemplates
  state.finalDataSize = finalDataSize
  state.originalData = originalData
}

export default {
  TEMPLATES_UPDATED
}
