import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS, PERMISSION_TYPE } from '@/store/user/constants'
import { CHANNEL_KEYWORDS } from '@/api/channels/constants'

const CAMPAIGNS_MODULE = 'campaigns'

export default [
  {
    path: '/campaigns',
    component: loadView('ViewCampaigns', 'campaigns'),
    children: [
      {
        path: '',
        name: 'campaigns',
        component: loadView('ViewCampaignsList', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CAMPAIGNS
        }
      },
      {
        path: CHANNEL_KEYWORDS.PUSH_NOTIFICATION + '/new',
        name: 'campaign-push-notification-add',
        component: loadView(
          'push_notification/ViewPushNotificationAdd',
          'campaigns'
        ),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_PN_CAMPAIGN,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.NEWS_FEED + '/new',
        name: 'campaign-news-feed-add',
        component: loadView('news_feed/ViewNewsFeedAdd', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_CREATION,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.GROWTH_TOOL + '/new',
        name: 'campaign-growth-tool-add',
        component: loadView('growth_tool/ViewGrowthToolAdd', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_GROWTH_CAMPAIGN,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.EMAIL + '/new',
        name: 'campaign-email-add',
        component: loadView('email/ViewEmailAdd', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_CREATION,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.SMS + '/new',
        name: 'campaign-sms-add',
        component: loadView('sms/ViewSmsAdd', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_SMS_CAMPAIGN,
          roleTypes: [PERMISSION_TYPE.TARGET],
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.NEWSLETTER + '/new',
        name: 'campaign-newsletter-add',
        component: loadView('newsletter/ViewNewsletterAdd', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_CREATION,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.INBOX + '/new',
        name: 'campaign-inbox-add',
        component: loadView('inbox/ViewInboxAdd', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_INBOX_CAMPAIGN,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.PUSH_NOTIFICATION + '/:campaignId',
        name: 'campaign-push-notification-edit',
        component: loadView(
          'push_notification/ViewPushNotificationEdit',
          'campaigns'
        ),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_PN_CAMPAIGN,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.NEWS_FEED + '/:campaignId',
        name: 'campaign-news-feed-edit',
        component: loadView('news_feed/ViewNewsFeedEdit', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_UPDATE,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.GROWTH_TOOL + '/:campaignId',
        name: 'campaign-growth-tool-edit',
        component: loadView('growth_tool/ViewGrowthToolEdit', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_GROWTH_CAMPAIGN,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.SMS + '/:campaignId',
        name: 'campaign-sms-edit',
        component: loadView('sms/ViewSmsEdit', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_SMS_CAMPAIGN,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.EMAIL + '/:campaignId',
        name: 'campaign-email-edit',
        component: loadView('email/ViewEmailEdit', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_UPDATE,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.NEWSLETTER + '/:campaignId',
        name: 'campaign-newsletter-edit',
        component: loadView('newsletter/ViewNewsletterEdit', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_UPDATE,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.INBOX + '/:campaignId',
        name: 'campaign-inbox-edit',
        component: loadView('inbox/ViewInboxEdit', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_INBOX_CAMPAIGN,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.PUSH_NOTIFICATION + '/:campaignId',
        name: 'campaign-push-notification-clone',
        component: loadView(
          'push_notification/ViewPushNotificationClone',
          'campaigns'
        ),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_PN_CAMPAIGN,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.NEWS_FEED + '/:campaignId',
        name: 'campaign-news-feed-clone',
        component: loadView('news_feed/ViewNewsFeedClone', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_CREATION,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.GROWTH_TOOL + '/:campaignId',
        name: 'campaign-growth-tool-clone',
        component: loadView('growth_tool/ViewGrowthToolClone', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_GROWTH_CAMPAIGN,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.SMS + '/:campaignId',
        name: 'campaign-sms-clone',
        component: loadView('sms/ViewSmsClone', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_SMS_CAMPAIGN,
          roleTypes: [PERMISSION_TYPE.TARGET],
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.EMAIL + '/:campaignId',
        name: 'campaign-email-clone',
        component: loadView('email/ViewEmailClone', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_CREATION,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.NEWSLETTER + '/:campaignId',
        name: 'campaign-newsletter-clone',
        component: loadView('newsletter/ViewNewsletterClone', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAMPAIGN_CREATION,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.INBOX + '/:campaignId',
        name: 'campaign-inbox-clone',
        component: loadView('inbox/ViewInboxClone', 'campaigns'),
        meta: {
          navigationDrawer: true,
          module: CAMPAIGNS_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_INBOX_CAMPAIGN,
          whiteBackground: true,
          savePrevRoute: true
        }
      }
    ]
  }
  // {
  //   path: '/wizard',
  //   component: loadView('ViewWizard', 'wizard'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'campaigns',
  //       component: loadView('StepsWizzard', 'wizard'),
  //       meta: {
  //         navigationDrawer: true,
  //         module: CAMPAIGNS_MODULE,
  //         role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_CAMPAIGNS
  //       }
  //     }
  //   ]
  // }
]
