import { loadView } from './utils'
import { PERMISSIONS_CONSTANTS } from '@/store/user/constants'
import { CHANNEL_KEYWORDS } from '@/api/channels/constants'
const MESSAGES_MODULE = 'messages'

export default [
  {
    path: '/messages',
    component: loadView('ViewMessages', 'messages'),
    children: [
      {
        path: '',
        name: 'messages',
        component: loadView('ViewMessagesList', 'messages'),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MESSAGES
        }
      },
      {
        path: 'new/:keyword',
        name: 'message-add',
        component: loadView('ViewMessageAdd', 'messages'),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.GROWTH_TOOL + '/new',
        name: 'growth-tool-add',
        component: loadView('growth_tool/ViewGrowthToolAdd', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_GROWTH_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.PUSH_NOTIFICATION + '/new',
        name: 'push-notification-add',
        component: loadView(
          'push_notification/ViewPushNotificationAdd',
          MESSAGES_MODULE
        ),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_PN_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.INBOX + '/new',
        name: 'inbox-add',
        component: loadView('inbox/ViewInboxAdd', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_INBOX_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.SMS + '/new',
        name: 'sms-add',
        component: loadView('sms/ViewSmsAdd', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_SMS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.NEWSLETTER + '/new',
        name: 'newsletter-add',
        component: loadView('newsletter/NewsletterAdd', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: ':messageId',
        name: 'message-edit',
        component: loadView('ViewMessageEdit', 'messages'),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.GROWTH_TOOL + '/:messageId',
        name: 'growth-tool-edit',
        component: loadView('growth_tool/ViewGrowthToolEdit', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_GROWTH_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.PUSH_NOTIFICATION + '/:messageId',
        name: 'push-notification-edit',
        component: loadView(
          'push_notification/ViewPushNotificationEdit',
          MESSAGES_MODULE
        ),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_PN_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.INBOX + '/:messageId',
        name: 'inbox-edit',
        component: loadView('inbox/ViewInboxEdit', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_INBOX_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.SMS + '/:messageId',
        name: 'sms-edit',
        component: loadView('sms/ViewSmsEdit', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_UPDATE_SMS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.NEWSLETTER + '/:messageId',
        name: 'newsletter-edit',
        component: loadView('newsletter/NewsletterEdit', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path:
          CHANNEL_KEYWORDS.NEWSLETTER +
          '/:messageId/' +
          CHANNEL_KEYWORDS.NEWSLETTER_SCHEDULE +
          '/new',
        name: 'schedule-new',
        component: loadView('newsletter/schedule/ScheduleAdd', MESSAGES_MODULE),
        props: true,
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path:
          CHANNEL_KEYWORDS.NEWSLETTER +
          '/:messageId/' +
          CHANNEL_KEYWORDS.NEWSLETTER_SCHEDULE +
          '/:scheduleId',
        name: 'schedule-edit',
        component: loadView(
          'newsletter/schedule/ScheduleEdit',
          MESSAGES_MODULE
        ),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path:
          CHANNEL_KEYWORDS.NEWSLETTER +
          '/:messageId/' +
          CHANNEL_KEYWORDS.NEWSLETTER_SCHEDULE +
          '/clone/:scheduleId',
        name: 'schedule-clone',
        component: loadView(
          'newsletter/schedule/ScheduleClone',
          MESSAGES_MODULE
        ),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: 'clone/:messageId',
        name: 'message-clone',
        component: loadView('ViewMessageClone', 'messages'),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_ACCESS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.GROWTH_TOOL + '/clone/:messageId',
        name: 'growth-tool-clone',
        component: loadView('growth_tool/ViewGrowthToolClone', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_GROWTH_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.PUSH_NOTIFICATION + '/clone/:messageId',
        name: 'push-notification-clone',
        component: loadView(
          'push_notification/ViewPushNotificationClone',
          MESSAGES_MODULE
        ),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_PN_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.INBOX + '/clone/:messageId',
        name: 'inbox-clone',
        component: loadView('inbox/ViewInboxClone', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_INBOX_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      },
      {
        path: CHANNEL_KEYWORDS.SMS + '/clone/:messageId',
        name: 'sms-clone',
        component: loadView('sms/ViewSmsClone', MESSAGES_MODULE),
        meta: {
          navigationDrawer: true,
          module: MESSAGES_MODULE,
          role: PERMISSIONS_CONSTANTS.MDS_CAN_CREATE_SMS_MESSAGES,
          whiteBackground: true,
          savePrevRoute: true
        }
      }
    ]
  }
]
