function eventsMap(event) {
  const {
    id,
    type,
    key,
    description,
    status,
    metadata,
    date,
    channel,
    target
  } = event

  return {
    id,
    type,
    key,
    description,
    status,
    metadata,
    channel,
    target,
    date
  }
}

function eventsListMap(list) {
  const events = []
  for (let item of list) {
    events.push(eventsMap(item))
  }
  return events
}

export { eventsMap, eventsListMap }
