function CHANNEL_BUDGETS_UPDATED(state, { channelBudgets, finalDataSize }) {
  state.channelBudgets = channelBudgets
  state.finalDataSize = finalDataSize
}
function BUDGETS_CAMPAIGNS(state, { budgetCampaigns, finalDataSize }) {
  state.budgetCampaigns = budgetCampaigns
  state.finalDataSizeCampaigns = finalDataSize
}
export default {
  CHANNEL_BUDGETS_UPDATED,
  BUDGETS_CAMPAIGNS
}
