/**
 * This file serves the purpose of defining constants when dealing with
 * the user vuex store. It's a special case, as the user uses
 * local storage with encryption.
 */
export const USER_CONSTANTS = {
  USER: 'user',
  EMAIL: 'email',
  NAME: 'name',
  PICTURE: 'picture',
  PERMISSIONS: 'permissions',
  AUTH_TOKEN: 'auth_token',
  REFRESH_TOKEN: 'refresh_token',
  TARGETS: 'targets'
}

export const PERMISSION_TYPE = {
  APPLICATION: 'application',
  TARGET: 'target'
}

/**
 * Constants that can be used to reference a specific permission
 */
export const PERMISSIONS_CONSTANTS = {
  MDS_ADMIN: 'mds_admin',
  CAN_ACCESS: 'can_access',
  MDS_DOCUMENTATION: 'documentation',
  MDS_SEGMENT_DISPATCH: 'mds_segment_dispatch',
  MDS_SEGMENTS_ADMIN: 'mds_segments_admin',
  MDS_SEGMENT_DELETE: 'mds_segment_delete',
  MDS_SEGMENT_UPDATE: 'mds_segment_update',
  MDS_SEGMENT_CREATION: 'mds_segment_creation',
  MDS_SEGMENTS_ACCESS_INFO: 'mds_segments_access_info',
  MDS_CAN_ACCESS_DATASOURCE: 'mds_can_access_datasource',
  MDS_CAN_ACCESS_MESSAGES: 'mds_can_access_messages',
  MDS_CAN_ACCESS_SEGMENTATION: 'mds_can_access_segmentation',
  MDS_CAN_ACCESS_CAMPAIGNS: 'mds_can_access_campaigns',
  MDS_CAN_ACCESS_MEDIA: 'mds_can_access_media',
  MDS_CAMPAIGN_CREATION: 'mds_campaign_creation',
  MDS_CAMPAIGN_UPDATE: 'mds_campaign_update',
  MDS_CAMPAIGN_DELETE: 'mds_campaign_delete',
  MDS_CAMPAIGN_DISPATCH: 'mds_campaign_dispatch',
  MDS_CAMPAIGNS_ADMIN: 'mds_campaigns_admin',
  MDS_CAMPAIGNS_APPROVE: 'mds_campaigns_approve',
  MDS_MEDIA_CREATE: 'mds_media_create',
  MDS_CAN_ACCESS_CODE_SNIPPETS: 'mds_can_access_codesnippets',
  MDS_CAN_CREATE_CODE_SNIPPETS: 'mds_can_create_codesnippets',
  MDS_CAN_UPDATE_CODE_SNIPPETS: 'mds_can_update_codesnippets',
  MDS_CAN_DELETE_CODE_SNIPPETS: 'mds_can_delete_codesnippets',

  MDS_SEGMENTS_SYNC_ONDEMAND: 'mds_segments_sync_ondemand',
  MDS_CAN_ACCESS_NL_SP: 'mds_can_access_nl_sp',
  MDS_CAN_ACCESS_EVENT_LOG: 'mds_event_log',
  MDS_CAN_ACCESS_REMINDER_WIDGET: 'mds_can_see_reminder_widget',

  MDS_CAN_ACCESS_PN_MESSAGES: 'mds_can_access_pn_messages',
  MDS_CAN_CREATE_PN_MESSAGES: 'mds_can_create_pn_messages',
  MDS_CAN_UPDATE_PN_MESSAGES: 'mds_can_update_pn_messages',
  MDS_CAN_DELETE_PN_MESSAGES: 'mds_can_delete_pn_messages',

  MDS_CAN_ACCESS_PN_CAMPAIGN: 'mds_can_access_pn_campaign',
  MDS_CAN_CREATE_PN_CAMPAIGN: 'mds_can_create_pn_campaign',
  MDS_CAN_UPDATE_PN_CAMPAIGN: 'mds_can_update_pn_campaign',
  MDS_CAN_DELETE_PN_CAMPAIGN: 'mds_can_delete_pn_campaign',

  MDS_CAN_ACCESS_GROWTH_MESSAGES: 'mds_can_access_growth_messages',
  MDS_CAN_CREATE_GROWTH_MESSAGES: 'mds_can_create_growth_messages',
  MDS_CAN_UPDATE_GROWTH_MESSAGES: 'mds_can_update_growth_messages',
  MDS_CAN_DELETE_GROWTH_MESSAGES: 'mds_can_update_growth_messages',

  MDS_CAN_ACCESS_GROWTH_CAMPAIGN: 'mds_can_access_growth_campaign',
  MDS_CAN_CREATE_GROWTH_CAMPAIGN: 'mds_can_create_growth_campaign',
  MDS_CAN_UPDATE_GROWTH_CAMPAIGN: 'mds_can_update_growth_campaign',
  MDS_CAN_DELETE_GROWTH_CAMPAIGN: 'mds_can_delete_growth_campaign',

  MDS_CAN_ACCESS_INBOX_MESSAGES: 'mds_can_access_inbox_messages',
  MDS_CAN_CREATE_INBOX_MESSAGES: 'mds_can_create_inbox_messages',
  MDS_CAN_UPDATE_INBOX_MESSAGES: 'mds_can_update_inbox_messages',
  MDS_CAN_DELETE_INBOX_MESSAGES: 'mds_can_delete_inbox_messages',

  MDS_CAN_ACCESS_INBOX_CAMPAIGN: 'mds_can_access_inbox_campaigns',
  MDS_CAN_CREATE_INBOX_CAMPAIGN: 'mds_can_create_inbox_campaigns',
  MDS_CAN_UPDATE_INBOX_CAMPAIGN: 'mds_can_update_inbox_campaigns',
  MDS_CAN_DELETE_INBOX_CAMPAIGN: 'mds_can_delete_inbox_campaigns',

  MDS_CAN_ACCESS_SMS_MESSAGES: 'mds_can_access_sms_messages',
  MDS_CAN_CREATE_SMS_MESSAGES: 'mds_can_create_sms_messages',
  MDS_CAN_UPDATE_SMS_MESSAGES: 'mds_can_update_sms_messages',
  MDS_CAN_DELETE_SMS_MESSAGES: 'mds_can_delete_sms_messages',

  MDS_CAN_ACCESS_SMS_CAMPAIGN: 'mds_can_access_sms_campaigns',
  MDS_CAN_CREATE_SMS_CAMPAIGN: 'mds_can_create_sms_campaigns',
  MDS_CAN_UPDATE_SMS_CAMPAIGN: 'mds_can_update_sms_campaigns',
  MDS_CAN_DELETE_SMS_CAMPAIGN: 'mds_can_delete_sms_campaigns',
  MDS_CAN_APPROVE_SMS_CAMPAIGN: 'mds_can_approve_sms_campaigns',

  MDS_CAN_ACTIVATE_RETARGETING: 'mds_can_activate_retargeting',

  MDS_CAN_ACCESS_TEMPLATES: 'mds_can_access_querytemplates',
  MDS_CAN_CREATE_TEMPLATES: 'mds_can_create_querytemplates',
  MDS_CAN_UPDATE_TEMPLATES: 'mds_can_update_querytemplates',
  MDS_CAN_DELETE_TEMPLATES: 'mds_can_delete_querytemplates',

  MDS_CAN_SELECT_COMMERCIAL: 'mds_query_template_commercial',
  MDS_CAN_SELECT_MARKETING: 'mds_query_template_marketing',
  MDS_CAN_SELECT_ADVERTISING: 'mds_query_template_advertising',
  MDS_CAN_SELECT_ONSITE: 'mds_query_template_on_site',
  MDS_CAN_SELECT_CRM: 'mds_query_template_crm',
  MDS_CAN_SELECT_OTHERS: 'mds_query_template_others',

  MDS_CAN_ACCESS_BUDGET: 'mds_can_see_budget_module',
  MDS_CAN_CREATE_BUDGET: 'mds_can_create_budget_module',
  MDS_CAN_UPDATE_BUDGET: 'mds_can_update_budget_module',

  MDS_CAN_ACCESS_RATE: 'mds_can_access_rate_module',
  MDS_CAN_CREATE_RATE: 'mds_can_create_rate',
  MDS_CAN_UPDATE_RATE: 'mds_can_update_rate',

  MDS_CAN_ACCESS_CAMPAIGN_TAGS: 'mds_campaign_tags_access',
  MDS_CAN_CREATE_CAMPAIGN_TAGS: 'mds_campaign_tags_create',
  MDS_CAN_UPDATE_CAMPAIGN_TAGS: 'mds_campaign_tags_edit',
  MDS_CAN_UPDATE_STATUS_CAMPAIGN_TAGS: 'mds_campaign_tags_update_status',

  MDS_CAN_ACCESS_DEEPLINKS: 'mds_deeplink_access',
  MDS_CAN_CREATE_DEEPLINKS: 'mds_deeplink_creation',
  MDS_CAN_UPDATE_DEEPLINKS: 'mds_deeplink_update',

  MDS_CAN_ACCESS_LOGIC_GROUPS: 'mds_logicgroup_access',
  MDS_CAN_CREATE_LOGIC_GROUPS: 'mds_logicgroup_creation',
  MDS_CAN_UPDATE_LOGIC_GROUPS: 'mds_logicgroup_update',

  MDS_CAN_DELETE_EMAIL_TEMPLATES: 'mds_can_delete_emailtemplates',
}
