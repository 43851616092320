import { SOURCES } from '@/constants/sources'

function growthToolFullViewMap(message) {
  let {
    id,
    name,
    description,
    channel,
    valid,
    active,
    vertical_id: verticalId,
    target_id: targetId,
    created_at: createdAt,
    created_by: createdBy,
    updated_at: updatedAt,
    updated_by: updatedBy,
    templates,
    data: {
      instance_id: instanceId,
      custom_query: customQuery,
      sql_fields: sqlFields,
      button,
      project_id: projectId,
      named_users: namedUsers,
      android_channels: androidChannels,
      ios_channels: iosChannels
    },
    growth_tool: {
      program_id: programId,
      objective_id: objectiveId,
      custom_tag: customTag,
      incentive_type_id: incentiveTypeId,
      incentive_discount: incentiveDiscount,
      incentive_amount_currency_id: incentiveAmountCurrency,
      incentive_amount: incentiveAmount,
      incentive_amount_euros: incentiveAmountEuros,
      time_constraint: timeConstraint,
      source = null,
      logic_group: logicGroup,
      query_template_id: queryTemplateId,
      query_template_placeholders: queryTemplatePlaceholders,
      query_method: queryMethod
    }
  } = message

  let channelID = channel.id
  let channelName = channel.name
  const ret = {
    id,
    name,
    description,
    channelID,
    channelName,
    channel,
    valid,
    active,
    verticalId,
    targetId,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    templates,
    data: {
      instanceId,
      customQuery,
      sqlFields,
      projectId,
      namedUsers,
      androidChannels,
      iosChannels
    },
    programId,
    objectiveId,
    customTag,
    button: {
      type: button.type,
      action: {
        type: button.action.type,
        content: button.action.content
      }
    },
    incentiveTypeId,
    incentiveDiscount,
    incentiveAmountCurrency,
    incentiveAmount,
    incentiveAmountEuros,
    timeConstraint,
    source: source === SOURCES.AWS_DATALAKE,
    logicGroupId: logicGroup.id,
    logicGroupName: logicGroup.name,
    queryTemplateId,
    queryTemplatePlaceholders,
    queryMethod
  }
  return ret
}

function programsListMap(list) {
  let programs = []
  for (let item of list) {
    programs.push(programMap(item))
  }
  return programs
}

function programMap(program) {
  return {
    id: program.id,
    description: program.description
  }
}

function objectivesListMap(list) {
  let objectives = []
  for (let item of list) {
    objectives.push(objectiveMap(item))
  }
  return objectives
}

function objectiveMap(objective) {
  return {
    id: objective.id,
    description: objective.description
  }
}

function incentiveTypesListMap(list) {
  let incentiveTypes = []
  for (let item of list) {
    incentiveTypes.push(incentiveTypeMap(item))
  }
  return incentiveTypes
}

function incentiveTypeMap(incentiveType) {
  return {
    id: incentiveType.id,
    description: incentiveType.description
  }
}

function validateMap(response) {
  return {
    processed: response ? response.processed : 0
  }
}

export {
  growthToolFullViewMap,
  programsListMap,
  programMap,
  objectivesListMap,
  objectiveMap,
  incentiveTypesListMap,
  incentiveTypeMap,
  validateMap
}
