import { apiGetTagByKey, apiGetTags } from '@/api/instance-tags'
import CacheSystem from '@/utils/cacheSystem'

/**
 * Fetches the instance tags from the API, firing
 * any necessary logic associated (such as caching).
 * @param {*} context Vuex context (auto-injected).
 * @param {*} payload The payload to send to the API.
 * @param {*} useCache Whether to use the cache (default is true).
 */
async function fetchTags(context, payload, useCache = true) {
  let cacheSystem = new CacheSystem(useCache)

  cacheSystem.implementGetCacheFunction(() => {
    let result
    if (context.state.tags) {
      result = context.state.tags
    }
    return result
  })

  cacheSystem.implementSetCacheFunction(async () => {
    let result = await apiGetTags()
    let commit = context.commit
    commit('INSTANCE_TAGS', result)
    return result
  })

  return cacheSystem.execute()
}

async function fetchTagByKey(_, key) {
  return apiGetTagByKey(key)
}

export default {
  fetchTags,
  fetchTagByKey
}
