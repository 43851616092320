import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App'
import router from './router/index.js'
import store from './store'
import './registerServiceWorker'
import { i18n } from './i18n'
import '@/fonts.css'
import CountryFlag from 'vue-country-flag'
import DOMPurify from 'dompurify'
import GoogleClient from '@/plugins/googleClient'

Vue.config.productionTip = false

Vue.use(GoogleClient, { client_id: window.CONFIG.GOOGLE_CLIENT_ID })
Vue.use(CountryFlag)
Vue.directive('sanitize-html', (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value, { ADD_ATTR: ['target'] })
})

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
