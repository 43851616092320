/**
 * Signals Vuex that the query template areas list has changed.
 * @param {vuexState} state
 * @param {*} param1 The areas to mutate.
 */
function AREAS_UPDATED(state, { areas }) {
  state.areas = areas
}

/**
 * Signals Vuex's state that the instances have been updated.
 * @param {*} state The vuex state.
 * @param {*} param1
 */
const TEMPLATES_UPDATED = (
  state,
  { queryTemplates, finalDataSize, originalData }
) => {
  state.queryTemplates = queryTemplates
  state.finalDataSize = finalDataSize
  state.originalData = originalData
}

export default {
  AREAS_UPDATED,
  TEMPLATES_UPDATED
}
