import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import userRoutes from './users'
import segmentRoutes from './segments'
import { loadView } from './utils'
import { hasToken } from '@/utils/auth'
import instancesRoutes from './instances'
import jobRoutes from './jobs'
import docsRoutes from './docs'
import campaignRoutes from './campaigns'
import mediaRoutes from './media'
import messageRoutes from './messages'
import settingsRoutes from './settings'
import codeSnippetRoutes from './code-snippets'
import appRoutes from './app'
import templatesRoutes from './querytemplates'
import emailTemplatesRoutes from './emailtemplates'
import ratesRoutes from './rates'
import budgetRoutes from './budgets'
import logicRoutes from './logicgroups'
import deeplinkRoutes from './deeplink'
import contactLookup from '@/router/contactlookup'
import dispatchCampaigns from '@/router/campaigns-dispatch'
import tagsRoutes from '@/router/tags'
import { USER_CONSTANTS } from '@/store/user/constants'
import { addPrevRoute } from '@/utils/prevRoute'

Vue.use(Router)

const PERMISSION_READ = 'can_access'

const baseRoutes = [
  {
    path: '/',
    name: 'homepage',
    component: loadView('ViewHomepage')
  },
  {
    path: '/inactive',
    name: 'inactive',
    component: loadView('ViewAccountInactive')
  },
  {
    path: '/home',
    name: 'home',
    component: loadView('ViewDashboardMain', 'dashboard'),
    meta: {
      navigationDrawer: true,
      module: 'dashboard',
      role: PERMISSION_READ
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...appRoutes,
    ...baseRoutes,
    ...userRoutes,
    ...segmentRoutes,
    ...instancesRoutes,
    ...jobRoutes,
    ...campaignRoutes,
    ...messageRoutes,
    ...mediaRoutes,
    ...settingsRoutes,
    ...codeSnippetRoutes,
    ...docsRoutes,
    ...templatesRoutes,
    ...emailTemplatesRoutes,
    ...ratesRoutes,
    ...budgetRoutes,
    ...logicRoutes,
    ...contactLookup,
    ...deeplinkRoutes,
    ...dispatchCampaigns,
    ...tagsRoutes
  ]
})

function isHomepageRoute(to) {
  return to.name === 'homepage'
}

router.beforeEach(async (to, from, next) => {
  if (from.name !== to.name) {
    store.dispatch('app/loadingPageStart')
  }
  if (to.meta.savePrevRoute) {
    addPrevRoute(from)
  }
  store.dispatch('app/processModuleContextChanged', { from: from, to: to })

  if (to.name !== 'homepage') {
    try {
      const authToken = await hasToken()
      if (!authToken || authToken === 'null') {
        store.dispatch('user/clearUserData')
        next({ name: 'homepage' })
      }
      store.dispatch('user/updateTokens', {
        authToken,
        refreshToken: store.state.user.securedState.get(USER_CONSTANTS.USER)[
          USER_CONSTANTS.REFRESH_TOKEN
        ]
      })

      // next({ name: to.name })
      // refreshToken(token, refreshedToken)
    } catch (error) {
      isHomepageRoute(to) ? next() : next({ name: 'homepage' })
      store.dispatch('user/clearUserData')
    }
  }

  next()
})

// TODO - lazy loading disabled to allow vuex cross module communication
// router.beforeResolve(async (to, from, next) => {
//   var module = to.meta.module
//
//   // Load module locales
//   await loadLanguage({ module })
//
//   // Load module store
//   if (module) {
//     if (!(module in store._modules.root._children)) {
//       var response = await import(`@/modules/${module}/_store`)
//       store.registerModule(module, response.default)
//     }
//   }
//
//   next()
// })

router.afterEach(() => {
  store.dispatch('app/loadingPageEnd')
})

export default router
