import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'
import { CHANNEL_COLORS, CHANNEL_KEYWORDS } from '@/api/channels/constants'
import { SOURCES } from '@/constants/sources'

/**
 * WARNING: The below data and function should be centralized or reorganized.
 * Computes the status' color for the message.
 */
const STATE_COLORS = {
  ERROR: '#E36049',
  VALID: '#2A8091'
}

/**
 * A constant to specify functions that allow
 * to create the actions that can be used in messages.
 */
const action = {
  CREATE: () => {
    return {
      event: 'create',
      label: i18n.t('messages.actions.create'),
      icon: 'add',
      isPrimary: true,
      options: [
        {
          keyword: CHANNEL_KEYWORDS.NEWS_FEED,
          color: CHANNEL_COLORS.NEWS_FEED,
          icon: 'move_to_inbox',
          label: i18n.t('messages.title.newsfeed')
        },
        {
          keyword: CHANNEL_KEYWORDS.NEWSLETTER,
          color: CHANNEL_COLORS.NEWSLETTER,
          icon: 'mdi-email-multiple',
          label: i18n.t('messages.title.newsletter')
        },
        {
          keyword: CHANNEL_KEYWORDS.EMAIL,
          color: CHANNEL_COLORS.EMAIL,
          icon: 'email',
          label: i18n.t('messages.title.email')
        }
      ]
    }
  },
  DELETE: message => {
    return {
      event: 'delete',
      icon: 'delete',
      label: i18n.t('messages.actions.delete'),
      confirmationRequired: true,
      confirmationTitleKey: i18n.t('messages.delete_confirmation.title'),
      confirmationDescriptionKey: i18n.t(
        'messages.delete_confirmation.description',
        message
      ),
      active: true
    }
  },
  EDIT: () => {
    return {
      event: 'edit',
      icon: 'edit',
      label: i18n.t('messages.actions.edit'),
      active: true // MUST DEFINE POLICY FOR EDIT BUTTON "ENABLE/DISABLE", IF ANY
    }
  },
  VIEW: () => {
    return {
      event: 'edit',
      icon: 'visibility',
      label: i18n.t('messages.actions.view'),
      active: true // MUST DEFINE POLICY FOR EDIT BUTTON "ENABLE/DISABLE", IF ANY
    }
  },
  CLONE: () => {
    return {
      event: 'clone',
      icon: 'file_copy',
      label: i18n.t('messages.actions.clone')
    }
  },
  SEND_TEST_GT: () => {
    return {
      active: true,
      event: 'sendTest',
      icon: 'mdi-cellphone-android',
      label: i18n.t('messages.growth_tool.actions.send_test')
    }
  },
  AUDIT: () => {
    return {
      event: 'audit',
      icon: 'mdi-update',
      label: i18n.t('messages.view_audit')
    }
  }
}

/**
 * Specifies the column names for the message list view.
 * The values are obtained from the getter.js and responseMapper.js, as well as the internationalization18
 * (i18) values.
 * Essentially, maps a message object to the object that will be displayed
 * on the list view.
 * @param {*} message The message that will be converted to a ListView DTO.
 */

function messageToListDTO(message) {
  return {
    id: message.id,
    channel: {
      keyword: message.channel.keyword
    },
    name: message.name,
    channelName: message.channelName,
    updated_at: formatDate(message.updatedAt),
    updated_by: message.updatedBy,
    icon:
      message.source !== undefined
        ? {
            code: message.source === SOURCES.AWS_DATALAKE ? `mdi-aws` : `$bigquery`,
            label: i18n.t(`segments.form.sources.${message.source}`)
          }
        : undefined,
    status: message.valid
      ? { label: 'Valid', color: STATE_COLORS.VALID, type: 'JChip' }
      : { label: 'Error', color: STATE_COLORS.ERROR, type: 'JChip' }
  }
}

/**
 * Returns the messages list, with the
 * format ready to be rendered in the DataTable.vue component (ListDTO format).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */

function messagesListDTO(state, getters, rootState, rootGetters) {
  if (!state.messages) return []

  return state.messages.map(message => {
    let data = messageToListDTO(message)
    return {
      ...data,
      actions: actionsOnMessage(state, getters, rootState, rootGetters)(message)
    }
  })
}

/**
 The actions that the user can perform on the message list as a whole (i.e. on the group).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnMessageList(state, getters, rootState, rootGetters) {
  let actions = []

  if (rootGetters['user/canCreateMessages']) {
    actions.push(action.CREATE())

    if (rootGetters['user/canCreateGrowthToolMessage']) {
      const gtActionOption = {
        keyword: CHANNEL_KEYWORDS.GROWTH_TOOL,
        color: CHANNEL_COLORS.GROWTH_TOOL,
        icon: 'mdi-cellphone-iphone',
        label: i18n.t('messages.title.growth_tool')
      }

      actions[0].options.unshift(gtActionOption)
    }

    if (rootGetters['user/canCreatePushNotificationMessage']) {
      const pnActionOption = {
        keyword: CHANNEL_KEYWORDS.PUSH_NOTIFICATION,
        color: CHANNEL_COLORS.PUSH_NOTIFICATION,
        icon: 'mdi-cellphone-android',
        label: i18n.t('messages.title.push_notification')
      }

      actions[0].options.unshift(pnActionOption)
    }

    if (rootGetters['user/canCreateInboxMessage']) {
      const inboxActionOption = {
        keyword: CHANNEL_KEYWORDS.INBOX,
        color: CHANNEL_COLORS.INBOX_MESSAGE,
        icon: 'mdi-inbox',
        label: i18n.t('messages.title.inbox')
      }

      actions[0].options.unshift(inboxActionOption)
    }

    if (rootGetters['user/canCreateSmsMessage']) {
      const smsActionOption = {
        keyword: CHANNEL_KEYWORDS.SMS,
        color: CHANNEL_COLORS.SMS,
        icon: 'mdi-message',
        label: i18n.t('messages.title.sms')
      }

      actions[0].options.unshift(smsActionOption)
    }
  }

  return actions
}
/**
 * Responsible for declaring the actions that can be performed on a specific message.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
function actionsOnMessage(state, getters, rootState, rootGetters) {
  return function(message) {
    if (!message) return []

    const actions = getActionsByChannel(message)

    return filterActionsListByPermission(rootGetters, message.updatedBy, [
      { actionGroup: actions.first },
      {
        label: i18n.t('messages.actions.more'),
        icon: 'more_vert',
        menu: {
          list: actions.more,
          date: {
            display: false
          }
        }
      }
    ])
  }
}

/**
 * Returns the actions that the current user can perform
 */
const getActionsByChannel = message => {
  switch (message.channel.keyword) {
    case CHANNEL_KEYWORDS.GROWTH_TOOL:
      return getGrowthToolActions(message)
    case CHANNEL_KEYWORDS.INBOX:
      return getInboxMessageActions(message)
    case CHANNEL_KEYWORDS.PUSH_NOTIFICATION:
      return getPushNotificationActions(message)
    case CHANNEL_KEYWORDS.SMS:
      return getSmsActions(message)
    default:
      return getDefaultActions(message)
  }
}

const getDefaultActions = (message, permissions) => {
  if (!permissions) {
    permissions = {
      view: 'user/canEditMessage',
      edit: 'user/canSeeMessage',
      clone: 'user/canCloneMessage',
      delete: 'user/canDeleteMessage'
    }
  }

  const viewAction = {
    action: action.VIEW(message),
    permission: permissions.view
  }
  const editAction = {
    action: action.EDIT(message),
    permission: permissions.edit
  }
  const cloneAction = {
    action: action.CLONE(message),
    permission: permissions.clone
  }
  const deleteAction = {
    action: action.DELETE(message),
    permission: permissions.delete
  }
  const auditAction = {
    action: action.AUDIT(message),
    permission: permissions.view
  }

  const moreNoClone = [deleteAction, auditAction]
  return {
    first: [editAction, viewAction],
    more:
      message.channel.keyword === CHANNEL_KEYWORDS.NEWSLETTER
        ? moreNoClone
        : [cloneAction, ...moreNoClone]
  }
}

const getGrowthToolActions = message => {
  const defaultActions = getDefaultActions(message, {
    view: 'user/canSeeGrowthToolMessage',
    edit: 'user/canEditGrowthToolMessage',
    clone: 'user/canCloneGrowthToolMessage',
    delete: 'user/canDeleteGrowthToolMessage'
  })
  const sendTestAction = {
    action: action.SEND_TEST_GT(),
    permission: 'user/canEditGrowthToolMessage'
  }
  return {
    first: defaultActions.first,
    more: [...defaultActions.more, sendTestAction]
  }
}

const getInboxMessageActions = message => {
  const defaultActions = getDefaultActions(message, {
    view: 'user/canSeeInboxMessage',
    edit: 'user/canEditInboxMessage',
    clone: 'user/canCreateInboxMessage',
    delete: 'user/canDeleteInboxMessage'
  })
  return {
    first: defaultActions.first,
    more: defaultActions.more
  }
}

const getPushNotificationActions = message => {
  const defaultActions = getDefaultActions(message, {
    view: 'user/canSeePushNotificationMessage',
    edit: 'user/canEditPushNotificationMessage',
    clone: 'user/canClonePushNotificationMessage',
    delete: 'user/canDeletePushNotificationMessage'
  })
  return {
    first: defaultActions.first,
    more: defaultActions.more
  }
}

const getSmsActions = message => {
  const defaultActions = getDefaultActions(message, {
    view: 'user/canSeeSmsMessage',
    edit: 'user/canEditSmsMessage',
    clone: 'user/canCreateSmsMessage',
    delete: 'user/canDeleteSmsMessage'
  })
  return {
    first: defaultActions.first,
    more: defaultActions.more
  }
}

export default {
  messagesListDTO,
  actionsOnMessageList,
  actionsOnMessage
}
