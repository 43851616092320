import { apiExportSentStats, apiGetStatistics } from '@/api/statistics'
import moment from 'moment/moment'
import { CHANNEL_KEYWORDS } from '@/api/channels/constants'
import { exportCsv } from '@/utils/exportCsv'

/**
 * Fetches the statistics from the API.
 * @param {*} param0
 * @param {*} request The request to send to the api (e.g. variables to filter by).
 */
async function fetchStatistics(request) {
  let commitPayload = { stats: [] }
  const dateNow = moment()
  const dateLastMonth = moment(dateNow).subtract(1, 'months')
  request.start_date = dateLastMonth.format('YYYY-MM-DD')
  request.end_date = dateNow.format('YYYY-MM-DD')
  try {
    let response = await apiGetStatistics(request)
    let dataObject = { days: [], values: [] }
    for (const value of response) {
      dataObject.days.push(value['day'])
      dataObject.values.push(value['value'])
    }
    commitPayload = {
      stats: dataObject
    }
    return commitPayload
  } catch (error) {
    return { stats: undefined }
  }
}

async function fetchStats({ commit }, request) {
  let response = await fetchStatistics(request)
  commit('STATISTICS_UPDATED', response)
}

async function fetchEmailStats({ commit }, request) {
  request['channel'] = CHANNEL_KEYWORDS.EMAIL
  let response = await fetchStatistics(request)
  commit('EMAIL_STATS_UPDATED', response)
}

async function fetchNewsletterStats({ commit }, request) {
  request['channel'] = CHANNEL_KEYWORDS.NEWSLETTER
  let response = await fetchStatistics(request)
  commit('NEWSLETTER_STATS_UPDATED', response)
}

async function fetchPushNotificationStats({ commit }, request) {
  request['channel'] = CHANNEL_KEYWORDS.PUSH_NOTIFICATION
  let response = await fetchStatistics(request)
  commit('PN_STATS_UPDATED', response)
}

async function fetchInboxMessageStats({ commit }, request) {
  request['channel'] = CHANNEL_KEYWORDS.INBOX
  let response = await fetchStatistics(request)
  commit('IM_STATS_UPDATED', response)
}

async function fetchSMSStats({ commit }, request) {
  request['channel'] = CHANNEL_KEYWORDS.SMS
  let response = await fetchStatistics(request)
  commit('SMS_STATS_UPDATED', response)
}

/**
 * Exports the statistics from the API.
 * @param {*} request The request to send to the api (e.g. variables to filter by).
 */
async function exportStatistics(request) {
  const dateNow = moment()
  const dateLastMonth = moment(dateNow).subtract(1, 'months')
  request.filters.start_date = dateLastMonth.format('YYYY-MM-DD')
  request.filters.end_date = dateNow.format('YYYY-MM-DD')
  if (request.channel) {
    request.filters.channel = request.channel
  }

  try {
    let response = await apiExportSentStats(request.filters)
    exportCsv(response, request.fileName)
  } catch (error) {
    return { stats: undefined }
  }
}

async function exportStats({ commit }, request) {
  request.fileName = getStatsCsvFileName(request.channel)
  return exportStatistics(request)
}

function getStatsCsvFileName(channel) {
  let fileName = 'list_all_channels'
  if (channel) {
    fileName = `list_${channel}`
  }
  return fileName
}

export default {
  fetchStats,
  fetchEmailStats,
  fetchNewsletterStats,
  fetchPushNotificationStats,
  fetchInboxMessageStats,
  fetchSMSStats,
  exportStats
}
