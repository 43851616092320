/**
 * Signal the Vuex state that the countries names were cached.
 * @param {*} state
 * @param {array} list
 */
function COUNTRY_NAMES_CACHED(state, list) {
  state.countryNames = list
}

/**
 * Signal the Vuex state that the countries names were cached.
 * @param {*} state
 * @param {array} list
 */
function CURRENCIES_CACHED(state, list) {
  state.currencies = list
}

export default {
  COUNTRY_NAMES_CACHED,
  CURRENCIES_CACHED
}
