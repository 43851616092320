import { i18n } from '@/i18n'
import { formatDate } from '@/utils/commons/dateUtils'
import { filterActionsListByPermission } from '@/utils/auth'
import { getCountryName } from '@/utils/commons/countryUtils'
import { TAG_KEYS } from '@/api/instance-tags/constants'
import { SYNC_STATUS } from '@/api/instances/constants'

const _colors = {
  info: '#2A8091',
  neutral: '#8e8e93'
}

/**
 * A constant to specify functions that allow
 * to create the actions that can be used in instances.
 */
const action = {
  CREATE: () => {
    return {
      event: 'create',
      label: i18n.t('instances.titles.create'),
      icon: 'add',
      isPrimary: true,
      options: []
    }
  },
  DELETE: instance => {
    return {
      event: 'delete',
      icon: 'delete',
      label: i18n.t('delete'),
      confirmationRequired: true,
      confirmationTitleKey: i18n.t('instances.delete_confirmation.title'),
      confirmationDescriptionKey: i18n.t(
        'instances.delete_confirmation.description',
        instance
      ),
      disabled:
        instance.syncStatus === SYNC_STATUS.PROCESSING ||
        instance.sculptorSync === true
    }
  },
  EDIT: instance => {
    return {
      event: 'view',
      icon: 'edit',
      label: instance.sculptorSync
        ? i18n.t('instances.sculptor_sync')
        : i18n.t('edit'),
      disabled:
        instance.syncStatus === SYNC_STATUS.PROCESSING ||
        instance.sculptorSync === true
    }
  },
  VIEW: instance => {
    return {
      event: 'view',
      icon: 'visibility',
      label: instance.sculptorSync
        ? i18n.t('instances.sculptor_sync')
        : i18n.t('view'),
      disabled: instance.syncStatus === SYNC_STATUS.PROCESSING
    }
  },
  SYNC: instance => {
    return {
      event: 'sync',
      icon: 'mdi-database-refresh',
      label: i18n.t('sync_segment_ondemand'),
      confirmationRequired: true,
      confirmationTitleKey: i18n.t('instances.sync_confirmation.title'),
      confirmationDescriptionKey: i18n.t(
        'instances.sync_confirmation.description',
        instance
      ),
      disabled:
        instance.syncStatus === SYNC_STATUS.PROCESSING ||
        instance.sculptorSync === true
    }
  },
  CLONE: instance => {
    return {
      event: 'clone',
      icon: 'file_copy',
      label: i18n.t('clone'),
      disabled: instance.syncStatus === SYNC_STATUS.PROCESSING
    }
  },
  DOWNLOAD: instance => {
    return {
      event: 'download',
      icon: 'email',
      label: i18n.t('send_by_email'),
      disabled:
        instance.syncStatus === SYNC_STATUS.PROCESSING ||
        instance.sculptorSync === true
    }
  },
  AUDIT: () => {
    return {
      event: 'audit',
      icon: 'mdi-update',
      label: i18n.t('instances.view_audit')
    }
  }
}

/**
 * Returns the instances list, with the
 * format ready to be rendered in the JList.vue component.
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
const instancesListDTO = (state, getters, rootState, rootGetters) => {
  if (!state.instances) return []
  return state.instances.map(instance => {
    const data = instanceToListDTO(instance)
    return {
      ...data,
      actions: actionsOnInstance(
        state,
        getters,
        rootState,
        rootGetters
      )(instance)
    }
  })
}

/**
 * The actions that the user can perform on the instance list as a whole (i.e. on the group).
 * @param {*} state
 * @param {*} getters
 * @param {*} rootState
 * @param {*} rootGetters
 */
const actionsOnInstanceList = (state, getters, rootState, rootGetters) => {
  const actions = []
  if (rootGetters['user/canCreateInstances']) {
    actions.push(action.CREATE())
  }

  return actions
}

/**
 * Specifies the column names for the segment list view.
 * The values are obtained from the getter.js and responseMapper.js, as well as the internationalization18
 * (i18) values.
 */
const instanceToListDTO = instance => {
  return {
    id: instance.id,
    name: instance.name,
    segment: instance.dataSourceName,
    target: formatTarget(instance.target),
    status: instance.isActive
      ? {
          label: i18n.t('instances.active'),
          color: _colors.info,
          type: 'JChip'
        }
      : {
          label: i18n.t('instances.inactive'),
          color: _colors.neutral,
          type: 'JChip'
        },
    tags: {
      label: i18n.t('instances.fields.categorization'),
      color: _colors.neutral,
      type: 'JChipList',
      value: formatTagsList(instance.tags)
    },
    updated_at: formatDate(instance.updatedDate),
    user_updated_by: instance.updatedBy,
    canExpand: instance.tags.some(t => t.text === TAG_KEYS.SYNC_AIRSHIP),
    endDate: instance.endDate,
    syncIcons: { ...getSyncIcons(instance) }
  }
}

function getSyncIcons(instance) {
  const syncIcons = {}
  if (hasAirshipTag(instance.tags) && instance.syncStatus) {
    const labelColor = processStatus(instance.syncStatus)
    syncIcons.airship = {
      code: 'edgesensor_high',
      label: `Sync Airship status: ${labelColor.label}`,
      color: labelColor.color
    }
  }
  if (
    hasSyncMallIncentiveTag(instance.tags) &&
    instance.syncMall &&
    instance.syncMall.status
  ) {
    const labelColor = processStatus(instance.syncMall.status)
    syncIcons.mall = {
      code: 'monetization_on',
      label: `Sync Incentive status: ${labelColor.label}`,
      color: labelColor.color
    }
  }
  return syncIcons
}

const processStatus = status => {
  if (!status) return {}
  let label = i18n.t('instances.sync_status.expired')
  let color = syncColors.expired
  if (status === SYNC_STATUS.PROCESSING) {
    label = i18n.t('instances.sync_status.processing')
    color = syncColors.processing
  }
  if (status === SYNC_STATUS.ACTIVE) {
    label = i18n.t('instances.sync_status.active')
    color = syncColors.active
  }
  return { label, color }
}

const syncColors = {
  active: '#16A01C',
  processing: '#FFC300',
  expired: '#8e8e93'
}

function formatTarget(target) {
  return target && target !== ''
    ? getCountryName(target)
    : i18n.t('instances.fields.no_country')
}

function formatTagsList(tags) {
  if (tags.length === 0) return []
  const clone = [...tags]
  let list = [clone.pop().text]
  if (clone.length > 0) {
    list.push(`+${clone.length}`)
  }

  return list
}

function hasAirshipTag(tags) {
  return tags.some(t => t.text === TAG_KEYS.SYNC_AIRSHIP)
}

function hasSyncMallIncentiveTag(tags) {
  return tags.some(
    t => t.text === TAG_KEYS.SYNC_MALL || t.text === TAG_KEYS.INCENTIVE
  )
}

/**
 * Responsible for declaring the actions that can be performed on a specific segment instance.
 */
const actionsOnInstance = (state, getters, rootState, rootGetters) => {
  return function(instance) {
    let actions = []

    if (!instance) return actions

    /**
     * Specifies that the current user can perform the below actions.
     * action: the action that the user can perform
     * permission: path to the 'getter' that confirms the permission.
     */
    const editAction = {
      action: action.EDIT(instance),
      permission: 'user/canEditInstance'
    }
    const viewAction = {
      action: action.VIEW(instance),
      permission: 'user/canSeeInstance'
    }

    const actionGroup = instance.sculptorSync
      ? [viewAction]
      : [editAction, viewAction]
    let potentialActions = [
      {
        actionGroup
      },
      {
        label: i18n.t('campaigns.actions.more'),
        icon: 'more_vert',
        menu: {
          list: getMoreActions(instance),
          date: {
            display: false
          }
        }
      }
    ]
    actions = filterActionsListByPermission(
      rootGetters,
      instance.updatedBy,
      potentialActions
    )
    return actions
  }
}

const getMoreActions = instance => {
  const moreList = [
    {
      action: action.DOWNLOAD(instance),
      permission: 'user/canDownloadInstance'
    },
    {
      action: action.CLONE(instance),
      permission: 'user/canCloneInstance'
    }
  ]
  if (
    hasAirshipTag(instance.tags) &&
    instance.syncStatus === SYNC_STATUS.ACTIVE
  ) {
    moreList.push({
      action: action.SYNC(instance),
      permission: 'user/canSyncSegmentOnDemand'
    })
  }

  moreList.push(
    {
      action: action.DELETE(instance),
      permission: 'user/canDeleteInstance'
    },
    {
      action: action.AUDIT(instance),
      permission: 'user/canSeeInstance'
    }
  )
  return moreList
}

export default {
  instancesListDTO,
  actionsOnInstanceList,
  actionsOnInstance
}
